import React, { useState } from "react";
import styles from "./RegisteredUsersCategory.module.scss";
import {
  setGoBackToCategoryList,
  setPreviousLocation,
  openSuscriptionModal,
  filterUsers,
} from "../../store/actions";
import usePremiumCheck from "../../hooks/usePremiumCheck";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/svg/arrowRightIcon.svg";
import { ReactComponent as LeftArrow } from "../../assets/svg/arrowLeftIcon.svg";
import RegisteredUserItem from "./RegisteredUserItem";
import EmptyState from "../UI/EmptyState/EmptyState";
import { ReactComponent as NoResultsIcon } from "../../assets/svg/NoResultsMagnifier.svg";
import { fireAnalytics } from "../../utils/firebase";

const RegisteredUsersCategory = ({
  filterUsers,
  selectedSkill,
  setGoBackToCategoryList,
  openSuscriptionModal,
  isUserPremium,
  usersBySkills,
  selectedSkillsList,
  selectedJurisdictions,
  allCountrySelected,
  itemCount,
  setPreviousLocation,
  currentPage,
  initialState,
  userData,
  ...props
}) => {
  const [savedSelectedSkillList, setSavedSelectedSkillList] = useState([]);
  const premiumCheck = usePremiumCheck();
  const navigate = useNavigate();
  const location = useLocation();
  const calculatedTotalPages = Math.ceil(itemCount / 12);

  // Generamos el rango de páginas a mostrar
  const generatePageRange = (totalPages) => {
    const pages = [];
    const maxPagesToShow = 10;
    const minRangeToShow = 10;
    const initialPage = 1;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= minRangeToShow) {
        for (let i = 1; i <= maxPagesToShow; i++) {
          pages.push(i);
        }
      } else if (currentPage >= totalPages - minRangeToShow) {
        for (let i = totalPages - maxPagesToShow + 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        for (let i = currentPage - 5; i <= currentPage + 5; i++) {
          pages.push(i);
        }
      }
      if (pages[pages.length - 1] < totalPages) {
        pages.push("...");
        pages.push(totalPages);
      }
      if (pages[0] > initialPage) {
        pages.unshift("...");
        pages.unshift(initialPage);
      }
    }

    return pages.map((page, index) => {
      if (page === "...") {
        return (
          <span key={`dots-${page}`} className={styles.paginationDots}>
            {page}
          </span>
        );
      }
      return (
        <div key={`page-${page}`} style={{ display: "flex" }}>
          <button
            className={
              page == currentPage ? styles.currentPage : styles.pageNumber
            }
            onClick={() => {
              handlePagination(selectedSkill, selectedJurisdictions, allCountrySelected, page);
            }}
          >
            {page}
          </button>
          {page < totalPages ? (
            <span className={styles.pageSeparator}>&nbsp;&nbsp;</span>
          ) : null}
        </div>
      );
    });
  };

  // Manejamos la paginación
  const handlePagination = (selectedSkill, selectedJurisdictions, allCountrySelected, page) => {
    filterUsers(selectedSkill, selectedJurisdictions, allCountrySelected, page);
  };

  // Redireccionamos a la pagina del usuario
  const handleRedirect = (userId, userName) => {
    if (isUserPremium) {
      setPreviousLocation(location.pathname);
      setSavedSelectedSkillList(selectedSkill);
      setGoBackToCategoryList(false);
      navigate(`/profesionales/${userId}/${userName}`);
    } else {
      fireAnalytics("usuario_sin_plan", { no_plan_user_info: userData.id});
      openSuscriptionModal();
    }
  };

  // Ordenar usuarios según si tienen portafolio
  const sortedUsers = Array.isArray(usersBySkills)
    ? usersBySkills.slice().sort((a, b) => {
      const hasPortfolioA = a.user?.portfolio && a.user?.portfolio.length > 0;
      const hasPortfolioB = b.user?.portfolio && b.user?.portfolio.length > 0;

      if (hasPortfolioA && !hasPortfolioB) {
        return -1;
      } else if (!hasPortfolioA && hasPortfolioB) {
        return 1;
      } else {
        return 0;
      }
    })
    : [];

  return (
    <div className={styles.container}>
      <div className={styles.AllProjects}>
        {premiumCheck ? (
          <>
            <header className={styles.header}>
              <div className={styles.searchResults}>
                <div className={styles.searchResults_container}>
                  <p className={styles.searchResults_title}>
                    Encontramos {itemCount}{" "}
                    {itemCount == 1 ? "profesional" : "profesionales"}
                  </p>
                  <p className={styles.searchResults_subtitle}>
                    Página {currentPage} de{" "}
                    {calculatedTotalPages === 0 ? 1 : calculatedTotalPages}
                  </p>
                </div>
                <section className={styles.pagination}>
                  {currentPage >= 1 && (
                    <button
                      key={`prev-${currentPage}`}
                      className={styles.paginationButton}
                      onClick={() => {
                        if (currentPage > 1) {
                          handlePagination(
                            selectedSkill,
                            selectedJurisdictions,
                            allCountrySelected,
                            parseInt(currentPage) - 1
                          );
                        }
                      }}
                    >
                      <LeftArrow width={20} height={20} />
                    </button>
                  )}
                  <div className={styles.paginationNumbers}>
                    {calculatedTotalPages >= 1 &&
                      generatePageRange(calculatedTotalPages, currentPage)}
                  </div>
                  {currentPage <= calculatedTotalPages && (
                    <button
                      key={`next-${currentPage}`}
                      className={styles.paginationButton}
                      onClick={() => {
                        if (currentPage < calculatedTotalPages) {
                          handlePagination(
                            selectedSkill,
                            selectedJurisdictions,
                            allCountrySelected,
                            parseInt(currentPage) + 1
                          );
                        }
                      }}
                    >
                      <ArrowRight width={20} height={20} />
                    </button>
                  )}
                </section>
              </div>
            </header>
            <div
              className={`${styles.usersContainer} ${sortedUsers?.length === 0 &&
                styles.noResultsContainer_container
                } `}
            >
              {Array.isArray(sortedUsers) && sortedUsers?.length > 0 ? (
                sortedUsers.map((user, index) => (
                  <RegisteredUserItem
                    key={index}
                    user={user}
                    initialState={initialState}
                    stars={user.stars}
                    skills={user.skills}
                    portfolio={user.portfolio}
                    handleRedirect={handleRedirect}
                  />
                ))
              ) : (
                <div className={styles.noResultsContainer}>
                  <EmptyState
                    title="No encontramos profesionales con esa especialidad"
                    icon={<NoResultsIcon width={80} height={80} />}
                  />
                </div>
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  usersRegisterData: state.general.usersRegisteredData,
  userData: state.general.userData,
  usersBySkills: state.register.usersBySkill,
  itemCount: state.register.itemCount,
  selectedSkill: state.register.selectedSkill,
  selectedJurisdictions: state.register.selectedJurisdictions,
  currentPage: state.register.page,
  isUserPremium: state.general.isUserPremium,
});

export default connect(mapStateToProps, {
  filterUsers,
  setGoBackToCategoryList,
  setPreviousLocation,
  openSuscriptionModal,
})(RegisteredUsersCategory);
