import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  getSkills,
  sendSkills,
  addSkill,
  clearUserSkills,
  openMainModal,
  closeMainModal,
  removeSkill,
} from "../../store/actions/index";
import { fireAnalytics } from "../../utils/firebase";
import FileUpload from "../FileUpload/FileUpload";
import Button from "../UI/Forms/Button/Button";
import Input from "../UI/Forms/Input/Input";
import styles from "./FormularioPersona.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";
import WorkZoneSelector from "./WorkZoneSelector";
import UserSkillsUpdater from "./UserSkillsUpdater";
import { ReactComponent as ArgentinaMap } from "../../assets/svg/argentinaMap.svg";
import { Search, Check, HardHat, Info, ArrowLeft } from "lucide-react";
import useIsPhone from "../../hooks/useIsPhone";

const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;

  `;

const FormularioPersona = ({
  name,
  lastName,
  getSkills,
  sendSkills,
  addSkill,
  skills,
  userSkills,
  clearUserSkills,
  tel,
  address,
  webSite,
  file,
  removeSkill,
  openMainModal,
  closeMainModal,
}) => {

  const [showSkillsSuggestions, setShowSkillsSuggestions] = useState(false);
  const [showSkillsList, setShowSkillsList] = useState(false);
  const [inputValue, setInputValue] = useState("")
  const [matchedSkills, setMatchedSkills] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const isPhone = useIsPhone();

  const navigate = useNavigate();

  useEffect(() => {
    fireAnalytics("ob_step_2_profesional", { category: "navigation" });
    getSkills();
    clearUserSkills();
  }, []);

  const webSiteRegex = /(^[a-zA-Z_-]{3})([.]{1})([a-zA-Z0-9_.+-]+)([.]{1})([a-zA-Z_-]+)/;
  const telRegex = /[0-9+]/;

  const initialValues = {
    name,
    lastName,
    field: "",
    tel,
    address,
    webSite,
    jobBoardZones: [],
    allCountry: null,
    skills: [],
  }

  const personaSchema = Yup.object().shape({
    name: Yup.string().required("El campo es requerido."),
    lastName: Yup.string().required("El campo es requerido."),
    tel: Yup.string()
      .matches(telRegex, "Introduzca un formato válido")
      .min(7, "Ingrese un telefono válido")
      .max(13, "Ingrese un telefono válido"),
    userSkills: Yup.array().min(1, "Seleccioná al menos 1 rubro").nullable(),
    webSite: Yup.string().matches(webSiteRegex, "Introduzca un formato válido"),
    allCountry: Yup.boolean().nullable(),
  });

  const handleSpecialKey = (event) => {
    const filter = /^[a-zA-Z ]*$/;
    if (!filter.test(event.key)) {
      event.preventDefault();
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: personaSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      sendSkills(values, file, true, userSkills, navigate,);
    },
  });

  useEffect(() => {
    setMatchedSkills(skills);
  }, [skills]);

  const handleInputSearch = (e) => {
    const value = e.target.value;
    const filteredSkills = skills.filter((skill) => {
      return skill.name.toLowerCase().includes(value.toLowerCase());
    });
    setMatchedSkills(filteredSkills);
  };


  const handleAddSkill = (id) => {
    if (!userSkills.find((skill) => skill.id === id)) {
      addSkill(id);
    } else {
      removeSkill(id);
    }
    setShowSkillsSuggestions(false);
    setInputValue("");

  };

  const defaultPersonValues = {
    name: "Usuario",
    lastName: "sin identificar",
    allCountry: true,
  }

  const handleSkipButton = () => {
    const defaultSkillName = "Otros";
    const defaultSkill = matchedSkills.find(skill => skill.name === defaultSkillName);
    const defaultSkills = defaultSkill ? { id: defaultSkill.id, name: defaultSkill.name } : null;

    openMainModal({
      title: "¿Estás seguro?",
      body: "Si omites este paso, no podrás acceder a todas las funcionalidades de Edify.",
      yesLabel: "Omitir",
      noLabel: "Cancelar",
      iconType: "warning",
      confirmHandler: () => {
        addSkill(defaultSkills.id);
        sendSkills(defaultPersonValues, file, true, [defaultSkills], navigate,);
        closeMainModal()
      }
    })
  }

  useEffect(() => {
    if (formik.values.allCountry || formik.values.allCountry === null) {
      formik.setFieldValue("jobBoardZones", []);
    }
  }, [formik.values.allCountry]);

  useEffect(() => {
    if (formik.isValid &&
      userSkills.length > 0 &&
      (formik.values.jobBoardZones.length > 0 || formik.values.allCountry)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formik.isValid, userSkills, formik.values.jobBoardZones, formik.values.allCountry]);

  const handleToggleCheckbox = () => {
    const newValue = !formik.values.allCountry;
    formik.setFieldValue("allCountry", newValue);
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.formContainer}>
      <UserSkillsUpdater userSkills={userSkills} setFieldValue={formik.setFieldValue} />
      <FileUpload userType="person" file={file} name="file" />
      <div className={styles.formGroup_container}>
        <div className={styles.formGroup_container_form}>
          <h4 className={styles.formGroup_container_title}>Datos del usuario</h4>
          <div className={styles.formGroup_inputs}>
            <div className={styles.inputRow}>
              <div className={styles.formGroup}>
                <div className={styles.formGroup_title}>
                  Nombre*
                </div>
                <Input
                  placeholder="Ingrese su nombre"
                  handleKey={handleSpecialKey}
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${formik.errors.name ? "error" : "isValid"}`}
                  style={{ textAlign: "left" }}
                />
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formGroup_title}>
                  Apellido*
                </div>
                <Input
                  placeholder="Ingrese su apellido"
                  handleKey={handleSpecialKey}
                  type="text"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${formik.errors.lastName ? "error" : "isValid"}`}
                  style={{ textAlign: "left" }}
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={styles.formGroup}>
                <div className={styles.formGroup_title}>
                  Página Web
                </div>
                <Input
                  placeholder="https://www.ejemplo.com"
                  type="text"
                  name="webSite"
                  value={formik.values.webSite}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${formik.errors.webSite ? "error" : "isValid"}`}
                  style={{ textAlign: "left" }}
                />
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formGroup_title}>
                  Teléfono
                </div>
                <Input
                  placeholder="(+54) 1234-5678"
                  type="number"
                  name="tel"
                  value={formik.values.tel}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${formik.errors.tel ? "error" : "isValid"}`}
                  style={{ textAlign: "left" }}
                />
                {formik.errors.tel && <div className={styles.formGroup_errorMsg}>{formik.errors.tel}</div>}
              </div>
            </div>
            <div className={styles.formGroup_lastOne}>
              <div className={styles.formGroup_title}>
                Rubros*
              </div>
              <div className={styles.skillsContainer}>
                <button
                  type="button"
                  onClick={() => setShowSkillsList(!showSkillsList)}
                  className={styles.skillsContainer_inputContainer}
                >
                  <span className={styles.skillsContainer_selected}>
                    {userSkills?.length > 0 ? (
                      `${userSkills.length} rubro${userSkills.length > 1 ? "s" : ""} seleccionado${userSkills.length > 1 ? "s" : ""}`
                    ) : (
                      <span className={styles.skillsContainer_selected_emptyState}>Seleccioná al menos un rubro</span>
                    )}
                  </span>
                  <ArrowDownIcon
                    width={15}
                    title="Ver lista completa de rubros"
                    height={15}
                    className={`${styles.arrow} ${showSkillsList ? styles.arrowIconUp : styles.arrowIconDown}`}
                  />
                  {showSkillsList && (
                    <div className={styles.skillsContainer_listContainer}>
                      <div className={styles.skillsContainer_searchInputContainer}>
                        <Search size={16} className={styles.skillsContainer_searchIcon} />
                        <input className={styles.skillsContainer_searchInput}
                          type="text" placeholder="Buscar rubros . . ."
                          onClick={(e) =>
                            e.stopPropagation()
                          }
                          onChange={(e) => {
                            handleInputSearch(e)
                          }}
                        />
                      </div>
                      <div className={styles.skillsContainer_list}>
                        {matchedSkills?.length > 0 ? (
                          matchedSkills.map((skill) => {
                            return (
                              <div
                                className={`${styles.skillsContainer_list_item} ${userSkills?.find(userSkill => userSkill.id === skill.id) && styles.skillsContainer_list_item_selected}`}
                                key={skill.id}
                                onClick={(e) => {
                                  handleAddSkill(skill.id);
                                  e.stopPropagation();
                                }}
                              >
                                {skill.name}
                                {userSkills?.find(userSkill => userSkill.id === skill.id) && (
                                  <span className={styles.skillsContainer_list_item_check}>
                                    <Check size={16} />
                                  </span>
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <div className={styles.skillsContainer_list_item}>No existe ese rubro</div>
                        )}
                      </div>
                    </div>
                  )}
                </button>
                {userSkills?.length > 0 ? (
                  <div className={styles.skillsContainer_selected}>
                    {userSkills?.map((skill) => {
                      return (
                        <div
                          className={styles.skillsContainer_skillSelected}
                          key={skill.id}
                          onClick={() => removeSkill(skill.id)}
                        >
                          <HardHat size={14} />
                          {skill.name}&nbsp;  X
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className={styles.emptyState}>
                    <Info size={16} />
                    <span>Seleccioná al menos un rubro</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.workZoneSelector}>
          <div className={styles.workZoneSelector_title}>
            <h4 className={styles.skillsContainer_title}>Lugares donde trabajas</h4>
            <div
              className={`${styles.checkboxLabel} ${formik.values.allCountry && styles.selected} ${!formik.values.allCountry && styles.notSelected}`}
              onClick={handleToggleCheckbox}
            >
              <ArgentinaMap className={styles.map} width={25} height={20} />
              <input
                name="allCountry"
                type="checkbox"
                className={styles.checkBox}
                onChange={() => { }}
                checked={formik.values.allCountry === true}
                style={{ display: 'none' }}
              />
              Vendo productos y/o servicios en todo el país
            </div>
          </div>
          <WorkZoneSelector formik={formik} values={formik.values} setFieldValue={formik.setFieldValue} />
        </div>
      </div>
      <ButtonContainer >
        <Button
          colored="white"
          typebtn="link"
          to="/select-type"
          style={{ width: "fit-content", gap: "10px", padding: "0.8rem 2.4rem" }}
        >
          <ArrowLeft size={16} />
          Volver
        </Button>

        <button
          className={styles.btn_skip}
          type="button"
          onClick={() => handleSkipButton()}
        >
          {isPhone ? "Omitir" : "Omitir paso"}
        </button>
        <button
          className={styles.btn}
          disabled={!isFormValid}
          type="submit"
          onClick={() => {
            fireAnalytics("ob_step_2_profesional_success", {
              category: "onboarding",
            });
          }}
        >
          Siguiente
        </button>

      </ButtonContainer>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    name: state.register.personaForm.name,
    lastName: state.register.personaForm.lastName,
    profession: state.register.personaForm.profession,
    tel: state.register.personaForm.tel,
    address: state.register.personaForm.address,
    webSite: state.register.personaForm.webSite,
    file: state.register.file,
    skills: state.register.skills,
    userSkills: state.register.userSkills,
  };
};

export default connect(mapStateToProps, {
  getSkills,
  sendSkills,
  addSkill,
  clearUserSkills,
  openMainModal,
  closeMainModal,
  removeSkill,
})(FormularioPersona);

