import React, { useEffect, useState, useRef } from "react";
import styles from "./MercadoDeTrabajo.module.scss";
import { connect } from "react-redux";
import { openTutorialModal, getAllWorkBagUsers, openWorkOfferModal } from "../../store/actions";
import WorkersMaps from "../../components/UI/Maps/WorkersMap/WorkersMaps"
import { ReactComponent as NoProfileIcon } from "../../assets/svg/noProfileIcon.svg";
import { ReactComponent as PlusIcon } from "../../assets/svg/plusIcon.svg";
import FilterWorkBag from "../../components/Filters/FilterWorkBag/FilterWorkBag.js";
import OfertaDeTrabajo from "./OfertaDeTrabajo";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./MercadoDeTrabajo.css"
import ListedWorkers from "./ListedWorkers/ListedWorkers";
import { downloadFile } from "../../utils/fileUtils.js";
import useGetTodayDate from "../../hooks/useGetTodayDate";

const MercadoDeTrabajo = ({
    getAllWorkBagUsers,
    workBagUsers,
    openWorkOfferModal,
    workOfferModal,
    setShowWorkBagComponent,
    isPhone
}) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [filterMenu, setFilterMenu] = useState("");
    const [isFiltered, setIsFiltered] = useState(false);
    const [zoom, setZoom] = useState(5);
    const [center, setCenter] = useState([-31.417, -64.183]);
    const [loading, setLoading] = useState(true);
    const [coordinatesArray, setCoordinatesArray] = useState([]);
    const [selectedOptionJurisdictions, setSelectedOptionJurisdictions] = useState([]);
    const [selectedOptionSkills, setSelectedOptionSkills] = useState([]);
    const [bounds, setBounds] = useState(null);
    const [activeView, setActiveView] = useState("map");
    const [showSmallMap, setShowSmallMap] = useState(false);
    const [expanded, setExpanded] = useState(true);

    const mapContainerRef = useRef(null);
    const initialZoom = 5;
    const initialCenter = [-31.417, -64.183];


    useEffect(() => {
        getAllWorkBagUsers();
        window.scrollTo(0, 0);
    
    }, []);

    const handleCloseInfo = () => {
        setZoom(3);
        setCenter([-38.416097, -63.616672]);
        setIsFiltered(false);
        setSelectedUser(null);
        setSelectedOptionJurisdictions([]);
        setSelectedOptionSkills([]);
        setShowSmallMap(false);
    }

    const showNoMatchesToast = () => {
        toast.error(
            <div>
                <h2 className={styles.selectedUser_title}>¡UPS! No hay coincidencias</h2>
                <div className={styles.selectedUser_emptyList}>
                    <NoProfileIcon width={40} height={40} />
                    <p className={styles.selectedUser_emptyList_text}>No hay trabajadores que cumplan con los criterios de búsqueda</p>
                </div>
                <div className={styles.mapContainer_jobOffer_buttonContainer}>
                    <button
                        className={styles.mapContainer_jobOffer_button}
                        onClick={() => {
                            handleCreateJobOffer();
                            toast.dismiss();
                        }}>
                        <PlusIcon width={20} height={20} />
                        Crear oferta de trabajo
                    </button>
                </div>
            </div>,
            {
                autoClose: false,
                closeButton: (
                    <button onClick={() => { toast.dismiss(); handleCloseInfo() }} className={styles.selectedUser_title_closeButton}>X</button>
                )
            }
        );
    };

    useEffect(() => {
        if (isFiltered && workBagUsers.length === 0) {
            showNoMatchesToast();
        } else {
            toast.dismiss();
        }
    }, [isFiltered, workBagUsers.length]);

    const handleCreateJobOffer = () => {
        openWorkOfferModal();
    }

    const todayDate = useGetTodayDate();

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    };

    const formattedTodayDate = parseDate(todayDate);

    const formatEmploymentEndDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const handleDownload = (format) => {
        const headers = [
            'Nombre Completo',
            'Teléfono',
            'Email',
            'Lugar',
            'Rubro',
            'Libreta de Desempleo',
            'Disponibilidad'
        ];

        const data = workBagUsers.map(worker => ({
            'Nombre Completo': `"${(worker.name || '')} ${(worker.last_name || '')}"`,
            'Teléfono': `"${(worker.phone || '')}"`,
            'Email': `"${(worker.email || '')}"`,
            'Lugar': `"${(worker?.jobBoardZones ?? []).map(zone => zone.city.replace(", Argentina", "").trim()).join(", ")}"`,
            'Rubro': `"${(worker?.skill ?? []).map(skill => skill?.skill?.name.replace(/,/g, ' ').trim()).join(", ")}"`,
            'Libreta de Desempleo': worker?.unemployment_fund ? "Sí" : "No",
            'Disponibilidad': new Date(worker?.employment_end_date) < formattedTodayDate
                ? "Inmediata"
                : `A partir del ${formatEmploymentEndDate(worker?.employment_end_date)}`
        }));

        downloadFile([headers, ...data.map(worker => Object.values(worker))], 'workers_data', format);
    };

    return (
        <div className={styles.componentContainer} >
            <section className={styles.filterContainer}>
                <FilterWorkBag
                    workBagUsers={workBagUsers}
                    setIsFiltered={setIsFiltered}
                    setZoom={setZoom}
                    setCenter={setCenter}
                    setCoordinatesArray={setCoordinatesArray}
                    setFilterMenu={setFilterMenu}
                    setLoading={setLoading}
                    loading={loading}
                    coordinatesArray={coordinatesArray}
                    zoom={zoom}
                    center={center}
                    isPhone={isPhone}
                    setSelectedUser={setSelectedUser}
                    selectedOptionJurisdictions={selectedOptionJurisdictions}
                    setSelectedOptionJurisdictions={setSelectedOptionJurisdictions}
                    selectedOptionSkills={selectedOptionSkills}
                    setActiveView={setActiveView}
                    activeView={activeView}
                    setSelectedOptionSkills={setSelectedOptionSkills}
                    setShowSmallMap={setShowSmallMap}
                    onDownload={handleDownload}
                    setShowWorkBagComponent={setShowWorkBagComponent}
                />
            </section>
            {activeView === "map" && !isPhone ? (
                <div className={styles.listContainer}>
                    <section className={styles.mapContainer} ref={mapContainerRef}>
                        <WorkersMaps
                            ciudadesArray={workBagUsers}
                            setSelectedUser={setSelectedUser}
                            setIsFiltered={setIsFiltered}
                            isFiltered={isFiltered}
                            setZoom={setZoom}
                            setCenter={setCenter}
                            setCoordinatesArray={setCoordinatesArray}
                            setLoading={setLoading}
                            loading={loading}
                            coordinatesArray={coordinatesArray}
                            bounds={bounds}
                            setBounds={setBounds}
                            setShowSmallMap={setShowSmallMap}
                            showSmallMap={showSmallMap}
                            zoom={zoom}
                            center={center}
                            initialCenter={initialCenter}
                            initialZoom={initialZoom}
                            setExpanded={setExpanded}
                        />
                    </section>
                </div>
            ) :
                <ListedWorkers
                    workers={workBagUsers}
                    setSelectedUser={setSelectedUser}
                    setIsFiltered={setIsFiltered}
                    loading={loading}
                />
            }
            <ToastContainer
                position="bottom-right" />
            {workOfferModal && (
                <OfertaDeTrabajo />
            )}
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        workBagUsers: state.register.workBagUsers,
        workOfferModal: state.general.workOfferModal,
    };
}

export default connect(mapStateToProps, { openTutorialModal, getAllWorkBagUsers, openWorkOfferModal })(MercadoDeTrabajo);
