import React from "react";
import styles from "./ProjectDetails.module.scss";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../../../assets/images/fondoObraPrivada.jpg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg"
import { ReactComponent as ClockIcon } from "../../../assets/svg/clockIcon.svg";
import { connect } from "react-redux"

const ProjectDetails = ({ project }) => {

    const navigate = useNavigate();

    const coverImage = project?.proyecto_images[0]?.image || DefaultImage;

    const handleRedirectToProject = () => {
        navigate(`/obrasprivadas/${project.id}`);
    };

    // Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const options = { day: 'numeric', month: 'short' };
        return utcDate.toLocaleDateString('es-ES', options);
    };

    return (
        <div className={styles.container}>
            <div className={styles.container_image}>
                <img className={styles.container_image_img} src={coverImage} alt="" />
                <p className={styles.container_info_location}>{project?.zone}</p>
                <p className={styles.container_info_dates}>
                    {formatDate(project?.startDate)} - {formatDate(project?.endDate)}
                </p>
            </div>
            <div className={styles.container_info}>
                <div className={styles.container_info_text}>
                    <div className={styles.container_info_text_pill} />
                    <h4 className={styles.container_info_text_title}>{project?.name}</h4>
                    <div className={styles.container_info_footer_clock}>
                        <ClockIcon width={15} height={15} />
                        <relative-time lang="es" tense="past" datetime={project?.createdAt}></relative-time>
                    </div>
                    <p className={styles.container_info_text_description}>{project?.description}</p>
                </div>
            </div>
            <button className={styles.container_buttons} onClick={handleRedirectToProject}>
                Ver proyecto
            </button>
        </div>
    )
};

export default connect(null)(ProjectDetails);