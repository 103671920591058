import React, { useState, useEffect } from "react";
import styles from "./VoteReputation.module.scss";
import { connect } from "react-redux";
import { getUserSkill, likeUser } from "../../../../store/actions";
import { Star, X } from "lucide-react";

const VoteReputation = ({ getUserSkill, userSkill, userCalificatedData, likeUser }) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);
 

  useEffect(() => {
    getUserSkill(userCalificatedData?.userData?.id);
  }, []);
  
  const handleCategoryChange = (event) => {
    const value = event.target.value;
    if (selectedSkills.includes(value)) {
      setSelectedSkills(prev => prev.filter(cat => cat !== value));
    } else {
      setSelectedSkills(prev => [...prev, value]);
    }
  };

  const removeSkill = (categoryId) => {
    setSelectedSkills(prev => prev.filter(cat => cat !== categoryId));
  }

  const handleVote = () => {
    const skillIds = selectedSkills.map(skill => parseInt(skill));
    likeUser(parseInt(rating),  comment, parseInt(userCalificatedData?.userData?.id), skillIds);
  };

  const isFormDisabled = selectedSkills.length === 0;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.formGroup}>
            <label>Elegir rubro a calificar (1 o más):</label>
            <select 
              onChange={handleCategoryChange}
              value={selectedSkills[selectedSkills.length - 1] || ''}
            >
              <option value="" disabled>Seleccionar rubro</option>
              {userSkill.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            {selectedSkills.length > 0 && (
              <div className={styles.selectedCategories}>
                {selectedSkills.map(catId => (
                  <span key={catId} className={styles.categoryTag}>
                    {userSkill.find(c => c.id === catId)?.name}
                    <button onClick={() => removeSkill(catId)}>
                      <X size={12} />
                    </button>
                  </span>
                ))}
              </div>
            )}
          </div>

          <div className={styles.formGroup}>
            <label>Escribir un comentario:</label>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Escribe tu comentario aquí..."
              disabled={isFormDisabled}
            />
          </div>

          <div className={styles.formGroup}>
            <label>Calificar:</label>
            <div className={styles.starRating}>
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  onMouseEnter={() => setHoveredRating(star)}
                  onMouseLeave={() => setHoveredRating(0)}
                  onClick={() => setRating(star)}
                  disabled={isFormDisabled}
                >
                  <Star
                    className={`${styles.star} ${
                      star <= (hoveredRating || rating) ? styles.active : ''
                    } ${isFormDisabled ? styles.disabled : ''}`}
                  />
                </button>
              ))}
            </div>
          </div>
        </div>

        <button
          className={styles.submitButton}
          onClick={handleVote}
          disabled={isFormDisabled || !comment || !rating}
        >
          Enviar calificación
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userSkill: state.register.userSkills,
    userCalificatedData: state.general.calificationModal,
  };
}

export default connect(mapStateToProps, { getUserSkill, likeUser })(VoteReputation);