import React from "react";
import styles from "./ProfesionalesRegistradosPagination.module.scss";
import { ArrowLeft, ArrowRight } from "lucide-react";

const ProfesionalesRegistradosPagination = ({ totalUsers, currentPage, setCurrentPage }) => {
    const usuariosPorPagina = 9;
    const totalPages = Math.ceil(totalUsers / usuariosPorPagina); 
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = () => {
        if (totalPages <= 9) {

            return pageNumbers.map((number) => (
                <button
                    key={number}
                    className={
                        currentPage === number
                            ? styles.pageButton_active
                            : styles.pageButton
                    }
                    onClick={() => setCurrentPage(number)}
                >
                    {number}
                </button>
            ));
        } else {
            let visiblePages = [];

            if (currentPage <= 5) {
                visiblePages = [...pageNumbers.slice(0, 7), "...", totalPages];
            } else if (currentPage >= totalPages - 4) {
                visiblePages = [1, "...", ...pageNumbers.slice(totalPages - 7)];
            } else {
                visiblePages = [
                    1,
                    "...",
                    ...pageNumbers.slice(currentPage - 3, currentPage + 2),
                    "...",
                    totalPages,
                ];
            }

            return visiblePages.map((item, index) =>
                item === "..." ? (
                    <span key={index} className={styles.dots}>
                        ...
                    </span>
                ) : (
                    <button
                        key={item}
                        className={
                            currentPage === item
                                ? styles.pageButton_active
                                : styles.pageButton
                        }
                        onClick={() => setCurrentPage(item)}
                    >
                        {item}
                    </button>
                )
            );
        }
    };

    return (
        <div className={styles.pagination}>
            <div className={styles.paginationItems}>
                <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={styles.arrowButton}
                >
                    <ArrowLeft size={20} />
                </button>
                {renderPageNumbers()}
                <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={styles.arrowButton}
                >
                    <ArrowRight size={20} />
                </button>
            </div>
        </div>
    );
};

export default ProfesionalesRegistradosPagination;

