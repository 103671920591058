import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp, getApp } from 'firebase/app';  
import firebaseConfig from '../firebaseConfig';

let app;
let analytics;

try {
  app = getApp();
  analytics = getAnalytics(app); 
} catch (error) {
  app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app); 
}

export { app, analytics };

export const fireAnalytics = (eventName, eventParams) => {
  logEvent(analytics, eventName, eventParams);
};
