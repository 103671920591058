import React, { useEffect, useState } from "react";
import styles from "./MisCotizacionesDeMateriales.module.scss";
import { connect } from "react-redux";
import { getMyQuotations, openSuscriptionModal } from "../../../../store/actions";
import { ReactComponent as MarketPlace } from "../../../../assets/svg/marketplace.svg";
import EmptyState from "../../../UI/EmptyState/EmptyState";
import { motion, AnimatePresence } from "framer-motion";
import PrivateQuotationItem from "../../../MainInfoItem/PrivateQuotationItem/PrivateQuotationItem";
import { useNavigate } from "react-router-dom";

const MisCotizacionesDeMateriales = ({ 
        isUserPremium, 
        getMyQuotations, 
        openSuscriptionModal, 
        cotizaciones, 
        userData,
        profileVisualizerData,
        myPerson, 
        setInfoReady
    }) => {

    const navigate = useNavigate();

    useEffect(() => {
        getMyQuotations(myPerson ? userData.id : profileVisualizerData.id);
    }, []);

    const cotizacionesToMap = cotizaciones.filter(cotizacion => cotizacion.type === "PRIVATE");

    useEffect(() => {
        const MIN_LOADING_TIME = 1000; 
      
        const timer = setTimeout(() => {
            if (cotizacionesToMap && cotizacionesToMap.length >= 0) {
                setInfoReady(true);
            }
        }, MIN_LOADING_TIME);

        return () => clearTimeout(timer);
    }, [cotizacionesToMap, setInfoReady]);

    const handleNewMaterialBudget = () => {
        if (isUserPremium) {
            navigate("/cotizaciondemateriales/crear");
        } else {
            openSuscriptionModal();
        }
    };

    return (
        <AnimatePresence exitBeforeEnter>
            <motion.div
                initial={{ opacity: 0, y: 200 }}
                animate={{ opacity: 1, y: 0 }}
                className={styles.MisCotizaciones}>
                {cotizacionesToMap && cotizacionesToMap.length > 0 ? (
                    <div className={styles.container}>
                        {cotizacionesToMap.map((cotizacion) => (
                            <PrivateQuotationItem
                                key={cotizacion.id}
                                quotation={cotizacion}
                            />
                        ))}
                    </div>
                ) : (
                    myPerson ? (
                    <div className={styles.emptyState} onClick={handleNewMaterialBudget}>
                        <EmptyState
                            title="No tienes cotizaciones de materiales ¡Crea una!"
                            icon={<MarketPlace width={50} height={50} />}
                        />
                    </div>
                    ) : (
                        <EmptyState
                            title="Este usuario no tiene cotizaciones de materiales"
                            icon={<MarketPlace width={50} height={50} />}
                        />
                    )
                )}
            </motion.div>
        </AnimatePresence>
    );
}

const mapStateToProps = (state) => ({
    cotizaciones: state.profile.viewQuotations,
    userData: state.general.userData,
    isUserPremium: state.general.isUserPremium,
});

export default connect(mapStateToProps, { getMyQuotations, openSuscriptionModal })(MisCotizacionesDeMateriales);
