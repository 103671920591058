import React, { useEffect } from "react";
import { motion } from "framer-motion";
import styles from "./Resumen.module.scss";
import { Mail, Phone, Globe, Instagram, Linkedin, Facebook, Twitter, CircleCheck, MapPin } from "lucide-react";
import MisSkills from "../MisSkills/MisSkills";

const Resumen = ({myPerson, userData, profileVisualizerData, perfilAjeno, setInfoReady}) => {

    useEffect(() => {
        const MIN_LOADING_TIME = 1000; 
      
        const timer = setTimeout(() => {
            setInfoReady(true);
        }
        , MIN_LOADING_TIME);

        return () => clearTimeout(timer);
    }, [setInfoReady]);

    const {
        linkedin,
        instagram,
        facebook,
        twitter,
        phone,
        type,
        description,
        jobBoardZones,
        username
      } = myPerson ? userData : profileVisualizerData;


    return (
        <div className={styles.resumen}>
            <div className={styles.resumen_leftSide}>
              <div className={styles.resumen_section}>
                <h4 className={styles.resumen_section_title}>
                  Contacto
                </h4>
                <div className={styles.resumen_section_info}>
                  <div className={styles.resumen_section_info_item}>
                    <Mail size={20} className={styles.resumen_section_info_item_icon} />
                    <p className={styles.resumen_section_info_item_data}>{username}</p>
                  </div>
                  {phone && (
                    <div className={styles.resumen_section_info_item}>
                      <Phone size={20} className={styles.resumen_section_info_item_icon} />
                      <p className={styles.resumen_section_info_item_data}>{phone}</p>
                    </div>
                  )}
                  {instagram && (
                    <div className={styles.resumen_section_info_item}>
                      <Instagram size={20} className={styles.resumen_section_info_item_icon} />
                      <p className={styles.resumen_section_info_item_data}>{instagram}</p>
                    </div>
                  )}
                  {linkedin && (
                    <div className={styles.resumen_section_info_item}>
                      <Linkedin size={20} className={styles.resumen_section_info_item_icon} />
                      <p className={styles.resumen_section_info_item_data}>{linkedin}</p>
                    </div>
                  )}
                  {facebook && (
                    <div className={styles.resumen_section_info_item}>
                      <Facebook size={20} className={styles.resumen_section_info_item_icon} />
                      <p className={styles.resumen_section_info_item_data}>{facebook}</p>
                    </div>
                  )}
                  {twitter && (
                    <div className={styles.resumen_section_info_item}>
                      <Twitter size={20} className={styles.resumen_section_info_item_icon} />
                      <p className={styles.resumen_section_info_item_data}>{twitter}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.resumen_rightSide}>
              <div className={styles.resumen_section}>
                <h4 className={styles.resumen_section_title}>
                  Descripción {type === "COMPANY" ? "de la empresa" : "profesional"}
                </h4>
                <p className={styles.resumen_section_text}>
                  {description === null || description === "" ? "No especificado" : description}
                </p>
              </div>
              <div className={styles.resumen_section}>
                <h4 className={styles.resumen_section_title}>
                  Rubros
                </h4>
                <MisSkills perfilAjeno={perfilAjeno} />
              </div>
              <div className={styles.resumen_section}>
                <h4 className={styles.resumen_section_title}>
                  Zona de trabajo
                </h4>
                <div className={styles.resumen_section_info}>
                  {jobBoardZones && jobBoardZones.some(zone => zone?.allCountry) ? (
                    <div className={styles.resumen_section_info_allCountry}>
                      <Globe size={20} className={styles.resumen_section_info_item_icon} />
                      <div className={styles.resumen_section_info_allCountry_data}>
                        <p className={styles.resumen_section_info_allCountry_data_title}>Disponibilidad nacional</p>
                        <p className={styles.resumen_section_info_allCountry_data_text}>Servicios/productos disponibles en todo el país</p>
                      </div>
                      <CircleCheck size={20} className={styles.resumen_section_info_allCountry_icon} />
                    </div>
                  ) : (
                    <div className={styles.resumen_section_info_zones}>
                      {jobBoardZones && jobBoardZones.map((zone, index) => (
                        <div key={index} className={styles.resumen_section_info_zones_item}>
                          <MapPin size={20} className={styles.resumen_section_info_item_icon} />
                          <div className={styles.resumen_section_info_item_data}>
                            <p className={styles.resumen_section_info_allCountry_data_title}>{zone?.jurisdiccion?.name}</p>
                            <p className={styles.resumen_section_info_item_allCountry_text}>{zone?.city?.split(',')[0]}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div >
    )

}

export default Resumen;