import React, { useState } from "react";
import styles from "./ProfesionalesSideBar.module.scss";
import {
    HardHat,
    House,
    Pickaxe,
    Bolt,
    Shovel,
    Hammer,
    BrickWall,
    Droplets,
    Anvil,
    Paintbrush,
    Star,
    ArrowDownAZ,
    Grid3X3,
    TrafficCone,
    ChevronLeft,
    ChevronRight,
    ChevronDown,
    X,
    MapPin,
} from "lucide-react";
import { motion } from "framer-motion";

const variants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
};

const heightVariants = {
    hidden: { height: 0 },
    visible: { height: "auto" },

};

const ProfesionalesSideBar = ({
    completeSkillsList,
    selectedSkillsList,
    usersInJurisdiction,
    selectedJurisdictionList,
    handleSelectedSkillIds,
    handleSelectedJurisdictionIds,
    allCountrySelected,
    setShowSideBar }) => {
    const [categoryViewMode, setCategoryViewMode] = useState("");
    const [expandedLetters, setExpandedLetters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const sortedSkills = completeSkillsList.sort((a, b) => b.users - a.users);

    const top10Skills = sortedSkills.slice(0, 10);

    const totalPages = Math.ceil(completeSkillsList.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentSkills = completeSkillsList.slice(startIndex, endIndex);

    const iconMap = {
        "albanileria": HardHat,
        "carpinteria": Hammer,
        "electricista": Pickaxe,
        "plomero": Droplets,
        "excavaciones": Shovel,
        "revoques": BrickWall,
        "cielorrasos": House,
        "estructuras metalicas": Anvil,
        "pintura": Paintbrush,
        "electricidad": Bolt,
    };

    const defaultIcon = TrafficCone;

    const normalizeName = (name) => {
        return name
            .toLowerCase()
            .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .replace(/[^a-z0-9\s]/g, "");
    };

    //Paginacion 
    const renderPagination = () => {
        const maxVisiblePages = 5;
        const pages = [];

        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= maxVisiblePages; i++) {
                    pages.push(i);
                }
                pages.push("...");
                pages.push(totalPages);
            } else if (currentPage >= totalPages - 2) {
                pages.push(1);
                pages.push("...");
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pages.push(i);
                }
            } else {
                pages.push(1);
                pages.push("...");
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pages.push(i);
                }
                pages.push("...");
                pages.push(totalPages);
            }
        }

        return (
            <div className={styles.search_allSkills_pagination}>
                <button
                    className={`${styles.search_allSkills_pagination_button} ${currentPage === 1 && styles.search_allSkills_pagination_button_disabled}`}
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                    disabled={currentPage === 1}
                >
                    <ChevronLeft size={20} />
                </button>
                {pages.map((page, index) => (
                    <button
                        key={index}
                        className={`${styles.search_allSkills_pagination_button} ${page === currentPage && styles.search_allSkills_pagination_button_active
                            }`}
                        onClick={() => {
                            if (typeof page === "number") {
                                setCurrentPage(page);
                            }
                        }}
                        disabled={page === "..."}
                    >
                        {page}
                    </button>
                ))}
                <button
                    className={`${styles.search_allSkills_pagination_button} ${currentPage === totalPages && styles.search_allSkills_pagination_button_disabled}`}
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                    disabled={currentPage === totalPages}
                >
                    <ChevronRight size={20} />
                </button>
            </div>
        );
    };

    const getCategoriesByLetter = (letter) => {
        return completeSkillsList.filter((skill) =>
            skill.name.charAt(0).toUpperCase() === letter
        );
    };

    //Obtenemos las letras que tienen categorias
    const getLettersWithCategories = () => {
        const letters = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
        return letters.filter((letter) =>
            completeSkillsList.some((skill) => skill.name.charAt(0).toUpperCase() === letter)
        );
    };

    const toggleLetterExpansion = (letter) => {
        setExpandedLetters((prev) =>
            prev.includes(letter) ? prev.filter((l) => l !== letter) : [...prev, letter]
        );
    };

    const handleToggleFilter = (filter) => {
        setCategoryViewMode((prev) => (prev === filter ? "" : filter));

    };

    const renderCategoriesByState = () => {
        switch (categoryViewMode) {
            case "topSkills":
                return (
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{ duration: 0.3 }}
                        className={styles.search_topSkills}>
                        {top10Skills.map((skill, index) => {
                            const normalizedSkillName = normalizeName(skill.name);

                            const iconKey = Object.keys(iconMap).find((key) =>
                                normalizedSkillName.includes(key)
                            );

                            const IconComponent = iconKey ? iconMap[iconKey] : defaultIcon;

                            return (
                                <button key={index} className={styles.search_topSkills_item} onClick={() => handleSelectedSkillIds(skill.name)}>
                                    <div className={styles.search_topSkills_item_skill}>
                                        <div className={styles.search_topSkills_item_iconContainer}>
                                            <IconComponent size={20} className={styles.search_topSkills_item_icon} />
                                        </div>
                                        <span className={styles.search_topSkills_item_name}>
                                            {skill.name}
                                        </span>
                                    </div>
                                    <span className={styles.search_topSkills_item_count}>{skill.users}</span>
                                </button>
                            );
                        })}
                    </motion.div>
                );
            case "allCategories":
                return (
                    <div className={styles.search_allSkills}>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: 0.3 }} className={styles.search_allSkills_container}>
                            {currentSkills.map((skill, index) => {
                                const normalizedSkillName = normalizeName(skill.name);
                                const iconKey = Object.keys(iconMap).find((key) =>
                                    normalizedSkillName.includes(key)
                                );
                                const IconComponent = iconKey ? iconMap[iconKey] : defaultIcon;

                                return (
                                    <button
                                        key={index}
                                        className={styles.search_allSkills_item}
                                        title={skill.name}
                                        onClick={() => handleSelectedSkillIds(skill.name)}
                                    >
                                        <div className={styles.search_allSkills_item_iconContainer}>
                                            <IconComponent size={20} className={styles.search_allSkills_item_icon} />
                                        </div>
                                        <span className={styles.search_allSkills_item_name}>
                                            {skill.name}
                                        </span>
                                    </button>
                                );
                            })}
                        </motion.div>
                        {renderPagination()}
                    </div>
                )
            case "alphabetical":
                const lettersWithCategories = getLettersWithCategories();

                return (
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{ duration: 0.3 }}
                        className={styles.search_alphabeticalSkills}>
                        <div className={styles.search_alphabeticalSkills_lettersContainer}>
                            {lettersWithCategories.map((letter, index) => (
                                <button
                                    key={index}
                                    className={`${styles.search_alphabeticalSkills_letter} ${expandedLetters.includes(letter) && styles.search_alphabeticalSkills_letter_active
                                        }`}
                                    onClick={() => toggleLetterExpansion(letter)}
                                >
                                    {letter}
                                </button>
                            ))}
                        </div>
                        <div className={styles.search_alphabeticalSkills_categoriesContainer}>
                            {lettersWithCategories.map((letter) => (
                                <div key={letter} className={styles.search_alphabeticalSkills_categoriesGroup}>
                                    <button
                                        className={styles.search_alphabeticalSkills_categoriesHeader}
                                        onClick={() => toggleLetterExpansion(letter)}
                                    >
                                        <span className={styles.search_alphabeticalSkills_categoriesHeader_letter}>{letter}</span>
                                        <ChevronDown
                                            size={16}
                                            className={`${styles.search_alphabeticalSkills_chevron} ${expandedLetters.includes(letter) && styles.search_alphabeticalSkills_chevron_expanded
                                                }`}
                                        />
                                        <div className={styles.search_alphabeticalSkills_categoriesDivider} />
                                        <span className={styles.search_alphabeticalSkills_categoriesCount}>
                                            {getCategoriesByLetter(letter).length}
                                        </span>
                                    </button>
                                    {expandedLetters.includes(letter) && (
                                        <motion.div
                                            initial="hidden"
                                            animate="visible"
                                            variants={heightVariants}
                                            className={`${styles.search_allSkills_container} ${expandedLetters.includes(letter) && styles.search_allSkills_container_expanded}`}>
                                            {getCategoriesByLetter(letter).map((skill, index) => {
                                                const normalizedSkillName = normalizeName(skill.name);
                                                const iconKey = Object.keys(iconMap).find((key) =>
                                                    normalizedSkillName.includes(key)
                                                );
                                                const IconComponent = iconKey ? iconMap[iconKey] : defaultIcon;

                                                return (
                                                    <button
                                                        key={index}
                                                        title={skill.name}
                                                        className={styles.search_alphabeticalSkills_categoryItem}
                                                        onClick={() => handleSelectedSkillIds(skill.name)}
                                                    >
                                                        <div className={styles.search_alphabeticalSkills_categoryItem_iconContainer}>
                                                            <IconComponent size={20} className={styles.search_alphabeticalSkills_categoryItem_icon} />
                                                        </div>
                                                        <span className={styles.search_alphabeticalSkills_categoryItem_name}>
                                                            {skill.name}
                                                        </span>
                                                    </button>
                                                );
                                            })}
                                        </motion.div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </motion.div>
                );
            case "location":
                const countryJurisdiction = usersInJurisdiction.find(jurisdiction => jurisdiction.allCountryCount);
                return (
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{ duration: 0.3 }}
                        className={styles.search_topSkills}>
                        <button className={styles.search_topSkills_item} onClick={() => handleSelectedJurisdictionIds("", true)}>
                            <div className={styles.search_topSkills_item_skill}>
                                <div className={styles.search_topSkills_item_iconContainer}>
                                    <MapPin size={20} className={styles.search_topSkills_item_icon} />
                                </div>
                                <span className={styles.search_topSkills_item_name}>
                                    {countryJurisdiction ? "Todo el país" : "Ninguna jurisdicción seleccionada"}
                                </span>
                            </div>
                            <span className={styles.search_topSkills_item_count}>
                                {countryJurisdiction ? countryJurisdiction.allCountryCount : 0}
                            </span>
                        </button>
                        {usersInJurisdiction
                            .filter(jurisdiction => jurisdiction.userCount > 0)
                            .map((jurisdiction, index) => {
                                return (
                                    <button key={index} className={styles.search_topSkills_item} onClick={() => handleSelectedJurisdictionIds(jurisdiction?.jurisdiccion_name)}>
                                        <div className={styles.search_topSkills_item_skill}>
                                            <div className={styles.search_topSkills_item_iconContainer}>
                                                <MapPin size={20} className={styles.search_topSkills_item_icon} />
                                            </div>
                                            <span className={styles.search_topSkills_item_name}>
                                                {jurisdiction.jurisdiccion_name}
                                            </span>
                                        </div>
                                        <span className={styles.search_topSkills_item_count}>{jurisdiction.userCount}</span>
                                    </button>
                                );
                            })}
                    </motion.div>
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.search}>
            <div className={styles.search_header}>
                <h3 className={styles.search_title}>Filtros de búsqueda</h3>
                <button className={styles.search_header_buttonClose} onClick={() => setShowSideBar(false)}>
                    <X size={24} />
                </button>
            </div>
            {(selectedSkillsList?.length > 0 || selectedJurisdictionList?.length > 0 || allCountrySelected) && (
                <div className={styles.search_selectedFilters}>
                    {allCountrySelected && (
                        <button className={styles.search_selectedFilters_filter} onClick={() => handleSelectedJurisdictionIds("", true)}>
                            Todo el país
                            <X size={16} className={styles.search_selectedFilters_filter_icon} />
                        </button>
                    )}
                    {[...(selectedSkillsList || []), ...(selectedJurisdictionList || [])].map((filter, index) => (
                        <button key={index} className={styles.search_selectedFilters_filter} onClick={() => {
                            if (selectedSkillsList.includes(filter)) {
                                handleSelectedSkillIds(filter);
                            } else {
                                handleSelectedJurisdictionIds(filter.name);
                            }
                        }
                        }>
                            {filter?.name || filter}
                            <X size={16} className={styles.search_selectedFilters_filter_icon} />
                        </button>
                    ))}
                </div>
            )}
            <div className={styles.search_body}>
                <button onClick={() => handleToggleFilter("topSkills")} className={styles.search_filter} >
                    <h4 className={styles.search_filter_title}>
                        <Star size={16} />
                        Categorías populares
                    </h4>
                    <div className={styles.search_categories_button} >
                        <ChevronRight size={16} className={styles.search_filter_icon} />
                    </div>
                </button>
                {categoryViewMode === "topSkills" && (
                    <div className={styles.search_searchBar}>
                        {renderCategoriesByState()}
                    </div>
                )}
                <button onClick={() => handleToggleFilter("allCategories")} className={styles.search_filter}>
                    <h4 className={styles.search_filter_title}>
                        <Grid3X3 size={16} />
                        Todas las categorías
                    </h4>
                    <div className={styles.search_filter_button} >
                        <ChevronRight size={16} className={styles.search_filter_icon} />
                    </div>
                </button>
                {categoryViewMode === "allCategories" && (
                    <div className={styles.search_searchBar}>
                        {renderCategoriesByState()}
                    </div>
                )}
                <button onClick={() => handleToggleFilter("alphabetical")} className={styles.search_filter}>
                    <h4 className={styles.search_filter_title}>
                        <ArrowDownAZ size={16} />
                        Categorías por letra
                    </h4>
                    <div className={styles.search_categories_button} >
                        <ChevronRight size={16} className={styles.search_filter_icon} />
                    </div>
                </button>
                {categoryViewMode === "alphabetical" && (
                    <div className={styles.search_searchBar}>
                        {renderCategoriesByState()}
                    </div>
                )}
                <button onClick={() => handleToggleFilter("location")} className={styles.search_filter}>
                    <h4 className={styles.search_filter_title}>
                        <MapPin size={16} />
                        Ubicación
                    </h4>
                    <div className={styles.search_filter_button} >
                        <ChevronRight size={16} className={styles.search_filter_icon} />
                    </div>
                </button>
                {categoryViewMode === "location" && (
                    <div className={styles.search_searchBar}>
                        {renderCategoriesByState()}
                    </div>
                )}
            </div>
            <div className={styles.search_footer}>
                <button className={styles.search_footer_button}
                    onClick={() => setShowSideBar(false)}
                    disabled={selectedSkillsList?.length === 0 && selectedJurisdictionList?.length === 0} >
                    Aplicar filtros
                </button>
            </div>
        </div >
    );
};

export default ProfesionalesSideBar;