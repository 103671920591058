import React from "react";
import styles from "./ListedWorkers.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import useGetTodayDate from "../../../hooks/useGetTodayDate";
import MapsLoading from "../../../components/UI/Loading/MapLoading";
import { Mail, Phone, MapPin, Clock, BookOpen } from 'lucide-react';

const ListedWorkers = ({ workers, loading }) => {
    const todayDate = useGetTodayDate();

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    };

    const formattedTodayDate = parseDate(todayDate);

    const formatEmploymentEndDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const handleMail = (email) => {
        const isGmail = email.includes("@gmail.com");
        const mailtoLink = isGmail ? `https://mail.google.com/mail/?view=cm&fs=1&to=${email}` : `mailto:${email}`;
        window.open(mailtoLink, "_blank");
    }

    const handlePhone = (phone) => {
        const whatsappLink = `https://wa.me/${phone.replace(/\D/g, '')}`;
        window.open(whatsappLink, "_blank");
    }

    return (
        <AnimatePresence exitBeforeEnter>
            {loading && <MapsLoading />}
            <motion.div className={styles.workersContainer}>
                {workers.map(worker => (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                        key={worker.id}
                        className={styles.workerCard}
                    >
                        <h3 className={styles.workerCard_name}>{worker.name} {worker.last_name}</h3>
                        <div className={styles.workerCard_item}>
                            <Phone size={16} onClick={() => handlePhone(worker.phone)} className={styles.iconButton} />
                            <span>{worker.phone}</span>
                        </div>
                        <div className={styles.workerCard_item}>
                            <Mail size={16} onClick={() => handleMail(worker.email)} className={styles.iconButton} />
                            <span>{worker.email}</span>
                        </div>
                        <div className={styles.workerCard_item}>
                            <MapPin size={16} className={styles.iconButton} />
                            <span>{worker?.jobBoardZones?.map(zone => zone.city.replace(", Argentina", "")).join(", ")}</span>
                        </div>
                        <div className={styles.workerCard_item}>
                            <BookOpen size={16} className={styles.iconButton} />
                            <span>Libreta de Desempleo: {worker?.unemployment_fund ? "Sí" : "No"}</span>
                        </div>
                        <div className={styles.workerCard_item}>
                            <Clock size={16} className={styles.iconButton} />
                            <span>Disponibilidad:
                                {new Date(worker?.employment_end_date) < formattedTodayDate ?
                                    "Inmediata" :
                                    `A partir del ${formatEmploymentEndDate(worker?.employment_end_date)}`
                                }</span>
                        </div>
                        <div className={styles.workerCard_itemNoIcon}>
                            <span className={styles.workerCard_itemNoIcon_title}>Rubro</span>
                            <div className={styles.workerCard_itemNoIcon_skill}>
                            {worker?.skill?.map((skill, index) => (
                                <span key={index} className={styles.workerCard_itemNoIcon_skillName}>
                                    {skill?.skill?.name}
                                </span>
                            ))}
                            </div>
                        </div>
                    </motion.div>
                ))}
            </motion.div>
        </AnimatePresence>
    );
}

export default ListedWorkers;

