import React, { useState, useEffect } from "react";
import styles from "./Profesionales.module.scss";
import { connect } from "react-redux";
import {
  getUsersRegisteredData,
  setGoBackToCategoryList,
  getAllUsersBySkills,
  filterUsers,
  getAllUsersByJurisdiction,
} from "../../store/actions";
import Botonera from "../../components/Botonera/Botonera";
import RegisteredUsersCategory from "../../components/RegisteredUsers/RegisteredUsersCategory";
import RegisteredUserItem from "../../components/RegisteredUsers/RegisteredUserItem";
import NewNav from "../../components/Nav/NewNav";
import styled from "styled-components";
import JurisdictionList from "../../components/UI/JurisdictionList/JurisdictionList";
import { Star } from "lucide-react";
import usePremiumCheck from "../../hooks/usePremiumCheck";
import { motion } from "framer-motion";
import ProfesionalesHeader from "./ProfesionalesHeader";
import ProfesionalesRegistradosPagination from "./ProfesionalesRegistradosPagination";
import ProfesionalesSideBar from "./ProfesionalesSideBar";
import RegisteredUserProfileLoader from "../../components/RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader";


const variants = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0 }
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 5%;
  padding-left: 5%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const Profesionales = ({
  usersRegisterData,
  getAllUsersBySkills,
  getUsersBySkillName,
  getUsersRegisteredData,
  setGoBackToCategoryList,
  completeSkillsList,
  selectedSkill,
  componentLoading,
  getAllUsersByJurisdiction,
  filterUsers,
  usersInJurisdiction,
  ...props }) => {

  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSkillsIds, setSelectedSkillsIds] = useState([]);
  const [selectedJurisdictionIds, setSelectedJurisdictionIds] = useState([]);
  const [initialState, setInitialState] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSideBar, setShowSideBar] = useState(false);
  const [allCountrySelected, setAllCountrySelected] = useState(false);

  const registeredUsersByPage = 9;

  const indexOfFirstUser = (currentPage - 1) * registeredUsersByPage;
  const indexOfLastUser = indexOfFirstUser + registeredUsersByPage;

  const usersRegisterByPages = usersRegisterData.slice(indexOfFirstUser, indexOfLastUser);


  const premiumCheck = usePremiumCheck();

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };


  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    getUsersRegisteredData();
    handleResize();
    getAllUsersBySkills();
    getAllUsersByJurisdiction();
  }, []);

  useEffect(() => {
    if (componentLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [componentLoading]);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleSelectedSkillIds = (skill) => {
    const skillId = completeSkillsList.find((completeSkill) => completeSkill.name === skill)?.id;
    if (selectedSkillsIds.includes(skillId)) {
      setSelectedSkillsIds(selectedSkillsIds.filter((id) => id !== skillId));
    }
    else {
      setSelectedSkillsIds([...selectedSkillsIds, skillId]);
    }
  }

  const handleSelectedJurisdictionIds = (jurisdiction, isAllCountry = false) => {
    if (isAllCountry) {
      setSelectedJurisdictionIds([]);
      setAllCountrySelected((prev) => !prev);
    } else {
      setAllCountrySelected(false);
      const jurisdictionId = JurisdictionList.find(
        (completeJurisdiction) => completeJurisdiction.name === jurisdiction
      )?.id;

      if (selectedJurisdictionIds.includes(jurisdictionId)) {
        setSelectedJurisdictionIds(selectedJurisdictionIds.filter((id) => id !== jurisdictionId));
      } else {
        setSelectedJurisdictionIds([...selectedJurisdictionIds, jurisdictionId]);
      }
    }
  };

  const selectedSkillsList = selectedSkillsIds?.map((id) => completeSkillsList.find((skill) => skill.id === id).name);
  const selectedJurisdictionList = selectedJurisdictionIds?.map((id) => JurisdictionList.find((jurisdiction) => jurisdiction.id === id));

  useEffect(() => {
    if (selectedSkillsIds?.length === 0 && selectedJurisdictionIds?.length === 0) {
      setInitialState(true)
    }
  }
    , [selectedSkillsIds, selectedJurisdictionIds]);

  const handleSearchButton = () => {
    setInitialState(false);
    filterUsers(selectedSkillsIds, selectedJurisdictionIds, allCountrySelected);
  }

  useEffect(() => {
    if (selectedSkillsIds.length > 0 || selectedJurisdictionIds.length > 0 || allCountrySelected) {
      setInitialState(false);
      filterUsers(selectedSkillsIds, selectedJurisdictionIds, allCountrySelected);
    }
  }, [selectedSkillsIds, selectedJurisdictionIds, allCountrySelected]);

  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile &&
        <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      <div className={styles.overflowContainer}>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.5 }}
          className={styles.container}>
          <ProfesionalesHeader
            inputValue={inputValue}
            handleSearchButton={handleSearchButton}
            setInputValue={setInputValue}
            completeSkillsList={completeSkillsList}
            selectedSkillsList={selectedSkillsList}
            selectedJurisdictionList={selectedJurisdictionList}
            setInitialState={setInitialState}
            handleSelectedSkillIds={handleSelectedSkillIds}
            handleSelectedJurisdictionIds={handleSelectedJurisdictionIds}
            setShowSideBar={setShowSideBar}
            showSideBar={showSideBar}
            allCountrySelected={allCountrySelected}
          />
          <div className={styles.allCategoriesContainer}>
            <div className={styles.infoContainer}>
              {initialState ? (
                <div className={styles.initialStateContainer}>
                  <div className={styles.initialStateContainer_content}>
                    <div className={styles.initialStateContainer_content_header}>
                      <div className={styles.initialStateContainer_content_header_icon}>
                        <Star size={24} style={{ color: "rgb(234 179 8)" }} />
                      </div>
                      <div className={styles.initialStateContainer_content_header_title}>
                        <h2 className={styles.initialStateContainer_content_header_title_text}>Perfiles destacados</h2>
                        <p className={styles.initialStateContainer_content_header_subtitle}>Encuentra a los mejores profesionales y empresas de la construcción</p>
                      </div>
                    </div>
                    <div className={styles.initialStateContainer_content_body}>
                      {usersRegisterByPages.map((user) => (
                        <RegisteredUserItem
                          key={user.id}
                          user={user}
                          completedFields={user.completedFields}
                          stars={user.stars}
                          initialState={initialState}
                          portfolio={user.portfolio}
                          profession={user.profession} />
                      ))}
                    </div>
                    <div className={styles.initialStateContainer_content_footer}>
                      <ProfesionalesRegistradosPagination
                        totalUsers={usersRegisterData.length}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                isLoading ? (
                  <div className={styles.loaderContainer}>
                    <RegisteredUserProfileLoader />
                  </div>
                ) : (
                  <RegisteredUsersCategory
                    selectedSkillsList={selectedSkillsIds}
                    allCountrySelected={allCountrySelected}
                    initialState={initialState} />
                )
              )}
            </div>
          </div>
          {showSideBar && (
            <div className={styles.sideBarOverlay}>
              <ProfesionalesSideBar
                selectedSkillsList={selectedSkillsList}
                selectedJurisdictionList={selectedJurisdictionList}
                handleSelectedJurisdictionIds={handleSelectedJurisdictionIds}
                handleSelectedSkillIds={handleSelectedSkillIds}
                setInitialState={setInitialState}
                completeSkillsList={completeSkillsList}
                setShowSideBar={setShowSideBar}
                setSelectedSkillsList={setSelectedSkillsIds}
                usersInJurisdiction={usersInJurisdiction}
                allCountrySelected={allCountrySelected}
              />
            </div>
          )}

        </motion.div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    usersRegisterData: state.general.usersRegisteredData,
    userData: state.general.userData,
    completeSkillsList: state.register.skillsList,
    selectedSkill: state.register.selectedSkill,
    componentLoading: state.general.componentLoading,
    usersInJurisdiction: state.profile.usersInJurisdiction
  }
};

export default connect(mapStateToProps, {
  getUsersRegisteredData,
  getAllUsersBySkills,
  filterUsers,
  setGoBackToCategoryList,
  getAllUsersByJurisdiction,
})(Profesionales);
