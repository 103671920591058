import React from "react";
import styles from "./ResumenSkeleton.module.scss";
import { motion } from "framer-motion";

const variants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
};

const ResumenSkeleton = () => {
    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ duration: 0.5 }}
            className={styles.resumenSkeleton}>
            <div className={styles.resumenSkeleton__header}></div>
            <div className={styles.resumenSkeleton__body}></div>
            <div className={styles.resumenSkeleton__body}></div>
        </motion.div>
    );
}

export default ResumenSkeleton;