import React, { useState } from "react";
import styles from "./PostularmeNDA.module.scss";
import styled from "styled-components";
import { ReactComponent as Postularme } from "../../../assets/svg/quieroPostularme.svg";
import { ReactComponent as Edify } from "../../../assets/svg/edify.svg";
import { ReactComponent as Atencion } from "../../../assets/svg/modalAtencion.svg";
import { connect } from "react-redux";
import {
  accessRequest,
} from "../../../store/actions/";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Trash } from "../../../assets/svg/trashModal.svg";
import { fireAnalytics } from "../../../utils/firebase";
import { Link } from "react-router-dom";
import PDF from "../../../components/Footer/PDF/Terminos-y-condiciones-edify-2021.pdf";
import { motion, AnimatePresence } from "framer-motion";

const variants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1 },
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PostularmeNDA = ({ userId, edifySpinner, currentPage, ...props }) => {
  const [isChecked, checkNDA] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    props.accessRequest(props.proyectoId, navigate, userId, currentPage);
    fireAnalytics("send_nda_approval", { category: "linking" });
    props.closeModal();
  };

  const handleCancel = () => {
    props.closeModal();
  };

  return (
    <Container>
      <div className={styles.Backdrop}>
        <AnimatePresence>
          <motion.div 
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
            transition={{ duration: 0.5 }}
          className={styles.generalContainer}>
          <div className={styles.sectionContainer3}>
            <div className={styles.sectionContainer3_logo}>
              <Edify />
            </div>
            <div className={styles.atention}>
              <Atencion width={50} height={50} />
            </div>

            <div className={styles.headerContainer3}>
              <h1>Atención</h1>
              <h3>
                Para poder descargar documentación del Proyecto deberás
                solicitar permiso para compartir información y firmar el
                siguiente NDA.
              </h3>
            </div>
            <div className={styles.textContainer}>
              <h3>DECLARACIÓN DE CONFIDENCIALIDAD</h3>
              <br />
              <div className={styles.text}>
                Que mediante la suscripción a la presente hago saber que
                podría acceder a la información confidencial relacionada con
                la actividad de las demás personas y usuarios que publicitan
                sus servicios y obras en la presente y en virtud de ello
                declaro:
                <br />
                <br />
                1. Toda la información que obtenga por el acceso a este sitio
                web será considerada confidencial en referencia a los datos
                referidos a la actividad comercial, patrimonial y jurídica de
                los demás usuarios y a todas las empresas vinculados a éstos y
                cualquier otra clase de información de similar naturaleza (la
                “Información Confidencial”), por lo que me obligo a mantener
                dicha Información Confidencial bajo la más estricta esfera de
                reserva y confidencialidad.
                <br />
                <br />
                2. Declaro conocer y aceptar que la Información Confidencial
                que me sea revelada podrá ser evaluada y utilizada única y
                exclusivamente a los fines del cumplir con las obligaciones
                asumidas. En consecuencia, no divulgaré la Información
                Confidencial a terceros, comprometiéndome a utilizar la misma
                únicamente a los fines precedentemente indicados y a no
                utilizarla para su propio beneficio o el de un tercero. La
                información se encontrará sujeta a los mismos términos y
                condiciones que los establecidos en el presente Convenio, a lo
                establecido en la Ley 24.766 (Ley de Confidencialidad).
                <br />
                <br />
                3. Propósito: En relación con ciertas discusiones de negocios
                entre La Parte Divulgante y La Parte Receptora, ambas partes
                pueden requerir que la información tenga carácter
                confidencial, incluyendo, sin limitación, información técnica,
                comercial y/o financiera oral, visual y/o escrita (incluyendo
                formato electrónico) que incluya, entre otras cosas
                (colectivamente, la "Información Confidencial") de los
                funcionarios, directores, empleados, afiliados , y/o agentes
                entre sí con el fin de evaluar ciertas oportunidades de
                negocio mutuo potenciales y posibles transacciones
                relacionadas con el mismo (el "Propósito Autorizado"). La
                información confidencial incluye, sin limitación, la
                existencia de este Acuerdo, informes, análisis, planes,
                propuestas, know-how, fórmulas, composiciones, procesos,
                documentos, diseños, bocetos, fotografías, gráficos, dibujos,
                especificaciones, equipos, muestras, clientes listas e
                información de precios.; en este Acuerdo, "Parte receptora"
                significa la parte que recibe Información Confidencial, y
                "Parte Divulgadora" significa la parte que divulga Información
                Confidencial y sus afiliados. Como condición para ser
                proporcionado con tal Información Confidencial, ambas partes
                acuerdan causar oportunamente a sus Representantes (como se
                define en el presente documento), a tratar la Información
                Confidencial de acuerdo con los términos de este Acuerdo.
                <br />
                <br />
                4. Información Confidencial. La Parte Receptora acepta que el
                uso de la Información Confidencial otorgada por la Parte
                Divulgante solo puede ser usada para el Propósito Autorizado y
                acordado. Las partes pueden divulgar la Información
                Confidencial o parte de esta a sus Directores, funcionarios,
                miembros, gerentes, empleados, contratistas, consultores,
                contadores y asesores legales de las afiliadas (las personas a
                las que dicha divulgación es permisible se denominan
                colectivamente "Representantes") quienes necesitan conocer tal
                Información Confidencial para el Propósito Autorizado y
                acordado, entendiendo que previamente a cada divulgación de
                Información Confidencial, Estos Representantes serán
                informados por la Parte Receptora acerca de su naturaleza
                Confidencial como así deberán informar a cualquier otra
                persona autorizada. Cada Parte acepta y garantiza que todas
                sus afiliadas que tengan acceso por cualquier razón a la
                Información Confidencial de la Parte Divulgante tendrán
                obligaciones legales aplicables por la Parte receptora, con
                alcance proporcional a los establecidos en el presente
                Acuerdo. A los efectos de este Acuerdo, una "afiliada" es
                cualquier persona, corporación, compañía, sociedad u otra
                entidad que directa o indirectamente, a través de uno o más
                intermediarios, controla, está controlada por, o está bajo
                control común con otra persona, corporación , Sociedad, u otra
                entidad. Las partes serán responsables por cualquier
                incumplimiento de este Acuerdo por sus respectivos
                Representantes y sus afiliados. Asimismo, la Parte Receptora
                pondrá en vigor y mantendrá todas las medidas técnicas y
                organizativas para impedir el procesamiento no autorizado o
                ilícito y la pérdida accidental o la destrucción o daño de
                cualquier Información Confidencial (incluyendo medidas para
                asegurar la confiabilidad del personal que tiene acceso a
                ella) Razonable teniendo en cuenta:
                <br />
                <ol type="A">
                  <li>el estado del desarrollo tecnológico;</li>
                  <li>el costo de implementar cualquier medida;</li>
                  <li>
                    los daños que pudieran resultar de un tratamiento no
                    autorizado o ilícito o pérdida accidental, destrucción o
                    daño a la Información Confidencial correspondiente;
                  </li>
                  <li>la naturaleza de la Información Confidencial.</li>
                </ol>
                <br />
                <br />
                5. Notificación de Divulgación. Cada parte notificará
                inmediatamente a la otra cualquier violación real de los
                términos de este Acuerdo, de la cual obtiene conocimiento
                real, y tomará todas las medidas razonables solicitadas por la
                otra parte para prevenir, controlar o remediar dicha
                violación.
                <br />
                <br />
                6. Información no confidencial. El término "Información
                Confidencial" no incluye ninguna información que: <br />
                <ol type="A">
                  <li>
                    Esté generalmente disponible y/o sea conocida por el
                    público (que no sea como resultado de una divulgación
                    directa o indirecta por la Parte Receptora o sus
                    Representantes, en violación de este Acuerdo);
                  </li>
                  <li>
                    Sea o esté disponible para la Parte Receptora en una forma
                    no confidencial de una fuente que no sea la Parte
                    Divulgante o sus Representantes, siempre y cuando dicha
                    fuente no esté vinculada por un acuerdo de
                    confidencialidad con u otra obligación o secreto a la
                    Parte Reveladora o a sus afiliados;
                  </li>
                  <li>
                    Sea o haya sido adquirido o desarrollado
                    independientemente por la Parte Receptora sin violar
                    ningún acuerdo de confidencialidad u obligación de secreto
                    a la Parte Divulgadora o de sus afiliados. Sin embargo,
                    cualquier combinación de características revelada a la
                    Parte Receptora no se considerará dentro de las
                    excepciones anteriores simplemente porque las
                    características individuales están separadamente en el
                    dominio público o en posesión de la Parte Receptora, a
                    menos que la combinación misma y su principio de
                    operación, Solicitud o empleo sean de dominio público o
                    estén en posesión de la Parte Receptora. La Parte
                    receptora tendrá la carga de prueba que la información
                    dada cae dentro de una o más de las categorías de
                    excepciones arriba mencionadas.
                  </li>
                  <li>
                    Sea obligado por ley a ser revelado o legalmente obtenido
                    de un tercero sin restricción alguna y sin incumplimiento
                    de este Acuerdo y cuyo tercero no está violando una
                    obligación de confidencialidad.
                  </li>
                </ol>
                <br />
                <br />
                7. Devolución de Documentos. Una vez que el Contrato haya
                terminado por cualquier causa, la Parte Receptora, deberá
                devolver todas las copias, extractos u otras reproducciones
                total o parcialmente de la Información Confidencial revelada
                por la Parte Divulgante en posesión de la primera, o de sus
                Representantes. Toda la Información Confidencial, sea o no
                devuelta quedará sujeta a este Acuerdo.
                <br />
                <br />
                8. Divulgación Comprometida. En el caso de que la Parte
                Receptora o cualquiera de sus Representantes esté legalmente
                obligado, de conformidad con una citación judicial, demanda
                civil o proceso similar, o de conformidad con la ley aplicable
                para divulgar cualquier Información Confidencial revelada por
                la Parte Divulgadora, deberá, en la medida legalmente
                permitida, proporcionar a ésta última aviso con prontitud de
                dicha solicitud o requisito. La Parte Reveladora podrá, a su
                exclusivo costo y gasto, solicitar una orden de protección
                apropiada u otro remedio. La Parte Receptora acuerda cooperar
                razonablemente con la Parte Reveladora en relación con la
                obtención de una orden de protección u otro remedio apropiado.
                <br />
                <br />
                9. Discusiones Confidenciales. Sin el previo consentimiento
                por escrito de la otra parte, las partes no revelarán, y
                mandarán a sus Representantes a no revelar a ninguna persona
                ni el hecho de que se estén celebrando discusiones o
                negociaciones sobre una posible transacción entre las partes,
                o que cualquiera de las partes haya solicitado o recibido
                información confidencial del otro, o cualquiera de los
                términos, condiciones u otros hechos con respecto a cualquier
                transacción potencial, incluyendo su estado.
                <br />
                <br />
                10. Propiedad. Cada parte acuerda que la Parte Divulgante es y
                seguirá siendo el propietario exclusivo de su Información
                Confidencial y todos los derechos de patentes, derechos de
                autor, secretos comerciales, marcas registradas y otros
                derechos de propiedad intelectual. Nada de lo dispuesto en
                este Acuerdo se interpretará en el sentido de que otorga a la
                Parte Receptora ningún derecho de propiedad ni ninguna
                licencia bajo derechos de propiedad intelectual de la Parte
                Divulgante o afiliada a la misma, ni a otros derechos con
                respecto a la Información Confidencial, con excepción de las
                especificadas en este documento.
                <br />
                <br />
                11. Plazo y Período Confidencial. Las obligaciones de
                confidencialidad, uso limitado y no divulgación de este
                documento son retroactivas al intercambio inicial de
                Información Confidencial entre las partes en relación con el
                Propósito Autorizado y tendrán un alcance de 10 años.
                <br />
                <br />
                12. No hay contrato. También se entiende y se acuerda que a
                menos que y hasta que un acuerdo de transacción definitiva
                haya sido ejecutado y entregado por y entre ambas partes, no
                se considerará que exista ningún contrato o acuerdo que
                establezca una transacción entre las partes, y ninguna de las
                partes será bajo cualquier obligación legal de cualquier clase
                con respecto a tal transacción en virtud de esta o cualquier
                expresión escrita o oral de la misma, excepto, en el caso de
                este Acuerdo, para los asuntos específicamente acordados aquí.
                <br />
                <br />
                13. Divisibilidad. Si alguna cláusula, subsección, párrafo,
                cláusula u oración de este Acuerdo se considera ilegal,
                inválida o no ejecutable, tal evento no afectará la legalidad,
                validez o ejecutabilidad de las partes restantes de este
                Acuerdo como un todo o cualquier parte del mismo.
                <br />
                <br />
                14. Acuerdo completo. Este Acuerdo constituye el acuerdo
                completo entre las partes en relación con el objeto del
                presente y reemplaza cualquier entendimiento verbal o escrito
                previo o contemporáneo relacionado con el objeto de este
                Acuerdo. Este Acuerdo no podrá ser modificado, enmendado,
                rescindido, cancelado o renunciado total o parcialmente,
                excepto mediante un instrumento escrito, firmado por ambas
                partes, que haga referencia específica a este Acuerdo y que
                especifique que este Acuerdo está siendo modificado o
                modificado Alterado.
                <br />
                <br />
                15. No asignación. Ninguna de las partes podrá ceder ninguno
                de sus derechos ni delegar ninguna de sus obligaciones en
                virtud del presente Acuerdo sin el consentimiento previo por
                escrito de la parte no cedente. Cualquier intento de cesión en
                violación de esta disposición será nulo.
                <br />
                <br />
                16. Contrapartes. El presente Acuerdo podrá celebrarse en dos
                o más copias idénticas, cada una de las cuales se considerará
                original y todas las que se consideren conjuntamente se
                considerarán constitutivas del Acuerdo cuando un representante
                debidamente autorizado de cada parte haya firmado una
                contraparte. Las partes acuerdan que la entrega de este
                Acuerdo por fax o archivos de imagen numéricos computarizados,
                como un archivo PDF adjunto a un correo electrónico, tendrá la
                misma fuerza y efecto que la entrega de las firmas originales
                y que cada parte puede utilizar dicho facsímil (O archivo
                numérico de computadora) firmas como evidencia de la ejecución
                y entrega de este Acuerdo por todas las partes en la misma
                medida en que podrían utilizarse las firmas originales. Este
                Acuerdo representa el entendimiento completo y las
                obligaciones de las partes con respecto al tema del Acuerdo y
                reemplaza todos los acuerdos o entendimientos previos
                relativos a la confidencialidad, ya sea escrito u oral.
                <br />
                <br />
                17. Ley aplicable. Este Contrato se regirá e interpretará de
                conformidad con las leyes de la República Argentina,
                excluyendo sus conflictos entre normas aplicables. Todas las
                controversias que surjan de o en relación con el presente
                Acuerdo serán sometidas a los Tribunales Ordinarios de la
                República Argentina. La parte prevaleciente recibirá sus
                costas, incluyendo honorarios razonables de abogado.
                <br />
                <br />
              </div>
              <p className={styles.text}>
                Esta obligación de reserva y confidencialidad seguirá
                envigencia aúndespués del vencimiento del plazo, de la
                rescisión o resolución del contrato o cese o
                interrupcióndelarelaciónlaboral,asumiendo la responsabilidad
                penal,administrativa o civil de los daños y perjuicios que por
                dolo o negligencia pudiera ocasionar la difusión de datos o
                información no publicados.{" "}
              </p>
            </div>

            <div className={styles.btnContainer}>
              <input
                id="termsAndConditions"
                name="termsAndConditions"
                type="checkbox"
                checked={isChecked}
              />
              <label
                htmlFor="termsAndConditions"
                className={`${styles.inputHelper} ${styles.inputHelperCheckbox}`}
                onClick={() => checkNDA(!isChecked)}
              >
                {" "}
              </label>
              <div className={styles.accepted}>
                <span className={styles.acceptedSpan}>
                  Acepto{" "}
                  <Link
                    to={PDF}
                    style={{
                      color: "rgba(0, 0, 0, 0.37)",
                      textDecorationLine: "revert",
                    }}
                    target="_blank"
                    download
                  >
                    Términos y Condiciones
                  </Link>
                </span>
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <button
                className={styles.postularmeButton}
                disabled={!isChecked}
                type="submit"
                onClick={() => {
                  handleClick(props.proyectoId, navigate);
                }}>
                <span className={styles.iconPostularme}>
                  <Postularme />
                </span>{" "}
                ¡Quiero postularme!
              </button>
              <button className={styles.closeButton} onClick={handleCancel}>
                <span className={styles.trash}>
                  <Trash />
                </span>{" "}
                Cancelar
              </button>
            </div>
          </div>
        </motion.div>
        </AnimatePresence >
      </div >
      
    </Container >
  );
};

const mapStateToProps = (state) => {
  return {
    proyectoId: state.dashboard.postulationProjectId,
    userId: state.general.userData.id,
    edifySpinner: state.general.edifySpinner,
    currentPage: state.dashboard.page
  };
};

export default connect(mapStateToProps, {
  accessRequest,
})(PostularmeNDA); 
