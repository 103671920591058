import React, { useEffect } from "react";
import { connect } from "react-redux";
import RegisterSteps from "../RegisterStepsPage";
import PersonaForm from "../../../components/FormularioPersona/FormularioPersona";
import EmpresaForm from "../../../components/FormularioEmpresa/FormularioEmpresa";
import styles from "./RegisterForm.module.scss";
import { AnimatePresence } from "framer-motion";
import { fireAnalytics } from "../../../utils/firebase.js";
import { CHANGE_TYPE } from "../../../store/actions/types";
import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0 },
};

const RegisterForm = ({ type, user, onChangeHandler, ...props }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    fireAnalytics("ob_step_2", { category: "navigation" });
  }, []);

  const actualYear = new Date().getFullYear();

  const form = type === "EMPRESA" ? <EmpresaForm {...props} /> : <PersonaForm {...props} />;

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.5 }}
      className={styles.RegisterFormContainer}>
      <section className={styles.RegisterForm}>
        <div className={styles.RegisterForm_body}>
          <RegisterSteps stepCount={2} progress={"40%"} />
          <h1 className={styles.title}>
            Datos de la cuenta
            <br />
          </h1>
          <AnimatePresence>{form}</AnimatePresence>
          <div className={styles.copyright}>
            Copyright © {actualYear} Edify, All rights reserved. Buenos Aires, Argentina.
          </div>
        </div>
      </section>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.register.type,
    user: state.general.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeHandler: (tipo) => dispatch({ type: CHANGE_TYPE, payload: tipo }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);