import React, { useEffect, useState } from "react";
import styles from "./SkillsEdit.module.scss";
import { connect } from "react-redux";
import { ReactComponent as AllSkillsIcon } from "../../../../assets/svg/allskills.svg";
import { HardHat, Trash2, Pickaxe, Plus, Search } from "lucide-react";
import {
  getSkills,
  addSkill,
  getUserSkill,
  deleteSkillFromProfile,
  openMainModal,
  removeSkill,
  sendSkillsProfile,
} from "../../../../store/actions";


const SkillsEdit = ({
  getSkills,
  userSkills,
  skills,
  addSkill,
  removeSkill,
}) => {

  const [availableSkills, setAvailableSkills] = useState([]);


  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    let availableSkills = skills.filter((skill) => {
      let skillExist = false;
      userSkills.map((userSkill) => {
        return (skillExist = userSkill.id === skill.id);
      });
      return !skillExist;
    });
    setAvailableSkills(availableSkills);
  }, [skills, userSkills]);

 
   const handleInputSearch = (e) => {
    const value = e.target.value;
    const filteredSkills = skills.filter((skill) => {
      return skill.name.toLowerCase().includes(value.toLowerCase());
    });
    setAvailableSkills(filteredSkills);
  };

  return (
    <div className={styles.skillsContainer}>
     <div className={styles.skillsInputContainer}> 
      <Search size={20} className={styles.skillsInput_searchIcon} />
      <input
        type="text"
        placeholder="Buscar rubros . . ."
        className={styles.skillsInput}
        onChange={handleInputSearch}
      />
      </div>
      <div className={styles.userSkill}>
        <div className={styles.userSkill_header}>
          <HardHat size={16} />
          <h3 className={styles.userSkill_header_text}>Mis rubros seleccionados</h3>
        </div>
        <div className={styles.userSkill_selectedSkills}>
          {Array.isArray(userSkills) &&
            userSkills.length > 0 ? (
            userSkills.map((skill, index) => {
              return (
                <div key={index} className={styles.userSkill_selectedSkill} onClick={() => removeSkill(skill.id)}>
                  <div className={styles.userSkill_selectedSkill_skill}>
                    <Pickaxe size={16} />
                    {skill.name}
                  </div>
                  <Trash2
                    size={16}
                    className={styles.deleteIcon}
                  />
                </div>
              );
            })
          ) : (
            <div className={styles.skill}>
              No hay rubros seleccionados
            </div>
          )}
        </div>
      </div>
      <div className={styles.allSkills}>
        <div className={styles.allSkills_header}>
          <div className={styles.allSkills_header_divider}>
            <div className={styles.allSkills_header_divider_line} />
          </div>
          <div className={styles.allSkills_header_textContainer}>
            <h3 className={styles.allSkills_header_text}>Rubros disponibles</h3>
          </div>
        </div>
        <div className={styles.allSkills_skills}>
          {Array.isArray(availableSkills) &&
            availableSkills.map((skill, index) => {
              return (
                <div key={index} className={styles.allSkills_skills_selectedSkill}
                  onClick={() => addSkill(skill.id)}
                >
                  <div className={styles.allSkills_skills_skill}>
                    <Pickaxe size={16} />
                  {skill.name}
                  </div>
                  <Plus size={16} className={styles.addIcon} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    skills: state.register.skills,
  };
};

export default connect(mapStateToProps, {
  getSkills,
  addSkill,
  getUserSkill,
  deleteSkillFromProfile,
  openMainModal,
  removeSkill,
  sendSkillsProfile,

})(SkillsEdit);

