import React from "react";
import styles from "./FinalBanner.module.scss";
import { FileText } from "lucide-react";
import { useNavigate } from "react-router-dom";
import usePremiumCheck from "../../hooks/usePremiumCheck";

const FinalBanner = () => {

    const premiumCheck = usePremiumCheck();

    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate("/obrasprivadas/crear");
    }


    return (
        <div className={styles.finalBanner}>
            <div className={styles.finalBannerContent}>
                <div className={styles.finalBannerInfo}>
                    <h2 className={styles.finalBannerTitle}>¿Listo para comenzar tu proyecto?</h2>
                    <p className={styles.finalBannerDescription}>Publica tu obra hoy mismo y conecta con los mejores profesionales de la construcción en nuestra plataforma.</p>
                    <button className={styles.finalBannerButton} onClick={premiumCheck(handleOnClick)}>
                    <FileText size={32} />
                    Publicar Proyecto Ahora
                    </button>
                </div>
                <div className={styles.finalBannerImage}/>
            </div>
        </div>
    );

}

export default FinalBanner;
