import React, { useEffect } from "react";
import styles from "./MisAccesos.module.scss";
import { connect } from "react-redux";
import { getPostulations } from "../../../../store/actions/index";
import { motion } from "framer-motion";
import EmptyState from "../../../UI/EmptyState/EmptyState";
import { ReactComponent as EmptyProyectIcon } from "../../../../assets/svg/misPostulacionesEmpty.svg";
import PostulationItem from "../../../MainInfoItem/PostulationItem/PostulationItem";

const MisAccesos = ({ getPostulations, postulaciones, myPerson, setInfoReady }) => {

    useEffect(() => {
        getPostulations();
    }, []);

    useEffect(() => {
        const MIN_LOADING_TIME = 1000;

        const timer = setTimeout(() => {
            if (postulaciones && postulaciones.length >= 0) {
                setInfoReady(true);
            }
        }, MIN_LOADING_TIME);

        return () => clearTimeout(timer);
    }, [postulaciones, setInfoReady]);


    return (
        <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.container}
        >
            {postulaciones && postulaciones.length > 0 ? (
                <div className={styles.container_body}>
                    {postulaciones.map((postulacion) => (
                        <PostulationItem
                        key={postulacion.id}
                        postulation={postulacion}
                        isPostulation
                        type="postulations"
                        myPerson={myPerson}
                        coverImage={postulacion.proyecto?.proyecto_images[0]?.image}
                        />
                    ))}
                </div>
            ) : (
                <EmptyState
                    title="No tienes accesos solicitados"
                    icon={<EmptyProyectIcon width={50} height={50} />}
                />
            )}
        </motion.section>
    );
};

const mapStateToProps = (state) => ({
    postulaciones: state.profile.postulations,
});

export default connect(mapStateToProps, { getPostulations })(MisAccesos);