import React, { useEffect, useState } from "react";
import styles from "./LicitacionesPublicas.module.scss";
import { connect } from "react-redux";
import {
  clearProjects,
  filterPublicProjects,
  openFeedTextModal,
} from "../../store/actions";
import FilterPublicProject from "../../components/Filters/FilterPublicProjects/FilterPublicProject";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import Botonera from "../../components/Botonera/Botonera";
import useSuscriptionModalTimeout from "../../hooks/useSuscriptionModalTimeout";
import Pagination from "../../hooks/pagination";
import { ReactComponent as NoResultsIcon } from "../../assets/svg/NoResultsMagnifier.svg";
import NewNav from "../../components/Nav/NewNav";
import styled from "styled-components";
import { fireAnalytics } from "../../utils/firebase";
import RegisteredUserProfileLoader from "../../components/RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 5%;
  padding-left: 5%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`;


const LicitacionesPublicas = ({
  allProjects,
  loading,
  userData,
}) => {
  const pageNumberLimit = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isAdjudicated, setIsAdjudicated] = useState(false);
  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    response: allProjects,
  };
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const onPrevClick = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setCurrentPage((prev) => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage((prev) => prev + 1);
  };

  useSuscriptionModalTimeout();

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
    fireAnalytics("obras_publicas_view", { category: "obras_publicas", obras_publicas_user_info_viewer: userData.id });
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile &&
        <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      <div className={styles.projectsContainer}>
        <FilterPublicProject
          setIsFiltered={setIsFiltered}
          setIsAdjudicated={setIsAdjudicated}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <div className={styles.AllProjects} id="allProjects">
          {loading ? (
            <RegisteredUserProfileLoader/>
          ) : (
            <div className={styles.generalContainer}>
              {allProjects && allProjects.length === 0 ? (
                <div className={styles.emptyState}>
                  <EmptyState title="No hay resultados para su búsqueda." icon={<NoResultsIcon width={80} height={80}/>} />
                </div>
              ) : null}
              <Pagination
                {...paginationAttributes}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
                onPageChange={onPageChange}
                isPublicProject={true}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {

  return {
    allProjects: state.dashboard.viewProjects,
    isFilter: state.dashboard.isFilter,
    page: state.dashboard.page,
    searchParams: state.dashboard.searchParams,
    loading: state.dashboard.loading,
    userData: state.general.userData,
  };
};

export default connect(mapStateToProps, {
  clearProjects,
  filterPublicProjects,
  openFeedTextModal,
})(LicitacionesPublicas);
