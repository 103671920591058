import { useMemo } from 'react';

const useProfileCompletion = (userProfile) => {
  // Define los campos relevantes con sus valores predeterminados y puntos asignados
  const fields = {
    // Campos comunes
    description: { value: userProfile.description, points: 10 },
    phone: { value: userProfile.phone, points: 5 },
    image_profile: { 
      value: userProfile.image_profile, 
      default: "defaultImage.jpg", 
      points: 5 
    },
    stars: { value: userProfile.stars, minValue: 1, points: 10 },
    facebook: { value: userProfile.facebook, points: 5 },
    linkedin: { value: userProfile.linkedin, points: 5 },
    twitter: { value: userProfile.twitter, points: 5 },
    instagram: { value: userProfile.instagram, points: 5 },
    address: { value: userProfile.address, points: 5 },
    url_site_web: { value: userProfile.url_site_web, points: 5 },
    skills: {
      value: userProfile.skills,
      default: "Otros",
      points: 10,
      check: skills => skills.some(skill => skill.skill.name !== "Otros")
    },
    // Evaluación de campos de nombre según `type`
    nameLastName: {
      value: userProfile.name + " " + userProfile.last_name,
      default: "Usuario sin identificar",
      points: userProfile.type === "PERSON" ? 20 : 0,
      check: () => userProfile.type === "PERSON" && userProfile.name !== "Usuario" && userProfile.last_name !== "sin identificar"
    },
    razon_social: {
      value: userProfile.razon_social,
      default: "Empresa sin identificar",
      points: userProfile.type === "COMPANY" ? 20 : 0,
      check: () => userProfile.type === "COMPANY" && userProfile.razon_social !== "Empresa sin identificar"
    }
  };

  // Calcula el porcentaje de completitud del perfil
  const completionPercentage = useMemo(() => {
    let totalPoints = 0;
    let achievedPoints = 0;

    for (const field in fields) {
      const { value, points, default: defaultValue, minValue, check } = fields[field];
      totalPoints += points;

      const isFieldCompleted = 
        (defaultValue && value !== defaultValue) || 
        (minValue && value >= minValue) ||          
        (!defaultValue && !minValue && value) ||     
        (check && check(value));                     

      if (isFieldCompleted) achievedPoints += points;
    }

    return (achievedPoints / totalPoints) * 100;
  }, [userProfile]);

  return completionPercentage;
};

export default useProfileCompletion;