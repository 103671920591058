import React from 'react';
import styles from './Input.module.scss';

const Input = ({ field, handleKey, ...props }) => {
  return (
    <div className={styles.inputContainer}>
      <input 
        {...field} 
        {...props}
        onKeyDown={handleKey} 
        className={styles.input} />
    </div>
  );
};

export default Input;