import React from "react";
import styles from "./AllProjectsItemEmptyCard.module.scss";
import { fireAnalytics } from "../../../utils/firebase";
import {
    resetProjectToEdit,
    } from "../../../store/actions/index";
import { connect } from "react-redux";
import defaultImage from "../../../assets/images/foto-bienvenida.jpg";
import alertWarning from "../../../assets/svg/modalAtencion.svg"
import { useNavigate } from "react-router-dom";

const AllProjectsItemEmptyCard = () => {

    const navigate = useNavigate();

    const handleNewOpenProject = () => {
        fireAnalytics("dsh_create_project", { category: "dashboard" });
        navigate("/obrasprivadas/crear");
        resetProjectToEdit();
      };
    

    return (
        <div className={styles.container}>
         <div className={styles.textContainer}>
            <img src={alertWarning} alt="Imagen 2" className={styles.textContainer_image} />
            <h3 className={styles.title}>No tenemos proyectos privados activos</h3>
            <p className={styles.textContainer_text} onClick={() => handleNewOpenProject()}>¡Haz click y publicá el tuyo ahora!</p>
        </div>   
        <div className={styles.imageContainer}>
            <img src={defaultImage} alt="Imagen 1" className={styles.imageContainer_image} />
        </div>    
        </div>
    );
    }

const mapStateToProps = (state) => {
    return {
       
    };
}

export default connect(mapStateToProps,{
    resetProjectToEdit,
})(AllProjectsItemEmptyCard);