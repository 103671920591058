import React from "react";
import styles from "./PostulationItem.module.scss";
import { useNavigate } from "react-router-dom";
import { MapPin } from "lucide-react";


const PostulationItem = ({ postulation, coverImage }) => {

    const navigate = useNavigate();

    const handleRedirectToProject = () => {
        navigate(`/obrasprivadas/${postulation.proyecto.id}`);
    };


    const translateStatusPostulation = (status) => {
        switch (status) {
            case 'SEND':
                return 'Pendiente';
            case 'ALLOWED':
                return 'Aceptada';
            case 'NEGATED':
                return 'Rechazada';
            default:
                return '';
        }
    }

    const formatDate = (date) => {
        const utcDate = new Date(date);
        const year = utcDate.getUTCFullYear();
        const month = utcDate.getUTCMonth() + 1;
        const day = utcDate.getUTCDate();
        return `${day}/${month}/${year}`;
    };


    return (
        <div className={styles.container}>
            <div className={styles.container_header}>
                <div className={styles.container_header_leftSide}>
                    <h2 className={styles.container_header_title}>Obra: {postulation?.proyecto?.name}</h2>
                    <div className={styles.container_header_location}>
                        <MapPin size={16} />
                        <p> {postulation?.proyecto?.zone}</p>
                    </div>
                </div>
                <div className={styles.container_header_rightSide}>
                    <div className={`${styles.container_header_status}
                        ${postulation?.status === "ALLOWED" ? styles.container_header_status_allowed :
                            postulation?.status === "NEGATED" ? styles.container_header_status_negated :
                                styles.container_header_status_send}`}>
                        <p>{translateStatusPostulation(postulation?.status)}</p>
                    </div>
                </div>
            </div>
            <div className={styles.container_body}>
                <p className={styles.container_body_description}>{postulation?.proyecto?.description}</p>
                <div className={styles.container_footer}>
                    <div className={styles.container_header_dateSent}>
                        <span className={styles.container_header_dateSent_text}>Solicitado:</span>
                        <span className={styles.container_header_dateSent_date}>{formatDate(postulation?.proyecto?.createdAt)}</span>
                    </div>
                    <button className={styles.container_button} onClick={handleRedirectToProject}>
                        <p>Ver detalles</p>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default PostulationItem;
