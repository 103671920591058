import React, { useState, useEffect } from "react";
import styles from "./ProfesionalesHeader.module.scss";
import { Search, Users, UserRoundX, MapPin, Plus, SlidersHorizontal } from "lucide-react";
import JurisdictionList from "../../components/UI/JurisdictionList/JurisdictionList";
import useSectionTracker from "../../hooks/useSectionTracker";
import { motion } from "framer-motion";

const ProfesionalesHeader = ({
    inputValue,
    completeSkillsList,
    selectedSkillsList,
    selectedJurisdictionList,
    handleSelectedSkillIds,
    handleSelectedJurisdictionIds,
    handleSearchButton,
    setShowSideBar,
    showSideBar,
    allCountrySelected,
    setInputValue }) => {

    const [suggestions, setSuggestions] = useState([]);
    const [noResultMessage, setNoResultMessage] = useState(" ");
    const { isVisible: isHeaderVisible, dismiss: dismissHeader } = useSectionTracker('profesionalesHeader', {
        isDismissable: true,
        trackVisits: false,
        defaultVisibility: true
    });

    //Funciones del input 

    const normalizarAcentos = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const handleInputChange = (e) => {
        const search = e.target.value.toLowerCase();
        generateSuggestions(search);
        setInputValue(search);

        if (e.key === "Enter") {
            if (suggestions.length > 0) {
                const matchingSuggestion = suggestions.find(suggestion => normalizarAcentos(suggestion.name.toLowerCase()) === search);

                if (matchingSuggestion) {
                    setInputValue("");
                    setNoResultMessage(" ");
                } else {
                    setSuggestions([]);
                    setNoResultMessage("La búsqueda no coincide con ninguna sugerencia");
                }
            } else {
                if (search !== "") {
                    setNoResultMessage("No se encontraron resultados");
                }
            }
        }
    };

    useEffect(() => {
        if (inputValue.length > 3 && suggestions.length === 0 && selectedSkillsList.length === 0) {
            setNoResultMessage("No se encontraron resultados");
        } else {
            setNoResultMessage(" ");
        }
    }, [suggestions]);

    const generateSuggestions = (search) => {
        if (search === "") {
            setSuggestions([]);
        } else {
            const newSuggestions = completeSkillsList.filter((skill) =>
                normalizarAcentos(skill.name.toLowerCase()).startsWith(search)
            );
            setSuggestions(newSuggestions);
        };
    };

    const handleSuggestionClick = (suggestion) => {
        setInputValue(suggestion);
        handleSelectedSkillIds(suggestion);
        setSuggestions([]);
        setNoResultMessage(" ");
    }

    return (
        <div className={styles.headerContainer}>
            <motion.div
                initial={false}
                animate={{
                    opacity: isHeaderVisible ? 1 : 0,
                    height: isHeaderVisible ? "auto" : 0,
                }}
                className={styles.toolTipWrapper}
                transition={{
                    type: "spring",
                    damping: 20,
                    stiffness: 100
                }}>
                <div className={styles.toolTipContainer}>
                    <div className={styles.toolTipContainer_text}>
                        <h1 className={styles.toolTipContainer_text_header}>Encontrá el profesional perfecto para tu proyecto</h1>
                        <p className={styles.toolTipContainer_text_description}>Conectamos tus necesidades con los mejores profesionales y proveedores de la industria. Filtrá por especialidad, ubicación y encontrá el match perfecto para tu equipo.</p>
                    </div>
                    <button
                        onClick={dismissHeader}
                        className={styles.toolTipContainer_closeButton}
                        aria-label="Cerrar header"
                    >
                        &times;
                    </button>
                </div>
            </motion.div>
            <div className={styles.headerContainer_searchInput}>
                <h2 className={styles.headerContainer_searchInput_title}>Buscar por:</h2>
                <div className={styles.headerContainer_searchInput_container}>
                    <div className={styles.headerContainer_searchInput_containerSkill}>
                        <label className={styles.headerContainer_searchInput_label}>
                            <Search size={18} className={styles.headerContainer_searchInput_icon} />
                            Especialidad</label>
                        <input
                            className={styles.headerContainer_searchInput_input}
                            type="text"
                            placeholder="¿Qué especialidad estás buscando?"
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleInputChange} />

                        {noResultMessage !== " " && (
                            <ul className={styles.suggestions}>
                                <li className={styles.suggestions_item}>
                                    <div className={styles.suggestions_item_container}>
                                        <div className={styles.suggestions_item_icon}>
                                            <UserRoundX size={20} />
                                        </div>
                                        <div className={styles.suggestions_item_name}>
                                            <p className={styles.suggestions_item_name}>
                                                {noResultMessage}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        )}
                        {Array.isArray(suggestions) && suggestions.length > 0 && (
                            <ul className={styles.suggestions}>
                                {suggestions.map((suggestion) => (
                                    <li className={styles.suggestions_item} key={suggestion.name}>
                                        <div className={styles.suggestions_item_container} onClick={() => handleSuggestionClick(suggestion.name)}>
                                            <div className={styles.suggestions_item_icon}>
                                                <Users size={20} />
                                            </div>
                                            <div className={styles.suggestions_item_name}>
                                                <p className={styles.suggestions_item_name}>
                                                    {suggestion.name}
                                                </p>
                                                <p className={styles.suggestions_item_subTitle}>
                                                    {suggestion.users === 1 ?
                                                        `${suggestion.users} profesional disonible` :
                                                        `${suggestion.users} profesionales disponibles`}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className={styles.headerContainer_searchInput_divider} >
                        <Plus size={24} className={styles.headerContainer_searchInput_dividerIcon} />
                    </div>
                    <div className={styles.headerContainer_searchInput_selectContainer}>
                        <label className={styles.headerContainer_searchInput_label}>
                            <MapPin size={18} className={styles.headerContainer_searchInput_selectIcon} />
                            Ubicación</label>
                        <select
                            value={selectedJurisdictionList[0]?.name || ""}
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                if (selectedValue === "allCountry") {
                                    handleSelectedJurisdictionIds("", true);
                                } else {
                                    handleSelectedJurisdictionIds(selectedValue);
                                }
                            }}
                            className={styles.headerContainer_searchInput_select}>
                            <option value="" disabled>Cualquier ubicación</option>
                            <option value="allCountry">Todo el país</option>
                            {JurisdictionList.map((jurisdiction) => (
                                <option key={jurisdiction.id} value={jurisdiction.name}>{jurisdiction.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.headerContainer_searchInput_buttonContainer}>
                        <button
                            className={styles.headerContainer_searchInput_buttonFilters}
                            onClick={() => setShowSideBar(!showSideBar)}
                        >
                            <SlidersHorizontal size={18} />
                        </button>
                        <button className={styles.headerContainer_searchInput_button} onClick={handleSearchButton}>
                            <Users size={20} />
                            <span className={styles.headerContainer_searchInput_button_label}>Buscar</span>
                        </button>

                    </div>
                </div>
            </div>
            {(selectedSkillsList?.length > 0 || selectedJurisdictionList?.length > 0 || allCountrySelected) && (
                <div className={styles.selectedSkillsContainer}>
                    {allCountrySelected && (
                        <div key="allCountry" className={styles.selectedSkillsContainer_skill}>
                            <p className={styles.selectedSkillsContainer_skill_text}>Todo el país</p>
                            <div
                                className={styles.selectedSkillsContainer_skill_close}
                                onClick={() => handleSelectedJurisdictionIds("", true)}
                            >
                                <span className={styles.selectedSkillsContainer_skill_close_text}>x</span>
                            </div>
                        </div>
                    )}
                    {[...(selectedSkillsList || []), ...(selectedJurisdictionList || [])].map((item) => (
                        <div key={item?.name || item} className={styles.selectedSkillsContainer_skill}>
                            <p className={styles.selectedSkillsContainer_skill_text}>{item?.name || item}</p>
                            <div
                                className={styles.selectedSkillsContainer_skill_close}
                                onClick={() => {
                                    if (selectedSkillsList?.includes(item)) {
                                        handleSelectedSkillIds(item);
                                    } else if (selectedJurisdictionList?.includes(item)) {
                                        handleSelectedJurisdictionIds(item.name);
                                    }
                                }}
                            >
                                <span className={styles.selectedSkillsContainer_skill_close_text}>x</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ProfesionalesHeader;

