import { useEffect, useCallback } from 'react';
import useLocalStorage from './useLocalStorage';

const useSectionTracker = (sectionKey, options = {}) => {
  const {
    trackVisits = true,
    isDismissable = false,
    defaultVisibility = true,
    expirationDays = null
  } = options;

  const [lastVisit, setLastVisit] = useLocalStorage(
    `lastVisit_${sectionKey}`,
    null
  );

  const [isVisible, setIsVisible] = useLocalStorage(
    `visibility_${sectionKey}`,
    isDismissable ? defaultVisibility : true
  );

  // Verificar si la última visita ha expirado
  useEffect(() => {
    if (trackVisits && expirationDays && lastVisit) {
      const expirationDate = new Date(lastVisit);
      expirationDate.setDate(expirationDate.getDate() + expirationDays);
      
      if (new Date() > expirationDate) {
        setLastVisit(new Date().toISOString());
      }
    }
  }, [trackVisits, lastVisit, expirationDays, setLastVisit]);


  const updateLastVisit = useCallback(() => {
    const now = new Date().toISOString();
    setLastVisit(now); 
    return now;
  }, [setLastVisit]);

  const dismiss = useCallback(() => {
    if (!isDismissable) return;
    setIsVisible(false);
  }, [isDismissable, setIsVisible]);

  const reset = useCallback(() => {
    if (isDismissable) setIsVisible(true);
    if (trackVisits) setLastVisit(new Date().toISOString());
  }, [isDismissable, trackVisits, setIsVisible, setLastVisit]);

  const countNewItems = useCallback((items, datePath = 'createdAt') => {

    if (!items || !Array.isArray(items)) {
      console.error('Se esperaba un array de items:', items);
      return 0;
    }
  
    const getItemDate = (item) => {
      try {
        if (item[datePath]) {
          return new Date(item[datePath]);
        }

        if (datePath.includes('.')) {
          const dateValue = datePath.split('.').reduce(
            (obj, key) => (obj && obj[key] !== undefined) ? obj[key] : null, 
            item
          );
          if (dateValue) return new Date(dateValue);
        }
  
        const commonDatePaths = [
          'createdAt',
          'date',
          'fechaCreacion',
          'timestamp',
          'data.createdAt',
          'attributes.created_at',
          'fecha_creacion'
        ];
  
        for (const path of commonDatePaths) {
          const value = path.split('.').reduce(
            (obj, key) => (obj && obj[key] !== undefined) ? obj[key] : null, 
            item
          );
          if (value) {
            const date = new Date(value);
            if (!isNaN(date.getTime())) return date;
          }
        }
  
        return null;
      } catch (e) {
        console.error('Error al obtener fecha del item:', item, e);
        return null;
      }
    };
  
    try {
      const lastVisitDate = new Date(lastVisit);
      if (isNaN(lastVisitDate.getTime())) {
        console.error('Fecha de última visita inválida:', lastVisit);
        return 0;
      }
  
      return items.filter(item => {
        if (!item || typeof item !== 'object') return false;
  
        const itemDate = getItemDate(item);
        if (!itemDate || isNaN(itemDate.getTime())) {
          console.warn('Item sin fecha válida:', item);
          return false;
        }
  
        return itemDate > lastVisitDate;
      }).length;
    } catch (error) {
      console.error('Error al contar nuevos items:', error);
      return 0;
    }
  }, [trackVisits, lastVisit]);

  return {
    lastVisit,
    updateLastVisit,
    getLastVisit: () => lastVisit,
    isVisible,
    dismiss,
    reset,
    countNewItems
  };
};

export default useSectionTracker;