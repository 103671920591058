import React from "react";
import styles from "./ProyectoIdHeader.module.scss";
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrowDownIcon.svg";
import { ReactComponent as Check } from "../../../../assets/svg/checkmark.svg";
import { ReactComponent as Close } from "../../../../assets/svg/xClose.svg";
import { ReactComponent as SendPostComment } from "../../../../assets/svg/sendPostComment.svg";
import SubProjectBudgets from "../../SubProjects/SubProjectBudgets";
import { Pencil, Trash2, UserCheck, UserRoundX, ChevronDown } from "lucide-react";

const ProyectoIdHeader = ({
    proyecto,
    showPostulationsMenu,
    showBudgetsMenu,
    isActive,
    handleHeaderButtonsMenu,
    menuRef,
    isOwnProject,
    postulacionesDeProyecto,
    handleAccessRequest,
    showPostulations,
    togglePostulations,
    isPostulationSent,
    isPostulationAllowed,
    isPostulationDenied,
    handlePostulationButton,
    handleEditProject,
    handleDeleteProject
}) => {


    let textToShow = isPostulationAllowed ? "Aceptado" : isPostulationDenied ? "Solicitud rechazada" : isPostulationSent ? "Esperando respuesta" : "Enviar postulación";
    let iconToShow = isPostulationAllowed ? <UserCheck size={20}/> : isPostulationDenied ? <UserRoundX size={20} /> : isPostulationSent ? <SendPostComment height={20} width={20} /> : <SendPostComment height={20} width={20} />;



    return (
    <header className={styles.mainHeader}>
                <div className={styles.mainHeader_buttons}>
                    {isOwnProject &&
                        <div className={`${styles.mainHeader_postulaciones} ${isActive === "postulations" && styles.isActive}`} >
                            <h3 className={styles.mainHeader_postulaciones_title} onClick={(e) => handleHeaderButtonsMenu(e, "postulations")}>
                                POSTULACIONES
                                <ChevronDown size={20} />
                            </h3>
                            {showPostulationsMenu &&
                                <div className={styles.mainHeader_postulaciones_container} ref={menuRef}>
                                    <div className={styles.mainHeader_postulaciones_container_header}>
                                        Recibidas
                                        <div className={styles.mainHeader_postulaciones_container_header_countContainer}>
                                            {postulacionesDeProyecto.filter(postulation => postulation.status === "SEND").length > 0 &&
                                                <span className={styles.mainHeader_postulaciones_container_header_count}>{postulacionesDeProyecto.filter(postulation => postulation.status === "SEND").length}</span>
                                            }
                                            <ArrowIcon
                                                className={showPostulations[0] ? styles.arrowIconDown : styles.arrowIconRight}
                                                height={15}
                                                width={15}
                                                onClick={() => togglePostulations(0)}
                                            />
                                        </div>
                                    </div>
                                    {showPostulations[0] && postulacionesDeProyecto && postulacionesDeProyecto.length > 0 && postulacionesDeProyecto.map((postulation, index) => {
                                        return postulation.status === "SEND" &&
                                            <div className={styles.mainHeader_postulaciones_container_userPostulation} key={index}>
                                                <div className={styles.mainHeader_postulaciones_container_user}>
                                                    <img src={postulation.user.image_profile} alt="Imagen de perfil" className={styles.mainHeader_postulaciones_container_user_image} />
                                                    <p className={styles.mainHeader_postulaciones_container_user_name}>{postulation.user.type === "COMPANY" ?
                                                        postulation.user.razon_social : postulation.user.name + " " + postulation.user.last_name}</p>
                                                </div>
                                                <div className={styles.mainHeader_postulaciones_container_status}>
                                                    <p className={styles.mainHeader_postulaciones_container_status_accept} title="Aceptar">
                                                        <Check height={10} width={10} onClick={() => handleAccessRequest(parseInt(postulation.id), "ALLOWED", "POSTULACION_ACCEPTED")} />
                                                    </p>
                                                    <p className={styles.mainHeader_postulaciones_container_status_deny} title="Rechazar">
                                                        <Close height={10} width={10} onClick={() => handleAccessRequest(parseInt(postulation.id), "NEGATED", "POSTULACION_NEGATED")} />
                                                    </p>
                                                </div>
                                            </div>
                                    }
                                    )}
                                    <div className={styles.mainHeader_postulaciones_container_header}>
                                        Aceptadas
                                        <div className={styles.mainHeader_postulaciones_container_header_countContainer}>
                                            {postulacionesDeProyecto.filter(postulation => postulation.status === "ALLOWED").length > 0 &&
                                                <span className={styles.mainHeader_postulaciones_container_header_count}>{postulacionesDeProyecto.filter(postulation => postulation.status === "ALLOWED").length}</span>
                                            }
                                            <ArrowIcon
                                                className={showPostulations[1] ? styles.arrowIconDown : styles.arrowIconRight}
                                                height={15}
                                                width={15}
                                                onClick={() => togglePostulations(1)}
                                            />
                                        </div>
                                    </div>
                                    {showPostulations[1] && postulacionesDeProyecto && postulacionesDeProyecto.length > 0 && postulacionesDeProyecto.map((postulation, index) => {
                                        return postulation.status === "ALLOWED" &&
                                            <div className={styles.mainHeader_postulaciones_container_userPostulation} key={index}>
                                                <div className={styles.mainHeader_postulaciones_container_user}>
                                                    <img src={postulation.user.image_profile} alt="Imagen de perfil" className={styles.mainHeader_postulaciones_container_user_image} />
                                                    <p className={styles.mainHeader_postulaciones_container_user_name}>{postulation.user.type === "COMPANY" ?
                                                        postulation.user.razon_social : postulation.user.name + " " + postulation.user.last_name}</p>
                                                </div>
                                                <div className={styles.mainHeader_postulaciones_container_status}>
                                                    <p className={styles.mainHeader_postulaciones_container_status_acceptedTxt}>Aceptada</p>
                                                </div>
                                            </div>
                                    }
                                    )}
                                    <div className={styles.mainHeader_postulaciones_container_header}>
                                        Rechazadas
                                        <div className={styles.mainHeader_postulaciones_container_header_countContainer}>
                                            {postulacionesDeProyecto.filter(postulation => postulation.status === "DENIED").length > 0 &&
                                                <span className={styles.mainHeader_postulaciones_container_header_count}>{postulacionesDeProyecto.filter(postulation => postulation.status === "DENIED").length}</span>
                                            }
                                            <ArrowIcon
                                                className={showPostulations[2] ? styles.arrowIconDown : styles.arrowIconRight}
                                                height={15}
                                                width={15}
                                                onClick={() => togglePostulations(2)}
                                            />
                                        </div>
                                    </div>
                                    {showPostulations[2] && postulacionesDeProyecto && postulacionesDeProyecto.length > 0 && postulacionesDeProyecto.map((postulation, index) => {
                                        return postulation.status === "DENIED" &&
                                            <div className={styles.mainHeader_postulaciones_container_userPostulation} key={index}>
                                                <div className={styles.mainConteHeaderulaciones_container_user}>
                                                    <img src={postulation.user.image_profile} alt="Imagen de perfil" className={styles.mainHeader_postulaciones_container_user_image} />
                                                    <p className={styles.mainHeader_postulaciones_container_user_name}>{postulation.user.type === "COMPANY" ?
                                                        postulation.user.razon_social : postulation.user.name + " " + postulation.user.last_name}</p>
                                                </div>
                                                <div className={styles.mainHeader_postulaciones_container_status}>
                                                    <p className={styles.mainHeader_postulaciones_container_status_deniedTxt}>Rechazada</p>
                                                </div>
                                            </div>
                                    }
                                    )}
                                </div>
                            }
                        </div>
                    }
                    {isOwnProject &&
                        <div className={`${styles.mainHeader_cotizaciones} ${isActive === "budgets" && styles.isActive} `}>
                            <h3 className={styles.mainHeader_cotizaciones_title} onClick={(e) => handleHeaderButtonsMenu(e, "budgets")}>
                                COTIZACIONES
                                <ChevronDown size={20} />
                            </h3>
                            {showBudgetsMenu &&
                                <div className={styles.mainHeader_cotizaciones_container} ref={menuRef}>
                                    {proyecto?.subproyecto && proyecto?.subproyecto.length > 0 ? proyecto?.subproyecto.map((subProject, index) => {
                                        return <SubProjectBudgets subproject={subProject} key={index} mainProject={proyecto} />
                                    }
                                    ) : <p className={styles.mainHeader_cotizaciones_container_noResults}>No hay cotizaciones</p>}
                                </div>}
                        </div>
                    }

                </div>
                {!isOwnProject && (
                    <div className={styles.mainHeader_status} >
                        <h3 className={`${styles.mainHeader_status_title} ${isPostulationSent && styles.sent} ${isPostulationAllowed && styles.allowed}
                            ${isPostulationDenied && styles.denied}`} onClick={handlePostulationButton}>
                                {iconToShow}
                                {textToShow}

                            </h3>
                    </div>
                )}
                {isOwnProject && <div className={styles.mainHeader_status_buttons}>
                    <button className={styles.mainHeader_status_buttons_edit} onClick={() => handleEditProject()}>
                        <Pencil size={20} />
                        </button>
                    <button className={styles.mainHeader_status_buttons_delete} title="Borrar proyecto" onClick={() => handleDeleteProject()}>
                        <Trash2 size={20} />
                        </button>
                </div>}
            </header>
    )
}

export default ProyectoIdHeader;