import React from "react";
import styles from "./ModalPublication.module.scss";
import { motion } from "framer-motion";
import { fireAnalytics } from "../../utils/firebase";
import { ReactComponent as WorkOffer } from "../../assets/svg/trabajadoresIcon.svg";
import { X } from "lucide-react";
import { ReactComponent as CreatePublicationIcon } from "../../assets/svg/crearPublicacion.svg";
import { ReactComponent as Search } from "../../assets/svg/misProyectosLittle.svg";
import { ReactComponent as MarketPlace } from "../../assets/svg/marketplace.svg";
import { ReactComponent as ProyectosActive } from "../../assets/svg/nav-proyectos-active.svg";
import { connect } from "react-redux";
import {
    openSuscriptionModal,
    openWorkOfferModal,
    openFeedTextModal
} from "../../store/actions";
import { useNavigate } from "react-router-dom";

const ModalPublication = ({ 
        setModalPublication, 
        openSuscriptionModal, 
        openWorkOfferModal,
        openFeedTextModal,
        userData, 
        isUserPremium }) => {

    const navigate = useNavigate();

    const handleNewOpenProject = () => {
        if (isUserPremium) {
            fireAnalytics("crear_obra_privada", { obra_privada_user_id: userData.id });
            navigate("/obrasprivadas/crear");
        } else {
            fireAnalytics("usuario_sin_plan", { no_plan_user_info: userData.id });
            openSuscriptionModal();
        }
    };

    const handleNewMaterialBudget = () => {
        if (isUserPremium) {
            navigate("/cotizaciondemateriales/crear");
        } else {
            fireAnalytics("usuario_sin_plan", { no_plan_user_info: userData.id });
            openSuscriptionModal();
        }
    };

    const handleNewFeedPost = () => {
        if (isUserPremium) {
            openFeedTextModal();
        } else {
            fireAnalytics("usuario_sin_plan", { no_plan_user_info: userData.id });
            openSuscriptionModal();
        }
    };

    const handleHelp = () => {
        setModalPublication(false);
        navigate("/faq");
    };

    const handleClose = () => {
        setModalPublication(false);
    }

    //CARDS

    const modalPublicationContent = [
        {
            title: "Nueva obra privada",
            logo: <Search width={24} height={24} />,
            subTitle: "Gestiona proyectos de construcción",
            items: [
                "Indica el nombre y lugar.",
                "Programa las fechas para la recepción de presupuestos.",
                "Selecciona un rubro y adjunta documentos.",
            ],
            handleClick: handleNewOpenProject,
            color: "#234BA2",
            backgroundColor: "#E3EDF9",
            border: "2px solid #A7C7F5",
            hover: "#DBEAFE",
        },
        {
            title: "Cotizar materiales",
            logo: <MarketPlace width={24} height={24} className={styles.cardLogo_quotation} />,
            subTitle: "Solicita presupuestos de materiales",
            items: [
                "Lista los materiales necesarios.",
                "Especifica cantidades y detalles.",
                "Recibe múltiples cotizaciones.",
            ],
            handleClick: handleNewMaterialBudget,
            color: "#45A7FF",
            border: "2px solid #ADDFF7",
            backgroundColor: "#E0F3FF",
            hover: "#D9EFFF",
        },
        {
            title: "Publicar en el muro",
            subTitle: "Comparte contenido con la comunidad",
            logo: <ProyectosActive width={24} height={24} />,
            items: [
                "Comparte noticias y actualizaciones.",
                "Inicia discusiones relevantes.",
                "Conecta con otros profesionales.",
            ],
            handleClick: handleNewFeedPost,
            color: "#7000FF",
            border: "2px solid #CAADF7",
            backgroundColor: "#F0E8FF",
            hover: "#E3D5FF",
        },
        {
            title: "Oferta de trabajo",
            subTitle: "Publica oportunidades laborales",
            logo: <WorkOffer width={24} height={24} className={styles.cardLogo_workOffer} />,
            items: [
                "Define requisitos del puesto.",
                "Establece condiciones laborales.",
                "Recibe postulaciones de profesionales.",
            ],
            handleClick: openWorkOfferModal,
            color: "#22af86",
            border: "2px solid #B0E5D3",
            backgroundColor: "#E2F7F1",
            hover: "#D3F0E8",
        },
    ];

    return (
        <motion.div
            className={styles.modal}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className={styles.modal_container}>
                <div className={styles.modal_container_body}>
                    <div className={styles.modal_container_title}>
                        ¿Qué tipo de publicación querés crear?
                        <div className={styles.modal_container_close} onClick={handleClose}>
                            <X width={20} height={20} />
                        </div>
                    </div>
                    <div className={styles.modal_container_body_container}>
                        {modalPublicationContent.map((content, index) => (
                            <motion.div
                                key={index}
                                whileHover={{ scale: 1.02, backgroundColor: content.hover }}
                                transition={{ duration: 0.2 }}
                                className={styles.cardFront}
                                style={{ border: content.border, background: content.backgroundColor }}
                                onClick={() => {
                                    content.handleClick();
                                    setModalPublication(false);
                                }}
                            >
                                {content.logo}
                                <div className={styles.cardTitle}>
                                    {content.title}
                                </div>
                                <div className={styles.cardBody}>
                                    <span className={styles.cardSubTitle}>{content.subTitle}</span>
                                    <ul className={styles.cardItems}>
                                        {content.items.map((item, i) => (
                                            <li className={styles.cardItems_item} key={i}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
                <div className={styles.modal_container_footer} onClick={handleHelp}>
                    ¿Necesitas ayuda?
                    <span className={styles.modal_container_footer_help}>Ver guía</span>
                </div>
            </div>
        </motion.div>
    );

}

const mapStateToProps = (state) => {
    return {
        isUserPremium: state.general.isUserPremium,
        userData: state.general.userData,
    };
};

export default connect(mapStateToProps, {
    openSuscriptionModal,
    openWorkOfferModal,
    openFeedTextModal,

})(ModalPublication);

