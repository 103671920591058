import React from "react";
import { connect } from "react-redux";
import SolicitudesItem from "../SolicitudesItem/SolicitudesItem";
import PresupuestosItem from "../SolicitudesItem/Presupuestos/PresupuestosItem/PresupuestosItem";
import styles from "./SolicitudesWrapper.module.scss";
import { ReactComponent as Notification } from "../../../assets/svg/notification.svg";
import { ReactComponent as EmptyNotificationIcon } from "../../../assets/svg/nav-notif.svg";
import CotizacionesItem from "../SolicitudesItem/Cotizaciones/CotizacionesItem";
import ContactSolicitud from "../SolicitudesItem/ContactSolicitud";
import CoincidenceSolicitud from "../SolicitudesItem/CoincidenceSolicitud";
import PostLikeSolicitud from "../SolicitudesItem/Posts/PostLikeSolicitud";
import PostCommentSolicitud from "../SolicitudesItem/Posts/PostCommentSolicitud";
import WorkOfferAplicationSolicitud from "../SolicitudesItem/OfertaDeTrabajo/WorkOfferAplicationSolicitud";
import ProjectComment from "../SolicitudesItem/Proyectos/ProjectComment";
import PortfolioSolicitud from "../SolicitudesItem/Portfolio/PortfolioSolicitud";

const SolicitudesWrapper = ({ solicitudesList }) => {

  if (!solicitudesList) {
    return (
      <div className={`${styles.container}`}>
        <h1 className={styles.notifications}>
          <span className={styles.svg}>
            <Notification />
          </span>
          Notificaciones
        </h1>
        <div className={styles.emptySolicitud}>
          <div className={styles.emptySolicitudContainer}>
            <EmptyNotificationIcon />
            <p>No tienes notificaciones</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.container}`}>
      {solicitudesList.length > 0 ? (
        solicitudesList
          .sort((a, b) => Date.parse(b.sendDate) - Date.parse(a.sendDate))
          .map((solicitud, index) => {
            if (
              solicitud.type === "POSTULACION_SEND" ||
              solicitud.type === "USER_POSTULATION_SEND"
            ) {
              return (
                <SolicitudesItem
                  item={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            } else if (
              solicitud.type === "POSTULACION_NEGATED" ||
              solicitud.type === "USER_POSTULATION_NEGATED"
            ) {
              return (
                <SolicitudesItem
                  item={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            }
            else if (
              solicitud.type === "POSTULACION_ACCEPTED" ||
              solicitud.type === "USER_POSTULATION_ACCEPTED"
            ) {
              return (
                <SolicitudesItem
                  item={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            } else if (
              solicitud.type === "COMMENT_RESPONDIDO" ||
              solicitud.type === "USER_COMMENT_RESPONDIDO"
            ) {
              return (
                <ProjectComment
                  contact={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                  response
                />
              );
            } else if (
              solicitud.type === "NEW_PROFILE_VIEW" ||
              solicitud.type === "USER_NEW_PROFILE_VIEW"
            ) {
              return (
                <SolicitudesItem
                  item={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            } else if (
              solicitud.type === "USER_COMMENT_ENVIADO"
            ) {
              return (
                <SolicitudesItem
                  item={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            } else if (
              solicitud.type === "COMMENT_ENVIADO"
            ) {
              return (
                <ProjectComment
                  solicitudType={solicitud.type}
                  key={index}
                  contact={solicitud}
                />
              );
            } else if (solicitud.type === "QUOTATION_SEND") {
              return (
                <CotizacionesItem
                  userInfo={solicitud?.userInfo}
                  id={solicitud?.id}
                  userSender={solicitud?.userSender}
                  userFullName={`${solicitud?.userSender?.name} ${solicitud?.userSender?.last_name}`}
                  projectName={solicitud?.quotation?.name}
                  file={solicitud?.subQuotationPostulation?.file}
                  projectId={solicitud?.quotation?.id}
                  key={index}
                  cotizacion={solicitud}
                />
              );
            } else if (solicitud?.postulacionPermiso?.status === "DENIED") {
              return (
                <SolicitudesItem
                  item={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            } else if (solicitud.type === "PRESUPUESTO_ENVIADO") {
              return (
                <PresupuestosItem
                  key={index}
                  userInfo={solicitud.userInfo}
                  presupuesto={solicitud}
                  projectId={""}
                  userSender={solicitud.userSender}
                  userFullName={`${solicitud.userSender.name} ${solicitud.userSender.last_name}`}
                  subProjectDescription={
                    solicitud.subProyectoPresupuesto?.descripcion
                  }
                  subProjectName={
                    solicitud?.subProyectoPresupuesto?.descripcion
                  }
                  projectName={solicitud?.subProyectoPresupuesto?.descripcion}
                  budget={solicitud?.subProyectoPresupuesto?.budget}
                  solicitudType={solicitud.type}
                  type={solicitud.type}
                />
              );
            }
            else if (solicitud.type === "NEW_CONTACT_REQUEST") {
              return (
                <ContactSolicitud
                  contact={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            } else if (solicitud.type === "NEW_COINCIDENCES") {
              return (
                <CoincidenceSolicitud
                  contact={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            } else if (solicitud.type === "POST_LIKE_SEND") {
              return (
                <PostLikeSolicitud
                  contact={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            } else if (solicitud.type === "POST_COMMENT_SEND") {
              return (
                <PostCommentSolicitud
                  contact={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            } else if (solicitud.type === "NEW_JOB_APLICATION") {
              return (
                <WorkOfferAplicationSolicitud
                  contact={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            } else if (solicitud.type === "QUESTION_RESPONDIDO") {
              return (
                <PortfolioSolicitud
                  contact={solicitud}
                  solicitudType={solicitud.type}
                  key={index}
                />
              );
            }
            return null;
          })
      ) : (
        <div className={styles.emptySolicitud}>
          <div className={styles.emptySolicitudContainer}>
            <EmptyNotificationIcon />
            <p>No tienes notificaciones</p>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    solicitudesList: state.dashboard.solicitudesList,
  };
};

export default connect(mapStateToProps)(SolicitudesWrapper);
