import React, { useEffect, useState } from "react";
import styles from "./FeaturedContractors.module.scss";
import { HardHat, Award, Users, Hammer, Mail, Eye, MessageCircle, Briefcase } from "lucide-react";
import { connect } from "react-redux";
import { getUsersRegisteredData, openSendContactNotificationModal } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { ReactComponent as StarFull } from "../../assets/svg/starFullSvg.svg";
import { ReactComponent as HalfStar } from "../../assets/svg/halfStarSvg.svg";
import { ReactComponent as StarEmpty } from "../../assets/svg/emptyStarSvg.svg";
import usePremiumCheck from "../../hooks/usePremiumCheck";

const FeaturedContractors = ({ getUsersRegisteredData, usersRegisterData, openSendContactNotificationModal }) => {
    const [isPaused, setIsPaused] = useState(false);

    const premiumCheck = usePremiumCheck();

    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
        getUsersRegisteredData();
    }, []);

    const handleContact = (userInfo) => {
        openSendContactNotificationModal(userInfo);
    };

    return (
        <div className={styles.featuredContractors}>
            <div className={styles.featuredContractors__header}>
                <div className={styles.featuredContractors__header__title}>
                    <h2 className={styles.featuredContractors__header__title__txt}>
                        <Award size={32} className={styles.featuredContractors__header__icon} />
                        Contratistas Destacados
                    </h2>
                    <p className={styles.featuredContractors__header__subtitle}>Profesionales con trayectoria comprobada y excelentes calificaciones</p>
                </div>
                <button className={styles.featuredContractors__header__button} onClick={() => navigate("/profesionales")}>
                    <Users size={20} />
                    Ver todos los contratistas
                </button>
            </div>
            <div className={styles.featuredContractors__carousel}>
                <div
                    className={`${styles.featuredContractors__carousel__container} ${isPaused ? styles.paused : ''
                        }`}
                >
                    {[...usersRegisterData, ...usersRegisterData].map((user, index) => {
                        const nameToShow = user.type === "COMPANY" ? user.razon_social : user.name + " " + user.last_name;
                        const userStars = user?.stars?.toFixed(1 - (user?.stars % 1 === 0));

                        let starsToShow = [];
                        const starsCount = userStars !== null ? Math.floor(userStars) : 0;

                        for (let i = 0; i < 5; i++) {
                            if (i < starsCount) {
                                starsToShow.push(<StarFull key={i} width={15} height={15} style={{ color: "rgb(250 204 21 )" }} />);
                            } else if (i === starsCount && userStars % 1 !== 0) {
                                starsToShow.push(<HalfStar key={i} width={15} height={15} />);
                            } else {
                                starsToShow.push(<StarEmpty key={i} width={15} height={15} />);
                            }
                        }

                        const imageError = user.image_profile === "https://edify-files-dev.s3.amazonaws.com/profile-pictures/ebba9aa8-4346-4662-b3e5-8699015659ba-image-profile-default.png";
                        const skills = user?.skill?.map((skill) => skill?.skill?.name);

                        return (
                            <div
                                key={index}
                                className={styles.featuredContractors__card}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className={styles.featuredContractors__card__borderTop} />
                                <div className={styles.featuredContractors__cardBody}>
                                    <div className={styles.featuredContractors__card__header}>
                                        <div className={styles.featuredContractors__card__header__imageContainer}>
                                            {!imageError && user.image_profile ? (
                                                <img
                                                    className={styles.featuredContractors__card__header__imageContainer__img}
                                                    src={user.image_profile}
                                                    alt="user"
                                                />
                                            ) : (
                                                <div className={styles.featuredContractors__card__header__imageContainer__icon}>
                                                    <HardHat size={32} />
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.featuredContractors__card__header__info}>
                                            <h3 className={styles.featuredContractors__card__header__info__txt}>{nameToShow}</h3>
                                            <div className={styles.featuredContractors__card__header__stars}>
                                                {starsToShow}
                                                <span className={styles.featuredContractors__card__header__stars__count}>({user.stars.toFixed(1)})</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.featuredContractors__card__info}>
                                        <div className={styles.featuredContractors__card__info__contact}>
                                            <Mail size={16} style={{ marginTop: "0.3rem", minWidth: "1.6rem", color: "rgb(35 75 162 )" }} />
                                            <span>{user.username}</span>
                                        </div>
                                        <div className={styles.featuredContractors__card__info__skills}>
                                            <Hammer size={16} style={{ marginTop: "0.3rem", minWidth: "1.6rem", color: "rgb(35 75 162 )" }} />
                                            {skills[0]}
                                            {skills.length > 1 && `(${skills.length - 1})`}
                                        </div>
                                        {user?.portfolio?.length > 0 && (
                                            <div className={styles.featuredContractors__card__info__contact}>
                                                <Briefcase size={16} style={{ marginTop: "0.3rem", minWidth: "1.6rem", color: "rgb(35 75 162 )" }} />
                                                <span>{user.portfolio.length} Productos/servicios en edify</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.featuredContractors__card__buttons}>
                                        <button className={styles.featuredContractors__card__buttons__buttonPrimary} onClick={premiumCheck(handleContact, user)}>
                                            <MessageCircle size={16} style={{ minWidth: "1.6rem" }} />
                                            Contactar
                                        </button>
                                        <button className={styles.featuredContractors__card__buttons__button} onClick={() => navigate(`/profesionales/${user.id}/${nameToShow}`)}>
                                            <Eye size={16} style={{ minWidth: "1.6rem" }} />
                                            Ver Perfil
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        usersRegisterData: state.general.usersRegisteredData
    }
}

const mapDispatchToProps = {
    getUsersRegisteredData,
    openSendContactNotificationModal
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedContractors);
