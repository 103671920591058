import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  generateVisit,
  getProfileVisualizer,
  getProfileData,
} from "../../store/actions";
import PerfilMain from "../../components/Perfil/PerfilMain/PerfilMain";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import RegisteredUserProfileLoader from "./RegisteredUsersSkeleton/RegisteredUserProfileLoader";
import Botonera from "../../components/Botonera/Botonera";

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;

`;

const RegisteredUserProfile = ({
  generateVisit,
  getProfileVisualizer,
  profileVisualizerData,
  getProfileData,
  loading,
  userData,
  ...props }) => {
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);

  const { id } = useParams();

  const userId = id === userData.id ? userData.id : id;

  const myPerson = userId === userData.id

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Genera la visita en el perfil
  useEffect(() => {
    if (!myPerson) {
      generateVisit(parseInt(userId));
    }
  }, []);

  useEffect(() => {
    if (!myPerson) {
      getProfileVisualizer(userId);
    } else {
      getProfileData(userId);
    }
  }, [userId]);


  return (
    <Container>
      {ocultarEnMobile ? <Botonera mobileStylesFromParent={mobileStylesFromParent} /> : null}
      {loading ? <RegisteredUserProfileLoader /> :
        <PerfilMain
          perfilAjeno={userId}
          profileVisualizerData={profileVisualizerData}
          myPerson={myPerson} />
      }
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    profileVisualizerData: state.general.profileVisualizer,
    loading: state.general.componentLoading,
  };
};

export default connect(mapStateToProps, {
  generateVisit,
  getProfileVisualizer,
  getProfileData,
})(RegisteredUserProfile);
