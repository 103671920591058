import React from "react";
import styles from "./TutorialSteps.module.scss";
import { motion } from "framer-motion";

const TutorialSteps = ({ stepCount, progress, totalSteps }) => {

    const ball = {
        hidden: {
            backgroundColor: "#e8e8e8",
            scale: 0,
        },
        visibleActive: {
            backgroundColor: "var(--color-mainBlue)",
            scale: 1,
        },
        visible: {
            backgroundColor: "#e8e8e8",
            border: "1px solid #d5d5d5",
            opacity: 0.64,
            scale: 1,
        },
    };

    return (
        <>
            <div className={styles.meter}>
                <span style={{ width: progress }}></span>
            </div>
            <motion.div
                className={styles.container}
                transition={{ ease: "easeInOut", duration: 0.5, delay: 0.5 }}
                animate={{ opacity: [0, 1] }}
            >
                <div className={styles.stepsContainer}>
                    {totalSteps === 3 ? (
                        <div className={styles.stepsProgress}>
                            <motion.div
                                transition={{ ease: "easeInOut", duration: 0.5, delay: 0.6 }}
                                variants={ball}
                                initial='hidden'
                                animate={stepCount === 1 ? "visibleActive" : "visible"}
                                className={`${styles.ball} ${styles.active}`}
                            ></motion.div>
                            <motion.div
                                transition={{ ease: "easeInOut", duration: 0.5, delay: 0.7 }}
                                variants={ball}
                                initial='hidden'
                                animate={stepCount === 2 ? "visibleActive" : "visible"}
                                className={stepCount >= 2 ? `${styles.ball} ${styles.active}` : `${styles.ball}`}
                            ></motion.div>
                            <motion.div
                                transition={{ ease: "easeInOut", duration: 0.5, delay: 0.8 }}
                                variants={ball}
                                initial='hidden'
                                animate={stepCount === 3 ? "visibleActive" : "visible"}
                                className={stepCount >= 3 ? `${styles.ball} ${styles.active}` : `${styles.ball}`}
                            ></motion.div>
                        </div>) : (
                        <div className={styles.stepsProgress}>
                            <motion.div
                                transition={{ ease: "easeInOut", duration: 0.5, delay: 0.6 }}
                                variants={ball}
                                initial='hidden'
                                animate={stepCount === 1 ? "visibleActive" : "visible"}
                                className={`${styles.ball} ${styles.active}`}
                            ></motion.div>
                            <motion.div
                                transition={{ ease: "easeInOut", duration: 0.5, delay: 0.7 }}
                                variants={ball}
                                initial='hidden'
                                animate={stepCount === 2 ? "visibleActive" : "visible"}
                                className={stepCount >= 2 ? `${styles.ball} ${styles.active}` : `${styles.ball}`}
                            ></motion.div>
                            <motion.div
                                transition={{ ease: "easeInOut", duration: 0.5, delay: 0.8 }}
                                variants={ball}
                                initial='hidden'
                                animate={stepCount === 3 ? "visibleActive" : "visible"}
                                className={stepCount >= 3 ? `${styles.ball} ${styles.active}` : `${styles.ball}`}
                            ></motion.div>
                            <motion.div
                                transition={{ ease: "easeInOut", duration: 0.5, delay: 0.9 }}
                                variants={ball}
                                initial='hidden'
                                animate={stepCount === 4 ? "visibleActive" : "visible"}
                                className={stepCount >= 4 ? `${styles.ball} ${styles.active}` : `${styles.ball}`}
                            ></motion.div>
                        </div>
                    )}
                </div>
            </motion.div>
        </>
    );
};

export default TutorialSteps;