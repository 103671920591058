import React, { useState } from "react";
import styles from "./PresupuestosItem.module.scss";
import { connect } from "react-redux";
import {
  setBudgetId,
  setViewNotification,
  deleteNotification,
} from "../../../../../store/actions";
import { ReactComponent as PresupuestoNew } from "../../../../../assets/svg/presupuestoNew.svg";
import { ReactComponent as TrashIcon } from "../../../../../assets/svg/trashIcon.svg";
import { ReactComponent as DownloadCloudIcon } from "../../../../../assets/svg/downloadCloudIcon.svg";
import LoadingDots from "../../../../UI/Loading/LoadingDots";
import { fireAnalytics } from "../../../../../utils/firebase"
import CheckedProfile from "../../../../../assets/images/perfilChecked.png";
import { useNavigate } from "react-router-dom";

const PresupuestosItem = ({
  userFullName,
  projectName,
  userinfo,
  subProjectDescription,
  subProjectId,
  projectId,
  budget,
  presupuesto,
  userSender,
  setViewNotification,
  deleteNotification,
  type,
  ...props
}) => {
 const navigate = useNavigate();
  const [showAllText, setShowAllText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id, view } = presupuesto;

  let limit = 400;
  let limitedText = "";
  const showMore = () => {
    setShowAllText(true);
  };
  if (showAllText || subProjectDescription?.length <= limit) {
    limitedText = <p>{subProjectDescription}</p>;
  } else {
    limitedText = (
      <p>
        {subProjectDescription?.substring(0, limit) + "..."}{" "}
        <span onClick={() => showMore()}>( Ver más )</span>
      </p>
    );
  }

  const handleClickProfile = () => {
    const userName = userSender.type === "COMPANY" ? userSender.razon_social : userSender.name + " " + userSender.last_name;
    navigate(`/profesionales/${userSender.id}/${userName}`);
  };

  const handleDeleteNotification = () => {
    deleteNotification(parseInt(id));
  }

  const handleSetViewNotification = () => {
    setIsLoading(true);
    setViewNotification(id);
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }

  const formatTimeFromNow = (time) => {
    const date = new Date(time);
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      return `${seconds === 1 ? '1 segundo' : `${seconds} segundos`}`;
    } else if (minutes < 60) {
      return `${minutes === 1 ? '1 minuto' : `${minutes} minutos`}`;
    } else if (hours < 24) {
      return `${hours === 1 ? '1 hora' : `${hours} horas`}`;
    } else if (days < 30) {
      return `${days === 1 ? '1 día' : `${days} días`}`;
    } else if (months < 12) {
      return `${months === 1 ? '1 mes' : `${months} meses`}`;
    } else {
      return `${years === 1 ? '1 año' : `${years} años`}`;
    }
  }

  const extractNumbersAndText = (str) => {
    const matches = str.match(/^(\d+)_([\s\S]*)$/); // Captura números seguidos de texto después del guion bajo
    if (matches) {
      const numbers = parseInt(matches[1]); // Extrae los números como un entero
      const text = matches[2]; // Extrae el texto después del guion bajo
      return { numbers, text };
    } else {
      return { numbers: null, text: null };
    }
  };

  // Extrayendo números y texto después del guion bajo de subProjectDescription
  const { numbers: subProjectNumbers, text: subProjectText } = extractNumbersAndText(
    subProjectDescription
  );

  return (
    <div className={styles.item}>
      <div className={styles.topContainer}>
        <div className={styles.nameContainer}>
          <PresupuestoNew />
          <h4 className={styles.newPresupuesto}>
            Nuevo presupuesto disponible
          </h4>
        </div>
        <div className={styles.container_buttons} >
          {view === null && !isLoading &&
            <div className={styles.container_isViewed} title="Marcar como vista" onClick={() => handleSetViewNotification()}>
              <div className={styles.container_isViewed_checked}></div>
            </div>
          }
          {isLoading && <div className={styles.container_isViewed}> <LoadingDots blueDots /> </div>}
          <div className={styles.downloadContainer} title="Descargar presupuesto">
            <a
              className={styles.download}
              href={budget}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setViewNotification(parseInt(id));
                fireAnalytics("download_obra_privada_budget", { obra_privada_budget_recived: userinfo.id, obra_privada_budget_sender: userSender.id });
              }}
            >
              <DownloadCloudIcon width={25} height={25} />
            </a>
          </div>
          <div className={styles.container_buttons_trash} onClick={() => handleDeleteNotification()} title="Eliminar notificación">
            <TrashIcon width={20} height={20} />
          </div>
        </div>
      </div>
      <div className={styles.notificationContainer}>
        <div className={styles.notificationContainer_profile} onClick={() => handleClickProfile()}>
          <img className={styles.notificationContainer_image} src={userSender?.image_profile} alt="user" />
          {userSender?.validated &&
            <img className={styles.notificationContainer_checked} src={CheckedProfile} alt="checked" />
          }
        </div>
        <div className={styles.notificationContainer_body}>
          <b className={styles.userName} onClick={() => handleClickProfile()}>{userFullName}</b>&nbsp;
          te ha enviado un presupuesto para tu rubro <b className={styles.notificationContainer_subProjectName} onClick={() => navigate(`obrasprivadas/${subProjectNumbers}`)}>{subProjectText}</b>
        </div>
      </div>
      <div className={styles.notificationContainer_time}>
        Hace &nbsp;{formatTimeFromNow(presupuesto?.createdAt)}
      </div>
    </div>
  );
};

export default connect(null, {
  setBudgetId,
  setViewNotification,
  deleteNotification,
})(PresupuestosItem);
