import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAdjudicatedProjectById,
} from "../../../store/actions";
import { useParams } from "react-router-dom";
import styles from "./AdjudicatedProjectById.module.scss";
import { Building2, Calendar, DollarSign, Download, MapPin } from "lucide-react";
import Botonera from "../../Botonera/Botonera";
import styled from "styled-components";

const Container = styled.div`
   display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 5%;
  padding-left: 5%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const AdjudicatedProjectById = ({
  adjudicatedProject,
  getAdjudicatedProjectById,
  ...props }) => {
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const { id } = useParams();
  // Dividimos la descripción en líneas usando '\n'
  const processDescription = (desc) => {
    if (!desc) return { isText: false, lines: [] };

    const lines = desc.split('\r\n').filter((line) => line.trim() !== '');

    // Si no hay saltos de línea significativos, consideramos que es un texto plano
    if (lines.length <= 1) {
      return { isText: true, text: desc.trim() };
    }

    const result = [];
    for (let i = 0; i < lines?.length; i += 2) {
      const key = lines[i]?.trim();
      const value = lines[i + 1]?.trim();
      if (key && value) {
        result.push({ key, value });
      }
    }
    return { isText: false, lines: result };
  };

  const { isText, lines, text } = processDescription(adjudicatedProject?.description);
  const descriptionLines = isText ? [] : lines;
  const visibleLines = isDescriptionExpanded ? descriptionLines : descriptionLines.slice(0, 5);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStylesFromParent(windowWidth < 768);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
    getAdjudicatedProjectById(id);
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Formateamos fechas
  const formatDate = (date) => {
    const utcDate = new Date(date);
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1;
    const day = utcDate.getUTCDate();
    return `${day}/${month}/${year}`;
  };

  //Formateamos el monto a mostrar
  const formatMoney = (amount) => {
    return new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <Container>
    <div className={styles.container}>
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.subtitle}>Adjudicacion publica</p>
          <h1 className={styles.title}>
            {adjudicatedProject?.name}
          </h1>
        </div>

        <div className={styles.layout}>
          <div className={styles.mainContent}>
            <div className={styles.card}>
              <h2 className={styles.cardTitle}>Información General</h2>

              <div className={styles.infoList}>
                <div className={styles.infoItem}>
                  <MapPin className={styles.icon} />
                  <div>
                    <p className={styles.infoTitle}>Ubicación</p>
                    <p className={styles.infoContent}>{adjudicatedProject?.jurisdiccion?.name}</p>
                  </div>
                </div>

                <div className={styles.infoItem}>
                  <Calendar className={styles.icon} />
                  <div>
                    <p className={styles.infoTitle}>Fecha de adjudicación</p>
                    <p className={styles.infoContent}>{formatDate(adjudicatedProject?.adjudicatedDate)}</p>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <DollarSign className={styles.icon} />
                  <div>
                    <p className={styles.infoTitle}>Moneda</p>
                    <p className={styles.infoContent}>{adjudicatedProject?.currency !== "undefined" ? adjudicatedProject?.currency : "Pesos Arg"}</p>
                  </div>
                </div>

                <div className={styles.infoItem}>
                  <DollarSign className={styles.icon} />
                  <div>
                    <p className={styles.infoTitle}>Presupuesto oficial</p>
                    <p className={styles.infoContent}>{formatMoney(adjudicatedProject?.monto)}</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className={styles.sidebar}>
            <div className={styles.card}>
              <h2 className={styles.cardTitle}>Empresa adjudicada</h2>
              <div className={styles.infoItem}>
                <Building2 className={styles.icon} />
                <div>
                  <p className={styles.infoContent}>{adjudicatedProject?.empresa}</p>
                  <span className={styles.infoContent}>{adjudicatedProject?.email}</span>
                  <span className={styles.infoContent}>{adjudicatedProject?.phone}</span>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <h2 className={styles.cardTitle}>Tipo de Obra</h2>
              <p className={styles.infoContent}>{adjudicatedProject?.obra_type?.name}</p>
            </div>
            <div className={styles.card}>
              <h2 className={styles.cardTitle}>Fuente</h2>
              <p className={styles.infoContent}>{adjudicatedProject?.fuente}</p>
            </div>
            <div className={styles.card}>
              <h2 className={styles.cardTitle}>Organismo</h2>
              <p className={styles.infoContent}>{adjudicatedProject?.organismo?.name}</p>
            </div>

            <div className={styles.card}>
              <h2 className={styles.cardTitle}>Documentación</h2>
              <a href={adjudicatedProject?.link} target="_blank" rel="noreferrer" className={styles.downloadButton}>  <Download className={styles.buttonIcon} />
                Descargar pliego</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    adjudicatedProject: state.profile.adjudicatedProject,
  };
}

export default connect(mapStateToProps, {
  getAdjudicatedProjectById,
})(AdjudicatedProjectById); 