import React, { useState } from "react";
import styles from "./CotizacionesItem.module.scss";
import { connect } from "react-redux";
import {
  setBudgetId,
  setViewNotification,
  deleteNotification,
} from "../../../../store/actions";
import { ReactComponent as CotizacionNew } from "../../../../assets/svg/presupuestoNew.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/svg/trashIcon.svg";
import { ReactComponent as DownloadCloudIcon } from "../../../../assets/svg/downloadCloudIcon.svg";
import CheckedProfile from "../../../../assets/images/perfilChecked.png";
import LoadingDots from "../../../UI/Loading/LoadingDots";
import { fireAnalytics } from "../../../../utils/firebase";
import { useNavigate } from "react-router-dom";

const CotizacionesItem = ({
  userFullName,
  projectName,
  subProjectDescription,
  subProjectId,
  projectId,
  id,
  file,
  userSender,
  setViewNotification,
  postulacionPermiso,
  deleteNotification,
  cotizacion,
  userInfo,
  ...props
}) => {
 const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { name, last_name, razon_social, type } = userSender;

  const completeName = type === "COMPANY" ? razon_social : name + " " + last_name;
 
  const handleDeleteNotification = () => {
    deleteNotification(parseInt(id));
  }

  const handleSetViewNotification = () => {
    setIsLoading(true);
    setViewNotification(id);
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }

  const formatTimeFromNow = (time) => {
    const date = new Date(time);
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      return `${seconds === 1 ? '1 segundo' : `${seconds} segundos`}`;
    } else if (minutes < 60) {
      return `${minutes === 1 ? '1 minuto' : `${minutes} minutos`}`;
    } else if (hours < 24) {
      return `${hours === 1 ? '1 hora' : `${hours} horas`}`;
    } else if (days < 30) {
      return `${days === 1 ? '1 día' : `${days} días`}`;
    } else if (months < 12) {
      return `${months === 1 ? '1 mes' : `${months} meses`}`;
    } else {
      return `${years === 1 ? '1 año' : `${years} años`}`;
    }
  }

  const handleClickProyecto = () => {
    navigate(`/cotizaciondemateriales/${projectId}`);
  };

  const handleClickProfile = () => {
    const userName = userSender.type === "COMPANY" ? userSender.razon_social : userSender.name + " " + userSender.last_name;
    navigate(`/profesionales/${userSender.id}/${userName}`);
  };

  return (
    <div className={styles.item}>
      <div className={styles.topContainer}>
        <div className={styles.nameContainer}>
          <CotizacionNew width={30} height={40} />
          <h4 className={styles.newPresupuesto}>Nuevo presupuesto de materiales recibido</h4>
        </div>
        <div className={styles.container_buttons} >
          {cotizacion?.view === null && !isLoading &&
            <div className={styles.container_isViewed} title="Marcar como vista" onClick={() => handleSetViewNotification()}>
              <div className={styles.container_isViewed_checked}></div>
            </div>
          }
          {isLoading && <div className={styles.container_isViewed}> <LoadingDots blueDots /> </div>}
          <div className={styles.downloadContainer} title="Descargar cotización">
            <a
              className={styles.download}
              href={file}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setViewNotification(id);
                fireAnalytics("download_quotation_budget", { quotation_budget_recived: userInfo.id, quotation_budget_sender: userSender.id });
              }}
            >
              <DownloadCloudIcon width={25} height={25} />
            </a>
          </div>
          <div className={styles.container_buttons_trash} onClick={() => handleDeleteNotification()} title="Eliminar notificación">
            <TrashIcon width={20} height={20} />
          </div>
        </div>
      </div>
      <div className={styles.notificationContainer}>
        <div className={styles.notificationContainer_profile} onClick={() => handleClickProfile()}>
          <img className={styles.notificationContainer_image} src={userSender?.image_profile} alt="user" />
          {userSender?.validated &&
            <img className={styles.notificationContainer_checked} src={CheckedProfile} alt="checked" />
          }
        </div>
        <div className={styles.notificationContainer_txtContainer}>
          <div className={styles.notificationContainer_info}>
            <p className={styles.notificationContainer_txt}>
              {completeName}&nbsp;
              te ha enviado un presupuesto de materiales para la solicitud&nbsp;
              <span
                className={styles.projectNameStyle}
                onClick={() => handleClickProyecto()}
              >
                {projectName}
              </span>
            </p>
          </div>

        </div>
      </div>
      <div className={styles.notificationContainer_time}>
        Hace &nbsp;{formatTimeFromNow(cotizacion?.createdAt)}
      </div>
    </div>
  );
};

export default connect(null, {
  setBudgetId,
  setViewNotification,
  deleteNotification,
})(CotizacionesItem);
