import React, { useState, useEffect, useRef } from "react";
import styles from "./ProfileEditModalNew.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import { useFormik } from "formik";
import SkillsEdit from "./SkillsEdit/SkillsEdit";
import {
    sendSkillsProfile,
    testDescriptionEdit,
    getCities,
    getUserSkill,
    editUserData
} from "../../../store/actions";
import JurisdictionList from "../../UI/JurisdictionList/JurisdictionList";
import * as Yup from "yup";
import useUserImage from "../../../hooks/useUserImage";
import ErrorMenu from "./ErrorMenu";
import useIsPhone from "../../../hooks/useIsPhone";
import { X, Camera, Link as LinkIcon, Edit3, UserRound, HardHat, Building2, MapPin, Trash2, Info, ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';

const variants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
};


const ProfileEditModalNew = ({
    setIsEditing,
    userData,
    getUserSkill,
    sendSkillsProfile,
    testDescriptionEdit,
    userSkills,
    cities,
    getCities,
    editUserData,
    ...props }) => {

    const [activeSection, setActiveSection] = useState('Datos personales');
    const { imageError, initials, profileImage } = useUserImage(userData);
    const [imagePreview, setImagePreview] = useState(null);
    const [editImg, setEditImg] = useState(false);
    const [file, setFile] = useState(null);
    const [editSkills, setEditSkills] = useState(false);
    const [selectedJurisdiction, setSelectedJurisdiction] = useState({ id: "", name: "" });
    const [showErrors, setShowErrors] = useState(false);
    const [showLeftSide, setShowLeftSide] = useState(true);

    const isPhone = useIsPhone();

    const userFullName = userData.type === "COMPANY" ? userData.razon_social : userData.name + " " + userData.last_name;

    const inputFileRef = useRef(null);

    const handleEditImgClick = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }

        setEditImg(!editImg);
    };

    useEffect(() => {
        getUserSkill(userData.id);
    }, []);

    const addHttpToUrl = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return `http://${url}`;
        }
        return url;
    };

    const urlRegex = /^(https?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
    const twitterRegex = /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]{1,15}\/?$/i;
    const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.]{1,}\/?$/i;
    const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._]{1,}\/?$/i;
    const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]{1,}\/?$/i;

    const formik = useFormik({
        initialValues: {
            name: userData?.name === undefined || userData.name === null ? "" : userData.name,
            last_name: userData?.last_name === undefined || userData.last_name === null ? "" : userData.last_name,
            razon_social: userData?.razon_social === undefined || userData.razon_social === null ? "" : userData.razon_social,
            type: userData?.type,
            jobBoardZones: !Array.isArray(userData.jobBoardZones) || userData.jobBoardZones.length === 0 ? [] : userData.jobBoardZones,
            allCountry: userData?.jobBoardZones?.filter(zone => zone.allCountry === true).length > 0,
            phone: userData.phone === undefined || userData.phone === null ? "" : userData.phone,
            url_site_web: userData.url_site_web === undefined || userData.url_site_web === null ? "" : userData.url_site_web,
            instagram: userData.instagram === undefined || userData.instagram === null ? "" : userData.instagram,
            facebook: userData.facebook === undefined || userData.facebook === null ? "" : userData.facebook,
            twitter: userData.twitter === undefined || userData.twitter === null ? "" : userData.twitter,
            linkedin: userData.linkedin === undefined || userData.linkedin === null ? "" : userData.linkedin,
            description: userData.description === undefined || userData.description === null || userData.description === "" ? "" : userData.description,
            image: userData.image_profile === undefined || userData.image_profile === null ? "" : userData.image_profile,
            userSkills: userSkills
        },
        validationSchema: Yup.object({
            razon_social: Yup.string()
                .when('type', {
                    is: 'COMPANY',
                    then: Yup.string()
                        .required("Debes ingresar un nombre de empresa")
                        .notOneOf(["Agregar"], "Coloque un nombre válido")
                        .max(50, "Máximo 50 caracteres"),
                }),
            name: Yup.string()
                .when('type', {
                    is: 'PERSON',
                    then: Yup.string()
                        .required("Debes ingresar un nombre")
                        .notOneOf(["Agregar"], "Coloque un nombre válido")
                        .max(50, "Máximo 50 caracteres"),
                }),
            last_name: Yup.string()
                .when('type', {
                    is: 'PERSON',
                    then: Yup.string()
                        .required("Debes ingresar un apellido")
                        .notOneOf(["Agregar"], "Coloque un apellido válido")
                        .max(50, "Máximo 50 caracteres"),
                }),
            userSkills: Yup.array().min(1, "Debes seleccionar al menos un rubro"),
            url_site_web: Yup.string()
                .transform(addHttpToUrl)
                .matches(urlRegex, "Ingrese una URL válida (ej: www.mi_sitio_web.com)"),
            twitter: Yup.string()
                .transform(addHttpToUrl)
                .matches(twitterRegex, "Ingrese una URL válida de Twitter (ej: https://twitter.com/mi_cuenta)")
                .nullable(),

            facebook: Yup.string()
                .transform(addHttpToUrl)
                .matches(facebookRegex, "Ingrese una URL válida de Facebook (ej: www.facebook.com/mi_cuenta)")
                .nullable(),

            instagram: Yup.string()
                .transform(addHttpToUrl)
                .matches(instagramRegex, "Ingrese una URL válida de Instagram (ej: www.instagram.com/mi_cuenta)")
                .nullable(),

            linkedin: Yup.string()
                .transform(addHttpToUrl)
                .matches(linkedinRegex, "Ingrese una URL válida de LinkedIn (ej: www.linkedin.com/in/mi_cuenta)")
                .nullable(),
        }),
        validateOnBlur: true,
        enableReinitialize: true,
    });

    //validaciones de formulario
    useEffect(() => {
        if (formik.values.jobBoardZones.length === 0) {
            formik.setFieldValue("allCountry", true);
        }
    }, [formik.values.jobBoardZones]);

    useEffect(() => {
        if (formik.values.type === "COMPANY") {
            formik.setFieldValue("name", "");
            formik.setFieldValue("last_name", "");
        } else {
            formik.setFieldValue("razon_social", "");
        }
    }, [formik.values.type]);

    useEffect(() => {
        if (userSkills.length === 0) {
            formik.setFieldValue("userSkills", []);
        }
    }, [userSkills]);


    useEffect(() => {
        if (selectedJurisdiction?.id) {
            getCities(selectedJurisdiction?.id);
        }
    }, [selectedJurisdiction?.id]);

    const handleSectionChange = (section) => {
        setActiveSection(section);
        if (isPhone) {
            setShowLeftSide(false);
        }
    };

    // Función para volver al menú en móviles
    const handleBackToMenu = () => {
        setShowLeftSide(true);
    };

    const renderContent = () => {
        switch (activeSection) {
            case 'Datos personales':
                return (
                    <form onSubmit={formik.handleSubmit} className={styles.form}>
                        <div className={styles.formGroup}>
                            <label className={styles.formGroup_label} htmlFor="type">Tipo de cuenta</label>
                            <div className={styles.formGroup_buttons}>
                                <button
                                    className={`${styles.formGroup_buttons_button} ${formik.values.type === "PERSON" ? styles.formGroup_buttons_button_active : ""}`}
                                    type="button"
                                    onClick={() => {
                                        formik.setFieldValue("type", "PERSON");
                                    }}
                                >
                                    <UserRound size={20} />
                                    <span>Persona</span>
                                </button>
                                <button
                                    className={`${styles.formGroup_buttons_button} ${formik.values.type === "COMPANY" ? styles.formGroup_buttons_button_active : ""}`}
                                    type="button"
                                    onClick={() => {
                                        formik.setFieldValue("type", "COMPANY");
                                    }}
                                >
                                    <Building2 size={20} />
                                    <span>Empresa</span>
                                </button>
                            </div>
                        </div>
                        {formik.values.type === "COMPANY" ? (
                            <div className={styles.formGroup}>
                                <label className={styles.formGroup_label} htmlFor="razon_social">Razón social</label>
                                <input
                                    className={`${styles.formGroup_input} ${formik.errors.razon_social && styles.error}`}
                                    type="text"
                                    name="razon_social"
                                    id="razon_social"
                                    placeholder="Nombre de la empresa"
                                    value={formik.values.razon_social}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        ) : (
                            <>
                                <div className={styles.formGroup}>
                                    <label className={styles.formGroup_label} htmlFor="name">Nombre</label>
                                    <input
                                        className={`${styles.formGroup_input} ${formik.errors.name && styles.error}`}
                                        type="text"
                                        name="name"
                                        placeholder="Nombre"
                                        id="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                <div className={styles.formGroup}>
                                    <label className={styles.formGroup_label} htmlFor="last_name">Apellido</label>
                                    <input
                                        className={`${styles.formGroup_input} ${formik.errors.last_name && styles.error}`}
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        placeholder="Apellido"
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </>
                        )}
                        <div className={styles.formGroup}>
                            <label className={styles.formGroup_label} htmlFor="phone">Teléfono</label>
                            <input
                                className={`${styles.formGroup_input} ${formik.errors.phone && styles.error}`}
                                type="text"
                                name="phone"
                                id="phone"
                                placeholder="Teléfono"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label className={styles.formGroup_label} htmlFor="jobBoardZones">
                                <MapPin size={16} />
                                Zona de trabajo
                            </label>
                            <div className={styles.formGroup_checkBoxContainer}>
                                <input
                                    className={styles.formGroup_checkbox}
                                    type="checkbox"
                                    name="allCountry"
                                    id="allCountry"
                                    checked={formik.values.allCountry}
                                    onChange={formik.handleChange}

                                />
                                <label htmlFor="allCountry">Todo el país</label>
                            </div>
                            {Array.isArray(formik.values.jobBoardZones) && !formik.values.allCountry &&
                                formik.values.jobBoardZones.filter(zone => zone.allCountry !== true).map((zone, index) => (
                                    <div key={index} className={styles.formGroup_workZone_citySelected}>
                                        <div className={styles.formGroup_workZone_citySelected_info}>
                                            <span className={styles.formGroup_workZone_citySelected_info_province}>{zone?.jurisdiccion?.name || zone?.name}</span>
                                            <span className={styles.formGroup_workZone_citySelected_info_city}>{zone?.city?.split(",")[0]}</span>
                                        </div>
                                        <button
                                            className={styles.formGroup_workZone_citySelected_button}
                                            type="button"
                                            onClick={() => {
                                                const newZones = formik.values.jobBoardZones.filter((_, i) => i !== index);
                                                formik.setFieldValue("jobBoardZones", newZones);
                                            }}
                                        >
                                            <Trash2 size={16} />
                                        </button>
                                    </div>
                                ))}
                            {!formik.values.allCountry && (
                                <div className={styles.formGroup_workZone}>
                                    <div className={styles.formGroup_workZone_title}>Provincia</div>
                                    <select
                                        className={styles.formGroup_workZone_select}
                                        name="jurisdictionList"
                                        id="jurisdictionList"
                                        value={selectedJurisdiction?.id}
                                        onChange={(e) => {
                                            const name = e.target.options[e.target.selectedIndex].dataset.name;
                                            setSelectedJurisdiction({
                                                id: e.target.value,
                                                name: name || ""
                                            });
                                        }}
                                    >
                                        <option value="">Seleccionar provincia</option>
                                        {JurisdictionList.map((jurisdiction, index) => (
                                            <option key={index} value={jurisdiction.id} data-name={jurisdiction.name}>
                                                {jurisdiction.name}
                                            </option>
                                        ))}
                                    </select>
                                    {cities.length > 0 && selectedJurisdiction.name !== "" && (
                                        <div className={styles.formGroup_workZone_cityList}>
                                            <div className={styles.formGroup_workZone_title}>Ciudades</div>
                                            <div className={styles.formGroup_workZone_cityList_container}>
                                                {cities.map((city, index) => {
                                                    const isChecked = formik.values.jobBoardZones.some(zone => zone.city === city.name);

                                                    return (
                                                        <label key={index} className={styles.formGroup_workZone_cityList_item}>
                                                            <input
                                                                className={styles.formGroup_workZone_cityList_item_checkbox}
                                                                type="checkbox"
                                                                checked={isChecked}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        const newZones = [
                                                                            ...formik.values.jobBoardZones,
                                                                            {
                                                                                jurisdiccion: selectedJurisdiction?.id,
                                                                                name: selectedJurisdiction?.name,
                                                                                city: city.name
                                                                            }
                                                                        ];
                                                                        formik.setFieldValue("jobBoardZones", newZones);
                                                                    } else {
                                                                        const updatedZones = formik.values.jobBoardZones.filter(zone => zone.city !== city.name);
                                                                        formik.setFieldValue("jobBoardZones", updatedZones);
                                                                    }
                                                                }}
                                                            />
                                                            <p style={{ color: "rgb(55 65 81)" }}>{city?.name?.split(",")[0]}</p>
                                                        </label>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </form>
                );
            case 'Mis rubros':
                return (
                    <div className={styles.profileModal_form_container}>
                        <SkillsEdit
                            editSkills={editSkills}
                            setEditSkills={setEditSkills}
                            userSkills={userSkills}
                            setFieldValue={formik.setFieldValue} />
                    </div>

                )
            case 'Descripción':
                return (
                    <form onSubmit={formik.handleSubmit} className={styles.form}>
                        <div className={styles.formGroup}>
                            <label className={styles.formGroup_label} htmlFor="description">Descripción</label>
                            <textarea
                                name="description"
                                id="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder={formik.values.type === "COMPANY" ? "Describe tu empresa . . ." : "Describe tu perfil . . ."}
                                maxLength={500}
                                className={styles.formGroup_textArea}
                            />
                            <div className={styles.formGroup_textArea_counter}>Máximo {formik.values.description.length}/500 caracteres</div>
                        </div>
                    </form>
                );
            case 'Redes':
                return (
                    <form onSubmit={formik.handleSubmit} className={styles.form}>
                        <div className={styles.formGroup}>
                            <label className={styles.formGroup_label} htmlFor="url_site_web">Sitio web</label>
                            <input
                                type="text"
                                name="url_site_web"
                                id="url_site_web"
                                placeholder="https://mi_sitio_web.com"
                                className={`${styles.formGroup_input} ${formik.errors.url_site_web && styles.error}`}
                                value={formik.values.url_site_web}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label className={styles.formGroup_label} htmlFor="facebook">Facebook</label>
                            <input
                                type="text"
                                name="facebook"
                                id="facebook"
                                placeholder="https://facebook.com/mi_cuenta"
                                className={`${styles.formGroup_input} ${formik.errors.facebook && styles.error}`}
                                value={formik.values.facebook}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label className={styles.formGroup_label} htmlFor="instagram">Instagram</label>
                            <input
                                type="text"
                                name="instagram"
                                id="instagram"
                                placeholder="https://instagram.com/mi_cuenta"
                                className={`${styles.formGroup_input} ${formik.errors.instagram && styles.error}`}
                                value={formik.values.instagram}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label className={styles.formGroup_label} htmlFor="linkedin">LinkedIn</label>
                            <input
                                type="text"
                                name="linkedin"
                                id="linkedin"
                                placeholder="https://linkedin.com/mi_cuenta"
                                className={`${styles.formGroup_input} ${formik.errors.linkedin && styles.error}`}
                                value={formik.values.linkedin}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    </form>
                );
            default:
                return null;
        }
    }



    const handleSubmit = async () => {
        await editUserData(formik.values, file, userData, userSkills, formik.values.description);

        setIsEditing(false);
    };

    return (
        <div className={styles.backDrop}>
            <AnimatePresence>
                <motion.div
                    className={styles.modalContainer}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={variants}
                    transition={{ duration: 0.3 }}
                >
                    <header className={styles.modalHeader}>
                        {isPhone ? (
                            !showLeftSide ? (
                                <div className={styles.modalHeader_mobile}>
                                    <button
                                        className={styles.modalHeader_backButton}
                                        onClick={handleBackToMenu}
                                    >
                                        <ChevronLeft size={20} />
                                    </button>
                                    <h2 className={styles.modalHeader_title}>{activeSection}</h2>
                                </div>
                            ) : (
                                <h2 className={styles.modalHeader_title}>Editar Perfil</h2>
                            )
                        ) : (
                            <h2 className={styles.modalHeader_title}>Editar Perfil</h2>
                        )}
                        <button className={styles.modalHeader_close} onClick={() => setIsEditing(false)}><X size={20} /></button>
                    </header>
                    <div className={styles.modalContent}>
                    {(!isPhone || showLeftSide) && (
                        <div className={`${styles.modalContent_left} ${isPhone ? styles.phoneWidth : ''}`}>
                            <div className={styles.modalContent_left_data}>
                                <div className={styles.modalContent_left_data_image}>
                                    {imagePreview ? (
                                        <img
                                            className={styles.modalContent_left_data_image_img}
                                            src={imagePreview}
                                            alt="user"
                                        />
                                    ) : (
                                        !imageError && profileImage ? (
                                            <img
                                                className={styles.modalContent_left_data_image_img}
                                                src={profileImage}
                                                alt="user"
                                            />
                                        ) : (
                                            <div className={styles.modalContent_left_data_image_initials}>{initials}</div>
                                        ))
                                    }
                                    <div className={styles.modalContent_left_data_image_overlay} onClick={handleEditImgClick}>
                                        <Camera size={20} />
                                    </div>
                                    <label htmlFor="image" className={styles.labelHidden}>
                                        <input
                                            ref={inputFileRef}
                                            type="file"
                                            name="image"
                                            id="image"
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file) {
                                                    setImagePreview(URL.createObjectURL(file));
                                                    setFile(file);
                                                    formik.setFieldValue("image", file);
                                                }
                                            }}
                                            className={styles.userInfo_label}
                                            accept="image/*"
                                        />
                                    </label>
                                </div>
                                <h3 className={styles.modalContent_left_data_name}>{userFullName}</h3>
                                <p className={styles.modalContent_left_data_mail}>{userData?.username}</p>
                            </div>
                            <div className={styles.modalContent_left_nav}>
                                <button
                                    className={activeSection === 'Datos personales' ? styles.modalContent_left_nav_button_active : styles.modalContent_left_nav_button}
                                    onClick={() => handleSectionChange('Datos personales')}
                                >
                                    <UserRound size={20} />
                                    <span style={{marginRight: "auto"}}>Datos personales</span>
                                    {isPhone && <ChevronRight size={20} />}
                                </button>
                                <button
                                    className={activeSection === 'Mis rubros' ? styles.modalContent_left_nav_button_active : styles.modalContent_left_nav_button}
                                    onClick={() => handleSectionChange('Mis rubros')}
                                >
                                    <HardHat size={20} />
                                    <span style={{marginRight: "auto"}}>Mis rubros</span>
                                    {isPhone && <ChevronRight size={20} />}
                                </button>
                                <button
                                    className={activeSection === 'Descripción' ? styles.modalContent_left_nav_button_active : styles.modalContent_left_nav_button}
                                    onClick={() => handleSectionChange('Descripción')}
                                >
                                    <Edit3 size={20} />
                                    <span style={{marginRight: "auto"}}>Descripción</span>
                                    {isPhone && <ChevronRight size={20} />}
                                </button>
                                <button
                                    className={activeSection === 'Redes' ? styles.modalContent_left_nav_button_active : styles.modalContent_left_nav_button}
                                    onClick={() => handleSectionChange('Redes')}
                                >
                                    <LinkIcon size={20} />
                                    <span style={{marginRight: "auto"}}>Redes</span>
                                    {isPhone && <ChevronRight size={20} />}
                                </button>
                            </div>

                        </div>
                    )}
                    {(!isPhone || !showLeftSide) && (
                        <div className={styles.modalContent_right}>
                            {renderContent()}
                        </div>
                    )}
                    </div>
                    <div className={styles.modalFooter}>
                        {!formik.isValid && (
                            <div className={styles.toolTipError}>
                                <div className={styles.toolTipError_list}>
                                    <Info size={16} />
                                    Ver errores de validación
                                </div>
                                <ChevronDown size={16} onClick={() => setShowErrors(!showErrors)} className={styles.toolTipError_icon} />
                                {showErrors && (
                                    <div className={styles.toolTipError_errorMenu}>
                                        <ErrorMenu
                                            setShowErrors={setShowErrors}
                                            errors={formik.errors}
                                            handleSectionChange={handleSectionChange} />
                                    </div>
                                )}
                            </div>
                        )}
                        <div className={styles.modalFooter_buttonContainer}>
                            <button className={styles.modalFooter_btnClose} onClick={() => setIsEditing(false)}>Cancelar</button>
                            <button
                                disabled={!formik.isValid || userSkills?.length === 0}
                                className={styles.modalFooter_submit}
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Guardar cambios
                            </button>
                        </div>
                    </div>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userSkills: state.register.userSkills,
        cities: state.register.cities,
    };
}

export default connect(mapStateToProps, {
    sendSkillsProfile,
    testDescriptionEdit,
    getCities,
    getUserSkill,
    editUserData
})(ProfileEditModalNew);