import React, { useEffect, useState } from "react";
import styles from "./PerfilMain.module.scss";
import { getPortfolio } from "../../../store/actions/registerActions";
import { testDescriptionEdit } from "../../../store/actions/profileActions";
import styled from "styled-components";
import { connect } from "react-redux";
import ProfileEditModalNew from "../../UI/ProfileEditModal/ProfileEditModalNew";
import NavBar from "./NavBar/NavBar";
import UserInfo from "../PerfilMain/UserInfo/UserInfo";
import MisComentarios from "./MisComentarios/MisComentarios";
import MisProyectos from "./MisProyectos/MisProyectos";
import MisProductos from "./MisProductos/MisProductos";
import MisAccesos from "./MisAccesos/MisAccesos";
import MisCotizacionesDeMateriales from "./MisCotizacionesDeMateriales/MisCotizacionesDeMateriales";
import MiCuenta from "./MiCuenta/MiCuenta";
import NewNav from "../../Nav/NewNav";
import RegisteredUserProfileLoader from "../../RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader";
import Resumen from "./Resumen/Resumen";
import { motion } from "framer-motion";
import { PackagePlus, Star } from "lucide-react";
import { openCalificationModal } from "../../../store/actions";

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding-right: 5%;
  padding-left: 5%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 60px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }`;

const PerfilMain = ({
  userId,
  userData,
  getPortfolio,
  myPerson,
  perfilAjeno,
  profileVisualizerData,
  testDescriptionEdit,
  openCalificationModal,
  ...props
}) => {
  const [createNewPortfolio, setCreateNewPortfolio] = useState(false);
  const [isActive, setIsActive] = useState("Resumen");
  const [isEditing, setIsEditing] = useState(false);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [infoReady, setInfoReady] = useState(false);

  const handleResize = () => {
    setOcultarEnMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    handleResize();
    window.scrollTo({ top: 0, behavior: 'smooth' });

    return () => {
      window.removeEventListener('scroll', handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const handleNewPortfolioClick = (value) => {
    setCreateNewPortfolio(value);
  };


  const renderSection = () => {
    switch (isActive) {
      case 'Resumen':
        return (
          <Resumen
            myPerson={myPerson}
            userData={userData}
            profileVisualizerData={profileVisualizerData}
            perfilAjeno={perfilAjeno}
            setInfoReady={setInfoReady}
          />
        )
      case 'Productos/Servicios':
        return (
          <div className={styles.sectionWrapper}>
            <div className={styles.sectionWrapper_header}>
              <h2 className={styles.sectionWrapper_header_title}>Productos y servicios</h2>
              {myPerson && (
                <button
                  className={styles.sectionWrapper_header_button}
                  onClick={() => handleNewPortfolioClick(true)}
                >
                  <PackagePlus size={20} />
                  Crear nuevo
                </button>
              )}
            </div>
            <MisProductos
              createNewPortfolio={createNewPortfolio}
              perfilAjeno={perfilAjeno}
              myPerson={myPerson}
              handleNewPortfolioClick={handleNewPortfolioClick}
              userData={userData}
              setInfoReady={setInfoReady}
            />
          </div>
        )
      case 'Calificaciones':
        return (
          <div className={styles.sectionWrapper}>
            <div className={styles.sectionWrapper_header}>
              <h2 className={styles.sectionWrapper_header_title}>Calificaciones y reseñas</h2>
              {!myPerson && (
                <button className={styles.sectionWrapper_header_button} onClick={() => openCalificationModal(profileVisualizerData)}>
                  <Star size={20} />
                  Calificar
                </button>
              )}
            </div>
            <MisComentarios perfilAjeno={perfilAjeno} myPerson={myPerson} setInfoReady={setInfoReady} />
          </div>
        )
      case 'Proyectos publicados':
        return (
          <div className={styles.sectionWrapper}>
            <div className={styles.sectionWrapper_header}>
              <h2 className={styles.sectionWrapper_header_title}>Proyectos publicados</h2>
            </div>
            <MisProyectos fromPage myPerson={myPerson} perfilAjeno={perfilAjeno} userData={userData} setInfoReady={setInfoReady} />
          </div>
        )
      case 'Accesos a obras':
        return (
          <div className={styles.sectionWrapper}>
            <div className={styles.sectionWrapper_header}>
              <h2 className={styles.sectionWrapper_header_title}>Accesos solicitados a obras</h2>
            </div>
            <MisAccesos fromPage myPerson={myPerson} setInfoReady={setInfoReady} />
          </div>
        )
      case 'Cotización de materiales':
        return (
          <div className={styles.sectionWrapper}>
            <div className={styles.sectionWrapper_header}>
              <h2 className={styles.sectionWrapper_header_title}>Solicitud de materiales</h2>
            </div>
            <MisCotizacionesDeMateriales myPerson={myPerson} setInfoReady={setInfoReady} profileVisualizerData={profileVisualizerData} />
          </div>
        )
      case 'Configuración':
        return (
          <div className={styles.sectionWrapper}>
            <div className={styles.sectionWrapper_header}>
              <h2 className={styles.sectionWrapper_header_title}>Configuración de la cuenta</h2>
            </div>
            <MiCuenta setInfoReady={setInfoReady} />
          </div>
        )
      default:
        return null;
    }
  }


  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      <Wrapper>
        <UserInfo
          fromPage
          setIsEditing={setIsEditing}
          userData={perfilAjeno}
          profileVisualizerData={profileVisualizerData}
          myPerson={myPerson} />
        <NavBar
          perfilAjeno={perfilAjeno}
          myPerson={myPerson}
          setIsActive={setIsActive}
          setInfoReady={setInfoReady}
          isActive={isActive} />

        <div className={styles.mainContainer}>
          {!infoReady &&
            <motion.div
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.3 }}
              className={styles.skeletonContainer}>
              <RegisteredUserProfileLoader />
            </motion.div>
          }
          <div className={`${infoReady ? styles.readyContainer : styles.notReady}`}>
            {renderSection()}
          </div>
        </div>
        {isEditing && (
          <ProfileEditModalNew setIsEditing={setIsEditing} userData={userData} />
        )}
      </Wrapper>
    </Container >
  );
};

const mapStateToProps = (state, ownProps) => {

  return {
    perfilAjeno: ownProps.perfilAjeno,
    userData: state.general.userData,
  };
};

export default connect(mapStateToProps, {
  getPortfolio,
  testDescriptionEdit,
  openCalificationModal,
})(PerfilMain);
