import React, { useState, useEffect } from "react";
import styles from "./PortfolioItem.module.scss";
import { connect } from "react-redux";
import { deletePortfolio, getPortfolioQuestions, openMainModal } from "../../../store/actions";
import { ReactComponent as ClockIcon } from "../../../assets/svg/clockIcon.svg";
import { ReactComponent as CommentsIcon } from "../../../assets/svg/commentPost.svg";
import PortfolioItemModal from "./PortfolioItemModal";
import RelativeTimeElement from "@github/relative-time-element";
import PortfolioQuestion from "./PortfolioQuestion";
import PortfolioAnswer from "./PortfolioAnswer";
import { ReactComponent as ProductIcon } from "../../../assets/svg/ProductIcon.svg";
import { ReactComponent as ServiceIcon } from "../../../assets/svg/ServiceIcon.svg";
import { Trash2, MessageCircle } from "lucide-react";


const PortfolioItem = ({
  portfolio,
  deletePortfolio,
  userData,
  myPerson,
  getPortfolioQuestions,
  portfolioQuestions,
  openMainModal }) => {
  const [showPortfolio, setShowPortfolio] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);

  const coverImage = portfolio?.portfolio_images[0]?.image;
  useEffect(() => {
    if (!portfolioQuestions[portfolio.id]) {
      getPortfolioQuestions(portfolio.id);
    }
  }, [portfolio.id, portfolioQuestions, getPortfolioQuestions]);

  // Formateamos fechas
  const formatDate = (date) => {
    const utcDate = new Date(date);
    const options = { day: 'numeric', month: 'short' };
    return utcDate.toLocaleDateString('es-ES', options);
  };

  // Maneja el borrado del portfolio
  const handleDeletePortfolio = () => {
    openMainModal({
      title: "Eliminar portfolio",
      body: "¿Estás seguro que deseas eliminar este publicación de tu perfil?",
      iconType: "warning",
      confirmHandler: () => {
        deletePortfolio(parseInt(portfolio.id));
      },
    });
  };

  const portfolioQuestionsForItem = portfolioQuestions[portfolio.id] || [];
  const questionsNotAnswered = portfolioQuestionsForItem.filter((question) => question?.answers?.length === 0);

  return (
    <div className={styles.container}>
      <div className={styles.container_image} onClick={() => setShowPortfolio(true)}>
        <img className={styles.container_image_img} src={coverImage} alt="portfolio" />
        {myPerson && (
          <button className={styles.container_buttons_delete} onClick={(e) => { handleDeletePortfolio(); e.stopPropagation() }}>
            <Trash2 size={16} />
          </button>
        )}
      </div>
      <div className={styles.container_info} onClick={() => setShowPortfolio(true)}>
        <div className={styles.container_info_text}>
          <div className={styles.container_info_text_pill} />
          <h4 className={styles.container_info_text_title}>{portfolio?.name}</h4>
          {portfolio?.type && (
            <div className={styles.container_info_text_icons}>
              <div className={styles.container_info_footer}>
                <div className={styles.container_info_footer_clock}>
                  <ClockIcon width={15} height={15} />
                  <relative-time lang="es" tense="past" datetime={portfolio?.createdAt}></relative-time>
                </div>
              </div>
              {portfolio?.type === "producto" ? (
                <span className={styles.container_info_text_icons_backGround} title="Producto">
                  <ProductIcon width={20} height={20} />
                </span>
              ) : (
                <span className={styles.container_info_text_icons_backGround} title="Servicio">
                  <ServiceIcon width={25} height={25} />
                </span>
              )}
              {portfolio?.price && (
                <span className={styles.container_info_text_price}>
                  $ {parseInt(portfolio?.price, 10)}
                </span>
              )}
              {portfolio?.currencyType && (
                <span className={styles.container_info_text_price}>
                  {portfolio?.currencyType}
                </span>
              )}
              {portfolio?.priceUnit && (
                <span className={styles.container_info_text_price}>
                  {portfolio?.priceUnit}
                </span>
              )}
            </div>
          )}
          <p className={styles.container_info_text_description}>{portfolio?.description}</p>
        </div>
      </div>
      <div className={styles.container_buttons}>
        {myPerson ? (
          <div className={styles.container_buttons_title}>
            <div className={styles.container_info_footer_comments}>
              <MessageCircle size={16} />
              <p>{portfolioQuestionsForItem.length} &nbsp;
                {(portfolioQuestionsForItem.length > 1) || (portfolioQuestionsForItem.length === 0) ? "preguntas" : "pregunta"}</p>
            </div>
            <button className={styles.container_buttons_questions_answerBtn}
              disabled={questionsNotAnswered.length === 0}
              onClick={() => setShowAnswer(true)}>
              Responder
              {questionsNotAnswered.length >= 1 && (
                <span className={styles.container_buttons_questions_answerBtn_number}>{questionsNotAnswered.length}</span>
              )}
            </button>
          </div>
        ) : (
          <div className={styles.container_buttons_title}>
            <div className={styles.container_info_footer_comments}>
              <MessageCircle size={16} />
              {portfolioQuestionsForItem.length} &nbsp;{(portfolioQuestionsForItem.length > 1) || (portfolioQuestionsForItem.length === 0) ? "preguntas" : "pregunta"}
            </div>
            <button className={styles.container_buttons_questions_btn} onClick={() => setShowQuestions(true)}>Preguntar</button>
          </div>
        )}
      </div>
      {showPortfolio && (
        <PortfolioItemModal
          item={portfolio}
          myPerson={myPerson}
          userData={userData}
          closeModal={() => setShowPortfolio(false)}
          portfolioQuestionsForItem={portfolioQuestionsForItem} />
      )}
      {showQuestions && (
        <PortfolioQuestion portfolioId={portfolio.id} closeModal={() => setShowQuestions(false)} userData={userData} />
      )}
      {showAnswer && (
        <PortfolioAnswer portfolioId={portfolio.id} closeModal={() => setShowAnswer(false)} portfolioQuestions={questionsNotAnswered} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    portfolioQuestions: state.profile.portfolioQuestions,
  };
};

export default connect(mapStateToProps, { deletePortfolio, getPortfolioQuestions, openMainModal })(PortfolioItem);