import * as actionType from "../actions/types";

const INITIAL_STATE = {
  file: {},
  dataProfile: false,
  skillProfile: false,
  shareProfile: false,
  proyectos: [],
  setProjectLoading: false,
  projectQuestions: [],
  project: [],
  postulations: [],
  postulationsInProject: [],
  comments: {},
  userCalifications: [],
  proyectoId: null,
  location: {
    lat: null,
    lng: null,
  },
  quotationToEdit: [],
  projectToEdit: [],
  subProjectId: null,
  subProjectBudgets: {},
  updatedSubProjectList: [],
  subProjectsType: [],
  projectIdForSub: null,
  proyectoPrivadoPorId: [],
  projectStep1Id:[],
  showSubprojects: false,
  sendProject: null,
  sendSubprojects: [],
  sendSubQuotations: [],
  portfolioQuestions: [],
  quotation: [],
  viewQuotations: [],
  profileBudgetSender: [],
  newSkill: null,
  subProjectsEdit: null,
  menuShow: false,
  openMainId: null,
  goBackToCategoryList: false,
  quotations: [],
  profileVisits: [],
  subQuotations: [],
  subQuotationsPostulations: [],
  suscriptionLoading: false,
  cancelSuscriptionLoading: false,
  isFreeTrialSuccess: false,
  publicQuotation: [],
  publicProject: [],
  adjudicatedProject: [],
  materialsBySubquotation: [],
  usersInJurisdiction: [],
};

//Elimina un subProjecto de un proyecto aún no creado
const removeSubProject = (state, subProyectoListId) => {

  return {
    ...state,
    sendSubprojects: state.sendSubprojects.filter(
      (subProject) => subProject.subProyectoListId !== subProyectoListId
    ),
  };
};

const setProjectIdForSubproject = (state, project) => {
  let proyectoId = state.proyectos.find((projectState) => {
    return (
      projectState.name === project.name &&
      projectState.description === project.description
    );
  });
  return {
    ...state,
    projectIdForSub: proyectoId.id,
  };
};

const setSendProjectToReducer = (state, payload) => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  let startDate = yyyy + "-" + mm + "-" + dd;

  let sendProject = {
    ...payload.project,
    image: payload.image,
    startDate: startDate,
  };
  return {
    ...state,
    sendProject: sendProject,
  };
};

const setSendSubProjectToReducer = (state, payload) => {

  let splitOptionValues = payload.name.split("*");

  let sendSubProject = {
    name: splitOptionValues[1],
    description: payload.description,
    startDate: payload.startDate || payload.startDateSub,
    endDate: payload.endDate,
    link: payload.link,
    subProyectoListId: splitOptionValues[0],
    plantillaDePresupuesto: payload.plantillaDePresupuesto, 
    computoMetrico: payload.metricComputation,
    especificacionesTecnicas: payload.technicalSpecifications,
    planosArchivo: payload.planosFile,
  };

  return {
    
    ...state,
    sendSubprojects: state.sendSubprojects.concat(sendSubProject),
  };
};

const setEditSendSubProjectReducer = (state, payload) => {
  let editSubprojectArray = state.sendSubprojects.filter((sub) => {
    return JSON.stringify(sub) !== JSON.stringify(payload.subProjectToEdit);
  });
  return {
    ...state,
    sendSubprojects: editSubprojectArray.concat(payload.sendSubproject),
  };
};


const setSendSubProjectFromEdit = (state, subprojects) => {
  let formatedArray = subprojects.map((sub) => {
    let endDate = sub.endDate.slice(0, 10);
    let startDate = sub.startDate.slice(0, 10);
    let newSub = state.projectBudgets.find((item) => item.id === sub.id);
    return {
      ...sub,
      endDate: endDate,
      startDate: startDate,
      budgets: [...newSub.budgets],
    };
  });
  return {
    ...state,
    subProjectsEdit: formatedArray,
  };
};

const setSendSubQuotationToReducer = (state, payload) => {
  let splitOptionValues = payload.isEditing ? payload.name : payload.nameSub.split("*");

  let sendSubQuotation = {
    name: payload.isEditing ? splitOptionValues : splitOptionValues[1],
    description: payload.isEditing ? payload.description : payload.descriptionSub,
    subquotationListId: payload.isEditing ? payload.subquotationListId : splitOptionValues[0],
    plantillaDeCotizacion: payload.isEditing ? payload.plantillaDeCotizacion : payload.file,
  };

  if (payload.isEditing) {
    const editIndex = state.sendSubQuotations.findIndex(
      (subQuotation) => subQuotation.subquotationListId === sendSubQuotation.subquotationListId
    );

    if (editIndex !== -1) {
      const updatedSendSubQuotations = [...state.sendSubQuotations];
      updatedSendSubQuotations[editIndex] = sendSubQuotation;

      return {    
        ...state,
        sendSubQuotations: updatedSendSubQuotations,
      };
    }
  }

  return {
    ...state,
    sendSubQuotations: state.sendSubQuotations.concat(sendSubQuotation),
  };
};

//Elimina un rubro agregado a la cotizacion de materiales
const removeSubQuotation = (state, subQuotationListId) => {
  return {
    ...state,
    sendSubQuotations: state.sendSubQuotations.filter(
      (subQuotation) => subQuotation.subquotationListId !== subQuotationListId
    ),
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SET_GO_BACK_TO_CATEGORY_LIST:
      return {
        ...state,
        goBackToCategoryList: action.payload,
      };
    case actionType.SET_NEW_PROFILE_IMAGE:
      return {
        ...state,
        file: action.payload,
      };
    case actionType.OPEN_EDIT_PROFILE_DATA:
      return {
        ...state,
        dataProfile: true,
      };
    case actionType.CLOSE_EDIT_PROFILE_DATA:
      return {
        ...state,
        dataProfile: false,
      };
    case actionType.OPEN_EDIT_PROFILE_SKILL:
      return {
        ...state,
        skillProfile: true,
      };
    case actionType.CLOSE_EDIT_PROFILE_SKILL:
      return {
        ...state,
        skillProfile: false,
      };
    case actionType.SET_NEWSKILL_TO_LIST:
      return {
        ...state,
        newSkill: action.payload,
      };
    case actionType.SET_USERS_IN_JURISDICTION:
      return {
        ...state,
        usersInJurisdiction: action.payload,
      };
    case actionType.GET_POSTULATIONS:
      return {
        ...state,
        postulations: action.payload,
      };
    case actionType.GET_PROJECTS:
      return {
        ...state,
        proyectos: action.payload,
      };
    case actionType.GET_PROJECT_QUESTIONS:
      return {
        ...state,
        projectQuestions: action.payload,
      };
    case actionType.GET_PRIVATE_PROJECT_BY_ID:
      return {
        ...state,
        proyectoPrivadoPorId: action.payload,
      };
    case actionType.SET_PRIVATE_PROJECT_LOADING:
      return {
        ...state,
        setProjectLoading: action.payload,
      };
    case actionType.GET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    case actionType.SET_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          lat: action.payload.lat,
          lng: action.payload.lng,
        },
      };
    case actionType.GET_SUBPROJECTS_TYPE:
      return {
        ...state,
        subProjectsType: action.payload,
      };
      case actionType.PROFILE_DATA_OF_USER_SENDER:
        return {
          ...state,
          profileBudgetSender: action.payload,
        };
      case actionType.GET_SUBPROJECT_BUDGETS:
        return {
          ...state,
          subProjectBudgets: {...state.subProjectBudgets, [action.payload.subProjectId]: action.payload.budgets}
        };
      case actionType.CLEAR_SUBPROJECT_BUDGETS:
        return {
          ...state,
          subProjectBudgets: {},
        };
      case actionType.UPDATE_SUBPROJECTS_LIST:
      return {
        ...state,
        updatedSubProjectList: action.payload,
      };
      case actionType.GET_POSTULATION_PROJECT_STATUS:
        return {
          ...state,
          postulationsInProject: action.payload,
        };
    case actionType.SET_QUOTATION_TO_EDIT:
      return {
        ...state,
        quotationToEdit: action.payload,
      };
    case actionType.GET_QUOTATIONS_TYPE:
      return {
        ...state,
        quotations: action.payload,
        page: action.page,
        itemCount: action.payload.itemCount,
        take: action.payload.take,
        loading: false,
        isFilter: action.isFilter,
        searchParams: action.searchParams,
      };
    case actionType.SET_PUBLIC_QUOTATION:
      return {
        ...state,
        publicQuotation: action.payload,
      };
    case actionType.GET_QUOTATION:
      return {
        ...state,
        quotation: action.payload,
      };
    case actionType.SET_ADJUDICATED_PUBLIC_PROJECT:
      return {
        ...state,
        adjudicatedProject: action.payload,
      };
      case actionType.SET_PUBLIC_PROJECT:
        return {
          ...state,
          publicProject: action.payload,
        };
    case actionType.GET_MY_QUOTATIONS_USERPANEL:
      return {
        ...state,
        viewQuotations: action.payload,
      };
    case actionType.USER_CALIFICATIONS:
      return {
        ...state,
        userCalifications: action.payload,
      };
    case actionType.GET_VISITS:
      return {  
        ...state,
        profileVisits: action.payload,
      };
    case actionType.GET_SUBQUOTATIONS_TYPE:
      return {
        ...state,
        subQuotations: action.payload,
      };
    case actionType.GET_SUB_QUOTATIONS_POSTULATIONS:
      return {
        ...state,
        subQuotationsPostulations: action.payload,
      };
    case actionType.CLEAR_QUOTATIONS:
      return {
        ...state,
        quotations: [],
      };
    case actionType.CLEAR_SUBQUOTATIONS:
      return {
        ...state,
        sendSubQuotations: [],
      };
      case actionType.GET_PORTFOLIO_QUESTIONS:
        return {
          ...state,
          portfolioQuestions: {
            ...state.portfolioQuestions,
            [action.idProyecto]: action.payload, 
          },
        };
    case actionType.OPEN_SHARE_PROFILE:
      return {
        ...state,
        shareProfile: action.payload,
      };
    case actionType.OPEN_PROFILE_MENU:
      return {
        ...state,
        menuShow: action.payload,
      };
    case actionType.SET_PROJECT_ID:
      return setProjectIdForSubproject({ ...state }, action.payload);
    case actionType.SET_PROJECTSTEP1_ID:
      return {
        ...state,
        projectStep1Id: action.payload,
      }
    case actionType.SET_SEND_PROJECT:
      return setSendProjectToReducer({ ...state }, action.payload);
    case actionType.SET_SEND_SUBPROJECT:
      return setSendSubProjectToReducer(
        { ...state },
        action.payload,
        action.plantilla
      );
    case actionType.SET_NEW_SUBPROJECT:
      return {
        ...state,
        subProjectsEdit: state.subProjectsEdit.concat(action.payload),
      };
    case actionType.SET_DELETE_SUBPROJECT:
      return removeSubProject({ ...state }, action.payload);
    case actionType.SET_EDIT_SUBPROJECT:
      return setEditSendSubProjectReducer({ ...state }, action.payload);

    case actionType.SET_SEND_SUBQUOTATION:
      return setSendSubQuotationToReducer(
        { ...state },
        action.payload,
        action.plantilla
      );
    case actionType.SET_DELETE_SUBQUOTATION:
      return removeSubQuotation({ ...state }, action.payload);
    case actionType.RESET_SUBPROJECTS:
      return {
        ...state,
        sendSubprojects: [],
      };
    case actionType.SET_SUBPROJECTS_FROM_EDIT:
      return setSendSubProjectFromEdit({ ...state }, action.payload);
    case actionType.RESET_ID_PROJECT:
      return {
        ...state,
        projectIdForSub: null,
      };
    case actionType.SET_PROJECT_TO_EDIT:
      return {
        ...state,
        projectToEdit: action.payload,
      };
    case actionType.RESET_PROJECT_TO_EDIT:
      return {
        ...state,
        projectToEdit: [],
      };
    case actionType.SET_SUBPROJECT_ID:
      return {
        ...state,
        subProjectId: action.payload,
      };
    case actionType.SET_OPEN_MAIN_ID:
      return {
        ...state,
        openMainId: action.payload,
      };
    case actionType.SUSCRIPTION_LOADING:
      return {
        ...state,
        suscriptionLoading: !state.suscriptionLoading,
      };
    case actionType.ACTIVATE_FREE_TRIAL:
      return {
        ...state,
        isFreeTrialSuccess: action.payload,
      }
    case actionType.CANCEL_SUSCRIPTION_LOADING:
      return {
        ...state,
        cancelSuscriptionLoading: !state.cancelSuscriptionLoading,
      };
      case actionType.GET_MATERIALS_SUCCESS:
      return {
        ...state,
        materialsBySubquotation: {
          [action.payload.subquotationId]: action.payload.materials,
        },
      };
    case actionType.GET_MATERIALS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
