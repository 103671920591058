import React from "react";
import styles from "./FeedCardUsersReacted.module.scss";
import { motion } from "framer-motion";

const FeedCardUsersReacted = ({ users, handleProfile }) => {
    return (
        <div className={styles.menu}>
            {Array.isArray(users) &&
                users.map((user, index) => {
                    let completeName = user.user.type === "COMPANY" ? user.user.razon_social : user.user.name + " " + user.user.last_name;
                    let imageProfile = user.user.image_profile;
                    return (
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            className={styles.menu_item} key={index} onClick={() => handleProfile(user.id, completeName)}>
                            <img src={imageProfile} alt="" className={styles.menu_item_img} />
                            <p className={styles.menu_item_txt}>{completeName}</p>
                        </motion.div>
                    )
                })}
        </div>
    );
}

export default FeedCardUsersReacted;