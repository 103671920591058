import React from "react";
import styles from "./FeaturesSection.module.scss";
import {
    History,
    CirclePercent,
    ListPlus,
    FileCheck,
    DollarSign
} from "lucide-react";

const FeaturesSection = () => {

    const featuresToDisplay = [
        {
            title: "3. Recibe Presupuestos",
            description: "Los proveedores certificados de nuestra plataforma te enviarán sus mejores ofertas directamente a través del sistema.",
            icon: DollarSign,
        },
        {
            title: "4. Gestiona y Compara",
            description: "Descarga, compara y gestiona todos los presupuestos recibidos directamente desde la plataforma para tomar la mejor decisión.",
            icon: FileCheck,
        }
    ];

    return (
        <div className={styles.featureBox}>
            <div className={styles.feature}>
                <div className={styles.feature_icon}>
                    <ListPlus size={24} />
                </div>
                <div className={styles.feature_body}>
                    <h3 className={styles.feature_title}>1. Arma tu listado</h3>
                    <p className={styles.feature_description}>Crea tu lista de materiales utilizando nuestra base de datos pre-cargada o agrega items personalizados según
                        tus necesidades específicas</p>
                </div>
            </div>
            <div className={styles.featureRelative}>
                <div className={styles.feature_line} />
                <h3 className={styles.feature_subTitle}>2. Elige tu camino:</h3>
                <div className={styles.featureRelative_margin}>
                    <div className={styles.feature}>
                        <div className={styles.feature_icon}>
                            <History size={24} />
                        </div>
                        <div className={styles.feature_body}>
                            <h3 className={styles.feature_title}>Estimación Rápida</h3>
                            <p className={styles.feature_description}>Obtén una estimación instantánea basada en nuestros registros históricos y precios de referencia del mercado.</p>
                        </div>
                    </div>
                </div>
                <div className={styles.featureRelative_margin}>
                    <div className={styles.featureGreen}>
                        <div className={styles.featureGreen_icon}>
                            <CirclePercent size={24} />
                        </div>
                        <div className={styles.feature_body}>
                            <h3 className={styles.feature_title}>Precios Reales</h3>
                            <p className={styles.feature_description}>Publica tu listado y recibe cotizaciones actualizadas directamente de proveedores certificados.</p>
                        </div>
                    </div>
                </div>
            </div>
            {featuresToDisplay.map((feature, index) => (
                <div className={styles.feature} key={index}>
                    <div className={styles.feature_icon}>
                        <feature.icon size={24} />
                    </div>
                    <div className={styles.feature_body}>
                        <h3 className={styles.feature_title}>{feature.title}</h3>
                        <p className={styles.feature_description}>{feature.description}</p>
                    </div>
                </div>
            ))
            }
        </div >
    );
}

export default FeaturesSection;