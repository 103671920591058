import React, { useEffect, useState } from "react";
import styles from "./PrivateProyects.module.scss";
import styled from "styled-components";
import Botonera from "../../components/Botonera/Botonera";
import { connect } from "react-redux";
import {
  getProjectsByType,
  openFeedTextModal,
  clearProjects,
  closeMainModal,
  resetProjectToEdit,
} from "../../store/actions";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import useSuscriptionModalTimeout from "../../hooks/useSuscriptionModalTimeout";
import Pagination from "../../hooks/pagination";
import AllProjectsItemEmptyCard from "../../components/Dashboard/AllProjectsItem/AllProjectsItemEmptyCard";
import FilterPrivateProjects from "../../components/Filters/FilterPrivateProjects/FilterPrivateProjects";
import { motion } from "framer-motion";
import { ReactComponent as NoResultsIcon } from "../../assets/svg/NoResultsMagnifier.svg";
import { FileText, FileSearch } from "lucide-react";
import NewNav from "../../components/Nav/NewNav";
import { useNavigate } from "react-router-dom";
import { fireAnalytics } from "../../utils/firebase";
import RegisteredUserProfileLoader from "../../components/RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader";
import FeaturesSection from "./FeaturesSection";
import Steps from "./Steps";
import FeaturedContractors from "./FeaturedContractors";
import FinalBanner from "./FinalBanner";
import usePremiumCheck from "../../hooks/usePremiumCheck";
import useSectionTracker from "../../hooks/useSectionTracker";

const variants = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0 },
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 5%;
  padding-left: 5%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

function PrivateProjects({

  allProjects,
  getProjectsByType,
  clearProjects,
  closeMainModal,
  loading,
  userData,
  totalPrivateProjects,
}) {

  const [isOpen, setIsOpen] = useState(false);
  const pageNumberLimit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [showProjectList, setShowProjectList] = useState(false);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const obrasPrivadasTracker = useSectionTracker('obras-privadas', {
    trackVisits: true,
    expirationDays: 30
  });

  useSuscriptionModalTimeout();

  const navigate = useNavigate();

  const premiumCheck = usePremiumCheck();

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  useEffect(() => {
    handleResize();
    clearProjects();
    fireAnalytics("obras_privadas_view", { action: "view", obras_privadas_user_viewer_info: userData.id });
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getProjectsByType("PRIVATE", currentPage, pageNumberLimit);
  }, [currentPage, clearProjects]);


  function setModalOpen(isOpen) {
    closeMainModal();
    setIsOpen(isOpen);
  }

  const handleNewOpenProject = () => {
    resetProjectToEdit();
    navigate("/obrasprivadas/crear");
    closeMainModal();
    fireAnalytics("intent_create_obras_privadas", { action: "create", intent_create_obra_privada: userData.id });
  };


  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    response: allProjects,
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const onPrevClick = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setCurrentPage((prev) => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage((prev) => prev + 1);
  };

  const handleViewList = () => {
    fireAnalytics("obras_privadas_view_list", { action: "view_list", obras_privadas_user_list_info: userData.id });
    setShowProjectList(true);
    obrasPrivadasTracker.updateLastVisit();
  }

  const newPrivateProjectsCount = obrasPrivadasTracker.countNewItems(totalPrivateProjects, 'createdAt');

  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      <div className={styles.container}>
        {!showProjectList ? (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ duration: 0.5 }}
          className={styles.infoContainer}>
            <div className={styles.header}>
              <div className={styles.header_titleContainer}>
                <h1 className={styles.header_title}>
                  Conectamos tu <span style={{ color: "#234ba2" }}>Proyecto</span> con los Mejores Profesionales
                </h1>
                <p className={styles.header_subtitle}>
                  Simplificamos el proceso de contratación. Publica tu obra y recibe propuestas de contratistas calificados y verificados.
                </p>
                <div className={styles.header_buttonContainer}>
                  <button className={styles.header_buttonPrimary} onClick={premiumCheck(handleNewOpenProject)}>
                    <div className={styles.header_buttonIcon}>
                    <FileText size={24} style={{ minWidth: "2.4rem" }} />
                    </div>
                    <span>Publicar proyecto</span>
                  </button>
                  <button className={styles.header_button} onClick={handleViewList}>
                    <div className={styles.header_buttonIcon}>
                    <FileSearch size={24} style={{ minWidth: "2.4rem" }} />
                    </div>
                    <div className={styles.header_button_text}>
                    Explorar proyectos
                    {newPrivateProjectsCount > 0 && <span className={styles.header_buttonCounter}>{newPrivateProjectsCount}</span>}
                    </div>
                  </button>
                </div>
              </div>
              <div className={styles.header_featuresContainer}>
                <FeaturesSection />
              </div>
            </div>
            <div className={styles.steps}>
              <Steps />
            </div>
            <div className={styles.footer}>
              <FeaturedContractors />
              <FinalBanner />
            </div>
          </motion.div>
        ) : (
          <>
            <FilterPrivateProjects />
            {Array.isArray(allProjects) && allProjects.length === 0 && !loading ? (
              <div className={styles.emptyState}>
                <EmptyState title="No hay resultados para su búsqueda." icon={<NoResultsIcon width={80} height={80} />} />
              </div>
            ) : loading ? (
              <RegisteredUserProfileLoader />
            ) : <div className={styles.AllProjects} id="allProjects">
              {allProjects?.itemCount || allProjects?.length > 0 ? (
                <Pagination
                  {...paginationAttributes}
                  onPrevClick={onPrevClick}
                  onNextClick={onNextClick}
                  onPageChange={onPageChange}
                  setModalOpen={setModalOpen}
                  isOpen={isOpen}
                />
              ) : (
                <AllProjectsItemEmptyCard />
              )}
            </div>}
          </>
        )}
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.dashboard.loading,
    allProjects: state.dashboard.viewProjects,
    searchParams: state.dashboard.searchParams,
    isFilter: state.dashboard.isFilter,
    page: state.dashboard.page,
    itemCount: state.dashboard.itemCount,
    take: state.dashboard.take,
    proyectos: state.profile.proyectos,
    postulaciones: state.profile.postulations,
    userData: state.general.userData,
    totalPrivateProjects: state.dashboard.totalPrivateProjects,
  };
};

export default connect(mapStateToProps, {
  getProjectsByType,
  clearProjects,
  closeMainModal,
  resetProjectToEdit,
  openFeedTextModal,
})(PrivateProjects);
