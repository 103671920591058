import React from "react";
import styles from "./ProgressBarLoader.module.scss";

const ProgressBarLoader = () => {
    return (
        <div className={styles.progressBarLoader}>
        <div className={styles.progressBar}></div>
        </div>
    );
    };

export default ProgressBarLoader;