import React from "react";
import styles from "./FeaturesSection.module.scss";
import { Filter, Star, Shield, ClipboardCheck } from "lucide-react";

const FeaturesSection = () => {

    const features = [
        {
            icon: <Filter size={24} />,
            title: "Filtrado Inteligente",
            description: "Encuentra obras por ubicación y tipo de trabajo solicitado."
        },
        {
            icon: <ClipboardCheck size={24} />,
            title: "Cotizaciones Detalladas",
            description: "Recibe presupuestos completos directamente en la plataforma."
        },
        {
            icon: <Shield size={24} />,
            title: "Documentos Protegidos",
            description: "Control total sobre quién accede a la información de tu proyecto."
        },
        {
            icon: <Star size={24} />,
            title: "Calificaciones Verificadas",
            description: "Sistema de valoración basado en proyectos completados."
        }
    ]


    return (

            <div className={styles.featuresContainer}>
                {features.map((feature, index) => (
                    <div key={index} className={styles.feature}>
                        <div className={styles.featureIconContainer}>
                            <div className={styles.featureIcon}>
                                {feature.icon}
                            </div>
                        </div>
                        <h3 className={styles.featureTitle}>{feature.title}</h3>
                        <p className={styles.featureDescription}>{feature.description}</p>
                    </div>
                ))}
            </div>

    );

}

export default FeaturesSection;
