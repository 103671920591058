import React, { useEffect } from "react";
import styles from "./MisSkills.module.scss";
import { connect } from "react-redux";
import {
  getSkills,
  getUserSkill,
} from "../../../../store/actions";
import { HardHat } from "lucide-react";

const MisSkills = ({
  perfilAjeno,
  getSkills,
  getUserSkill,
  userSkills,
}) => {

  useEffect(() => {
    getSkills();
    getUserSkill(perfilAjeno);
  }, [perfilAjeno, getSkills, getUserSkill]);


  return (
    <div className={styles.skills}>
      {userSkills && userSkills.length > 0 ? (
        userSkills.map((skill, index) => {
          return (
            <div key={index} className={styles.skill}>
              {skill.name}
              <HardHat size={24} className={styles.skill_icon} />
            </div>
          );
        })
      ) : (
        <div className={styles.skill}>
          No hay rubros especificados
        </div>
      )}
    </div>
    
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    skills: state.register.skills,
    userSkills: state.register.userSkills,
  };
};

export default connect(mapStateToProps, {
  getSkills,
  getUserSkill,
})(MisSkills);