import React, { useState } from "react";
import styles from "./FeedCardComment.module.scss";
import useUserImage from "../../../hooks/useUserImage";
import { ReactComponent as NoImage } from "../../../assets/svg/noImageIcon.svg";
import TextareaAutosize from "react-textarea-autosize";
import { connect } from "react-redux";
import {
    deletePostComment,
    editPostComment,
    createPostComment,
    likePostComment,
    responsePostComment,
} from "../../../store/actions";
import { Send, X, Heart } from "lucide-react";
import ResponseComments from "./ResponseComments";
import { motion } from "framer-motion";

const responseVariants = {
    hidden: {
        opacity: 0,
        y: -10,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.3,
        },
    },
};


const FeedCardComment = ({
    comments,
    showComments,
    createPostComment,
    likePostComment,
    userData,
    id,
    responsePostComment,
    deletePostComment,
    editPostComment,
    calcularDiferenciaTiempo,
    setShowComments,
    isHovered,
}) => {
    const [commentEditing, setCommentEditing] = useState(false);
    const [editComment, setEditComment] = useState("");
    const [menu, setMenu] = useState(false);
    const [commentContent, setCommentContent] = useState("");
    const [commentResponse, setCommentResponse] = useState("");
    const [showResponseInput, setShowResponseInput] = useState(false);


    const { imageError, initials, profileImage } = useUserImage(userData);

    const handleToggleMenu = (id) => {
        setMenu(menu === id ? null : id);
    };

    const handleMenuAction = (action, commentId) => {
        if (action === "Editar") {
            setCommentEditing(commentId);
        } else if (action === "Eliminar") {
            handleDeleteComment(commentId)
        }
    };

    //Funcion para crear un comentario
    const handlePostComment = (postId, content) => {
        createPostComment(parseInt(postId), content);
        setCommentContent("");
        setShowComments(true);
    }

    //Funcion para eliminar un comentario
    const handleDeleteComment = (commentId) => {
        deletePostComment(commentId, id)
    }

    const handleEditComment = (commentId, content) => {
        editPostComment(commentId, content, id)
        setCommentEditing(false)
    }

    const handleLikeComment = (commentId) => {
        likePostComment(commentId, id)
    }

    const handleShowResponseInput = (commentId) => {
        if (showResponseInput === commentId) {
            setShowResponseInput(false)
            return
        }
        setShowResponseInput(commentId)
    }

    const handleResponseComment = (commentId, content) => {
        responsePostComment(id, commentId, content)
        setCommentResponse("")
        setShowResponseInput(false)
    }


    return (
        <>
            <div className={styles.itemCommentHeader}>
                <div className={styles.itemCommentHeader_imgContainer}>
                    {!imageError && profileImage ? (
                        <img
                            className={styles.itemCommentHeader_imgContainer_img}
                            src={profileImage}
                            alt="user"
                        />
                    ) : initials ? (
                        <div className={styles.itemCommentHeader_imgContainer_initials}>{initials}</div>
                    ) : (
                        <div className={styles.itemCommentHeader_imgContainer_img}>{<NoImage width={60} height={60} />}</div>
                    )}
                </div>
                <div className={styles.input}>
                    <TextareaAutosize
                        type="text"
                        placeholder={"Agregar comentario"}
                        className={styles.input_item}
                        id="comment"
                        style={{ height: "40px" }}
                        value={commentContent}
                        onChange={(e) => setCommentContent(e.target.value)} />
                    <div onClick={() => commentContent.length >= 2 && handlePostComment(id, commentContent)}
                        className={`${styles.input_item_iconContainer} ${commentContent.length >= 2 && styles.input_item_iconContainer_active}`}>
                        <Send size={16} />
                    </div>
                </div>
            </div>

            {showComments && (
                Array.isArray(comments) && comments?.length > 0 &&
                comments?.map((comment, index) => {
                    let commentCompleteName = comment.user.type === "COMPANY" ? comment.user.razon_social : comment.user.name + " " + comment.user.last_name;
                    let commentImageProfile = comment.user.image_profile;
                    let contenido = comment.content;
                    let creacion = comment.createdAt;
                    let isMyComment = comment.user.id === userData.id;
                    let commentId = comment.id;
                    let likeCount = comment?.likes?.length || 0;
                    let isMyLike = comment?.likes?.some((like) => like.user.id === userData.id);
                    let commentResponses = comment?.commentResponse;
                    let intials = commentCompleteName.split(" ").map((n) => n[0]).join("");
                    let profileImageError = comment.user.image_profile === "https://edify-files-dev.s3.amazonaws.com/profile-pictures/ebba9aa8-4346-4662-b3e5-8699015659ba-image-profile-default.png";
                    return (
                        <motion.div
                            variants={responseVariants}
                            initial="hidden"
                            animate="visible"
                            className={styles.itemCommentBody_item} key={index}>
                            <div className={styles.itemCommentBody_item_imgContainer}>
                                {!profileImageError && commentImageProfile ? (
                                    <img
                                        className={styles.itemCommentBody_item_imgContainer_img}
                                        src={commentImageProfile}
                                        alt="user"
                                    />
                                ) : (
                                    <div className={styles.itemCommentBody_item_imgContainer_initials}>
                                        {intials}
                                    </div>
                                )}
                            </div>
                            <div className={styles.itemCommentBody_item_contentContainer}>
                                <div className={`${styles.itemCommentBody_item_content} ${isHovered && styles.itemCommentBody_item_content_hovered}`}>
                                    <div className={styles.itemCommentBody_item_content_header}>
                                        <div className={styles.itemCommentBody_item_content_header_name}>
                                            {commentCompleteName}
                                            <span className={styles.itemCommentBody_item_content_header_time}>
                                                {calcularDiferenciaTiempo(creacion)}
                                            </span>
                                        </div>
                                        {isMyComment && (
                                            <button className={styles.itemCommentBody_menuButton} onClick={() => handleToggleMenu(commentId)}>. . .
                                                {menu === commentId && (
                                                    <div className={styles.itemCommentBody_menu}>
                                                        <div className={styles.itemCommentBody_menu_item} onClick={() => handleMenuAction("Editar", commentId)}>Editar</div>
                                                        <div className={styles.itemCommentBody_menu_item} onClick={() => handleMenuAction("Eliminar", commentId)}>Eliminar</div>
                                                    </div>
                                                )}
                                            </button>
                                        )}
                                    </div>
                                    {commentEditing === commentId ? (
                                        <div className={styles.itemCommentBody_item_content_contentContainerEdit}>
                                            <TextareaAutosize
                                                type="text"
                                                className={styles.itemCommentBody_input_item}
                                                id="comment"
                                                minRows={3}
                                                value={editComment || contenido}
                                                onChange={(e) => setEditComment(e.target.value)} />
                                            <div className={styles.itemCommentBody_input_item_buttonsContainer}>
                                                <div className={styles.itemCommentBody_input_item_iconContainer} onClick={() => setCommentEditing(false)}>
                                                    <X size={16} />
                                                </div>
                                                <div className={`${styles.itemCommentBody_input_item_iconContainer} ${editComment.length > 0 && styles.itemCommentBody_input_item_iconContainer_active}`}
                                                    onClick={() => editComment?.length > 0 && handleEditComment(commentId, editComment)}>
                                                    <Send size={16} />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.itemCommentBody_item_text}>
                                            {contenido}
                                        </div>
                                    )}
                                </div>
                                <div className={styles.itemCommentBody_item_likesAndRespond}>
                                    <button
                                        className={`${styles.itemCommentBody_item_likesAndRespond_btn} ${isMyLike && styles.itemCommentBody_item_likesAndRespond_btn_active}`}
                                        onClick={() => handleLikeComment(commentId)}>
                                        <Heart size={16} />
                                        {likeCount > 0 && <span className={styles.itemCommentBody_item_likesAndRespond_btn_icon}>{likeCount}</span>}
                                        Me gusta
                                    </button>
                                    <button className={styles.itemCommentBody_item_likesAndRespond_btn} onClick={() => handleShowResponseInput(commentId)}>
                                        Responder
                                    </button>
                                </div>
                                {showResponseInput === commentId && (
                                    <div className={styles.itemCommentBody_item_responseContainer}>
                                        <div className={styles.itemCommentBody_item_responseContainer_imgContainer}>
                                            {!imageError && profileImage ? (
                                                <img
                                                    className={styles.itemCommentBody_item_responseContainer_imgContainer_img}
                                                    src={profileImage}
                                                    alt="user"
                                                />
                                            ) : (
                                                <div className={styles.itemCommentBody_item_responseContainer_imgContainer_img}>
                                                    {<NoImage width={25} height={25} />}
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.itemCommentBody_item_responseContainer_inputContainer}>
                                            <TextareaAutosize
                                                type="text"
                                                placeholder={"Escribe una respuesta..."}
                                                className={styles.itemCommentBody_item_responseContainer_input}
                                                id="comment"
                                                style={{ height: "40px" }}
                                                value={commentResponse}
                                                onChange={(e) => setCommentResponse(e.target.value)} />
                                            <div
                                                className={`${styles.itemCommentBody_item_responseContainer_iconContainer} ${commentResponse.length >= 2 && styles.itemCommentBody_item_responseContainer_iconContainer_active}`}
                                                onClick={() => commentResponse.length >= 2 && handleResponseComment(commentId, commentResponse)}>
                                                <Send size={16} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {commentResponses?.length > 0 && (
                                    <ResponseComments
                                        commentResponses={commentResponses}
                                        calcularDiferenciaTiempo={calcularDiferenciaTiempo}
                                        isHovered={isHovered}
                                    />
                                )}
                            </div>
                        </motion.div>
                    )
                }
                )

            )}

        </>
    )
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps, {
    deletePostComment,
    editPostComment,
    createPostComment,
    likePostComment,
    responsePostComment,
})(FeedCardComment);
