import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { connect } from 'react-redux';
import { getQuotationById, getMaterialsBySubquotationId, getSubQuotationsPostulation, deleteQuotation, openMainModal, PauseQuotation } from '../../../store/actions/profileActions';
import styles from './QuotationDetails.module.scss';
import QuotationForm from '../CotizadorDeMateriales/Quotationform';
import MaterialTable from '../CotizadorDeMateriales/MaterialTable';
import { useNavigate, useParams } from 'react-router-dom';
import NewNav from '../../Nav/NewNav';
import Botonera from '../../Botonera/Botonera';
import { Inbox, Pause, Pencil, Play, Trash2, Download } from 'lucide-react';
import Tooltip from '../../UI/ToolTip/ToolTip';
import { motion } from 'framer-motion';
import RegisteredUserProfileLoader from '../../RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  padding-right: 5%;
  padding-left: 5%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
  `;

const variants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const QuotationDetails = ({
  quotationData,
  materialsBySubquotation,
  getQuotationById,
  getMaterialsBySubquotationId,
  loading,
  getSubQuotationsPostulation,
  subQuotationsPostulations,
  deleteQuotation,
  openMainModal,
  PauseQuotation,
}) => {
  const [materials, setMaterials] = useState([]);
  const [receivedQuotes, setReceivedQuotes] = useState([]);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [showToolTip, setShowToolTip] = useState({
    edit: { show: false, text: "Editar" },
    pause: { show: false, text: "Pausar" },
    reactivate: { show: false, text: "Reactivar" },
    delete: { show: false, text: "Eliminar" },
    budget: { show: false, text: "Presupuestos" },
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (id && (!quotationData || quotationData.id !== id || shouldRefresh)) {
      getQuotationById(id, false, null);
      setShouldRefresh(false);
    }
  }, [id, quotationData?.id, shouldRefresh, getQuotationById]);

  useEffect(() => {
    const subquotationId = quotationData?.subquotation?.[0]?.id;

    if (subquotationId) {
      Promise.all([
        getMaterialsBySubquotationId(subquotationId),
        getSubQuotationsPostulation(subquotationId),
      ]).finally(() => {
        setIsLoading(false);
      });
    }
  }, [quotationData?.subquotation, getMaterialsBySubquotationId, getSubQuotationsPostulation]);


  useEffect(() => {
    if (materialsBySubquotation) {
      const transformedMaterials = Object.values(materialsBySubquotation).flat();
      setMaterials(transformedMaterials.map((category) => ({
        category_id: category.category_id,
        name: category.name,
        materials: category.materials,
      })));
    }

    if (subQuotationsPostulations) {
      setReceivedQuotes(subQuotationsPostulations);
    }
  }, [materialsBySubquotation, subQuotationsPostulations]);


  const handleEditClick = () => {
    navigate(`/cotizaciondemateriales/edit/${id}`);
  };

  const handleDeleteQuotation = (id) => {
    openMainModal({
      title: "¿Estás seguro de querer eliminar la cotización?",
      body: "Si la eliminas no podrás recuperarla.",
      isSuccess: false,
      iconType: "warning",
      confirmHandler: () => {
        deleteQuotation(id, navigate);
      }
    });
  };

  const handleToggleQuotation = (id, isActive) => {
    const action = isActive ? "reactivar" : "pausar";
    const title = `¿Estás seguro de querer ${action} la cotización?`;
    const body = isActive
      ? "Si reactivas la cotización, estará disponible para los usuarios."
      : "Si pausas la cotización, no podrá ser vista por los usuarios de Edify.";

    openMainModal({
      title,
      body,
      isSuccess: false,
      iconType: "warning",
      confirmHandler: async () => {
        await PauseQuotation(id, isActive); // Realiza la acción
        setShouldRefresh(true); // Activa la actualización de datos
      },
    });
  };

  const handleDownload = (fileUrl) => {
    if (!fileUrl) {
      alert("El archivo no está disponible.");
      return;
    }
    window.open(fileUrl, '_blank');
  };

  const handleTooltip = (button) => {
    setShowToolTip((prevState) => ({
      ...prevState,
      [button]: { ...prevState[button], show: !prevState[button].show },
    }));
  };

  const formatDate = (date) => {
    const utcDate = new Date(date);
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1;
    const day = utcDate.getUTCDate();
    return `${day}/${month}/${year}`;
  };

  if (loading) return <p>Cargando...</p>;

  const isPaused = !quotationData.quotation?.active;

  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      {isLoading ? (
        <RegisteredUserProfileLoader />
      ) : (
        <div className={styles['quotation-details']}>
          <div className={styles['main-content']}>
            <div className={styles['header']}>
              <div className={styles['title']} >
                Cotización de materiales
                <h1 className={styles['text']}>{quotationData?.quotation?.name}</h1>
              </div>

              <div className={styles['actions']}>
                {isPaused && <div className={styles['paused-tag']}>
                  Cotización Pausada</div>}
                <button className={styles['edit']} onClick={handleEditClick}
                  onMouseEnter={() => handleTooltip("edit")}
                  onMouseLeave={() => handleTooltip("edit")}>
                  <Pencil size={20} />
                  {showToolTip.edit.show && <Tooltip text={showToolTip.edit.text} />}
                </button>
                <button
                  className={styles[isPaused ? 'reactivate' : 'pause']}
                  onClick={() => handleToggleQuotation(id, isPaused)}
                  onMouseEnter={() => handleTooltip(isPaused ? "reactivate" : "pause")}
                  onMouseLeave={() => handleTooltip(isPaused ? "reactivate" : "pause")}>
                  {isPaused ? <Play size={20} /> : <Pause size={20} />}
                  {showToolTip[isPaused ? "reactivate" : "pause"]?.show && (
                    <Tooltip
                      text={isPaused ? "Reanudar" : "Pausar"}
                    />
                  )}
                </button>
                <button className={styles['delete']} onClick={() => handleDeleteQuotation(id)}
                  onMouseEnter={() => handleTooltip("delete")}
                  onMouseLeave={() => handleTooltip("delete")}>
                  <Trash2 size={20} />
                  {showToolTip.delete.show && <Tooltip text={showToolTip.delete.text} />}
                </button>
                <button className={styles['showBudgets']} onClick={() => setShowBudgetModal(true)}
                  onMouseEnter={() => handleTooltip("budget")}
                  onMouseLeave={() => handleTooltip("budget")}
                  >
                  <Inbox size={20} />
                  {showToolTip.budget.show && <Tooltip text={showToolTip.budget.text} />}
                  {receivedQuotes.length > 0 && <span>{receivedQuotes.length}</span>}
                </button>
              </div>

            </div>
            <div className={styles['body']}>
              <div className={styles['general-info']}>
                <QuotationForm quotation={quotationData} isPaused={isPaused} />
                <MaterialTable materials={materials} isEditable={false} isMyQuotation />
              </div>
              {showBudgetModal && (
                <div className={styles['budget-modal']}>
                  <motion.div
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                    className={styles['received-quotes']}>
                    <h3 className={styles['received-title']}>
                      {`${receivedQuotes.length >= 1 ? (receivedQuotes.length === 1 ? '1 Presupuesto' : `${receivedQuotes.length} Presupuestos`) : 'Presupuestos'}`}
                      <div className={styles['close']} onClick={() => setShowBudgetModal(false)}>X</div>
                    </h3>
                    <div className={styles['received-quotes-list']}>
                      {receivedQuotes.length === 0 ? (
                        <div className={styles['empty-state']}>
                          <Inbox size={48}  />
                          <p>No tienes presupuestos recibidos</p>
                        </div>
                      ) : (
                        receivedQuotes.map((quote, idx) => {
                          const { file, user, end_date } = quote;
                          const { type, razon_social, name, last_name, image_profile } = user;

                          return (
                            <div key={idx} className={styles['received-quote']}>
                              <div className={styles['quote-content']}>
                                <div className={styles['user-info']}>
                                  <img src={image_profile} alt="user" className={styles['profile-image']} />
                                  <div className={styles['user-name']}>
                                    <p>
                                      {type === "COMPANY" ? razon_social : `${name} ${last_name}`}
                                    </p>
                                  </div>
                                </div>

                                <div className={styles['duration']}>
                                  Presupuesto válido hasta
                                  <p>{formatDate(end_date)}</p>
                                </div>
                                <button className={styles['download']} onClick={() => handleDownload(file)}>
                                  <Download size={14} />
                                  Descargar</button>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </motion.div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};


const mapStateToProps = (state) => ({
  quotationData: state.profile.quotation,
  materialsBySubquotation: state.profile.materialsBySubquotation,
  loading: state.profile.loading,
  error: state.profile.error,
  userData: state.general.userData,
  subQuotationsPostulations: state.profile.subQuotationsPostulations,
});

const mapDispatchToProps = {
  getQuotationById,
  getMaterialsBySubquotationId,
  getSubQuotationsPostulation,
  deleteQuotation,
  openMainModal,
  PauseQuotation
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetails);
