import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
    getPrivateProjectById,
    getPostulationProjectStatus,
    validateAccessRequest,
    openNDAModal,
    getPostulations,
    setPostulationProjectId,
    setProjectToEdit,
    setPostulationProject,
    deleteProyecto,
    getProjectQuestions,
} from "../../../../store/actions";
import Botonera from "../../../Botonera/Botonera";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ProyectoId.module.scss";
import { ReactComponent as FacebookIcon } from "../../../../assets/svg/Facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../../assets/svg/Instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../../../assets/svg/LinkedIN.svg";
import { ReactComponent as TwitterIcon } from "../../../../assets/svg/twitterSvg.svg";
import { ReactComponent as WhatsAppIcon } from "../../../../assets/svg/whatsAppIcon.svg";
import ProjectQuestions from "./ProjectQuestions";
import ProjectAnswer from "./ProjectAnswer";
import { Carousel } from 'nuka-carousel';
import "./NukaCarousel.css";
import { motion } from "framer-motion";
import ProyectoIdHeader from "./ProyectoIdHeader";
import ProyectoSkeletonLoader from "./ProjectSkeleton";
import NewNav from "../../../Nav/NewNav";
import styled from "styled-components";
import SubProjectInformation from "./SubProjectInformation";
import UploadBudgetDisclaimer from "./UploadBudgetDisclaimer";
import {
    MapPin,
    CalendarDays,
    FileText,
    Bookmark,
    HardHat,
    UserCheck,
    UserRoundX,
    Send,
    MessageSquare,
    Briefcase,
    Clock,
    ChevronRight
} from "lucide-react";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 5%;
  padding-left: 5%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

`;


const ProyectoId = ({
    setProjectLoading,
    getPrivateProjectById,
    getPostulationProjectStatus,
    getProjectQuestions,
    postulacionesDelUsuario,
    postulacionesDeProyecto,
    projectQuestions,
    proyecto,
    openNDAModal,
    validateAccessRequest,
    getPostulations,
    setProjectToEdit,
    setPostulationProject,
    setPostulationProjectId,
    deleteProyecto,
    budgets,
    userData,
    ...props }) => {
    const [showSubProjectInfo, setShowSubProjectInfo] = useState(null);
    const [showPostulations, setShowPostulations] = useState({});
    const [editProject, setEditProject] = useState(false);
    const [showSubProjectDocus, setShowSubProjectDocus] = useState(null);
    const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
    const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
    const [showSubProjectsMenu, setShowSubProjectsMenu] = useState(false);
    const [showPostulationsMenu, setShowPostulationsMenu] = useState(false);
    const [showPostulationModal, setShowPostulationModal] = useState(null);
    const [showBudgetsMenu, setShowBudgetsMenu] = useState(false);
    const [showQuestionsModal, setShowQuestionsModal] = useState(false);
    const [showAnswerModal, setShowAnswerModal] = useState(false);
    const [questionToAnswer, setQuestionToAnswer] = useState(null);
    const [randomImage, setRandomImage] = useState("");
    const [isActive, setIsActive] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const menuRef = useRef(null);
    //Definimos si es proycto propio o de otro usuario
    const isOwnProject = proyecto?.user?.id === userData?.id;

    // Datos de redes sociales
    const { facebook = '', instagram = '', linkedin = '', twitter = '', phone = '' } = proyecto?.user || {};

    const noSocialNetworks = !facebook && !instagram && !linkedin && !twitter && !phone;

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowSubProjectsMenu(false);
            setShowPostulationsMenu(false);
            setShowBudgetsMenu(false);
            setIsActive(null);
        }
    };

    useEffect(() => {
        if (proyecto?.proyecto_images && proyecto?.proyecto_images.length === 0) {
            getRandomImage();
        }
        getProjectQuestions(parseInt(id));
    }, [proyecto.id]);

    useEffect(() => {
        if (showSubProjectsMenu || showPostulationsMenu || showBudgetsMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showSubProjectsMenu, showPostulationsMenu, showBudgetsMenu]);

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setOcultarEnMobile(windowWidth < 768);
        setMobileStylesFromParent(windowWidth < 768);
    };

    // Ejecutar handleResize al cargar el componente
    useEffect(() => {
        handleResize();
    }, []);

    // Agregar un event listener para el cambio de tamaño de la ventana
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        // Limpiar el event listener al desmontar el componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

   // Formateamos fechas
const formatDate = (date) => {
    const utcDate = new Date(date);
    const year = utcDate.getUTCFullYear();
    const monthNames = [
        'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 
        'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
    const month = monthNames[utcDate.getUTCMonth()];
    const day = utcDate.getUTCDate();
    return `${day} de ${month} ${year}`;
};

    useEffect(() => {
        getPrivateProjectById(id);
        getPostulationProjectStatus(parseInt(id));
        getPostulations(userData.id);
    }, []);

    //Muestra la info de los subproyectos
    const toggleSubProjectInfo = (index) => {
        setShowSubProjectInfo(prevIndex => (prevIndex === index ? null : index));
    };

    //Muestra las postulaciones
    const togglePostulations = (index) => {
        setShowPostulations((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    //Maneja la solicitud de acceso al proyecto
    const handleAccessRequest = (permisoId, status, solicitudType) => {

        validateAccessRequest(permisoId, status, solicitudType);
        setTimeout(() => {
            getPostulationProjectStatus(parseInt(id));
        }, 3000);
    }

    //Abre el modal de Postulacion
    const openPostulationModal = () => {
        setPostulationProjectId(proyecto?.id);
        openNDAModal(true);
    }

    //Define que boton mostrar
    const isPostulationSent = postulacionesDelUsuario.find(postulacion => postulacion.proyecto?.id === proyecto?.id && postulacion.status === "SEND");
    const isPostulationAllowed = postulacionesDelUsuario.find(postulacion => postulacion.proyecto?.id === proyecto?.id && postulacion.status === "ALLOWED");
    const isPostulationDenied = postulacionesDelUsuario.find(postulacion => postulacion.proyecto?.id === proyecto?.id && postulacion.status === "NEGATED");
    const isPostulationAvailable = !isOwnProject && !isPostulationSent && !isPostulationAllowed && !isPostulationDenied;

    // Estado actual de la postulación del usuario
    let userPostulationStatus;
    if (isPostulationSent) {
        userPostulationStatus = isPostulationSent.status;
    } else if (isPostulationAllowed) {
        userPostulationStatus = isPostulationAllowed.status;
    } else if (isPostulationDenied) {
        userPostulationStatus = isPostulationDenied.status;
    }

    const handlePostulationButton = () => {
        if (isPostulationSent) {
            setShowPostulationModal("sent");
        } else if (isPostulationAvailable) {
            openPostulationModal();
        } else if (isPostulationDenied) {
            setShowPostulationModal("denied");
        } else if (isPostulationAllowed) {
            setShowPostulationModal("allowed");
        } else {
            setShowPostulationModal(null);
        }
    }

    const handleHeaderButtonsMenu = (e, value) => {
        e.stopPropagation();
        setShowSubProjectInfo(null);
        setIsActive(null);
        if (value === "subProjects") {
            setShowSubProjectsMenu(!showSubProjectsMenu);
            setShowPostulationsMenu(false);
            setShowBudgetsMenu(false);
            setIsActive(value);
        } else if (value === "postulations") {
            setShowPostulationsMenu(!showPostulationsMenu);
            setShowSubProjectsMenu(false);
            setShowBudgetsMenu(false);
            setIsActive(value);
        } else if (value === "budgets") {
            setShowBudgetsMenu(!showBudgetsMenu);
            setShowSubProjectsMenu(false);
            setShowPostulationsMenu(false);
            setIsActive(value);
        }
    };

    //Muestra el PopUp de respuesta a pregunta
    const handleAnswerQuestion = (question) => {
        setQuestionToAnswer(question);
        setShowAnswerModal(true);
    }

    //Muestra el PopUp de edicion de proyecto 
    const handleEditProject = () => {
        setEditProject(true);
        setPostulationProject(proyecto);
        setProjectToEdit(parseInt(proyecto.id));
        navigate(`/obrasprivadas/edit/${proyecto.id}`);
    }

    //Borrar el proyecto 
    const handleDeleteProject = () => {
        deleteProyecto(parseInt(proyecto.id), navigate, true);
    }

    //Maneja la redirección al perfil del creador del proyecto
    const handleUserRedirect = () => {
        const userId = proyecto?.user?.id;
        const userFullName = proyecto?.user?.type === "COMPANY" ? proyecto?.user.razon_social : proyecto?.user?.name + " " + proyecto?.user?.last_name;
        navigate(`/profesionales/${userId}/${userFullName}`);
    }

    const constructionImages = [
        "https://images.pexels.com/photos/534220/pexels-photo-534220.jpeg?auto=compress&cs=tinysrgb&w=600",
        "https://images.pexels.com/photos/209266/pexels-photo-209266.jpeg?auto=compress&cs=tinysrgb&w=600",
        "https://images.pexels.com/photos/1451416/pexels-photo-1451416.jpeg?auto=compress&cs=tinysrgb&w=600",
        "https://images.pexels.com/photos/534228/pexels-photo-534228.jpeg?auto=compress&cs=tinysrgb&w=600"
    ];

    // Función para obtener una imagen aleatoria
    const getRandomImage = () => {
        const randomIndex = Math.floor(Math.random() * constructionImages.length);
        return setRandomImage(constructionImages[randomIndex]);
    }

    let textToShow = isPostulationAllowed ? "Aceptado" : isPostulationDenied ? "Solicitud rechazada" : isPostulationSent ? "Esperando respuesta" : "Enviar postulación";
    let iconToShow = isPostulationAllowed ? <UserCheck size={20} /> : isPostulationDenied ? <UserRoundX size={20} /> : isPostulationSent ? <Clock size={20} /> : <Send size={20} />;

    const handleShowSubProjectsDocs = (index) => {
        setShowSubProjectDocus((prev) => (prev === index ? null : index));
    }

    const calculateDaysLeft = (endDate) => {
        if (!endDate) return "No definido";
        const today = new Date();
        const end = new Date(endDate);
        const diff = end - today;
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        return days;
    }

    return (
        <Container>
            {!ocultarEnMobile && <NewNav />}
            <main className={styles.mainContainer}>
                {setProjectLoading ? <ProyectoSkeletonLoader /> :
                    (
                        <>
                            {isOwnProject && (
                                <ProyectoIdHeader
                                    isActive={isActive}
                                    isOwnProject={isOwnProject}
                                    isPostulationSent={isPostulationSent}
                                    isPostulationAllowed={isPostulationAllowed}
                                    isPostulationDenied={isPostulationDenied}
                                    isPostulationAvailable={isPostulationAvailable}
                                    postulacionesDeProyecto={postulacionesDeProyecto}
                                    proyecto={proyecto}
                                    userPostulationStatus={userPostulationStatus}
                                    handleHeaderButtonsMenu={handleHeaderButtonsMenu}
                                    showSubProjectsMenu={showSubProjectsMenu}
                                    showPostulationsMenu={showPostulationsMenu}
                                    showPostulations={showPostulations}
                                    showBudgetsMenu={showBudgetsMenu}
                                    handlePostulationButton={handlePostulationButton}
                                    handleEditProject={handleEditProject}
                                    handleDeleteProject={handleDeleteProject}
                                    togglePostulations={togglePostulations}
                                    handleAccessRequest={handleAccessRequest}
                                    menuRef={menuRef}
                                    toggleSubProjectInfo={toggleSubProjectInfo}
                                    showSubProjectInfo={showSubProjectInfo}
                                />
                            )}
                            <div className={styles.sectionWrapper}>
                                <section className={styles.cards_datos}>
                                    {proyecto?.proyecto_images && proyecto?.proyecto_images.length > 0 ?
                                        <Carousel
                                            showArrows
                                            wrapAround
                                            slidesToShow={1}
                                            className={styles.cards_datos_imageContainer}
                                        >
                                            {proyecto?.proyecto_images.map((image, index) => (
                                                <img className={styles.cards_datos_imageContainer_image} key={index} src={image.image} alt={proyecto.name} />
                                            ))}
                                        </Carousel>
                                        : <div className={styles.cards_datos_imageContainer}>
                                            <img src={randomImage} alt="Imagen de proyecto" className={styles.cards_datos_imageContainer_image} />
                                        </div>
                                    }
                                    <div className={styles.cards_datos_container}>
                                        <div className={styles.cards_datos_container_header}>
                                            <h3 className={styles.cards_datos_title}>
                                                Detalles del proyecto
                                            </h3>
                                            <div className={styles.cards_datos_container_header_items}>
                                                <div className={styles.cards_datos_container_header_item}>
                                                    <div className={styles.cards_datos_container_header_item_label}>
                                                        <Bookmark size={16} />
                                                        <span className={styles.cards_datos_container_header_item_label}>Nombre</span>
                                                    </div>
                                                    <p className={styles.cards_datos_container_header_item_value}>{proyecto?.name}</p>
                                                </div>
                                                <div className={styles.cards_datos_container_header_item}>
                                                    <div className={styles.cards_datos_container_header_item_label}>
                                                        <HardHat size={16} />
                                                        <span className={styles.cards_datos_container_header_item_label}>Rubro/s a cotizar</span>
                                                    </div>
                                                    <div className={styles.cards_datos_container_header_item_jobContainer}>
                                                        {Array.isArray(proyecto?.subproyecto) &&
                                                            proyecto?.subproyecto.map((subproject, index) => {
                                                                return (
                                                                    <p className={styles.cards_datos_container_header_item_job} key={index}>
                                                                        {subproject.name}
                                                                    </p>
                                                                );

                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.cards_datos_container_body}>
                                            <span className={styles.cards_datos_container_item}>
                                                <FileText size={16} />
                                                Descripción
                                            </span>
                                            <p className={styles.cards_datos_container_description}>{proyecto?.description}</p>
                                        </div>
                                    </div>
                                    <div className={styles.cards_datos_container_documentation}>
                                        <h3 className={styles.cards_datos_title}>
                                            Información para cotizar
                                        </h3>
                                        <div className={styles.cards_datos_container_documentation_items}>
                                            {Array.isArray(proyecto?.subproyecto) &&
                                                proyecto?.subproyecto.map((subproject, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className={styles.cards_datos_container_documentation_item} >
                                                                <div className={styles.cards_datos_container_documentation_item_label}>
                                                                    <div className={styles.cards_datos_container_documentation_item_label_icon}>
                                                                        <Briefcase size={16} />
                                                                    </div>
                                                                    <span className={styles.cards_datos_container_documentation_item_label_text}>{subproject.name}</span>
                                                                </div>
                                                                <div className={styles.cards_datos_container_documentation_item_icon} onClick={() => handleShowSubProjectsDocs(index)}>
                                                                    <ChevronRight size={16} />
                                                                </div>

                                                            </div>
                                                            {showSubProjectDocus === index && (
                                                                <motion.div
                                                                    initial={{ opacity: 0 }}
                                                                    animate={{ opacity: 1 }}
                                                                    exit={{ opacity: 0 }}
                                                                    transition={{ duration: 0.3 }}
                                                                    className={styles.cards_datos_container_documentation_item_docus}>
                                                                    <div className={styles.cards_datos_container_documentation_item_docus_dates}>
                                                                        <div className={styles.cards_datos_container_documentation_item_docus_dates_container}>
                                                                            <div className={styles.cards_datos_container_documentation_item_docus_dateItem}>
                                                                                <span>Inicio</span>
                                                                                <span className={styles.cards_datos_container_documentation_item_docus_date}>{formatDate(subproject.startDate)}</span>
                                                                            </div>
                                                                            <div className={styles.cards_datos_container_documentation_item_docus_divider} />
                                                                            <div className={styles.cards_datos_container_documentation_item_docus_dateItem}>
                                                                                <span>Fin</span>
                                                                                <span className={styles.cards_datos_container_documentation_item_docus_date}>{formatDate(subproject.endDate)}</span>
                                                                            </div>
                                                                            <div className={styles.cards_datos_container_documentation_item_docus_daysLeft} >
                                                                                <Clock size={16} />
                                                                                <span className={styles.cards_datos_container_documentation_item_docus_daysLeft_days}>{calculateDaysLeft(subproject?.endDate)}</span>
                                                                                <span className={styles.cards_datos_container_documentation_item_docus_daysLeft_title}>Días restantes</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {subproject?.description && subproject?.description !== "undefined" && (
                                                                        <div className={styles.cards_datos_container_documentation_item_docus_description}>
                                                                            <span className={styles.cards_datos_container_documentation_item_docus_description_title}>
                                                                                <FileText size={16} />
                                                                                Descripción
                                                                            </span>
                                                                            <p className={styles.cards_datos_container_documentation_item_docus_description_text}>
                                                                                {subproject.description}
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    <div className={styles.cards_datos_container_documentation_item_docus_info}>
                                                                        <h4 className={styles.cards_datos_container_documentation_item_docus_title}>
                                                                            Documentación disponible
                                                                        </h4>
                                                                        <div className={styles.cards_datos_container_documentation_item_docus_files_container}>
                                                                            <SubProjectInformation
                                                                                mainProjectId={proyecto?.id}
                                                                                subproyecto={subproject}
                                                                                postulationStatus={userPostulationStatus}
                                                                                handlePostulationButton={handlePostulationButton}
                                                                                isMyProject={isOwnProject}
                                                                                type={"isProject"} />
                                                                        </div>
                                                                    </div>
                                                                    <UploadBudgetDisclaimer />
                                                                </motion.div >
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </section>
                                <section className={styles.cards_questions}>
                                    <div className={styles.cards_user}>
                                        <div className={styles.cards_user_container} onClick={() => handleUserRedirect()}>
                                            <div className={styles.cards_user_container_user}>
                                                <img src={proyecto?.user?.image_profile} alt="Imagen de perfil" className={styles.cards_user_container_user_image} />
                                                <div className={styles.cards_user_container_user_data}>
                                                    <p className={styles.cards_user_container_user_name}>{proyecto?.user?.type === "COMPANY" ?
                                                        proyecto?.user?.razon_social : proyecto?.user?.name + " " + proyecto?.user?.last_name}</p>
                                                    <span className={styles.cards_user_container_user_title}>Creador del proyecto</span>
                                                </div>

                                            </div>
                                            {noSocialNetworks ? null : (
                                                <div className={styles.cards_user_container_user_socialNetworksContainer_icons}>
                                                    {facebook && (
                                                        <div className={styles.cards_user_container_user_socialNetworksContainer_item}>
                                                            <a href={facebook} target="_blank" rel="noreferrer" className={styles.cards_user_container_user_socialNetworksContainer_item_label}>
                                                                <FacebookIcon width={20} height={20} />
                                                            </a>
                                                        </div>
                                                    )}
                                                    {instagram && (
                                                        <div className={styles.cards_user_container_user_socialNetworksContainer_item}>
                                                            <a href={instagram} target="_blank" rel="noreferrer" className={styles.cards_user_container_user_socialNetworksContainer_item_label}>
                                                                <InstagramIcon width={20} height={20} />
                                                            </a>
                                                        </div>
                                                    )}
                                                    {linkedin && (
                                                        <div className={styles.cards_user_container_user_socialNetworksContainer_item}>
                                                            <a href={linkedin} target="_blank" rel="noreferrer" className={styles.cards_user_container_user_socialNetworksContainer_item_label}>
                                                                <LinkedinIcon width={20} height={20} />
                                                            </a>
                                                        </div>
                                                    )}
                                                    {twitter && (
                                                        <div className={styles.cards_user_container_user_socialNetworksContainer_item} >
                                                            <a href={twitter} target="_blank" rel="noreferrer" className={styles.cards_user_container_user_socialNetworksContainer_item_label}>
                                                                <TwitterIcon width={20} height={20} />
                                                            </a>
                                                        </div>
                                                    )}
                                                    {phone && (
                                                        <div className={styles.cards_user_container_user_socialNetworksContainer_item}>
                                                            <a href={`https://wa.me/${phone}`} target="_blank" rel="noreferrer" className={styles.cards_user_container_user_socialNetworksContainer_item_label}>
                                                                <WhatsAppIcon width={20} height={20} />
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.cards_user_location}>
                                            <div className={styles.cards_user_location_label}>
                                                <MapPin size={16} style={{ minWidth: "1.6rem" }} />
                                                Ubicación
                                            </div>
                                            <p className={styles.cards_user_location_value}>{proyecto?.zone}</p>
                                        </div>
                                        <div className={styles.cards_user_location}>
                                            <div className={styles.cards_user_location_label}>
                                                <CalendarDays size={16} style={{ minWidth: "1.6rem" }} />
                                                Fechas del proyecto
                                            </div>
                                            <div className={styles.cards_user_dates}>
                                                <span className={styles.cards_user_dates_dateTitle}>Inicio</span>
                                                <span className={styles.cards_user_dates_date}>{formatDate(proyecto?.startDate)}</span>
                                                <span className={styles.cards_user_dates_dateEnd}>Fin</span>
                                                <span className={styles.cards_user_dates_date}>{formatDate(proyecto?.endDate)}</span>
                                            </div>
                                        </div>
                                        {!isOwnProject && (
                                            <div className={styles.cards_user_status} >
                                                <h3 className={`${styles.cards_user_status_title} ${isPostulationSent && styles.sent} ${isPostulationAllowed && styles.allowed}
                            ${isPostulationDenied && styles.denied}`} onClick={handlePostulationButton}>
                                                    {iconToShow}
                                                    {textToShow}

                                                </h3>
                                            </div>
                                        )}
                                    </div>

                                    <div className={styles.cards_questions_container}>
                                        <h3 className={styles.cards_questions_title}>
                                            Preguntas
                                            <span className={styles.cards_questions_title_count}>
                                                {projectQuestions && projectQuestions.length}
                                            </span>
                                        </h3>
                                        {projectQuestions && projectQuestions.length > 0 ? (
                                            projectQuestions.map((question, index) => {
                                                const hasAnswer = !!question.answer;
                                                const isOwnAndNoAnswer = isOwnProject && !hasAnswer;

                                                return (
                                                    <div className={styles.cards_questions_container_question} key={index}>
                                                        <p className={styles.cards_questions_container_question_title}>{question.question}</p>
                                                        {isOwnAndNoAnswer ? (

                                                            <div className={styles.cards_questions_container_question_answerButtonContainer}>
                                                                <p className={styles.cards_questions_container_question_noAnswer}>
                                                                    <Clock size={16} style={{ minHeight: "1.6rem", minWidth: "1.6rem" }} />
                                                                    Sin responder
                                                                </p>
                                                                <button
                                                                    className={styles.cards_questions_container_question_answerButton}
                                                                    onClick={() => handleAnswerQuestion(question)}
                                                                >
                                                                    Responder
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            hasAnswer ? (
                                                                <p className={styles.cards_questions_container_question_answer}>
                                                                    {question.answer}
                                                                </p>
                                                            ) : (
                                                                <p className={styles.cards_questions_container_question_answerEmpty}>
                                                                    <Clock size={16} style={{ minHeight: "1.6rem", minWidth: "1.6rem" }} />
                                                                    El creador del proyecto aún no ha respondido
                                                                </p>
                                                            )
                                                        )}
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className={styles.cards_questions_empty}>
                                                <div className={styles.cards_questions_empty_icon}>
                                                    <MessageSquare size={30} />
                                                </div>
                                                <p className={styles.cards_questions_empty_text}>Nadie hizo preguntas aún</p>
                                                {!isOwnProject && (
                                                    <div className={styles.cards_questions_buttonContainer}>
                                                        <button className={styles.cards_questions_button} onClick={() => setShowQuestionsModal(true)}>
                                                            ¡Hacé la primera!
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {projectQuestions && projectQuestions.length > 0 && (
                                        <div className={styles.cards_questions_container_question_input} onClick={() => setShowQuestionsModal(true)}>
                                            <p className={styles.cards_questions_container_question_input_placeholder}>
                                                Hacer una pregunta
                                            </p>
                                            <Send size={16} style={{ minHeight: "1.6rem", minWidth: "1.6rem", color:"rgb(37 99 235)" }} />
                                        </div>
                                    )}
                                    </div>
                        </section>
            </div>
        </>
    )
}
{
    showPostulationModal && (
        <div className={styles.postulationModal}>
            <section className={styles.postulationModal_body}>
                <header className={styles.postulationModal_header}>
                    <button className={styles.postulationModal_header_close} onClick={() => setShowPostulationModal(false)}>X</button>
                </header>
                <h2 className={styles.postulationModal_body_title}>
                    {showPostulationModal === "sent" && "Solicitud enviada"}
                    {showPostulationModal === "denied" && "Solicitud rechazada"}
                    {showPostulationModal === "allowed" && "Solicitud aceptada"}
                </h2>
                <div className={styles.postulationModal_body_imageContainer}>
                    {showPostulationModal === "sent" && <img src={"https://cdn-icons-png.flaticon.com/128/4539/4539472.png"} alt="Sent" className={styles.postulationModal_body_image} />}
                    {showPostulationModal === "denied" && <img src={"https://cdn-icons-png.flaticon.com/128/1828/1828843.png"} alt="Denied" className={styles.postulationModal_body_image} />}
                    {showPostulationModal === "allowed" && <img src={"https://cdn-icons-png.flaticon.com/128/8215/8215539.png"} alt="Accepted" className={styles.postulationModal_body_image} />}
                </div>
                <p className={styles.postulationModal_body_txt}>
                    {showPostulationModal === "sent" && "El creador del proyecto aún no te habilitó para cotizar"}
                    {showPostulationModal === "denied" && "El creador del proyecto ha rechazado tu solicitud de participación en el proyecto"}
                    {showPostulationModal === "allowed" && "Ya puedes descargar toda la documentación en la sección 'RUBROS'"} </p>
            </section>
        </div>
    )
}
            </main >
    { showAnswerModal &&
    <ProjectAnswer closeModal={() => setShowAnswerModal(false)} question={questionToAnswer} projectId={proyecto.id} />
            }
{
    showQuestionsModal &&
        <ProjectQuestions projectId={proyecto.id} closeModal={() => setShowQuestionsModal(false)} />
}
{
    ocultarEnMobile ? <Botonera mobileStylesFromParent={mobileStylesFromParent} /> :
        null
}
        </Container >
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state.general.userData,
        proyecto: state.profile.proyectoPrivadoPorId,
        postulacionesDeProyecto: state.profile.postulationsInProject,
        postulacionesDelUsuario: state.profile.postulations,
        projectQuestions: state.profile.projectQuestions,
        setProjectLoading: state.profile.setProjectLoading,
    };
}

export default connect(mapStateToProps, {
    getPrivateProjectById,
    getPostulationProjectStatus,
    validateAccessRequest,
    openNDAModal,
    getPostulations,
    setPostulationProjectId,
    setProjectToEdit,
    deleteProyecto,
    getProjectQuestions,
    setPostulationProject,
})(ProyectoId);
