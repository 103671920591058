import React, { useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import styles from "./WorkOffer.module.scss";
import { getAllWorkOffers } from "../../store/actions";
import WorkApplyModal from "./WorkApplyModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faCalendarDays, faCompass } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ApplyIcon } from "../../assets/svg/sendPostComment.svg";
import { Search, Building2, HardHat, Mail, UserRoundPlus } from "lucide-react";
import { fireAnalytics } from "../../utils/firebase";

const sliderVariants = {
    incoming: (direction) => ({
        x: direction > 0 ? "100%" : "-100%",
        opacity: 0, 
        scale: 1, 
    }),
    active: { 
        x: 0, 
        opacity: 1,
        scale: 1,
    },
    exit: (direction) => ({
        x: direction > 0 ? "-100%" : "100%", 
        opacity: 0,
        display: 'none', 
        scale: 1, 
    }),
};

const sliderTransition = {
    duration: 0.5,
    ease: [0.56, 0.03, 0.12, 1.04]
};

const WorkOffer = ({
    allJobRequests,
    getAllWorkOffers,
    isPhone,
    setShowForm,
    setFormViewInPhone,
}) => {
    const [[workOffersCount, direction], setDirection] = useState([0, 0]);
    const [passedStartDate, setPassedStartDate] = useState(false);
    const [formattedSalary, setFormattedSalary] = useState("");
    const [showApplyModal, setShowApplyModal] = useState(false);

    useEffect(() => {
        getAllWorkOffers();
    }, [getAllWorkOffers]);

    const handleDirection = (newDirection) => {
        const workOffersLength = allJobRequests?.workOffers?.length || 0;
        let newIndex = workOffersCount + newDirection;

        if (newIndex < 0) {
            newIndex = workOffersLength - 1;
        } else if (newIndex >= workOffersLength) {
            newIndex = 0;
        }

        setDirection([newIndex, newDirection]);
    };

    const currentWorkOffer = useMemo(() => {
        return allJobRequests?.workOffers?.[workOffersCount % (allJobRequests?.workOffers?.length || 1)] || {};
    }, [workOffersCount, allJobRequests]);

    const formatStartDate = (date) => {
        let day = date?.split("T")[0].split("-")[2];
        let month = date?.split("T")[0].split("-")[1];
        let year = date?.split("T")[0].split("-")[0];
        return `${day}/${month}/${year}`;

    }

    useEffect(() => {

        let today = new Date();
        if (currentWorkOffer?.startDate < today) {
            setPassedStartDate(true);
        }
    }, [currentWorkOffer])

    useEffect(() => {

        const salary = currentWorkOffer?.salary ? Math.floor(parseFloat(currentWorkOffer?.salary)) : "0";

        if (currentWorkOffer?.salary === "0.00") {
            setFormattedSalary(" a convenir");
        } else if (currentWorkOffer?.salaryType === "Hour") {
            setFormattedSalary(`${salary}/hora`);
        } else if (currentWorkOffer?.salaryType === "Day") {
            setFormattedSalary(`${salary}/día`);
        } else if (currentWorkOffer?.salaryType === "Week") {
            setFormattedSalary(`${salary}/semana`);
        } else if (currentWorkOffer?.salaryType === "Month") {
            setFormattedSalary(`${salary}/mes`);
        } else if (currentWorkOffer?.salaryType === "Job") {
            setFormattedSalary(`${salary}/trabajo completo`);
        }

    }, [currentWorkOffer])

    // Función para calcular la diferencia de tiempo en minutos, horas, días, semanas y meses
    const calcularDiferenciaTiempo = (fechaCreacion) => {
        const fechaActual = new Date();
        const fechaCreacionPost = new Date(fechaCreacion);
        const fechaCreacionLocal = new Date(fechaCreacionPost.getTime() - (fechaCreacionPost.getTimezoneOffset() * 60000) - (3 * 60 * 60 * 1000));
        let diferencia = fechaActual - fechaCreacionLocal;

        if (diferencia < 0) {
            diferencia = -diferencia;
        }

        const minutos = Math.floor(diferencia / (1000 * 60));
        const horas = Math.floor(diferencia / (1000 * 60 * 60));
        const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
        const semanas = Math.floor(dias / 7);
        const meses = Math.floor(dias / 30);

        if (minutos < 1) {
            return "Ahora";
        } else if (minutos < 60) {
            return minutos === 1 ? "1 minuto" : `${minutos} minutos`;
        } else if (horas < 24) {
            return horas === 1 ? "1 hora" : `${horas} horas`;
        } else if (dias < 7) {
            return dias === 1 ? "1 día" : `${dias} días`;
        } else if (semanas < 4) {
            return semanas === 1 ? "1 semana" : `${semanas} semanas`;
        } else if (meses < 12) {
            return meses === 1 ? "1 mes" : `${meses} meses`;
        } else {
            const años = Math.floor(dias / 365);
            return años === 1 ? "1 año" : `${años} años`;
        }
    };

    const handleApply = () => {
        fireAnalytics("apply_work_offer", { category: "work_offer" });
        setShowApplyModal(true);
    }

    const formatwokZoneCityname = (workZone) => {
        return workZone.split(",")[0];
    }

    return (
        <main className={styles.workOffer_main}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className={styles.workOffer_headerContainer}>
                <h1 className={styles.workOffer_title}>Búsquedas abiertas
                    {isPhone && (
                        <div className={styles.workOffer_subTitlePhoneView_withOffers}>
                            <button className={styles.workOffer_subTitlePhoneView_withOffers_button} onClick={() => setShowForm(true)}>
                                Inscribirme
                            </button>
                        </div>
                    )}
                </h1>
                <div className={styles.workOffer_pill} />
            </motion.div>
            {isPhone ? (
                allJobRequests?.total > 0 ?
                    (
                        <motion.div
                            key={currentWorkOffer?.id}
                            custom={direction}
                            initial={{opacity: 0}}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className={styles.workOffer_card}>

                            <div className={styles.workOffer_cardHeader}>
                                <div className={styles.workOffer_cardHeader_imageContainer}>
                                    <img src={currentWorkOffer?.user?.image_profile} alt="Imagen de la oferta de trabajo" className={styles.workOffer_cardHeader_imageContainer_image} />
                                </div>
                                <div className={styles.workOffer_cardHeader_info}>
                                    <button className={styles.workOffer_cardHeader_button} onClick={() => handleApply()}>
                                        <ApplyIcon width={20} height={20} />
                                        Aplicar</button>
                                </div>
                            </div>
                            <div className={styles.workOffer_cardTitleContainer}>
                                <h2 className={styles.workOffer_cardTitleContainer_title}>{currentWorkOffer?.user?.type === "COMPANY" ? currentWorkOffer?.user?.razon_social : currentWorkOffer?.user?.name + " " + currentWorkOffer?.user?.last_name}</h2>
                                <p className={styles.workOffer_cardTitleContainer_date}>{calcularDiferenciaTiempo(currentWorkOffer?.createdAt)}</p>
                            </div>
                            <div className={styles.workOffer_cardSkillsContainer}>
                                <p className={styles.workOffer_cardSkillsContainer_title}>Rubros requeridos</p>
                                <div className={styles.workOffer_cardSkillsContainer_skills}>
                                    {currentWorkOffer?.skill?.map((skill, index) => (
                                        <p key={index} className={styles.workOffer_cardSkillsContainer_skills_skill}>{skill?.skill?.name}</p>
                                    ))}
                                </div>
                            </div>

                            <div className={styles.workOffer_cardFooterContainer}>
                                {currentWorkOffer?.description && (
                                    <div className={styles.workOffer_cardDescriptionContainer}>
                                        <p className={styles.workOffer_cardDescriptionContainer_description}>" {currentWorkOffer?.description} "</p>
                                    </div>
                                )}
                                <div className={styles.workOffer_cardFooterContainer_location}>
                                    {currentWorkOffer?.workZones?.map((workZone, index) => (
                                        <div key={index} className={styles.workOffer_cardFooterContainer_location_container}>
                                            <FontAwesomeIcon icon={faCompass} className={styles.workOffer_cardFooterContainer_location_container_icon} />
                                            <p className={styles.workOffer_cardFooterContainer_location_container_text}>{workZone?.jurisdiccion?.name} - {formatwokZoneCityname(workZone?.city)}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.workOffer_cardFooterContainer_startDate}>
                                    <FontAwesomeIcon icon={faCalendarDays} className={styles.workOffer_cardFooterContainer_location_container_icon} />
                                    <p className={styles.workOffer_cardFooterContainer_startDate_text}>
                                        {currentWorkOffer?.immediateStart || passedStartDate ? "Inmediato" : formatStartDate(currentWorkOffer?.startDate)}
                                    </p>
                                </div>
                                <div className={styles.workOffer_cardFooterContainer_salary}>
                                    <FontAwesomeIcon icon={faDollarSign} className={styles.workOffer_cardFooterContainer_location_container_icon} />
                                    <p className={styles.workOffer_cardFooterContainer_salary_text}>{formattedSalary}</p>
                                </div>
                                <div className={styles.workOffer_cardFooterContainer_duration}>
                                    <ApplyIcon width={20} height={20} className={styles.workOffer_cardFooterContainer_location_container_icon} />
                                    <p className={styles.workOffer_cardFooterContainer_duration_text}>Tenés tiempo hasta el {formatStartDate(currentWorkOffer?.activeUntil)}</p>
                                </div>
                            </div>
                        </motion.div>
                    ) : (
                        <motion.div
                            key={workOffersCount}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className={styles.workOffer_container}>
                            <div className={styles.workOffer_searchIcon}>
                                <Search size={50} />
                            </div>
                            <h2 className={styles.workOffer_card_title}>
                                Estamos buscando las mejores ofertas para vos
                            </h2>
                            <ul className={styles.workOffer_card_list}>
                                <li className={styles.workOffer_card_list_item}>
                                    <HardHat className={styles.workOffer_card_list_item_icon} />
                                    Próximamente encontrarás muchas oportunidades laborales aquí.
                                </li>
                                <li className={styles.workOffer_card_list_item} onClick={() => setShowForm(true)} style={{ cursor: "pointer" }}>
                                    <UserRoundPlus className={styles.workOffer_card_list_item_icon} />
                                    <p className={styles.workOffer_card_list_item_lastChild}><b>Inscribite gratis </b>hoy mismo y sé de los primeros en enterarte.</p>

                                </li>
                                <li className={styles.workOffer_card_list_item} >
                                    <Building2 className={styles.workOffer_card_list_item_icon} />
                                    ¿Sos empleador? Creá una oferta de trabajo y conectá con profesionales calificados.
                                </li>
                                <li className={styles.workOffer_card_list_item}>
                                    <Mail className={styles.workOffer_card_list_item_icon} />
                                    <p className={styles.workOffer_card_list_item_lastChild}>Si tenés dudas o consultas, escribinos a <a href="mailto:contacto@edify.la">contacto@edify.la</a>.</p>
                                </li>
                            </ul>
                        </motion.div>
                    )) : (
                <AnimatePresence custom={direction} mode="wait">
                    {allJobRequests?.total > 0 ?
                        (
                            <motion.div
                                key={currentWorkOffer?.id}
                                custom={direction}
                                variants={sliderVariants}
                                initial="incoming"
                                animate="active"
                                exit="exit"
                                transition={sliderTransition}
                                className={styles.workOffer_card}>
                                <div className={styles.workOffer_cardHeader}>
                                    <div className={styles.workOffer_cardHeader_imageContainer}>
                                        <img src={currentWorkOffer?.user?.image_profile} alt="Imagen de la oferta de trabajo" className={styles.workOffer_cardHeader_imageContainer_image} />
                                    </div>
                                    <div className={styles.workOffer_cardHeader_info}>
                                        <button className={styles.workOffer_cardHeader_button} onClick={() => handleApply()}>
                                            <ApplyIcon width={20} height={20} />
                                            Aplicar</button>
                                    </div>
                                </div>
                                <div className={styles.workOffer_cardTitleContainer}>
                                    <h2 className={styles.workOffer_cardTitleContainer_title}>{currentWorkOffer?.user?.type === "COMPANY" ? currentWorkOffer?.user?.razon_social : currentWorkOffer?.user?.name + " " + currentWorkOffer?.user?.last_name}</h2>
                                    <p className={styles.workOffer_cardTitleContainer_date}>{calcularDiferenciaTiempo(currentWorkOffer?.createdAt)}</p>
                                </div>
                                <div className={styles.workOffer_cardSkillsContainer}>
                                    <p className={styles.workOffer_cardSkillsContainer_title}>Rubros requeridos</p>
                                    <div className={styles.workOffer_cardSkillsContainer_skills}>
                                        {currentWorkOffer?.skill?.map((skill, index) => (
                                            <p key={index} className={styles.workOffer_cardSkillsContainer_skills_skill}>{skill?.skill?.name}</p>
                                        ))}
                                    </div>
                                </div>

                                <div className={styles.workOffer_cardFooterContainer}>
                                    {currentWorkOffer?.description && (
                                        <div className={styles.workOffer_cardDescriptionContainer}>
                                            <p className={styles.workOffer_cardDescriptionContainer_description}>" {currentWorkOffer?.description} "</p>
                                        </div>
                                    )}
                                    <div className={styles.workOffer_cardFooterContainer_location}>
                                        {currentWorkOffer?.workZones?.map((workZone, index) => (
                                            <div key={index} className={styles.workOffer_cardFooterContainer_location_container}>
                                                <FontAwesomeIcon icon={faCompass} className={styles.workOffer_cardFooterContainer_location_container_icon} />
                                                <p className={styles.workOffer_cardFooterContainer_location_container_text}>{workZone?.jurisdiccion?.name} - {formatwokZoneCityname(workZone?.city)}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.workOffer_cardFooterContainer_startDate}>
                                        <FontAwesomeIcon icon={faCalendarDays} className={styles.workOffer_cardFooterContainer_location_container_icon} />
                                        <p className={styles.workOffer_cardFooterContainer_startDate_text}>
                                            {currentWorkOffer?.immediateStart || passedStartDate ? "Inmediato" : formatStartDate(currentWorkOffer?.startDate)}
                                        </p>
                                    </div>
                                    <div className={styles.workOffer_cardFooterContainer_salary}>
                                        <FontAwesomeIcon icon={faDollarSign} className={styles.workOffer_cardFooterContainer_location_container_icon} />
                                        <p className={styles.workOffer_cardFooterContainer_salary_text}>{formattedSalary}</p>
                                    </div>
                                    <div className={styles.workOffer_cardFooterContainer_duration}>
                                        <ApplyIcon width={20} height={20} className={styles.workOffer_cardFooterContainer_location_container_icon} />
                                        <p className={styles.workOffer_cardFooterContainer_duration_text}>Tenés tiempo hasta el {formatStartDate(currentWorkOffer?.activeUntil)}</p>
                                    </div>
                                </div>
                            </motion.div>
                        ) : (
                            <motion.div
                                key={workOffersCount}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className={styles.workOffer_container}>
                                <div className={styles.workOffer_searchIcon}>
                                    <Search size={50} />
                                </div>
                                <h2 className={styles.workOffer_card_title}>
                                    Estamos buscando las mejores ofertas para vos
                                </h2>
                                <ul className={styles.workOffer_card_list}>
                                    <li className={styles.workOffer_card_list_item}>
                                        <HardHat className={styles.workOffer_card_list_item_icon} />
                                        Próximamente encontrarás muchas oportunidades laborales aquí.
                                    </li>
                                    <li className={styles.workOffer_card_list_item}>
                                        <UserRoundPlus className={styles.workOffer_card_list_item_icon} />
                                        <p className={styles.workOffer_card_list_item_lastChild}><b>Inscribite gratis </b>hoy mismo y sé de los primeros en enterarte.</p>
                                    </li>
                                    <li className={styles.workOffer_card_list_item}>
                                        <Building2 className={styles.workOffer_card_list_item_icon} />
                                        ¿Sos empleador? Creá una oferta de trabajo y conectá con profesionales calificados.
                                    </li>
                                    <li className={styles.workOffer_card_list_item}>
                                        <Mail className={styles.workOffer_card_list_item_icon} />
                                        <p className={styles.workOffer_card_list_item_lastChild}>Si tenés dudas o consultas, escribinos a <a href="mailto:contacto@edify.la">contacto@edify.la</a>.</p>
                                    </li>
                                </ul>
                            </motion.div>
                        )}
                </AnimatePresence>
            )}
            {allJobRequests?.total > 1 &&
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className={styles.buttons}>
                    <button className={styles.buttons_button} onClick={() => handleDirection(-1)}>Anterior</button>
                    <button className={styles.buttons_button} onClick={() => handleDirection(1)}>Siguiente</button>
                </motion.div>
            }
            {showApplyModal &&
                <WorkApplyModal
                    workOffer={currentWorkOffer}
                    setShowApplyModal={setShowApplyModal}
                    setFormViewInPhone={setFormViewInPhone}
                    isPhone={isPhone}
                    setShowForm={setShowForm}
                    allJobRequests={allJobRequests} />}
        </main>
    );
};

const mapStateToProps = state => {
    return {
        allJobRequests: state.register.allJobRequests
    };
};

export default connect(mapStateToProps, { getAllWorkOffers })(WorkOffer);

