import React, { useState, useEffect } from "react";
import styles from "./Cotizaciones.module.scss";
import styled from "styled-components";
import NewNav from "../../components/Nav/NewNav";
import Botonera from "../../components/Botonera/Botonera";
import CalculadoraDeMateriales from "../../components/MarketPlace/CalculadoraDeMateriales/CalculadoraDeMateriales";

const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-right: 5%;
    padding-left: 5%;
    max-width: 150rem;
        margin: auto;
    
    @media (max-width: 1200px) {
        padding-right: 5%;
        padding-left: 5%;
    }
    
    @media (max-width: 768px) {
        padding-right: 0;
        padding-left: 0;
    }
    `;

const Cotizaciones = () => {
    const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
    const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setOcultarEnMobile(windowWidth < 900);
        setMobileStylesFromParent(windowWidth < 900);
      };
    
      // Ejecutar handleResize al cargar el componente
      useEffect(() => {
        handleResize();
      }, []);
    
      // Agregar un event listener para el cambio de tamaño de la ventana
      useEffect(() => {
        window.addEventListener("resize", handleResize);
    
        // Limpiar el event listener al desmontar el componente
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);


    return (
        <Container>
            {!ocultarEnMobile && <NewNav />}
            {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
            <div className={styles.container}>
                <CalculadoraDeMateriales />
            </div>
        </Container>
    );
};

export default Cotizaciones;