import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {
  logout,
  openProfileMenu,
  openFeedTextModal,
  openTutorialModal,
} from "../../store/actions";
import styled from "styled-components";
import styles from "./Faq.module.scss";
import { ReactComponent as TutorialSvg } from "../../assets/svg/tutorialIcon.svg";
import FaqQuestions from "./FaqQuestions";
import NewNav from "../../components/Nav/NewNav";
import Botonera from "../../components/Botonera/Botonera";

const Container = styled.div`
  display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-right: 5%;
    padding-left: 5%;
    max-width: 150rem;
        margin: auto;
    
    @media (max-width: 1200px) {
        padding-right: 5%;
        padding-left: 5%;
    }
    
    @media (max-width: 768px) {
        padding-right: 0;
        padding-left: 0;
    }`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;

  @media (max-width: 768px) {
    padding-top: 8rem;
`;


const Faq = ({ openTutorialModal }) => {
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      <Wrapper>
        <div className={styles.Faq_subtitle} onClick={() => openTutorialModal()}>
          <span> Ver tutoriales </span>
          <TutorialSvg width={30} height={30} />
        </div>
        <FaqQuestions />
      </Wrapper>
    </Container>
  )
}
const mapStateToProps = (state) => ({
  userData: state.general.userData,

  solicitudesList: state.dashboard.solicitudesList,
});

export default
  connect(mapStateToProps, {
    logout,
    openProfileMenu,
    openFeedTextModal,
    openTutorialModal,
  })(Faq);