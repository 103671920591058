import React, { useEffect } from "react";
import styles from "./MiCuenta.module.scss";
import { connect } from "react-redux";
import { openSuscriptionModal, openMainModal, cancelCurrentSuscription } from "../../../../store/actions";
import moment from "moment";
import useGuestAccountCheck from "../../../../hooks/useGuestAccountCheck";
import { Crown, CircleCheck, X } from "lucide-react";
import Loading from "../../../UI/Loading/Loading";


const MiCuenta = ({ isUserPremium, openSuscriptionModal, isFreeTrialActive, userData, cancelSuscriptionLoading, setInfoReady }) => {
    const guestAccountCheck = useGuestAccountCheck()

    const handleSuscriptionButton = () => {
        openSuscriptionModal()
    }

    useEffect(() => {
        const MIN_LOADING_TIME = 1000;

        const timer = setTimeout(() => {
            if (userData) {
                setInfoReady(true);
            }
        }, MIN_LOADING_TIME);

        return () => clearTimeout(timer);
    }, [userData, setInfoReady]);


    const formatDate = (date) => {
        if (date !== null) {
            const formattedDate = moment(date).format("DD/MM/YYYY HH:mm");
            return formattedDate;
        } else {
            return " - ";
        }
    };

    const renderSuscriptionStatus = () => {
        if (isFreeTrialActive) {
            return (
                < div className={styles.planInfo}>
                    <div className={styles.planInfo_header}>
                        <h5 className={styles.planInfo_header_title}>
                            Plan Premium
                            <Crown size={20} />
                        </h5>
                        <span className={styles.planInfo_status}>
                            activo
                        </span>
                    </div>
                    <div className={styles.planInfo_listedItems}>
                        <span className={styles.planInfo_item}>Inicio: {formatDate(userData.free_trial?.start_date ? userData.free_trial.start_date : null)}</span>
                        <span className={styles.planInfo_item}>inalizacion: {formatDate(userData.free_trial?.end_date)}</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={styles.planInfo}>
                    <div className={styles.planInfo_header}>
                        <h5 className={styles.planInfo_header_title}>
                            Plan Premium
                            <Crown size={20} />
                        </h5>
                        <span className={styles.planInfo_activeStatus}>
                            Activo
                        </span>
                    </div>
                    <div className={styles.planInfo_listedItems}>
                        {userData.subscription?.plan_name ?
                            <span className={styles.planInfo_item}>{userData.subscription.plan_name}</span> :
                            <span className={styles.planInfo_item}>Edify Plan</span>}
                        {userData.subscription?.status === "pending" && <span className={styles.planInfo_item}>Estado: Pendiente de cobro</span>}
                        {userData.subscription?.status === "active" && <span className={styles.planInfo_item}>Estado: Pagado</span>}
                        {userData.subscription?.status === "expired" && <span className={styles.planInfo_item}>Estado: Cancelado</span>}
                        <span className={styles.planInfo_item}>Inicio: {formatDate(userData.subscription?.start_date ? userData.subscription.start_date : null)}</span>
                        <span className={styles.planInfo_item}>Finalizacion: {formatDate(userData.subscription?.end_date ? userData.subscription.end_date : null)}</span>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={styles.container}>
            <h4 className={styles.title}>Plan actual</h4>
            <div className={styles.infoContainer}>
                {isUserPremium ? (
                    renderSuscriptionStatus()
                ) : (
                    <div className={styles.basicPlan}>
                        <div className={styles.basicPlan_header}>
                            <h5 className={styles.basicPlan_header_title}>
                                Plan Básico
                            </h5>
                            <span className={styles.basicPlan_activeStatus}>
                                Activo
                            </span>
                        </div>
                        <div className={styles.basicPlan_description}>
                            <p className={styles.basicPlan_description_subtitle}>Plan gratuito con funcionalidades limitadas</p>
                            <div className={styles.basicPlan_description_disclaimer}>
                                <p className={styles.basicPlan_description_disclaimer_text}>
                                    Tu plan actual no permite realizar publicaciones. Actualiza a Premium para acceder a todas las funcionalidades.
                                </p>
                            </div>
                            <button
                                className={styles.subscriptionButton}
                                onClick={guestAccountCheck(handleSuscriptionButton)}
                            >
                                ¡Actualizar a Premium!
                            </button>
                        </div>
                    </div>
                )
                }
            </div>
            <div className={styles.planDescription}>
                {isUserPremium ? (
                    <>
                        <h5 className={styles.planDescription_title}>
                            Características del plan
                        </h5>
                        <ul className={styles.planDescription_list}>
                            <li className={styles.planDescription_list_item}>
                                <span className={styles.planDescription_bullet} />
                                <div className={styles.planDescription_list_item_text}>
                                    Acceso ilimitado a proyectos
                                </div>
                            </li>
                            <li className={styles.planDescription_list_item}>
                                <span className={styles.planDescription_bullet} />
                                <div className={styles.planDescription_list_item_text}>
                                    Publicaciones ilimitadas
                                </div>
                            </li>
                            <li className={styles.planDescription_list_item}>
                                <span className={styles.planDescription_bullet} />
                                <div className={styles.planDescription_list_item_text}>
                                    Atención personalizada
                                </div>
                            </li>
                        </ul>
                    </>
                ) : (
                    <>
                        <h5 className={styles.planDescription_title}>
                            Comparación de planes
                        </h5>
                        <div className={styles.planDescription_comparison}>
                            <div className={styles.planDescription_comparison_basicPlan}>
                                <p className={styles.planDescription_comparison_basicPlan_title}>Plan básico</p>
                                <ul className={styles.planDescription_list}>
                                    <li className={styles.planDescription_list_item}>
                                        <CircleCheck size={16} />
                                        <div className={styles.planDescription_list_item_text}>
                                            Acceso a la plataforma
                                        </div>
                                    </li>
                                    <li className={styles.planDescription_list_item}>
                                        <CircleCheck size={16} />
                                        <div className={styles.planDescription_list_item_text}>
                                            Perfil personal
                                        </div>
                                    </li>
                                    <li className={styles.planDescription_list_item}>
                                        <X size={16} style={{ color: "rgb(248 113 113)" }} />
                                        <div className={styles.planDescription_list_item_text}>
                                            Publicaciones limitadas
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.planDescription_comparison_premiumPlan}>
                                <p className={styles.planDescription_comparison_premiumPlan_title}>Plan Premium</p>
                                <ul className={styles.planDescription_list}>
                                    <li className={styles.planDescription_list_item}>
                                        <CircleCheck size={16} style={{ color: "rgb(37 99 235)" }} />
                                        <div className={styles.planDescription_list_item_text}>
                                            Publicaciones ilimitadas
                                        </div>
                                    </li>
                                    <li className={styles.planDescription_list_item}>
                                        <CircleCheck size={16} style={{ color: "rgb(37 99 235)" }} />
                                        <div className={styles.planDescription_list_item_text}>
                                            Perfil destacado
                                        </div>
                                    </li>
                                    <li className={styles.planDescription_list_item}>
                                        <CircleCheck size={16} style={{ color: "rgb(37 99 235)" }} />
                                        <div className={styles.planDescription_list_item_text}>
                                            Soporte prioritario
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </>
                )
                }

            </div>
            {cancelSuscriptionLoading && <Loading />}
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        isUserPremium: state.general.isUserPremium,
        isFreeTrialActive: state.general.isFreeTrialActive,
        userData: state.general.userData,
        cancelSuscriptionLoading: state.profile.cancelSuscriptionLoading,
        isCancelledSuscriptionStillActive: state.general.isCancelledSuscriptionStillActive,
        profileVisits: state.profile.profileVisits,
    };
};

export default connect(mapStateToProps, { openSuscriptionModal, openMainModal, cancelCurrentSuscription })(MiCuenta);
