import React, { useState, useEffect } from "react";
import styles from "./OfertaDeTrabajo.module.scss";
import { connect } from "react-redux";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import JurisdictionList from "../../components/UI/JurisdictionList/JurisdictionList";
import { getSkills, getCities, createWorkOffer, openMainModalDashboard, closeMainModal, closeWorkOfferModal } from "../../store/actions";
import useGetTodayDate from '../../hooks/useGetTodayDate';
import { motion, AnimatePresence } from "framer-motion";
import { fireAnalytics } from "../../utils/firebase";
import { MapPin, Briefcase, Calendar, DollarSign, FileText, Phone, ArrowRight, ArrowLeft, BookmarkCheck } from "lucide-react";

const variants = {
    visible: {
        scale: 1.0,
    },
    hidden: {
        scale: 1.1,
    },
};

const OfertaDeTrabajo = ({
    openMainModalDashboard,
    closeMainModal,
    cities,
    skills,
    userData,
    getSkills,
    createWorkOffer,
    closeWorkOfferModal,
    getCities }) => {
    const [showJurisdictionList, setShowJurisdictionList] = useState(false);
    const [showCityList, setShowCityList] = useState(false);
    const [showCitySuggestions, setShowCitySuggestions] = useState(false);
    const [cityInputValue, setCityInputValue] = useState('');
    const [showFormSection, setShowFormSection] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [showSkillsList, setShowSkillsList] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showStartJobDate, setShowStartJobDate] = useState(false);
    const [selectedSalaryType, setSelectedSalaryType] = useState('Hour');
    const [step, setStep] = useState(1);

    const today = useGetTodayDate();

    const STEPS = [
        { number: 1, title: 'Lugar*', icon: MapPin },
        { number: 2, title: 'Rubro*', icon: Briefcase },
        { number: 3, title: 'Fechas*', icon: Calendar },
        { number: 4, title: 'Condiciones salariales', icon: DollarSign },
        { number: 5, title: 'Descripción', icon: FileText },
        { number: 6, title: 'Contacto', icon: Phone },
    ];

    const nextStep = () => setStep(prev => Math.min(prev + 1, STEPS.length));
    const prevStep = () => setStep(prev => Math.max(prev - 1, 1));

    const renderStepsContent = () => {
        switch (step) {
            case 1:
                return (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                        className={styles.body_formGroup_workZone}>
                        {formik.values.workZone.length > 0 && (
                            <div className={styles.body_item_workZoneSelected}>
                                {formik.values.workZone.map((workZone, index) => (
                                    <div
                                        key={index}
                                        className={styles.body_item_workZoneSelected_item}
                                        onClick={() => { formik.setFieldValue('workZone', formik.values.workZone.filter((_, i) => i !== index)) }}>
                                        {workZone.name} - {workZone.city.split(",")[0]}&nbsp;X
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className={styles.body_formGroup_workZone_inputsContainer}>
                            <label className={styles.stepTitle}  htmlFor='jurisdictionList'>Provincia</label>
                            <div className={styles.body_item_workZoneContainer}>
                                <input
                                    type='text'
                                    id='jurisdictionList'
                                    name='jurisdictionList'
                                    placeholder='Elegir provincia'
                                    className={styles.body_item_workZoneInput}
                                    value={formik.values.selectedJurisdiction?.name || ''}
                                    readOnly
                                    onChange={formik.handleChange}
                                />
                                <ArrowDownIcon
                                    title='Ver lista de provincias'
                                    width={15}
                                    height={15}
                                    className={showJurisdictionList ? styles.workBag_workZoneArrowDown : styles.workBag_workZoneArrowUp}
                                    onClick={() => setShowJurisdictionList(!showJurisdictionList)} />
                             {showJurisdictionList && (
                                <ul className={styles.body_item_workZoneList}>
                                    {JurisdictionList.map((jurisdiction) => (
                                        <li
                                            key={jurisdiction.id}
                                            className={styles.body_item_workZoneList_item}
                                            onClick={() => {
                                                formik.setFieldValue('selectedJurisdiction', { id: jurisdiction.id, name: jurisdiction.name });
                                                setShowJurisdictionList(false);
                                            }}>
                                            {jurisdiction.name}
                                        </li>
                                    ))}
                                </ul>
                            )}       
                            </div>
                            <label className={styles.stepTitle}  htmlFor='workZone'>Ciudad</label>
                            <div className={`${styles.body_item_workZoneCityContainer} ${!formik.values.selectedJurisdiction && styles.disabledInput}`}>

                                <input
                                    type='text'
                                    id='workZone'
                                    name='workZone'
                                    placeholder='Elegir ciudad'
                                    value={cityInputValue}
                                    onChange={(e) => handleCitySuggestions(e.target.value)}
                                    className={`${styles.body_item_workZoneInput} ${!formik.values.selectedJurisdiction && styles.disabledInput}`}
                                />
                                <ArrowDownIcon
                                    title='Ver lista de ciudades'
                                    width={15}
                                    height={15}
                                    className={`${showCityList ? styles.body_item_workZoneArrowDown : styles.body_item_workZoneArrowUp} ${!formik.values.selectedJurisdiction && styles.disabledInput}`}
                                    onClick={() => setShowCityList(!showCityList)} />
                            {showCityList && !showCitySuggestions && (
                                <ul className={styles.body_item_workCityList}>
                                    {cities?.map((city) => (
                                        <li
                                            key={city.name}
                                            className={styles.body_item_workCityList_item}
                                            onClick={() => { handleAddWorkZone(city) }}
                                        >
                                            {city.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {showCitySuggestions && !showCityList && (
                                <ul className={styles.body_item_workCityList}>
                                    {matchingCities.map((city) => (
                                        <li
                                            key={city.name}
                                            className={styles.body_item_workCityList_item}
                                            onClick={() => { handleAddWorkZone(city) }}
                                        >
                                            {city.name}
                                        </li>
                                    ))}
                                </ul>
                            )}        
                            </div>       
                        </div>
                    </motion.div>
                );
            case 2:
                return (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                        className={styles.body_formGroup_workZone}>
                        {formik.values.skills.length > 0 && (
                            <div className={styles.body_item_skillsSelected}>
                                {formik.values.skills.map((skill, index) => (
                                    <div
                                        key={index}
                                        className={styles.body_item_skillsSelected_item}
                                        onClick={() => {
                                            formik.setFieldValue('skills', formik.values.skills.filter((_, i) => i !== index))
                                        }}
                                    >
                                        {skill.name}&nbsp;X
                                    </div>
                                ))}
                            </div>
                        )}
                        <label className={styles.stepTitle}  htmlFor='skills'>Rubro</label>
                        <div className={styles.body_item_workZoneContainer}>
                            <input
                                type='text'
                                id='skills'
                                name='skills'
                                placeholder='Ingrese 3 caracteres o despliegue la lista'
                                value={inputValue}
                                className={styles.body_item_workZoneInput}
                                onChange={(e) => handleSuggestions(e.target.value)}
                            />
                            <ArrowDownIcon
                                title='Ver lista de rubros'
                                width={15}
                                height={15}
                                className={showSkillsList ? styles.body_item_skillsArrowDown : styles.body_item_skillsArrowUp}
                                onClick={() => setShowSkillsList(!showSkillsList)} />
                        </div>
                        {showSkillsList && !showSuggestions && (
                            <ul className={styles.body_item_skillsSelected_list}>
                                {skills.map((skill) => (
                                    <li
                                        key={skill.id}
                                        className={styles.body_item_skillsSelected_itemList}
                                        onClick={() => handleAddSkill(skill.name, skill.id)}
                                    >
                                        {skill.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {showSuggestions && !showSkillsList && (
                            <ul className={styles.body_item_skillsSelected_list}>
                                {matchingSkills.map((suggestion) => (
                                    <li
                                        key={suggestion.id}
                                        className={styles.body_item_skillsSelected_itemList}
                                        onClick={() => handleAddSkill(suggestion.name, suggestion.id)}
                                    >
                                        {suggestion.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </motion.div>
                );
            case 3:
                return (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                        className={styles.body_formGroup_workZone}>
                        <div className={styles.body_item_dateContainer}>
                            <h4 className={styles.body_item_dateContainer_title}>
                                ¿Cuándo comienza el trabajo?
                            </h4>
                            <div className={styles.body_item_dateContainer_start}>
                                <label htmlFor='inmediateStart'>Inicio inmediato</label>
                                <input
                                    type='checkbox'
                                    id='immediateStart'
                                    name='immediateStart'
                                    value={formik.values.immediateStart}
                                    checked={formik.values.immediateStart}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <AnimatePresence>
                            {!formik.values.immediateStart && (
                                <motion.div
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: 'auto' }}
                                    exit={{ opacity: 0, height: 0 }}
                                    className={styles.body_item_dateContainer_exactDate}>
                                    <label htmlFor='startDate'>Fecha de inicio</label>
                                    <input
                                        type='date'
                                        id='startDate'
                                        name='startDate'
                                        min={convertDateToISOFormat(today)}
                                        max={formik.values.activeUntil}
                                        placeholder='Fecha de inicio'
                                        className={styles.body_item_dateContainer_input}
                                        value={formik.values.immediateStart ? convertDateToISOFormat(today) : formik.values.startDate}
                                        onChange={formik.handleChange}
                                    />
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <div className={styles.body_item_dateContainer}>
                            <h4 className={styles.body_item_dateContainer_title}>
                                ¿Hasta cuándo es válida la oferta?
                            </h4>
                            <div className={styles.body_item_dateContainer_endDate}>
                                <label htmlFor='activeUntil'>Fecha de finalización</label>
                                <input
                                    type='date'
                                    id='activeUntil'
                                    name='activeUntil'
                                    min={formik.values.startDate || convertDateToISOFormat(today)}
                                    placeholder='Fecha de publicación'
                                    className={styles.body_item_dateContainer_input}
                                    value={formik.values.activeUntil}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                    </motion.div>
                );
            case 4:
                return (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        className={styles.body_item_salaryOfferContainer}>
                        <label className={styles.stepTitle}  htmlFor='salary'>Remuneración ofrecida</label>
                        <div className={styles.body_item_salaryOffer}>
                            <input
                                type='text'
                                id='salary'
                                name='salary'
                                placeholder='Ingresar monto'
                                className={styles.body_item_salaryOffer_input}
                                value={formik.values.salaryFormatted}
                                onChange={(e) => handleSalaryChange(e)}
                            />
                            <span className={styles.body_item_salaryOffer_currency}>$</span>
                            <select
                                name='salaryType'
                                id='salaryType'
                                value={selectedSalaryType}
                                onChange={(e) => handleSalaryTypeChange(e)}
                                className={styles.body_item_salaryOffer_select}
                            >
                                <option className={styles.body_item_salaryOffer_option} value='Hour'>Hora</option>
                                <option className={styles.body_item_salaryOffer_option} value='Day'>Día</option>
                                <option className={styles.body_item_salaryOffer_option} value='Week'>Semana</option>
                                <option className={styles.body_item_salaryOffer_option} value='Month'>Mes</option>
                                <option className={styles.body_item_salaryOffer_option} value='Job'>Trabajo completo</option>
                            </select>
                        </div>
                    </motion.div>
                );
            case 5:
                return (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        className={styles.body_item_description}>
                        <label className={styles.stepTitle}  htmlFor='description'>Descripción de la oferta</label>
                        <textarea
                            name='description'
                            id='description'
                            placeholder='Escribe aquí los detalles de la oferta, requisitos, tareas a realizar, etc.'
                            className={styles.body_item_description_textarea}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                        />
                    </motion.div>
                );
            case 6:
                return (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        className={styles.body_item_contact}>
                        <label className={styles.stepTitle} htmlFor='contactMethod'>¿Cómo deseas ser contactado?</label>
                        <div className={styles.body_item_contactMethod}>
                            <label className={styles.body_item_contactAlternative_label} htmlFor='contactMethod'>Método de contacto</label>
                            <select
                                name='contactMethod'
                                id='contactMethod'
                                value={formik.values.contactMethod}
                                onChange={(e) => handleContactMethod(e)}
                                className={styles.body_item_contactMethod_select}
                            >
                                <option className={styles.body_item_contactMethod_option} value='email'>Email</option>
                                <option className={styles.body_item_contactMethod_option} value='phone'>Teléfono</option>
                            </select>
                        </div>
                        <div className={styles.body_item_contactAlternative}>
                            <label className={styles.body_item_contactAlternative_label} htmlFor='alternativeContact'>
                                Serás contactado
                            </label>
                            <input
                                type='text'
                                id='alternativeContact'
                                name='alternativeContact'
                                placeholder='Ingresar contacto'
                                value={formik.values.alternativeContact}
                                onChange={formik.handleChange}
                                className={styles.body_item_contactAlternative_input}
                            />
                        </div>
                    </motion.div>
                );
            default:
                return null;
        }
    };




    const convertDateToISOFormat = (date) => {
        const [dd, mm, yyyy] = date.split('/');
        return `${yyyy}-${mm}-${dd}`;
    };

    let startDateInitialValue = new Date();
    startDateInitialValue.setDate(startDateInitialValue.getDate());
    startDateInitialValue = startDateInitialValue.toISOString().split('T')[0];

    useEffect(() => {
        getSkills();
        fireAnalytics('open_work_offer_modal', { action: 'open_work_offer_modal', user_info_work_offer: userData.id });
    }, []);

    const initialValues = {
        selectedJurisdiction: null,
        workZone: [],
        skills: [],
        description: '',
        immediateStart: true,
        startDate: startDateInitialValue,
        contactMethod: 'email',
        alternativeContact: userData?.username,
        phone: userData?.phone,
        salary: 0,
        salaryFormatted: '',
        activeUntil: '',
        salaryType: selectedSalaryType,
        city: ''
    };

    const formSchema = Yup.object({
        selectedJurisdiction: Yup.object().nullable(),
        workZone: Yup.array().min(1, 'Debes seleccionar al menos una ciudad'),
        immediateStart: Yup.boolean().required('Debes elegir si la oferta es de inicio inmediato o no'),
        activeUntil: Yup.string().required('Debes ingresar la fecha de finalización de la oferta'),
        skills: Yup.array().min(1, '*Debes seleccionar al menos un rubro de trabajo').required('*Debes seleccionar al menos un rubro de trabajo'),
    });

    const formik = useFormik({
        validateOnMount: true,
        validateOnBlur: true,
        initialValues,
        validationSchema: formSchema,
        onSubmit: values => {
            createWorkOffer(values);
            formik.resetForm();
            formik.setValues(formik.initialValues);
            closeWorkOfferModal();
        }
    });

    const handleAddSkill = (skillName, skillId) => {
        const skillExists = formik.values.skills.some(skill => skill.id === skillId);
        if (!skillExists) {
            formik.setFieldValue('skills', [...formik.values.skills, { id: skillId, name: skillName }]);
        }
        setShowSkillsList(false);
        setShowSuggestions(false);
        setInputValue('');
    };

    const handleAddWorkZone = (city) => {
        const newWorkZone = [
            ...formik.values.workZone,
            {
                jurisdiccion: formik.values.selectedJurisdiction.id,
                name: formik.values.selectedJurisdiction.name,
                city: city.name
            }
        ];
        formik.setFieldValue('workZone', newWorkZone);
        formik.setFieldValue('city', city.name);
        formik.setFieldValue('selectedJurisdiction', null);
        formik.setFieldValue('city', "");
        setShowCityList(false);
        setShowCitySuggestions(false);
        setCityInputValue('');

    };


    const handleSuggestions = (value) => {

        if (value.length < 3) {
            setShowSuggestions(false)
        } else if (value.length >= 3) {
            setShowSkillsList(false)
            setShowSuggestions(true)
        }
        setInputValue(value)

    }

    const matchingSkills = skills?.filter((rubro) => {
        return rubro.name.toLowerCase().includes(inputValue.toLowerCase())
    })


    useEffect(() => {
        if (formik.values.selectedJurisdiction) {
            getCities(formik.values.selectedJurisdiction?.id)
        }
    }, [formik.values.selectedJurisdiction?.id]);

    const handleCitySuggestions = (value) => {
        if (value.length < 3) {
            setShowCitySuggestions(false)
        } else if (value.length >= 3) {
            setShowCityList(false);
            setShowCitySuggestions(true);
        }
        setCityInputValue(value)
    }

    const matchingCities = cities?.filter((city) => {
        return city.name.toLowerCase().includes(cityInputValue.toLowerCase())
    })

    const toggleFormSection = (index) => {
        setShowFormSection({
            ...showFormSection,
            [index]: !showFormSection[index]
        })
    }

    const handleSalaryTypeChange = (e) => {
        formik.handleChange(e);
        setSelectedSalaryType(e.target.value);
    };

    const handleSalaryChange = (e) => {
        let value = e.target.value.replace(/\./g, '');
        value = value.replace(/\D/g, '');

        if (value) {
            const numberValue = parseInt(value, 10);
            if (!isNaN(numberValue)) {
                const formattedValue = new Intl.NumberFormat('es-ES').format(numberValue);
                formik.setFieldValue('salary', numberValue);
                formik.setFieldValue('salaryFormatted', formattedValue);
            }
        } else {
            formik.setFieldValue('salary', '');
            formik.setFieldValue('salaryFormatted', '');
        }
    };

    const handleContactMethod = (e) => {
        let value = e.target.value;

        if (value === 'phone') {
            formik.setFieldValue('contactMethod', value);
            formik.setFieldValue('alternativeContact', userData?.phone);
        } else if
            (value === 'email') {
            formik.setFieldValue('contactMethod', value);
            formik.setFieldValue('alternativeContact', userData?.username);
        }
    }

    useEffect(() => {
        if (formik.values.immediateStart) {
            formik.setFieldValue('startDate', convertDateToISOFormat(today));
        }
    }, [formik.values.immediateStart, formik.setFieldValue, today]);

    const handleCloseModal = () => {
        openMainModalDashboard({
            title: '¿Estás seguro que deseas salir?',
            body: 'Si cierras la ventana perderás los datos ingresados',
            isSuccess: false,
            iconType: 'warning',
            confirmHandler: () => {
                closeMainModal();
                closeWorkOfferModal();
            },
        })
    }

    return (
        <div className={styles.overlay}>
            <motion.div
                initial="hidden"
                animate="visible"
                variants={variants}
                sizeTransition
                className={styles.container}>
                <div className={styles.containerBody}>
                    <h1 className={styles.containerBody_header_title}>
                        <div className={styles.containerBody_header}>
                            <Briefcase size={24} />
                            Crear oferta de trabajo
                        </div>
                        <button className={styles.containerBody_header_button} onClick={() => handleCloseModal()}>X</button>
                    </h1>
                    <div className={styles.containerBody_form}>
                        <div className={styles.containerBody_steps}>
                            {STEPS.map((stepItem) => (
                                <div
                                    key={stepItem.number}
                                    className={styles.containerBody_step}
                                    onClick={() => setStep(stepItem.number)}
                                >
                                    <div className={`${styles.containerBody_step_icon} ${stepItem.number === step && styles.containerBody_stepActive}`}>
                                        <stepItem.icon size={24} />
                                    </div>
                                    <span className={`${styles.containerBody_step_title} ${stepItem.number === step && styles.containerBody_stepActiveTitle}`}>{stepItem.title}</span>
                                </div>
                            ))}
                        </div>
                        <div className={styles.containerBody_progressBar}>
                            <div className={styles.containerBody_progressBar_fill} style={{ width: `${(step) * 16.6}%` }}></div>
                        </div>
                        <div className={styles.containerBody_content}>
                            {renderStepsContent()}
                        </div>
                        <div className={styles.containerBody_footer}>
                            {step > 1 && (
                                <button
                                    className={styles.containerBody_footer_buttonBack}
                                    onClick={() => prevStep()}
                                >
                                    <ArrowLeft size={16} />
                                    Anterior
                                </button>
                            )}
                            {step < 6 ? (
                                <button
                                    className={styles.containerBody_footer_buttonNext}
                                    onClick={() => nextStep()}
                                >
                                    Siguiente
                                    <ArrowRight size={16} />
                                </button>
                            ) : (
                                <button
                                    className={styles.containerBody_footer_buttonFinish}
                                    onClick={formik.handleSubmit}
                                    disabled={!formik.isValid}
                                >
                                    Publicar oferta
                                    <BookmarkCheck size={16} />
                                </button>
                            )}
                            </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        skills: state.register.skills,
        cities: state.register.cities,
        userData: state.general.userData,
    }
}

export default connect(mapStateToProps, {
    getSkills,
    getCities,
    createWorkOffer,
    openMainModalDashboard,
    closeWorkOfferModal,
    closeMainModal
})(OfertaDeTrabajo);
