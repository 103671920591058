import React, { useState, useEffect } from "react";
import styles from "./WorkZoneSelector.module.scss";
import JurisdictionList from "../UI/JurisdictionList/JurisdictionList";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";
import { getCities } from "../../store/actions";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { MapPin, X, Info } from "lucide-react";

const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
}

const WorkZoneSelector = ({ handleChange, setFieldValue, values, getCities, cities }) => {
    const [showJurisdictionList, setShowJurisdictionList] = useState(false);
    const [showCityList, setShowCityList] = useState(false);
    const [showCitySuggestions, setShowCitySuggestions] = useState(false);
    const [cityInputValue, setCityInputValue] = useState('');


    const handleAddWorkZone = (city) => {
        const newWorkZone = [
            ...values.jobBoardZones,
            {
                jurisdiccion: values.selectedJurisdiction.id,
                name: values.selectedJurisdiction.name,
                city: city.name
            }
        ];
        setFieldValue('jobBoardZones', newWorkZone);
        setFieldValue('city', city.name);
        setFieldValue('selectedJurisdiction', null);
        setFieldValue('city', "");
        setShowCityList(false);
        setShowCitySuggestions(false);
        setCityInputValue('');

    };

    useEffect(() => {
        if (values.selectedJurisdiction) {
            getCities(values.selectedJurisdiction?.id)
        }
    }, [values.selectedJurisdiction?.id]);

    const handleCitySuggestions = (value) => {
        if (value.length < 3) {
            setShowCitySuggestions(false)
        } else if (value.length >= 3) {
            setShowCityList(false);
            setShowCitySuggestions(true);
        }
        setCityInputValue(value)
    }

    const matchingCities = cities?.filter((city) => {
        return city.name.toLowerCase().includes(cityInputValue.toLowerCase())
    })

    return !values.allCountry && (

        <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            className={styles.body}>

            <div className={styles.body_formGroup_inputsContainer}>
                <div className={styles.body_workZoneContainer}>
                    <label className={styles.body_workZoneLabel}>Provincia</label>
                    <div className={styles.body_workZoneContainer_inputContainer}>
                        <button className={styles.body_workZoneContainer_inputContainer_button}
                            type="button"
                            onClick={() => setShowJurisdictionList(!showJurisdictionList)}>
                            <input
                                type='text'
                                id='jurisdictionList'
                                name='jurisdictionList'
                                placeholder='Selecciona una provincia'
                                className={styles.body_workZoneInput}
                                value={values.selectedJurisdiction?.name || ''}
                                readOnly
                                onChange={handleChange}
                            />
                            <ArrowDownIcon
                                title='Ver lista de provincias'
                                width={15}
                                height={15}
                                className={showJurisdictionList ? styles.workBag_workZoneArrowDown : styles.workBag_workZoneArrowUp}
                            />
                        </button>
                    </div>
                    {showJurisdictionList && (
                        <ul className={styles.body_workZoneList}>
                            {JurisdictionList.map((jurisdiction) => (
                                <li
                                    key={jurisdiction.id}
                                    className={styles.body_workZoneList_item}
                                    onClick={() => {
                                        setFieldValue('selectedJurisdiction', { id: jurisdiction.id, name: jurisdiction.name });
                                        setShowJurisdictionList(false);
                                    }}>
                                    {jurisdiction.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className={`${styles.body_workZoneContainer} ${!values.selectedJurisdiction && styles.disabledInput}`}>
                    <label className={styles.body_workZoneLabel}>Ciudad</label>
                    <div className={styles.body_workZoneContainer_inputContainer}>
                        <button className={styles.body_workZoneContainer_inputContainer_button}
                            type="button"
                            onClick={() => setShowCityList(!showCityList)}>
                            <input
                                type='text'
                                id='jobBoardZones'
                                name='jobBoardZones'
                                placeholder='Ciudad'
                                value={cityInputValue}
                                onChange={(e) => handleCitySuggestions(e.target.value)}
                                className={`${styles.body_workZoneInput} ${!values.selectedJurisdiction && styles.disabledInput}`}
                            />
                            <ArrowDownIcon
                                title='Ver lista de ciudades'
                                width={15}
                                height={15}
                                className={`${showCityList ? styles.body_item_workZoneArrowDown : styles.body_item_workZoneArrowUp} ${!values.selectedJurisdiction && styles.disabledInput}`}
                            />
                        </button>
                        {showCityList && !showCitySuggestions && (
                            <ul className={styles.body_workZoneList}>
                                {cities?.map((city) => (
                                    <li
                                        key={city.name}
                                        className={styles.body_workZoneList_item}
                                        onClick={() => { handleAddWorkZone(city) }}
                                    >
                                        {city.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {showCitySuggestions && !showCityList && (
                        <ul className={styles.body_workCityList}>
                            {matchingCities.map((city) => (
                                <li
                                    key={city.name}
                                    className={styles.body_workCityList_item}
                                    onClick={() => { handleAddWorkZone(city) }}
                                >
                                    {city.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>

            {values.jobBoardZones.length > 0 ? (
                <div className={styles.body_workZoneSelected}>
                    {values.jobBoardZones.map((workZone, index) => (
                        <div
                            key={index}
                            className={styles.body_workZoneSelected_item}
                            onClick={() => { setFieldValue('jobBoardZones', values.jobBoardZones.filter((_, i) => i !== index)) }}>
                            <MapPin size={16} />
                            {workZone.name} - {workZone.city.split(",")[0]}
                            <X size={16} className={styles.body_workZoneSelected_item_icon} />
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.emptyState}>
                    <Info size={16} style={{minWidth: "1.6rem"}} />
                    <span>Seleccione al menos una ubicación o marque "Vendo productos y/o servicios en todo el país"</span>
                </div>
            )}
        </motion.div>
    )
}


const mapStateToProps = (state) => {
    return {
        cities: state.register.cities,
    }
}

export default connect(mapStateToProps, { getCities })(WorkZoneSelector);