import React, { useState, useEffect } from "react";
import styles from "./MercadoDeTrabajoLanding.module.scss";
import styled from "styled-components";
import NewNav from "../../components/Nav/NewNav.js";
import Botonera from "../../components/Botonera/Botonera";
import { motion } from "framer-motion";
import MercadoDeTrabajo from "./MercadoDeTrabajo.js";
import { connect } from "react-redux";
import { openWorkOfferModal } from "../../store/actions";
import { Search, Briefcase, MapPin, UserRoundCheck, HardHat, ArrowRight, Clock10, List, MailWarning, Users, CircleCheck, Mail } from "lucide-react";
import usePremiumCheck from "../../hooks/usePremiumCheck.js";
import useIsPhone from "../../hooks/useIsPhone.js";
import useSectionTracker from "../../hooks/useSectionTracker.js";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 5%;
  padding-left: 5%;
  max-width: 150rem;
  margin: auto;

`;

const variants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
};


const MercadoDeTrabajoLanding = ({ openWorkOfferModal }) => {
    const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
    const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
    const [showWorkBagComponent, setShowWorkBagComponent] = useState(false);
    const { isVisible: isHeaderVisible, dismiss: dismissHeader } = useSectionTracker('cotizacionHeader', {
        isDismissable: true,
        trackVisits: false,
        defaultVisibility: true
    });

    const premiumCheck = usePremiumCheck();
    const isPhone = useIsPhone();

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setOcultarEnMobile(windowWidth < 900);
        setMobileStylesFromParent(windowWidth < 900);
    };

    useEffect(() => {
        handleResize();
    }, []);



    // Agregar un event listener para el cambio de tamaño de la ventana
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        // Limpiar el event listener al desmontar el componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const stepsToMap = [
        {
            title: "Busca o publica",
            description: "Encuentra trabajadores o publica tu oferta laboral con los detalles específicos que necesitas",
            icon: <Search size={32} />,
        },
        {
            title: "Selecciona candidatos",
            description: "Revisa perfiles, experiencia y referencias. Elige a los mejores candidatos",
            icon: <Users size={32} />,
        },
        {
            title: "Contrata",
            description: "Contacta directamente y coordina los detalles para comenzar a trabajar",
            icon: <CircleCheck size={32} />,
        },
    ];

    return (
        <Container>
            {!ocultarEnMobile && <NewNav />}
            {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
            {showWorkBagComponent ? (
                <MercadoDeTrabajo setShowWorkBagComponent={setShowWorkBagComponent} isPhone={isPhone} />
            ) : (
                <div className={styles.mercadoDeTrabajo_overflowContainer}>
                    <motion.section
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{ duration: 0.5 }}
                        className={styles.mercadoDeTrabajo}>
                        <motion.div
                            initial={false}
                            animate={{
                                opacity: isHeaderVisible ? 1 : 0,
                                height: isHeaderVisible ? "auto" : 0,
                            }}
                            className={styles.mercadoDeTrabajo_headerWrapper}
                            transition={{
                                type: "spring",
                                damping: 20,
                                stiffness: 100
                            }}>

                            <div className={styles.mercadoDeTrabajo_header}>
                                <h2 className={styles.mercadoDeTrabajo_header_title}>Dos formas de encontrar el talento ideal</h2>
                                <p className={styles.mercadoDeTrabajo_header_subtitle}>Nuestra plataforma te ofrece flexibilidad para encontrar profesionales de la construcción</p>
                                <button
                                    onClick={dismissHeader}
                                    className={styles.mercadoDeTrabajo_header_closeButton}
                                    aria-label="Cerrar header"
                                >
                                    &times;
                                </button>
                            </div>
                        </motion.div>
                        
                        <div className={styles.mercadoDeTrabajo_content}>
                            <div className={styles.mercadoDeTrabajo_content_item}>
                                <div className={styles.mercadoDeTrabajo_content_corner} />
                                <div className={styles.mercadoDeTrabajo_content_item_body}>
                                    <div className={styles.mercadoDeTrabajo_content_item_iconContainer}>
                                        <Search size={32} className={styles.mercadoDeTrabajo_content_item_icon} />
                                    </div>
                                    <h3 className={styles.mercadoDeTrabajo_content_item_title}>Búsqueda directa de trabajadores</h3>
                                    <p className={styles.mercadoDeTrabajo_content_item_description}>Encuentra profesionales calificados filtrando por ubicación, especialidad y experiencia.</p>
                                    <ul className={styles.mercadoDeTrabajo_content_item_list}>
                                        <li className={styles.mercadoDeTrabajo_content_item_list_item}>
                                            <MapPin size={20} className={styles.mercadoDeTrabajo_content_item_list_item_icon} />
                                            Filtrá por ubicación
                                        </li>
                                        <li className={styles.mercadoDeTrabajo_content_item_list_item}>
                                            <UserRoundCheck size={20} className={styles.mercadoDeTrabajo_content_item_list_item_icon} />
                                            Perfiles verificados
                                        </li>
                                        <li className={styles.mercadoDeTrabajo_content_item_list_item}>
                                            <HardHat size={20} className={styles.mercadoDeTrabajo_content_item_list_item_icon} />
                                            Búsqueda por especialidad
                                        </li>
                                    </ul>
                                </div>
                                <button className={styles.mercadoDeTrabajo_content_item_button} onClick={() => setShowWorkBagComponent(true)}>
                                    Buscar trabajadores
                                    <ArrowRight size={20} className={styles.mercadoDeTrabajo_content_item_button_icon} />
                                </button>
                            </div>
                            <div className={styles.mercadoDeTrabajo_content_item}>
                                <div className={styles.mercadoDeTrabajo_content_corner} />
                                <div className={styles.mercadoDeTrabajo_content_item_body}>
                                    <div className={styles.mercadoDeTrabajo_content_item_iconContainer}>
                                        <Briefcase size={32} className={styles.mercadoDeTrabajo_content_item_icon} />
                                    </div>
                                    <h3 className={styles.mercadoDeTrabajo_content_item_title}>Publicación de ofertas laborales</h3>
                                    <p className={styles.mercadoDeTrabajo_content_item_description}>Publica tus ofertas de trabajo y deja que los profesionales calificados se postulen.</p>
                                    <ul className={styles.mercadoDeTrabajo_content_item_list}>
                                        <li className={styles.mercadoDeTrabajo_content_item_list_item}>
                                            <Clock10 size={20} className={styles.mercadoDeTrabajo_content_item_list_item_icon} />
                                            Publicación rápida
                                        </li>
                                        <li className={styles.mercadoDeTrabajo_content_item_list_item}>
                                            <List size={20} className={styles.mercadoDeTrabajo_content_item_list_item_icon} />
                                            Selecciona tus requerimientos
                                        </li>
                                        <li className={styles.mercadoDeTrabajo_content_item_list_item}>
                                            <MailWarning size={20} className={styles.mercadoDeTrabajo_content_item_list_item_icon} />
                                            Evita spam
                                        </li>
                                    </ul>
                                    <button className={styles.mercadoDeTrabajo_content_item_buttonPrimary} onClick={premiumCheck(openWorkOfferModal)}>
                                        Publicar oferta
                                        <ArrowRight size={16} className={styles.mercadoDeTrabajo_content_item_button_icon} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.mercadoDeTrabajo_footer}>
                            <div className={styles.mercadoDeTrabajo_footer_header}>
                                <h3 className={styles.mercadoDeTrabajo_footer_header_title}>Proceso simple y efectivo</h3>
                                <p className={styles.mercadoDeTrabajo_footer_header_description}>En solo unos pasos podrás encontrar al trabajador ideal para tu proyecto</p>
                            </div>
                            <div className={styles.mercadoDeTrabajo_footer_steps}>
                                {stepsToMap.map((step, index) => (
                                    <div key={index} className={styles.mercadoDeTrabajo_footer_step}>
                                        <div className={styles.mercadoDeTrabajo_footer_step_iconContainer}>
                                            {step.icon}
                                        </div>
                                        <h4 className={styles.mercadoDeTrabajo_footer_step_title}>{step.title}</h4>
                                        <p className={styles.mercadoDeTrabajo_footer_step_description}>{step.description}</p>
                                        {index !== stepsToMap.length - 1 && (
                                            <div className={styles.mercadoDeTrabajo_footer_step_line}>
                                                <div className={styles.mercadoDeTrabajo_footer_step_line_dot} />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className={styles.mercadoDeTrabajo_footer_help}>
                                <h4 className={styles.mercadoDeTrabajo_footer_help_title}>¿Necesitas ayuda con el proceso?</h4>
                                <a href="mailto:contacto@edify.la" className={styles.mercadoDeTrabajo_footer_help_contact}>
                                    <Mail size={20} style={{ marginTop: "0.1rem" }} />
                                    <span className={styles.mercadoDeTrabajo_footer_help_contact_text}>Contacta con nuestro equipo de soporte</span>
                                </a>
                            </div>
                        </div>
                    </motion.section>
                </div>
            )}
        </Container>
    );
};

const mapDispatchToProps = {
    openWorkOfferModal,
};

export default connect(null, mapDispatchToProps)(MercadoDeTrabajoLanding);