import React, { useEffect } from "react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./store/reducers/index";
import theme from "./utils/theme";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./utils/global";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import environment from "environment";
import { GoogleOAuthProvider } from "@react-oauth/google";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["profile", "general", "register"],
};

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: environment.env, // Puede ser una variable de entorno
    // Se recomienda ajustar este valor para producción a 0.2. O usar el tracesSampler
    // para un control más profundo.
    tracesSampleRate: 1.0,
  });
}

const pReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(pReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);

const Index = () => {

  return (
    <GoogleOAuthProvider clientId="351687487628-chs76jl0qe4ukqre72vn70s5rdf77pj3.apps.googleusercontent.com">
      <Provider store={store}>
        <PersistGate loading={<p>Cargando</p>} persistor={persistor}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <>
                <App />
                <GlobalStyles />
              </>
            </ThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<Index />);

serviceWorker.unregister();

