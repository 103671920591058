import { useDispatch, useSelector } from "react-redux";
import { openSuscriptionModal } from "../store/actions";

export default function useGuestAccountCheck() {
    const dispatch = useDispatch();
    const isGuestAccount = useSelector((state) => state.general.userData.username === "invited@edify.la")

    const guestAccountCheck = (onClickHandler) => {
        if (isGuestAccount) {
          return () => dispatch(openSuscriptionModal())
        } else {
          return onClickHandler  
        }
    }
    return guestAccountCheck
}
