import React, { useState, useEffect } from "react";
import styles from "./CotizacionDeMateriales.module.scss";
import styled from "styled-components";
import Botonera from "../../components/Botonera/Botonera";
import { motion } from "framer-motion";
import {
  Store,
  Building2,
  Download,
  Mail,
  Award,
  History,
  CirclePercent,
  Shield
} from "lucide-react";
import NewNav from "../../components/Nav/NewNav";
import { useNavigate } from "react-router-dom";
import QuotationList from "../../components/MarketPlace/Cotizaciones/QuotationList";
import { fireAnalytics } from "../../utils/firebase";
import { connect } from "react-redux";
import { getFeaturedUsersForCarousel, getAllMaterialsCatalogFile } from "../../store/actions";
import FeaturesSection from "./FeaturesSection";
import usePremiumCheck from "../../hooks/usePremiumCheck";
import useSectionTracker from "../../hooks/useSectionTracker";

const variants = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0 },
};


const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  padding-right: 5%;
  padding-left: 5%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
  `;

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;

  `
  ;

const CotizacionDeMateriales = ({
  userData,
  totalPrivateQuotations,
  getFeaturedUsersForCarousel,
  usersForMaterialQuotationCarousel,
  getAllMaterialsCatalogFile }) => {
  const navigate = useNavigate();
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [showList, setShowList] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Publicas");
  const [isPaused, setIsPaused] = useState(false);
  const { isVisible: isHeaderVisible, dismiss: dismissHeader } = useSectionTracker('mercadoDeTrabajoHeader', {
    isDismissable: true,
    trackVisits: false,
    defaultVisibility: true
  });

  const cotizacionesTracker = useSectionTracker('cotizaciones', {
    trackVisits: true,
    expirationDays: 30
  });


  const premiumCheck = usePremiumCheck();

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getFeaturedUsersForCarousel();
  }, []);


  const handleQuotationListProjectType = (projectType) => {
    if (projectType === "Publicas") {
      fireAnalytics("cotizacion_de_materiales_publicas", { category: "cotizacion_de_materiales_publicas", public_quotation_user_info: userData.id });
    } else {
      fireAnalytics("cotizacion_de_materiales_privadas", { category: "cotizacion_de_materiales_privadas", private_quotation_user_info: userData.id });
      cotizacionesTracker.updateLastVisit();
    }
    setSelectedOption(projectType);
    setShowList(true);
  }

  const handleNavigateToEstimacionCostos = () => {
    fireAnalytics("iniciar_estimacion_costos", { category: "cotizacion_de_materiales_privadas", estimate_cost_user_info: userData.id });
    navigate("/cotizaciondemateriales/estimacion-costos");
  }

  const handleNavigateToQuotation = () => {
    fireAnalytics("iniciar_cotizacion", { category: "cotizacion_de_materiales_privadas", start_quotation_user_info: userData.id });
    navigate("/cotizaciondemateriales/crear");
  }

  const handleDownloadCatalog = () => {
    getAllMaterialsCatalogFile();
  }

  const newQuotationsCount = cotizacionesTracker.countNewItems(totalPrivateQuotations, 'createdAt');


  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      <Wrapper>
        {showList ?
          <QuotationList setSelectedOption={setSelectedOption} selectedOption={selectedOption} />
          : (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5 }}
              className={styles.generalContainer}>
              <motion.div
                className={styles.headerWrapper}
                initial={false}
                animate={{
                  opacity: isHeaderVisible ? 1 : 0,
                  height: isHeaderVisible ? "auto" : 0,
                }}
                transition={{
                  type: "spring",
                  damping: 20,
                  stiffness: 100
                }}>
                <div className={styles.header}>
                  <div className={styles.header_content}>
                    <h1 className={styles.header_title}>Cotización de materiales</h1>
                    <p className={styles.header_subTitle}>
                      Gestiona tus cotizaciones y accede a nuestra base de datos de materiales de construcción actualizada
                    </p>
                  </div>
                  <button
                    onClick={dismissHeader}
                    className={styles.header_closeButton}
                    aria-label="Cerrar header"
                  >
                    &times;
                  </button>
                </div>
              </motion.div>
              <div className={styles.body}>
                <div className={styles.body_container}>
                  <div className={styles.body_container_section}>
                    <h2 className={styles.body_container_section_title}>Sistema de cotización</h2>
                    <FeaturesSection />
                  </div>
                  <div className={styles.body_container_section}>
                    <h2 className={styles.body_container_section_title}>Comienza ahora</h2>
                    <div className={styles.body_container_section_buttonContainer}>
                      <button className={styles.body_container_section_buttonPrimary} onClick={premiumCheck(handleNavigateToEstimacionCostos)}>
                        <History size={24} style={{ minWidth: "2.4rem" }} />
                        Obtener estimación rápida
                      </button>
                      <button className={styles.body_container_section_buttonSecondary} onClick={premiumCheck(handleNavigateToQuotation)}>
                        <CirclePercent size={24} style={{ minWidth: "2.4rem" }} />
                        Cotizar con proveedores
                      </button>
                      <button className={styles.body_container_section_button} onClick={() => handleQuotationListProjectType("Privadas")}>
                        <Store size={20} style={{ minWidth: "2rem" }} />
                        Ver pedidos de cotización de usuarios
                        {newQuotationsCount > 0 && <span className={styles.body_container_section_buttonBadge}>{newQuotationsCount}</span>}
                      </button>
                      <button className={styles.body_container_section_button} onClick={() => handleQuotationListProjectType("Publicas")}>
                        <Building2 size={20} style={{ minWidth: "2rem" }} />
                        Ver pedidos de entidades públicas
                      </button>
                      <button className={styles.body_container_section_button} onClick={premiumCheck(handleDownloadCatalog)}>
                        <Download size={20} style={{ minWidth: "2rem" }} />
                        Descargar catálogo de materiales
                      </button>
                    </div>
                    <div className={styles.body_container_section_supplier}>
                      <h3 className={styles.body_container_section_supplier_title}>¿Eres proveedor?</h3>
                      <p className={styles.body_container_section_supplier_description}>Forma parte de nuestra base de datos enviándonos tu lista de precios actualizada.
                      </p>
                      <a href="mailto:contacto@edify.la" className={styles.body_container_section_supplier_buttonContainer}>
                        <Mail size={20} style={{ minWidth: "2rem" }} />
                        <span className={styles.body_container_section_supplier_button}>contacto@edify.la</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <div className={styles.footer_container}>
                  <div className={styles.footer_container_header}>
                    <div className={styles.footer_container_header_title}>
                      <h2 className={styles.footer_container_header_title_text}>
                        <Shield size={24} style={{ color: "rgb(79 70 229 )", minWidth: "2.4rem", marginTop: "0.2rem" }} />
                        <Award size={24} style={{ color: "rgb(37 99 235)", minWidth: "2.4rem", marginTop: "0.2rem" }} />
                        Proveedores destacados
                      </h2>
                      <p className={styles.footer_container_header_description}>
                        Trabajamos con las mejores empresas del sector para garantizar la calidad y confiabilidad de los materiales
                      </p>
                    </div>
                    <button className={styles.footer_container_header_button} onClick={() => navigate("/profesionales")}>
                      <Store size={20} style={{ minWidth: "2rem" }} />
                      Ver todos los proveedores
                    </button>
                  </div>
                  <div className={styles.footer_container_carousel}>
                    <div className={`${styles.footer_container_carousel_container} ${isPaused ? styles.paused : ''}`}>
                      {[...usersForMaterialQuotationCarousel, ...usersForMaterialQuotationCarousel].map((user, index) => {
                        const profileImage = user.image_profile;
                        const nameToshow = user.type === "PERSON" ? `${user.name} ${user.last_name}` : user.razon_social;
                        const imageError = user.image_profile === "https://edify-files-dev.s3.amazonaws.com/profile-pictures/ebba9aa8-4346-4662-b3e5-8699015659ba-image-profile-default.png";
                        return (
                          <motion.div
                            key={index}
                            className={styles.footer_container_carousel_item}
                            whileHover={{ scale: 1.05 }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => navigate(`/profesionales/${user.id}/${nameToshow}`)}
                          >
                            <div className={styles.footer_container_carousel_item_image}>
                              {!imageError && profileImage ? (
                                <img
                                  src={profileImage}
                                  alt="profile"
                                  className={styles.footer_container_carousel_item_image_img}
                                />
                              ) : (
                                <div className={styles.footer_container_carousel_item_image_default}>
                                  <Store size={48} />
                                </div>
                              )}
                            </div>
                            <div className={styles.footer_container_carousel_item_text}>
                              <p className={styles.footer_container_carousel_item_name}>{nameToshow}</p>
                              <p className={styles.footer_container_carousel_item_email}>{user.username}</p>
                            </div>
                          </motion.div>
                        );
                      }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )
        }
      </Wrapper>
    </Container >
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    usersForMaterialQuotationCarousel: state.general.usersFeaturedData,
    totalPrivateQuotations: state.dashboard.totalPrivateQuotations,
  };
}

export default connect(mapStateToProps, {
  getFeaturedUsersForCarousel,
  getAllMaterialsCatalogFile
})(CotizacionDeMateriales);
