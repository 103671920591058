import React, { useState, useEffect, useRef } from "react";
import styles from "./FilterWorkBag.module.scss";
import { connect } from "react-redux";
import { getSkills, filterWorkBagUsers, getAllWorkBagUsers, openSuscriptionModal, openWorkOfferModal } from "../../../store/actions";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/svg/arrowLeftIcon.svg";
import { motion } from "framer-motion";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/CloudUpload.svg";
import usePremiumCheck from "../../../hooks/usePremiumCheck";
import { MapIcon, List, Search, Briefcase, SlidersHorizontal } from "lucide-react";
import { fireAnalytics } from "../../../utils/firebase";

const FilterWorkBag = ({
    getSkills,
    rubros,
    filterWorkBagUsers,
    getAllWorkBagUsers,
    setIsFiltered,
    setZoom,
    setSelectedUser,
    setLoading,
    isUserPremium,
    openSuscriptionModal,
    isPhone,
    openWorkOfferModal,
    setCenter,
    selectedOptionJurisdictions,
    selectedOptionSkills,
    setSelectedOptionJurisdictions,
    onDownload,
    setActiveView,
    userData,
    setShowWorkBagComponent,
    activeView,
    setFilterMenu,
    workBagUsers,
    setSelectedOptionSkills,
    setShowSmallMap }) => {
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [disabledReset, setDisabledReset] = useState(true);
    const [hasFiltersChanged, setHasFiltersChanged] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    const filterMenuRef = useRef(null);
    const premiumCheck = usePremiumCheck();

    useEffect(() => {
        getSkills();
    }, []);

    const handleClickOutside = (event) => {
        if (filterMenuRef.current && !filterMenuRef.current.contains(event.target)) {
            setShowFilterMenu(false);
        }
    };

    useEffect(() => {
        if (showFilterMenu) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }

        // Limpia el evento al desmontar el componente
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [showFilterMenu]);

    const handleFilterClick = (filterName) => {
        if (selectedFilter === filterName) {
            setSelectedFilter(null);
            setFilterMenu("");
        } else {
            setSelectedFilter(filterName);
            setFilterMenu(filterName);
        }
    };

    const handleOptionSelectJurisdiction = (option) => {
        if (option.id === 0) {
            setSelectedOptionJurisdictions([]);
        } else {
            setSelectedOptionJurisdictions((prev) =>
                prev.includes(option.id) ? prev.filter((id) => id !== option.id) : [...prev, option.id]
            );
        }
        setSelectedFilter(null);
        setHasFiltersChanged(true);
        setShowFilterMenu(false);
    };

    const handleOptionSelectSkill = (option) => {
        setSelectedOptionSkills((prev) =>
            prev.includes(option.id) ? prev.filter((id) => id !== option.id) : [...prev, option.id]
        );
        setSelectedFilter(null);
        setHasFiltersChanged(true);
        setShowFilterMenu(false);
    };

    useEffect(() => {
        if (selectedOptionJurisdictions.length > 0 || selectedOptionSkills.length > 0) {
            setDisabledReset(false);
        } else {
            setDisabledReset(true);
        }
    }, [selectedOptionJurisdictions, selectedOptionSkills]);

    useEffect(() => {
        if (inputValue.length > 0) {
            const regex = new RegExp(inputValue, "i");
            const suggestions = rubros?.filter((rubro) => regex.test(rubro.name));
            setSuggestions(suggestions);
        } else {
            setSuggestions([]);
        }
    }, [inputValue]);

    const handleSuggestonClick = (suggestion) => {
        setSelectedOptionSkills((prev) => [...prev, suggestion.id]);
        setInputValue("");
        setSuggestions([]);
        setHasFiltersChanged(true);
    };



    const handleResteFilters = () => {
        getAllWorkBagUsers();
        setSelectedOptionJurisdictions([]);
        setSelectedOptionSkills([]);
        setSelectedFilter(null);
        setIsFiltered(false);
        setZoom(3);
        setCenter([-38.416097, -63.616672]);
        setSelectedUser(null);
        setHasFiltersChanged(false);
    };

    const handleRemoveJurisdiction = (id) => {
        setSelectedOptionJurisdictions((prev) => prev.filter((jurisdictionId) => jurisdictionId !== id));
        setShowFilterMenu(false);
    };

    const handleRemoveSkill = (id) => {
        setSelectedOptionSkills((prev) => prev.filter((skillId) => skillId !== id));
        setShowFilterMenu(false);
    };

    useEffect(() => {
        setLoading(true);

        if (!isUserPremium && hasFiltersChanged) {
            fireAnalytics("usuario_sin_plan", { no_plan_user_info: userData.id });
            openSuscriptionModal();
        } else {
            const filterParams = {
                jurisdicciones: selectedOptionJurisdictions,
                skills: selectedOptionSkills,
            };

            filterWorkBagUsers(filterParams);

            if (selectedOptionJurisdictions.length >= 1 || selectedOptionSkills.length >= 1) {
                setIsFiltered(true);
            } else {
                setIsFiltered(false);
                setZoom(3);
                setCenter([-38.416097, -63.616672]);
                setSelectedUser(null);
            }
        }

        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [selectedOptionJurisdictions, selectedOptionSkills, isUserPremium]);


    const jurisdictionArray = [
        { id: 0, name: "Todas" },
        { id: 1, name: "Buenos Aires" },
        { id: 2, name: "Catamarca" },
        { id: 3, name: "Chaco" },
        { id: 4, name: "Chubut" },
        { id: 5, name: "Ciudad Autónoma De Buenos Aires" },
        { id: 6, name: "Córdoba" },
        { id: 7, name: "Corrientes" },
        { id: 8, name: "Entre Ríos" },
        { id: 9, name: "Formosa" },
        { id: 10, name: "Jujuy" },
        { id: 11, name: "La Pampa" },
        { id: 12, name: "La Rioja" },
        { id: 13, name: "Mendoza" },
        { id: 14, name: "Misiones" },
        { id: 15, name: "Neuquén" },
        { id: 16, name: "Río Negro" },
        { id: 17, name: "Salta" },
        { id: 18, name: "San Juan" },
        { id: 19, name: "San Luis" },
        { id: 20, name: "Santa Cruz" },
        { id: 21, name: "Santa Fe" },
        { id: 22, name: "Santiago del Estero" },
        { id: 23, name: "Tierra del Fuego, Antártida e Isla del Atlántico Sur" },
        { id: 24, name: "Tucumán" },
    ];

    const handleClickView = (value) => {
        setActiveView(value);

        if (value === "map") {
            setShowSmallMap(false);
        }
    }

    return (
        <section className={styles.container}>
            <header className={styles.container_header} >
                <div className={styles.container_header_title}>
                    Bolsa de trabajo
                    <h1 className={styles.container_header_title_txt}>Encuentra trabajadores o publica ofertas para tu obra</h1>
                </div>
                <div className={styles.container_header_buttonContainer}>
                    <button className={styles.container_header_goBackBtn} onClick={() => setShowWorkBagComponent(false)}>
                        Voler al inicio
                    </button>
                    <div className={styles.mainFilter_createWorkOffer} onClick={premiumCheck(openWorkOfferModal)}>
                        <Briefcase size={16} />
                        <p className={styles.mainFilter_createWorkOffer_title}>Publicar oferta</p>
                    </div>
                </div>
            </header>
            <div className={styles.searchBar}>
                <div className={styles.searchBar_inputContainer}>
                    <Search size={16} className={styles.searchBar_icon} />
                    <input
                        type="text"
                        placeholder="Busca por rubro o palabras claves"
                        className={styles.searchBar_input}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    {suggestions.length > 0 && (
                        <div className={styles.searchBar_suggestions}>
                            {suggestions.map((suggestion) => (
                                <div
                                    key={suggestion.id}
                                    className={styles.searchBar_suggestions_item}
                                    onClick={() => handleSuggestonClick(suggestion)}
                                >
                                    {suggestion.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={styles.mainFilter_openFilters} onClick={(e) => {
                    premiumCheck(() => {
                        e.stopPropagation();
                        setShowFilterMenu(true);
                    })();
                }}>
                    <SlidersHorizontal size={20} />
                    <p className={styles.mainFilter_buttonOpenFilters_title}>Filtros</p>
                    {selectedOptionJurisdictions.length + selectedOptionSkills.length > 0 && (
                        <span className={styles.mainFilter_filterNumber}>
                            {selectedOptionJurisdictions.length + selectedOptionSkills.length}
                        </span>
                    )}
                    {showFilterMenu && (
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            className={styles.mainFilter_filterMenu}
                            ref={filterMenuRef}
                            onClick={(e) => e.stopPropagation()} >
                            <div className={styles.mainFilter_filterMenu_item}>
                                <div className={`${styles.mainFilter_filterMenu_item_title} ${selectedFilter === 'jurisdiction' && styles.isSelected}`} onClick={() => handleFilterClick('jurisdiction')}>
                                    <ArrowLeftIcon width={10} height={10} />
                                    <p>Jurisdicción</p>
                                </div>
                                {selectedFilter === 'jurisdiction' && (
                                    <div className={styles.mainFilter_filterMenu_subMenu} onClick={(e) => e.stopPropagation()}>
                                        {jurisdictionArray.map((jurisdiction) => (
                                            <div className={styles.mainFilter_filterMenu_subMenu_item} key={jurisdiction.id} onClick={() => handleOptionSelectJurisdiction({ id: jurisdiction.id, name: jurisdiction.name })}>
                                                {jurisdiction.name}
                                            </div>
                                        ))}
                                    </div>
                                )}

                            </div>
                            <div className={styles.mainFilter_filterMenu_item}>
                                <div className={`${styles.mainFilter_filterMenu_item_title} ${selectedFilter === 'rubro' && styles.isSelected}`} onClick={() => handleFilterClick('rubro')}>
                                    <ArrowLeftIcon width={10} height={10} />
                                    <p>Rubro</p>
                                </div>

                                {selectedFilter === 'rubro' && (
                                    <div className={styles.mainFilter_filterMenu_subMenu}>
                                        {rubros.map((rubro) => (
                                            <div className={styles.mainFilter_filterMenu_subMenu_item} key={rubro.id} onClick={() => handleOptionSelectSkill({ id: rubro.id, name: rubro.name })}>
                                                {rubro.name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {selectedOptionJurisdictions.length + selectedOptionSkills.length > 0 && (
                                <div className={styles.selectedFilters}>
                                    <p className={styles.selectedFilters_title}>Filtros seleccionados</p>
                                    <div className={styles.selectedFilters_boxes}>
                                        {selectedOptionJurisdictions.map((id) => (
                                            <div key={id} className={styles.selectedFilters_item}>
                                                <span>{jurisdictionArray.find((j) => j.id === id).name}</span>
                                                <button className={styles.selectedFilters_item_x} onClick={() => handleRemoveJurisdiction(id)}>x</button>
                                            </div>
                                        ))}
                                        {selectedOptionSkills.map((id) => (
                                            <div key={id} className={styles.selectedFilters_item}>
                                                <span>{rubros.find((r) => r.id === id).name}</span>
                                                <button className={styles.selectedFilters_item_x} onClick={() => handleRemoveSkill(id)}>x</button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </motion.div>
                    )}
                </div>
            </div>
            <div className={styles.mainFilter}>
                <div className={styles.mainFilter_searchResults}>
                    <p className={styles.mainFilter_searchResults_title}>
                        {`Mostrando ${workBagUsers.length === 1 ? "1 trabajador" : `${workBagUsers.length} trabajadores`}`}
                    </p>
                </div>
                {!isPhone && (
                    <div className={styles.mainFilter_buttonsContainer}>
                        {activeView === "list" && (
                            <div className={styles.downloadButtons} title="Descargar en formato .csv" onClick={premiumCheck(onDownload, 'csv')}>
                                <DownloadIcon width={20} height={20} />
                                <button className={styles.downloadButtons_button}>Descargar lista</button>
                            </div>
                        )}
                        <div className={styles.mainFilter_views} >
                            <button className={`${styles.mainFilter_views_button} ${activeView === "map" && styles.isActive}`} onClick={() => handleClickView("map")}>
                                <MapIcon size={16} />
                                Mapa
                            </button>
                            <button className={`${styles.mainFilter_views_button} ${activeView === "list" && styles.isActive}`} onClick={() => handleClickView("list")}>
                                <List size={16} />
                                Lista
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        workBagUsers: state.register.workBagUsers,
        rubros: state.register.skills,
        isUserPremium: state.general.isUserPremium,
        userData: state.general.userData,
    };
};

export default connect(mapStateToProps, { getSkills, filterWorkBagUsers, getAllWorkBagUsers, openSuscriptionModal, openWorkOfferModal })(FilterWorkBag);

