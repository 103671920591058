import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Input from "../UI/Forms/Input/Input";
import Button from "../UI/Forms/Button/Button";
import FileUpload from "../FileUpload/FileUpload";
import { connect } from "react-redux";
import {
  setEmpresa, openMainModal, getSkills,
  sendSkills,
  addSkill,
  removeSkill,
  clearUserSkills,
  closeMainModal,
} from "../../store/actions/index";
import styles from "./FormularioEmpresa.module.scss";
import { fireAnalytics } from "../../utils/firebase.js";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";
import { useNavigate } from "react-router-dom";
import WorkZoneSelector from "../FormularioPersona/WorkZoneSelector";
import UserSkillsUpdater from "../FormularioPersona/UserSkillsUpdater.js";
import { ReactComponent as ArgentinaMap } from "../../assets/svg/argentinaMap.svg";
import { Search, Check, HardHat, Info, ArrowLeft } from "lucide-react";
import useIsPhone from "../../hooks/useIsPhone.js";

const ButtonContainer = styled.div`
display: flex;
gap: 10px;
padding-top: 20px;

`;

const FormularioEmpresa = ({
  openMainModal,
  closeMainModal,
  file,
  getSkills,
  sendSkills,
  addSkill,
  removeSkill,
  clearUserSkills,
  skills,
  userSkills,
}) => {
  const [showSkillsSuggestions, setShowSkillsSuggestions] = useState(false);
  const [showSkillsList, setShowSkillsList] = useState(false);
  const [inputValue, setInputValue] = useState("")
  const [matchedSkills, setMatchedSkills] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const isPhone = useIsPhone();

  const navigate = useNavigate();

  useEffect(() => {
    fireAnalytics("ob_step_2_tipo_empresa", { category: "navigation" });
    getSkills();
    clearUserSkills();
  }, []);

  const webSiteRegex = /(^[a-zA-Z_-]{3})([.]{1})([a-zA-Z0-9_.+-]+)([.]{1})([a-zA-Z_-]+)/;
  const telRegex = /[0-9+]/;

  const initialValues = {
    companyName: "",
    tel: "",
    address: "",
    webSite: "",
    skills: [],
    allCountry: null,
    jobBoardZones: [],
  };

  const companySchema = Yup.object().shape({
    companyName: Yup.string().required("El campo es requerido."),
    tel: Yup.string()
      .matches(telRegex, "Introduzca un formato válido")
      .min(7, "Ingrese un telefono válido")
      .max(20, "Ingrese un telefono válido"),
    webSite: Yup.string().matches(webSiteRegex, "Introduzca un formato válido"),
    userSkills: Yup.array().min(1, "Seleccioná al menos 1 rubro").nullable(),
    allCountry: Yup.boolean().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: companySchema,
    onSubmit: (values) => {
      sendSkills(values, file, false, userSkills, navigate);
    },
  });

  useEffect(() => {
    setMatchedSkills(skills);
  }, [skills]);

  const handleInputSearch = (e) => {
    const value = e.target.value;
    const filteredSkills = skills.filter((skill) => {
      return skill.name.toLowerCase().includes(value.toLowerCase());
    });
    setMatchedSkills(filteredSkills);
  };


  const handleAddSkill = (id) => {
    if (!userSkills.find((skill) => skill.id === id)) {
      addSkill(id);
    } else {
      removeSkill(id);
    }
    setShowSkillsSuggestions(false);
    setInputValue("");

  };

  const defaultCompanyValues = {
    companyName: "Empresa sin identificar",
    allCountry: true,
  }

  const handleSpecialKey = (event) => {
    const filter = /^[a-zA-Z ]*$/;
    if (!filter.test(event.key)) {
      event.preventDefault();
    }
  }

  const handleSkipButton = () => {
    const defaultSkillName = "Otros";
    const defaultSkill = matchedSkills.find(skill => skill.name === defaultSkillName);
    const defaultSkills = defaultSkill ? { id: defaultSkill.id, name: defaultSkill.name } : null;

    openMainModal({
      title: "¿Estás seguro?",
      body: "Si omites este paso, no podrás acceder a todas las funcionalidades de Edify.",
      yesLabel: "Omitir",
      noLabel: "Cancelar",
      iconType: "warning",
      confirmHandler: () => {
        addSkill(defaultSkills.id);
        sendSkills(defaultCompanyValues, file, false, [defaultSkills], navigate);
        closeMainModal()
      }
    })
  }

  useEffect(() => {
    if (formik.values.allCountry || formik.values.allCountry === null) {
      formik.setFieldValue("jobBoardZones", []);
    }
  }, [formik.values.allCountry]);

  useEffect(() => {
    if (formik.isValid &&
      userSkills.length > 0 &&
      (formik.values.jobBoardZones.length > 0 || formik.values.allCountry)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formik.isValid, userSkills, formik.values.jobBoardZones, formik.values.allCountry]);

  const handleToggleCheckbox = () => {
    const newValue = !formik.values.allCountry;
    formik.setFieldValue("allCountry", newValue);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={styles.formContainer}>
        <UserSkillsUpdater userSkills={userSkills} setFieldValue={formik.setFieldValue} />
        <FileUpload file={file} userType="company" />
        <div className={styles.formGroup_container}>
          <div className={styles.formGroup_container_form}>
            <h4 className={styles.formGroup_container_title}>Datos de la empresa</h4>
            <div className={styles.formGroup_inputs}>
              <div className={styles.inputRow}>
                <div className={styles.formGroup}>
                  <div className={styles.formGroup_title}>
                    Nombre*
                  </div>
                  <Input
                    placeholder="Nombre de la empresa*"
                    type="text"
                    handleKey={handleSpecialKey}
                    name="companyName"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${formik.errors.companyName ? "error" : "isValid"}`}
                    style={{ textAlign: "left" }}
                  />
                </div>
                </div>
                <div className={styles.inputRow}>
                  <div className={styles.formGroup}>
                    <div className={styles.formGroup_title}>
                      Página Web
                    </div>
                    <Input
                      placeholder="https://www.ejemplo.com"
                      type="text"
                      name="webSite"
                      value={formik.values.webSite}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`${formik.errors.webSite ? "error" : "isValid"}`}
                      style={{ textAlign: "left" }}
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <div className={styles.formGroup_title}>
                      Teléfono
                    </div>
                    <Input
                      placeholder="(+54) 1234-5678"
                      type="number"
                      name="tel"
                      value={formik.values.tel}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`${formik.errors.tel ? "error" : "isValid"}`}
                      style={{ textAlign: "left" }}
                    />
                    {formik.errors.tel && <div className={styles.formGroup_errorMsg}>{formik.errors.tel}</div>}
                  </div>
                </div>
                <div className={styles.formGroup_lastOne}>
                  <div className={styles.formGroup_title}>
                    Rubros*
                  </div>
                  <div className={styles.skillsContainer}>
                    <button
                      type="button"
                      onClick={() => setShowSkillsList(!showSkillsList)}
                      className={styles.skillsContainer_inputContainer}
                    >
                      <span className={styles.skillsContainer_selected}>
                        {userSkills?.length > 0 ? (
                          `${userSkills.length} rubro${userSkills.length > 1 ? "s" : ""} seleccionado${userSkills.length > 1 ? "s" : ""}`
                        ) : (
                          <span className={styles.skillsContainer_selected_emptyState}>Seleccioná al menos un rubro</span>
                        )}
                      </span>
                      <ArrowDownIcon
                        width={15}
                        title="Ver lista completa de rubros"
                        height={15}
                        className={`${styles.arrow} ${showSkillsList ? styles.arrowIconUp : styles.arrowIconDown}`}
                      />
                      {showSkillsList && (
                        <div className={styles.skillsContainer_listContainer}>
                          <div className={styles.skillsContainer_searchInputContainer}>
                            <Search size={16} className={styles.skillsContainer_searchIcon} />
                            <input className={styles.skillsContainer_searchInput}
                              type="text" placeholder="Buscar rubros . . ."
                              onClick={(e) =>
                                e.stopPropagation()
                              }
                              onChange={(e) => {
                                handleInputSearch(e)
                              }}
                            />
                          </div>
                          <div className={styles.skillsContainer_list}>
                            {matchedSkills?.length > 0 ? (
                              matchedSkills.map((skill) => {
                                return (
                                  <div
                                    className={`${styles.skillsContainer_list_item} ${userSkills?.find(userSkill => userSkill.id === skill.id) && styles.skillsContainer_list_item_selected}`}
                                    key={skill.id}
                                    onClick={(e) => {
                                      handleAddSkill(skill.id);
                                      e.stopPropagation();
                                    }}
                                  >
                                    {skill.name}
                                    {userSkills?.find(userSkill => userSkill.id === skill.id) && (
                                      <span className={styles.skillsContainer_list_item_check}>
                                        <Check size={16} />
                                      </span>
                                    )}
                                  </div>
                                );
                              })
                            ) : (
                              <div className={styles.skillsContainer_list_item}>No existe ese rubro</div>
                            )}
                          </div>
                        </div>
                      )}
                    </button>
                    {userSkills?.length > 0 ? (
                      <div className={styles.skillsContainer_selected}>
                        {userSkills?.map((skill) => {
                          return (
                            <div
                              className={styles.skillsContainer_skillSelected}
                              key={skill.id}
                              onClick={() => removeSkill(skill.id)}
                            >
                              <HardHat size={14} />
                              {skill.name}&nbsp;  X
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className={styles.emptyState}>
                        <Info size={16} />
                        <span>Seleccioná al menos un rubro</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.workZoneSelector}>
              <div className={styles.workZoneSelector_title}>
                <h4 className={styles.skillsContainer_title}>Lugares donde trabajas</h4>
                <div
                  className={`${styles.checkboxLabel} ${formik.values.allCountry && styles.selected} ${!formik.values.allCountry && styles.notSelected}`}
                  onClick={handleToggleCheckbox}
                >
                  <ArgentinaMap className={styles.map} width={25} height={20} />
                  <input
                    name="allCountry"
                    type="checkbox"
                    className={styles.checkBox}
                    onChange={() => { }}
                    checked={formik.values.allCountry === true}
                    style={{ display: 'none' }}
                  />
                  Vendo productos y/o servicios en todo el país
                </div>
              </div>
              <WorkZoneSelector formik={formik} values={formik.values} setFieldValue={formik.setFieldValue} />
            </div>
          </div>
          <ButtonContainer >
            <Button
              colored="white"
              typebtn="link"
              to="/select-type"
              direction="right"
              style={{ width: "fit-content", gap: "10px", padding: "0.8rem 2.4rem" }}
            >
              <ArrowLeft size={16} />
              Volver
            </Button>

            <button
              className={styles.btn_skip}
              type="button"
              onClick={() => handleSkipButton()}
            >
              {isPhone ? "Omitir" : "Omitir paso"}
            </button>
            <button
              className={styles.btn}
              disabled={!isFormValid}
              type="submit"
              onClick={() => {
                fireAnalytics("ob_step_2_empresa_success", {
                  category: "onboarding",
                });
              }}
            >
              Siguiente
            </button>

          </ButtonContainer>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    companyName: state.register.empresaForm.companyName,
    tel: state.register.empresaForm.tel,
    file: state.register.file,
    skills: state.register.skills,
    userSkills: state.register.userSkills,
  };
};

export default connect(mapStateToProps, {
  setEmpresa, openMainModal, getSkills,
  sendSkills,
  addSkill,
  removeSkill,
  clearUserSkills,
  closeMainModal
})(FormularioEmpresa);
