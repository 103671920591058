import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import styles from './DownloadPdfButton.module.scss'
import { Download } from 'lucide-react';
import { connect } from 'react-redux';
import { closeMainModal, openMainModal } from '../../../store/actions';
import usePremiumCheck from '../../../hooks/usePremiumCheck';
import { fireAnalytics } from '../../../utils/firebase';

const DownloadPDFButton = ({ materials, setShowSendQuotePopup, openMainModal, closeMainModal, previewPopUp, closePreview, userData }) => {

  const premiumCheck = usePremiumCheck();

  const formatCurrency = (value) => {
    return `$${value?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  };

  const handleDownloadModal = () => {
    openMainModal({
      title: "Descargar estimación de costos",
      body: "¿Sabías que además de descargar el PDF, puedes enviar la cotización a los proveedores para que te envíen sus ofertas?",
      iconType: "warning",
      dismiss: true,
      yesLabel: "Enviar para cotizar",
      noLabel: "Descargar PDF",
      confirmHandler: () => {
        closeMainModal();
        setShowSendQuotePopup(true)
      },
      customCloseMainModal: () => {
        if (previewPopUp) {
          closePreview();
        }
        closeMainModal();
        handleDownload();
      }
    });
  };


  
  
const handleDownload = () => {
  fireAnalytics("download_estimacion_de_costos", { user_info_estimacion: userData.id });

  const doc = new jsPDF();

  const startY = 15; 

  doc.setFontSize(14);
  doc.text("Estimación de costos", 14, startY);

  doc.setTextColor(169, 169, 169);  
  doc.setFontSize(10);
  doc.text("Los precios son orientativos y no representan una oferta formal por parte de Edify.", 14, startY + 8);

  const tableStartY = startY + 18;  

  const tableBody = materials?.map(material => [
    material.name,
    material.category,
    material.unit,
    material.quantity,
    formatCurrency(material.price),
  ]);
  
  const total = materials.reduce((sum, material) => sum + material.price * material.quantity, 0);
  tableBody.push(['', '', 'Total', '', formatCurrency(total)]);

  doc.autoTable({
    head: [['Material', 'Categoría', 'Unidad', 'Cantidad', 'Precio']],
    body: tableBody,
    startY: tableStartY,
    columnStyles: {
      4: { halign: 'right' }, 
    },
  });

  doc.save('materiales_cotizacion.pdf');
};

  return (
    <button className={`${styles['download-btn']} ${previewPopUp && styles['preview-btn']}`} onClick={premiumCheck(handleDownloadModal)} disabled={materials.length === 0}>
      <Download style={{minHeight: "1.6rem", maxHeight: "1.6rem", minWidth: "1.6rem", maxWidth: "1.6rem"}} />
      <span>Descargar estimación</span>
    </button>
  );
};

export default connect(null,{openMainModal, closeMainModal})(DownloadPDFButton);
