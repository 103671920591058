import React, { useEffect, useState } from "react";
import styles from "./Resumen.module.scss";
import { loadUserData, setSuccess, setLoginAnimation } from "../../../store/actions";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import ResumenSkeleton from "./ResumenSkeleton";
import { ReactComponent as CheckIcon } from "../../../assets/svg/check-white.svg";
import RegisterSteps from "../RegisterStepsPage";
import { CircleCheck, Globe, Phone, HardHat, MapPin, ArrowLeft } from "lucide-react";
import useUserImage from "../../../hooks/useUserImage";
import Confetti from "react-confetti";

const childCompressionVariants = {
  visible: {
    opacity: 1,
    scale: 1,
    x: 0,
    y: 0,
    borderRadius: "0.75rem",
    transition: { duration: 0.5 }
  },
  hidden: {
    opacity: 0,
    scale: 0.01,
    x: 0,
    y: 0,
    borderRadius: "50%",
    transition: { duration: 0.8, ease: "easeInOut" }
  },
};

const variants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 50 },
};


const Resumen = ({
  setLoginAnimation,
  loginAnimation,
  userData,
  userSkills,
}) => {

  const [loading, setLoading] = useState(true);
  const { imageError, initials, profileImage } = useUserImage(userData);
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {

    if (!loading) {
      const timer = setTimeout(() => setShowConfetti(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  const navigate = useNavigate();

  const handleFinish = () => {
    setLoginAnimation(true);
  };

  useEffect(() => {
    if (loginAnimation) {
      setTimeout(() => {
        setLoginAnimation(false);
        navigate("/dashboard");
      }, 1000);
    }
  }, [loginAnimation, navigate]);

  let {
    last_name,
    name,
    phone,
    type,
    url_site_web,
    razon_social,
    jobBoardZones,
  } = userData;

  return (
    <div className={`${styles.resumenContainer} ${loginAnimation && styles.loginAnimation}`}>
      {loading ? (
        <ResumenSkeleton />
      ) : (
        <motion.div
          className={styles.resumenContainer_resumen}
          initial="visible"
          animate={loginAnimation ? "hidden" : "visible"}
          variants={childCompressionVariants}
          style={{ overflow: "hidden" }}>
          {showConfetti &&
            <div className={styles.confettiContainer}>
              <Confetti />
            </div>}
          <RegisterSteps stepCount={3} />
          <div className={styles.iconWrapper}>
            <CircleCheck size={32} />
          </div>
          <h1 className={styles.title}>
            ¡Registro completo! <br />
          </h1>
          <motion.div
            className={styles.sectionContainer}
            variants={variants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
          >
            <p className={styles.formSubtitle}>
              Por favor, revisa tus datos para finalizar
            </p>

            <div className={styles.infoContainer}>
              <div className={styles.dataContainer}>
                <div className={styles.dataContainer_imageWrapper}>
                  {!imageError && profileImage ? (
                    <img
                      src={profileImage}
                      alt="profile"
                      className={styles.dataContainer_imageWrapper_image}
                    />
                  ) : (
                    <div className={styles.dataContainer_imageWrapper_initials}>
                      <span>{initials}</span>
                    </div>
                  )}
                </div>
                <div className={styles.dataInfo}>
                  {type === "PERSON" ? (
                    <>
                      <div className={styles.dataTitle} title={name}>
                        {name} {last_name}
                      </div>
                    </>
                  ) : (
                    <div className={styles.dataTitle} title={razon_social}>
                      {razon_social !== "" ? razon_social : "Razon Social"}
                    </div>
                  )}
                  <div className={styles.dataInfo_contactData}>
                    <div className={styles.dataInfo_contactData_item}>
                      <Globe size={16} />
                      {url_site_web !== null ? url_site_web : "https://www.sitioweb.com"}
                    </div>
                    <div className={styles.dataInfo_contactData_item}>
                      <Phone size={16} />
                      {phone !== "" ? phone : "(+54) 11 1234-5678"}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.dataContainerBig}>
                <h4 className={styles.sectionTitle}>Rubros</h4>
                <div className={styles.dataContainerBig_items}>
                  {userSkills?.length > 0 &&
                    userSkills.map((skill, index) => {
                      return (
                        <div key={index} className={styles.dataTitle_item}>
                          <HardHat size={16} />
                          {skill.name}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className={styles.dataContainerBig}>
                <h4 className={styles.sectionTitle}>Lugares de trabajo</h4>
                <div className={styles.dataContainerBig_itemsLugares}>
                  {jobBoardZones && jobBoardZones.length > 0 ? (
                    jobBoardZones.some(zone => zone.allCountry) ? (
                      <div className={styles.dataTitle_item} style={{ padding: "1.2rem" }}>
                        <MapPin size={16} />
                        Todo el país
                      </div>
                    ) : (
                      jobBoardZones.map((zone, index) => (
                        <div key={index} className={styles.dataTitle_item} style={{ padding: "1.2rem" }}>
                          <MapPin size={16} />
                          {zone?.city?.split(",")[0]}
                        </div>
                      ))
                    )
                  ) : null}
                </div>
              </div>

            </div>
            <div className={styles.buttonsContainer}>
              <div
                className={styles.btnBack}
                onClick={() => navigate(-1)}
              >
                <ArrowLeft size={16} />
                Volver
              </div>
              <div className={styles.btn} onClick={handleFinish}>
                Finalizar
                <CheckIcon width={16} height={16} />
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    userSkills: state.register.userSkills,
    file: state.register.file,
    loginAnimation: state.general.loginAnimation,
  };
};

export default connect(mapStateToProps, {
  loadUserData,
  setSuccess,
  setLoginAnimation,
})(Resumen);


