import * as actionType from "../actions/types";

const INITIAL_STATE = {
  allProjects: [],
  viewProjects: [],
  viewStatus: 0,
  project: [],
  posteos: [],
  ownPosteos: [],
  recentPost: [],
  totalUsers: [],
  lastNewUsers: [],
  totalPublicProjects: [],
  lastPublicProject: [],
  totalPrivateProjects: [],
  totalPublicQuotations: [],
  totalAdjudicatedProjects: [],
  lastAdjudicatedProject: [],
  lastPublicQuotation: [],
  totalNewPublicQuotations: [],
  totalPrivateQuotations: [],
  totalNewPrivateQuotations: [],
  postulationProject: [],
  postulationProjectId: null,
  previousLocation: "",
  solicitudesList: [],
  budgetRequest: null,
  budgetlist: [],
  solicitudeId: null,
  tutorialModal: false,
  newNotifications: 0,
  userProjectInfo: [],
};

const setViewProjects = (state, payload) => {
  const allProjects = payload.data;
  const itemCount = payload.itemCount;
  return {
    ...state,
    allProjects: allProjects,
    viewProjects: allProjects,
    page: payload.page,
    itemCount,
    take: payload.take,
    loading: false,
    isFilter: payload.isFilter,
    searchParams: payload.searchParams,
    isLoading: payload.isLoading,
  };
};


//Al actualizar un comentario, se actualiza la lista de comentarios
const updateCommentFromList = (state, postId, newComments) => {
  const updatedPosteos = state.posteos.map((posteo) => {
    if (posteo.id === postId) {
      return {
        ...posteo,
        comments: newComments,
      };
    }
    return posteo;
  });

  const updatedOwnPosteos = state.ownPosteos.map((posteo) => {
    if (posteo.id === postId) {
      return {
        ...posteo,
        comments: newComments,
      };
    }
    return posteo;
  });

  return {
    ...state,
    posteos: updatedPosteos,
    ownPosteos: updatedOwnPosteos,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.GET_DASHBOARD_PROJECTS:
      return setViewProjects({ ...state }, action.payload);
    case actionType.SET_POSTULATION_PROJECT:
      return {
        ...state,
        postulationProject: action.payload,
      };
    case actionType.CLOSE_TUTORIAL_MODAL:
      return {
        ...state,
        tutorialModal: false,
      };
    case actionType.OPEN_TUTORIAL_MODAL:
      return {
        ...state,
        tutorialModal: true,
      };
    case actionType.SET_POSTULATION_PROJECT_ID:
      return {
        ...state,
        postulationProjectId: action.payload,
      };
    case actionType.SET_POSTULATION_PROJECT_ID:
      return {
        ...state,
        project: action.payload,
      };
    case actionType.GET_POSTEOS:
      return {
        ...state,
        posteos: action.payload,
      };
    case actionType.GET_OWN_POSTEOS:
      return {
        ...state,
        ownPosteos: action.payload,
      };
    case actionType.RECENT_POST:
      return {
        ...state,
        recentPost: action.payload,
      };
    case actionType.GET_POSTEO_WITH_COMMENTS:
      const { post, comments } = action.payload;
      return {
        ...state,
        posteos: state.posteos.map(p =>
          p.id === post.id
            ? {
              ...post,
              comments: Array.isArray(comments) ? comments : []
            }
            : p
        ),
        ownPosteos: state.ownPosteos.map(p =>
          p.id === post.id
            ? {
              ...post,
              comments: Array.isArray(comments) ? comments : []
            }
            : p
        )
      };
    case actionType.GET_POSTEO_COMMENTS:
      return updateCommentFromList(
        state,
        action.payload.postId,
        action.payload.comments
      );
    case actionType.GET_TOTAL_USERS:
      return {
        ...state,
        totalUsers: action.payload,
      };
    case actionType.GET_LAST_NEW_USERS:
      return {
        ...state,
        lastNewUsers: action.payload,
      };
    case actionType.GET_TOTAL_PUBLIC_PROJECTS:
      return {
        ...state,
        totalPublicProjects: action.payload.totalPublicProjects,
        lastPublicProject: action.payload.lastPublicProject,
      };
    case actionType.GET_TOTAL_PRIVATE_PROJECTS:
      return {
        ...state,
        totalPrivateProjects: action.payload,
      };
    case actionType.GET_TOTAL_ADJUDICATED_PROJECTS:
      return {
        ...state,
        totalAdjudicatedProjects: action.payload,
      };
    case actionType.GET_LAST_ADJUDICATED_PROJECT:
      return {
        ...state,
        lastAdjudicatedProject: action.payload,
      };
    case actionType.GET_TOTAL_PUBLIC_QUOTATIONS:
      return {
        ...state,
        totalPublicQuotations: action.payload.totalPublicQuotations,
        lastPublicQuotation: action.payload.lastPublicQuotation,
      };
    case actionType.GET_TOTAL_NEW_PUBLIC_QUOTATIONS:
      return {
        ...state,
        totalNewPublicQuotations: action.payload,
      };
    case actionType.GET_TOTAL_PRIVATE_QUOTATIONS:
      return {
        ...state,
        totalPrivateQuotations: action.payload,
      };
    case actionType.GET_TOTAL_NEW_PRIVATE_QUOTATIONS:
      return {
        ...state,
        totalNewPrivateQuotations: action.payload,
      };
    case actionType.CLEAR_POSTULATION_PROJECT:
      return {
        ...state,
        postulationProject: [],
      };
    case actionType.GET_SOLICITUDE_LIST:
      return {
        ...state,
        solicitudesList: action.payload,
        newNotifications: action.payload.filter(
          (item) => item.view !== true
        ).length,
      };
    case actionType.SET_BUDGET_ID:
      return {
        ...state,
        budgetRequest: action.payload,
      };
    case actionType.GET_BUDGETS_LIST:
      return {
        ...state,
        budgetList: action.payload,
      };
    case actionType.CLEAR_PROJECTS:
      return {
        ...state,
        allProjects: [],
        viewProjects: [],
      };
    case actionType.SET_PREVIOUS_LOCATION:
      return {
        ...state,
        previousLocation: action.payload,
      };
    case actionType.GET_DASHBOARD_PROJECTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
