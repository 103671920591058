import React from "react";
import styles from "./Steps.module.scss";
import { FileText, UserCheck, Lock, DollarSign } from "lucide-react";

const Steps = () => {

    const steps = [
        {
            title: "1. Publica tu Proyecto",
            icon: <FileText size={32} />,
            description: "Describe los detalles de tu obra y adjunta la documentación necesaria."
        },
        {
            title: "2. Recibe Solicitudes",
            icon: <UserCheck size={32} />,
            description: "Los contratistas interesados solicitarán acceso a tu documentación."
        },
        {
            title: "3. Autoriza Accesos",
            icon: <Lock size={32} />,
            description: "Revisa perfiles y decide a quién dar acceso a los detalles."
        },
        {
            title: "4. Compara Ofertas",
            icon: <DollarSign size={32} />,
            description: "Recibe y evalúa múltiples presupuestos en la plataforma."
        }

    ]

    return (
        <div className={styles.stepsContainer}>
            <h2 className={styles.stepsTitle}>¿Cómo Funciona?</h2>
            <div className={styles.steps}>
                {steps.map((step, index) => (
                    <div key={index} className={styles.stepRelative}>
                        <div className={styles.stepLine} />
                        <div className={styles.step} >
                            <div className={styles.stepIconContainer}>
                                <div className={styles.stepIcon}>
                                    {step.icon}
                                </div>
                            </div>
                            <h3 className={styles.stepTitle}>{step.title}</h3>
                            <p className={styles.stepDescription}>{step.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div >

    );

}

export default Steps;
