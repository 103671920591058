import React, { useState, useEffect } from "react";
import userImg from "../../assets/svg/user.svg";
import emptyImg from "../../assets/images/emptyImg.png";
import "./FileUpload.scss";
import { connect } from "react-redux";
import { setImage } from "../../store/actions/index";
import { Camera } from "lucide-react";

const FileUpload = ({ file, setImage }) => {
  const [image, setImageState] = useState(file && file.name ? file : null);
  const [fileName, setFileName] = useState("Buscar en tu computadora");
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (file && file.name) {
      imageChangeHandler({ target: { files: [file] } });
    }
  }, [file]);

  const imageChangeHandler = (event) => {
    if (event.target.files && event.target.files[0] && event.target.files[0].size < 50000000) {
      if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png") {
        let reader = new FileReader();
        reader.onload = (e) => {
          setImageState(e.target.result);
        };
        reader.readAsDataURL(event.target.files[0]);

        setFileName(event.target.files[0].name.slice(0, 10) + "...");
      } else {
        setFileName("jpeg/png unicamente");
      }
    } else {
      setFileName("No puede superar 500kb");
    }
    setImage(event.target.files[0]);
  };

  const imageChangeHandler2 = (event) => {
    if (event.target.files && event.target.files[0] && event.target.files[0].size < 50000000) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImageState(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setImage(event.target.files[0]);
    }
  };

  const userSVG = image === null ? <object className='user-svg--absolute' aria-label='user' data={userImg} type='image/svg+xml'></object> : null;

  return (
    <div className='img-container' >
      <div className='main-user__image'
        style={image !== null ? { border: "none" } : null}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {image !== null ? null : userSVG}
        <img src={image !== null ? image : emptyImg} alt='Profile' />
        <input 
          className='main-user__input' 
          id='file' 
          type='file' 
          onChange={imageChangeHandler2} 
          accept='image/*'
          style={{
            position: "absolute",
            top: 0,
            left: "50%",
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer", 
          }}
          />
        {isHovered && (
          <div className="camera-icon">
            <Camera size={24}  />
          </div>
        )}
      </div>

    </div>
  );
};

export default connect(null, { setImage })(FileUpload);