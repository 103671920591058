import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';


const firebaseConfig = {
    apiKey: "AIzaSyDvvjCss5Vi5FBngIxwbYIwVyGuDeu59hc",
    authDomain: "edify-firebase.firebaseapp.com",
    projectId: "edify-firebase",
    storageBucket: "edify-firebase.firebasestorage.app",
    messagingSenderId: "262004944712",
    appId: "1:262004944712:web:63581cf92f3a1950124d7d",
    measurementId: "G-XQMBGFQNSB"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  export default { app, analytics, firebaseConfig };