import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./NewNav.module.scss";
import {
  logout,
  openProfileMenu,
  viewAccessRequest,
  closeSuscriptionModal,
  setActivateFreeTrial,
  openMainModal,
  openFeedTextModal,
  closeMainModal,
  expandedSideBar,
  getTotalPrivateProjects,
  getTotalPrivateQuotations,
} from "../../store/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Negocios } from "../../assets/svg/negocios.svg";
import { ReactComponent as NegociosActive } from "../../assets/svg/negociosActive.svg";
import { ReactComponent as TrabajadoresIcon } from "../../assets/svg/trabajadoresIcon.svg";
import { ReactComponent as MarketPlace } from "../../assets/svg/marketplace.svg";
import { ReactComponent as MarketPlaceActive } from "../../assets/svg/marketplaceActive.svg";
import { ReactComponent as Search } from "../../assets/svg/misProyectosLittle.svg";
import { ReactComponent as SearchActive } from "../../assets/svg/misProyectosLittle.svg";
import { ReactComponent as Proyectos } from "../../assets/svg/nav-proyectos.svg";
import { ReactComponent as ProyectosActive } from "../../assets/svg/nav-proyectos-active.svg";
import { ReactComponent as Postulaciones } from "../../assets/svg/nav-postulaciones.svg";
import { ReactComponent as PostulacionesActive } from "../../assets/svg/nav-postulaciones-active.svg";
import { ReactComponent as LogOutIcon } from "../../assets/svg/logOutIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import useProfileCompletion from "../../hooks/useProfileCompletion";
import Tooltip from "../UI/ToolTip/ToolTip";
import useUserImage from "../../hooks/useUserImage";
import { PanelRightClose, PanelLeftClose } from "lucide-react";
import useSectionTracker from '../../hooks/useSectionTracker';

const NewNav = ({
  userData,
  closeMainModal,
  logout,
  openMainModal,
  viewAccessRequest,
  expandedSideBar,
  sideNavBarExpanded,
  getTotalPrivateProjects,
  totalPrivateProjects,
  getTotalPrivateQuotations,
  totalPrivateQuotations,
}) => {
  const [isActive, setIsActive] = useState(null);
  const [changedLocation, setChangedLocation] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [showBarTooltip, setShowBarTooltip] = useState({
    obrasPrivadas: { show: false, text: "Obras privadas" },
    obrasPublicas: { show: false, text: "Obras públicas" },
    muro: { show: false, text: "Muro" },
    cotizacion: { show: false, text: "Cotización de materiales" },
    profesionales: { show: false, text: "Profesionales" },
    mercado: { show: false, text: "Bolsa de trabajo" },
    menu: { show: false, text: "Expandir menú" },
    cerrar: { show: false, text: "Cerrar sesión" },
  });
  const navigate = useNavigate();
  const location = useLocation();
  const obrasPrivadasTracker = useSectionTracker('obras-privadas', {
    trackVisits: true,
    expirationDays: 30
  });

  const cotizacionesTracker = useSectionTracker('cotizaciones', {
    trackVisits: true,
    expirationDays: 30
  });
  const profileScoring = useProfileCompletion(userData);
  const { imageError, initials, profileImage } = useUserImage(userData);

  const getColorByScore = (score) => {
    if (score >= 75) return 'green';
    if (score >= 50) return 'yellow';
    if (score >= 25) return 'orange';
    return 'red';
  };


  const completeName =
    userData.type === "COMPANY"
      ? userData.razon_social
      : `${userData.name} + " " + ${userData.last_name}`;

  const handleRedirectToProfile = () => {
    navigate(`/profesionales/${userData.id}/${completeName}`);
  };

  const userName = userData?.type === "COMPANY" ? userData?.razon_social : userData?.name + " " + userData?.last_name;


  useEffect(() => {
    getTotalPrivateProjects();
    getTotalPrivateQuotations();
    const checkForUpdate = setInterval(() => viewAccessRequest(), 10000);
    return () => clearInterval(checkForUpdate);
  }, []);

  const handleRedirect = (title) => {
    setChangedLocation(!changedLocation);
    if (title === "Obras privadas") {
      navigate("/obrasprivadas");
    } else if (title === "Obras publicas") {
      navigate("/obraspublicas");
    } else if (title === "Home") {
      navigate("/dashboard");
    } else if (title === "cotizaciondemateriales") {
      navigate("/cotizaciondemateriales");
    } else if (title === "Profesionales") {
      navigate("/profesionales");
    } else if (title === "notificaciones") {
      navigate("/solicitudes");
    } else if (title === "FAQ") {
      navigate("/faq");
    } else if (title === "Mercado de trabajo") {
      navigate("/mercadodetrabajo");
    } else if (title === "backoffice")
      navigate("/backoffice");

  };

  useEffect(() => {
    if (location?.pathname === "/dashboard") {
      setIsActive("Home");
    }
    if (location?.pathname === "/obrasprivadas") {
      setIsActive("Obras privadas");
    }
    if (location?.pathname === "/obraspublicas") {
      setIsActive("Obras publicas");
    }
    if (location?.pathname === "/cotizaciondemateriales") {
      setIsActive("cotizaciondemateriales");
    }
    if (location?.pathname.startsWith("/profesionales")) {
      const pathParts = location.pathname.split("/");
      const idInPath = pathParts[2];
      if (idInPath === userData.id) {
        setIsActive("Perfil");
      } else {
        setIsActive("Profesionales");
      }
    }
    if (location?.pathname === "/segurosparatrabajadoresyequipos") {
      setIsActive("Seguros");
    }
    if (location?.pathname === "/alquilerdemaquinarias") {
      setIsActive("Alquiler de maquinarias");
    }
    if (location?.pathname === "/mercadodetrabajo") {
      setIsActive("Mercado de trabajo");
    }
    if (location?.pathname === "/solicitudes") {
      setIsActive("notificaciones");
    }
    if (location?.pathname === "/faq") {
      setIsActive("FAQ");
    }
    if (location?.pathname === "/backoffice") {
      setIsActive("backoffice");
    }
  }, [changedLocation, location?.pathname, userData.id]);


  const handleCloseSesion = () => {
    openMainModal({
      title: "¿Deseas cerrar la sesión activa?",
      body: "Dejarán de llegarte las notificaciones de actividad",
      isSuccess: false,
      iconType: "warning",
      confirmHandler: () => { logout(true, navigate); closeMainModal() },
    });
  };


  // Contar solo las obras nuevas (creadas después de la última visita)
  
  const newPrivateProjectsCount = obrasPrivadasTracker.countNewItems(totalPrivateProjects, 'createdAt');
  const newQuotationsCount = cotizacionesTracker.countNewItems(totalPrivateQuotations, 'createdAt');

  
  const isUserAdmin = userData.rols && userData.rols.filter((rol) => rol.name === "ADMIN").length > 0;

  const isDashboard = location.pathname === "/dashboard";

  const roundedScore = Math.round(profileScoring);
  const tooltipContent = {
    completionText:
      roundedScore <= 25 ? `Tu perfil está al ${roundedScore}% completado. Comienza a construir tu perfil.` :
        roundedScore <= 50 ? `Tu perfil está al ${roundedScore}% completado. Vas por buen camino.` :
          roundedScore <= 75 ? `Tu perfil está al ${roundedScore}% completado. ¡Casi llegas! Completa más información.` :
            `¡Tu perfil está al ${roundedScore}% completado! ¡Excelente trabajo!`,

    tips:
      roundedScore <= 25 ? [
        "Agrega tu nombre y apellido.",
        "Sube una foto de perfil.",
        "Completa tu información de contacto."
      ] : roundedScore <= 50 ? [
        "Describe tus habilidades o experiencia.",
        "Selecciona tus rubros principales.",
        "Comparte una breve descripción."
      ] : roundedScore <= 75 ? [
        "Agrega proyectos o trabajos realizados.",
        "Incluye certificaciones o estudios relevantes.",
        "Completa tus redes sociales o sitio web."
      ] : [
        "Responde de manera ágil las consultas.",
        "Solicita recomendaciones de clientes.",
        "Mantén tu perfil actualizado regularmente."
      ]
  };

  const handleToggleNav = () => {
    expandedSideBar(!sideNavBarExpanded);
  };

  const handleMouseEnter = (key) => {
    setShowBarTooltip((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], show: true },
    }));
  };

  const handleMouseLeave = (key) => {
    setShowBarTooltip((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], show: false },
    }));
  };

  return (
    <div className={styles.generalContainer}>
      <div className={`${styles.container} ${sideNavBarExpanded ? styles.container_fullWidth : ""}`}
      >
        <div className={styles.nav_userDataContainer} >
          <div className={`${styles.nav_imgContainer} ${isActive === "Perfil" && styles.isActive}`}
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
            onClick={() => handleRedirectToProfile()}>
            <div className={styles.nav_imgContainer_image}>
              <svg
                width="60"
                height="60"
                style={{ position: 'absolute', zIndex: 0 }}
              >
                <circle
                  cx="30"
                  cy="30"
                  r="28"
                  stroke="#e0e0e0"
                  strokeWidth="4"
                  fill="none"
                />
                <circle
                  cx="30"
                  cy="30"
                  r="28"
                  stroke={getColorByScore(profileScoring)}
                  strokeWidth="4"
                  fill="none"
                  strokeDasharray={Math.PI * 2 * 28}
                  strokeDashoffset={Math.PI * 2 * 28 * (1 - profileScoring / 100)}
                  style={{ transition: 'stroke-dashoffset 0.5s ease', transform: 'rotate(90deg)', transformOrigin: '50% 50%' }}
                />
              </svg>
              {!imageError && profileImage ? (
                <img
                  className={styles.nav_imgContainer_img}
                  src={profileImage}
                  alt="user"
                />
              ) : (
                <div className={styles.nav_imgContainer_initials}>
                  {initials}
                </div>
              )}
              <div className={styles.nav_userData_status_circle} />
              {showToolTip && isDashboard && (
                <Tooltip text={tooltipContent} fromNav />)}
            </div>
            <div className={`${styles.nav_userData} ${!sideNavBarExpanded && styles.nav_userData_hide}`}>
              <p className={styles.nav_userData_name}>{userName}</p>
              <span className={styles.nav_userData_status}>{userData?.username}</span>
            </div>
          </div>
        </div>
        <ul className={styles.nav_list}>
          <li
            onMouseEnter={() => handleMouseEnter("muro")}
            onMouseLeave={() => handleMouseLeave("muro")}
            className={`${styles.nav_navItem} ${isActive === "Home" ? styles.activeHome : ""
              }`}
            onClick={() => handleRedirect("Home")}
          >
            <div className={styles.nav_navItemIcon}>
              {isActive === "Home" ? (
                <ProyectosActive width={20} height={20} />
              ) : (
                <Proyectos width={20} height={20} />
              )}
              {showBarTooltip.muro.show && !sideNavBarExpanded && (
                <div className={styles.toolTipContainer} >
                  <Tooltip text={showBarTooltip.muro.text} />
                </div>
              )}
            </div>
            <div className={`${styles.nav_navItemText} ${!sideNavBarExpanded ? styles.hideText : ""}`}>Muro</div>

          </li>
          <li
            onMouseEnter={() => handleMouseEnter("obrasPrivadas")}
            onMouseLeave={() => handleMouseLeave("obrasPrivadas")}
            className={`${styles.nav_navItem} ${isActive === "Obras privadas" ? styles.activePrivate : ""
              }`}
            onClick={() => handleRedirect("Obras privadas")}
          >
            <div
              className={`${isActive === "Obras privadas"
                ? styles.nav_navItemIcon
                : styles.nav_navItemIconComunidad
                }`}
            >
              {isActive === "Obras privadas" ? (
                <SearchActive width={20} height={20} />
              ) : (
                <Search width={20} height={20} />
              )}
              {showBarTooltip.obrasPrivadas.show && !sideNavBarExpanded && (
                <div className={styles.toolTipContainer} >
                  <Tooltip text={showBarTooltip.obrasPrivadas.text} />
                </div>
              )}
              {newPrivateProjectsCount > 0 && !sideNavBarExpanded && (
                <span className={styles.nav_navItemIcon_iconbadgeContainer}>
                  <span className={styles.nav_navItemIcon_iconbadgeAnimation} />
                  <span className={styles.nav_navItemIcon_iconbadge} />
                </span>
              )}
            </div>
            <div className={`${styles.nav_navItemText} ${!sideNavBarExpanded ? styles.hideText : ""}`}>
              Obras privadas
              {newPrivateProjectsCount > 0 && (
                <span className={styles.nav_navItemText_badge}>
                  {newPrivateProjectsCount}
                </span>
              )}

            </div>

          </li>
          <li
            onMouseEnter={() => handleMouseEnter("obrasPublicas")}
            onMouseLeave={() => handleMouseLeave("obrasPublicas")}
            className={`${styles.nav_navItem} ${isActive === "Obras publicas" ? styles.activePublics : ""
              }`}
            onClick={() => handleRedirect("Obras publicas")}
          >
            <div className={styles.nav_navItemIcon}>
              {isActive === "Obras publicas" ? (
                <PostulacionesActive width={20} height={20} />
              ) : (
                <Postulaciones width={20} height={20} />
              )}
              {showBarTooltip.obrasPublicas.show && !sideNavBarExpanded && (
                <div className={styles.toolTipContainer} >
                  <Tooltip text={showBarTooltip.obrasPublicas.text} />
                </div>
              )}
            </div>
            <div className={`${styles.nav_navItemText} ${!sideNavBarExpanded ? styles.hideText : ""}`}>Obras públicas</div>

          </li>
          <li
            onMouseEnter={() => handleMouseEnter("cotizacion")}
            onMouseLeave={() => handleMouseLeave("cotizacion")}
            className={`${styles.nav_navItem} ${isActive === "cotizaciondemateriales" ? styles.activeQuotation : ""
              }`}
            onClick={() => handleRedirect("cotizaciondemateriales")}
          >
            <div
              className={`${isActive === "cotizaciondemateriales"
                ? styles.nav_navItemIcon
                : styles.nav_navItemIconComunidad
                }`}
            >
              {isActive === "cotizaciondemateriales" ? (
                <MarketPlaceActive width={20} height={20} />
              ) : (
                <MarketPlace width={20} height={20} />
              )}
              {showBarTooltip.cotizacion.show && !sideNavBarExpanded && (
                <div className={styles.toolTipContainer} >
                  <Tooltip text={showBarTooltip.cotizacion.text} />
                </div>
              )}
              {newQuotationsCount > 0 && !sideNavBarExpanded && (
                <span className={styles.nav_navItemIcon_iconbadgeContainer}>
                  <span className={styles.nav_navItemIcon_iconbadgeAnimation} />
                  <span className={styles.nav_navItemIcon_iconbadge} />
                </span>

              )}
            </div>
            <div className={`${styles.nav_navItemText} ${!sideNavBarExpanded ? styles.hideText : ""}`}>
              Cotización de materiales
              {newQuotationsCount > 0 && (
                <span className={styles.nav_navItemText_badge}>
                  {newQuotationsCount}
                </span>
              )}
            </div>

          </li>
          <li
            onMouseEnter={() => handleMouseEnter("profesionales")}
            onMouseLeave={() => handleMouseLeave("profesionales")}
            className={`${styles.nav_navItem} ${isActive === "Profesionales" ? styles.activeComunidad : ""
              }`}
            onClick={() => handleRedirect("Profesionales")}
          >
            <div
              className={`${isActive === "Profesionales"
                ? styles.nav_navItemIcon
                : styles.nav_navItemIconComunidad
                }`}
            >
              {isActive === "Profesionales" ? (
                <NegociosActive width={20} height={20} />
              ) : (
                <Negocios width={20} height={20} />
              )}
              {showBarTooltip.profesionales.show && !sideNavBarExpanded && (
                <div className={styles.toolTipContainer} >
                  <Tooltip text={showBarTooltip.profesionales.text} />
                </div>
              )}
            </div>
            <div className={`${styles.nav_navItemText} ${!sideNavBarExpanded ? styles.hideText : ""}`}>Profesionales</div>
          </li>
          <li
            onMouseEnter={() => handleMouseEnter("mercado")}
            onMouseLeave={() => handleMouseLeave("mercado")}
            className={`${styles.nav_navItem} ${isActive === "Mercado de trabajo" ? styles.activeWorkers : ""
              }`} onClick={() => handleRedirect("Mercado de trabajo")}>
            <div className={`${isActive === "Mercado de trabajo" ? styles.nav_navItemIconWorkers : styles.nav_navItemIcon}`}>
              {isActive === "Mercado de trabajo" ? (
                <TrabajadoresIcon width={20} height={20} />
              ) : (
                <TrabajadoresIcon width={20} height={20} />
              )}
              {showBarTooltip.mercado.show && !sideNavBarExpanded && (
                <div className={styles.toolTipContainer} >
                  <Tooltip text={showBarTooltip.mercado.text} />
                </div>
              )}
            </div>
            <div className={`${styles.nav_navItemText} ${!sideNavBarExpanded ? styles.hideText : ""}`}>Bolsa de trabajo</div>

          </li>

          <li
            onMouseEnter={() => handleMouseEnter("menu")}
            onMouseLeave={() => handleMouseLeave("menu")}
            className={styles.nav_navItem_lastItems} onClick={handleToggleNav}>
            <div className={styles.nav_navItemIconChevron}>
              {sideNavBarExpanded ? (
                <PanelLeftClose size={20} />
              ) : (
                <PanelRightClose size={20} />
              )}
              {showBarTooltip.menu.show && !sideNavBarExpanded && (
                <div className={styles.toolTipContainer} >
                  <Tooltip text={showBarTooltip.menu.text} />
                </div>
              )}
            </div>
            <div className={`${styles.nav_navItemText} ${!sideNavBarExpanded ? styles.hideText : styles.nav_navItemText_toggleMenu}`}>Ocultar menú</div>
          </li>
          {isUserAdmin && (
            <li
              className={`${styles.nav_navItem} ${isActive === "backoffice" ? styles.activeWorkers : ""
                }`}
              onClick={() => handleRedirect("backoffice")}
            >
              <div
                className={`${isActive === "backoffice"
                  ? styles.nav_navItemIconWorkers
                  : styles.nav_navItemIcon
                  }`}
              >
                {isActive === "backoffice" ? (
                  <FontAwesomeIcon icon={faUsers} />
                ) : (
                  <FontAwesomeIcon icon={faUsers} />
                )}
              </div>
              <div className={`${styles.nav_navItemText} ${!sideNavBarExpanded ? styles.hideText : ""}`}>Backoffice</div>
            </li>
          )}
          <li
            onMouseEnter={() => handleMouseEnter("cerrar")}
            onMouseLeave={() => handleMouseLeave("cerrar")}
            className={styles.nav_navItem}
            onClick={() => handleCloseSesion()}
          >
            <div className={styles.nav_navItemIcon}>
              <LogOutIcon width={20} height={20} />
              {showBarTooltip.cerrar.show && !sideNavBarExpanded && (
                <div className={styles.toolTipContainer} >
                  <Tooltip text={showBarTooltip.cerrar.text} />
                </div>
              )}
            </div>
            <div className={`${styles.nav_navItemText}  ${!sideNavBarExpanded ? styles.hideText : ""}`}>Cerrar sesión</div>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    solicitudesList: state.dashboard.solicitudesList,
    isFreeTrialAvailable: state.general.isFreeTrialAvailable,
    isFreeTrialSuccess: state.profile.isFreeTrialSuccess,
    newNotifications: state.dashboard.newNotifications,
    sideNavBarExpanded: state.general.sideNavBarExpanded,
    totalPrivateProjects: state.dashboard.totalPrivateProjects,
    totalPrivateQuotations: state.dashboard.totalPrivateQuotations,
  };
};

export default connect(mapStateToProps, {
  logout,
  openProfileMenu,
  closeSuscriptionModal,
  openMainModal,
  setActivateFreeTrial,
  openFeedTextModal,
  viewAccessRequest,
  closeMainModal,
  expandedSideBar,
  getTotalPrivateProjects,
  getTotalPrivateQuotations,
})(NewNav);
