import React, { useEffect, useState, useRef } from "react";
import styles from "./Feed.module.scss";
import { connect } from "react-redux";
import {
    openFeedTextModal,
    closeMainModal,
    getPosteos,
    getOwnPosteos,
    openWorkOfferModal,
    resetRecentPost,
    openSuscriptionModal
} from "../../../store/actions";
import usePremiumCheck from "../../../hooks/usePremiumCheck";
import FeedCard from "./FeedCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faVideo } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Cotizacion } from "../../../assets/svg/marketplaceActive.svg";
import { ReactComponent as ObraPrivada } from "../../../assets/svg/misProyectosLittle.svg";
import { ReactComponent as Proyectos } from "../../../assets/svg/nav-proyectos.svg";
import { HardHat, ArrowRight, SlidersHorizontal, FileText } from "lucide-react";
import { useNavigate } from "react-router-dom";
import FeedCardSkeleton from "./FeedCardSkeleton";
import InfiniteScroll from 'react-infinite-scroller';
import useUserImage from "../../../hooks/useUserImage";
import CLICK_IDS from "../../../utils/analyticsClickIds";
import { fireAnalytics } from "../../../utils/firebase";
import useIsPhone from "../../../hooks/useIsPhone";

const Feed = ({
    userData,
    openFeedTextModal,
    isUserPremium,
    closeMainModal,
    getPosteos,
    getOwnPosteos,
    ownPosteos,
    resetRecentPost,
    posteos,
    openSuscriptionModal,
    openWorkOfferModal,
    recentPost,
    sideNavBarExpanded,
    componentLoading,
    ...props }) => {
    const [selectedOption, setSelectedOption] = useState("Ordenar por");
    const [myPosts, setMyPosts] = useState(false);
    const [items, setItems] = useState(posteos);
    const [isSorted, setIsSorted] = useState(false);
    const [page, setPage] = useState(1);
    const [showFeedBar, setShowFeedBar] = useState(false);
    const [lastScrollPosition, setLastScrollPosition] = useState(0);

    const isPhone = useIsPhone();
    const buttonsContainerRef = useRef(null);
    const feedBarRef = useRef(null);
    const observerRef = useRef(null);

    // Configuración del Intersection Observer

    useEffect(() => {

        const options = {
            root: null,
            rootMargin: isPhone ? '-50px 0px 0px 0px' : '0px',
            threshold: [0, 0.9], 
        };
    
        observerRef.current = new IntersectionObserver((entries) => {
            const [entry] = entries;
            const currentScroll = window.scrollY; 
            const direction = currentScroll > lastScrollPosition ? 'down' : 'up';
            setLastScrollPosition(currentScroll);
    
            if (direction === 'down') {
                setShowFeedBar(entry.intersectionRatio < 0.9);
            } else if (direction === 'up') {
                setShowFeedBar(entry.intersectionRatio < 0.9 && currentScroll > (buttonsContainerRef.current?.offsetHeight || 0));
            }
        }, options);
    
        if (buttonsContainerRef.current) {
            observerRef.current.observe(buttonsContainerRef.current);
        }
    
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [lastScrollPosition, isPhone]);

    const { imageError, initials, profileImage } = useUserImage(userData);

    const premiumCheck = usePremiumCheck();
    const navigate = useNavigate();

    const shufflePost = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    //Carga los posteos
    useEffect(() => {
        if (posteos.length === 0) {
            getPosteos("DESC", "DATE");
        }
        resetRecentPost();
        handleShuffle();
    }, []);

    const handleShuffle = () => {
        setItems(shufflePost(posteos));
    };

    useEffect(() => {
        if (selectedOption === "propios") {
            setItems(ownPosteos);
        } else {
            setItems(posteos);
        }
    }, [selectedOption, posteos, ownPosteos]);



    useEffect(() => {
        if (selectedOption === "recomendados") {
            getPosteos("ASC", "MG");
            setItems(posteos);
        } else if (selectedOption === "recientes") {
            getPosteos("DESC", "DATE");
            setItems(posteos);
        } else if (selectedOption === "propios") {
            getOwnPosteos(userData.id);
            setItems(ownPosteos);
        } else if (selectedOption === "Ordenar por") {
            handleShuffle();
        }
    }, [selectedOption]);


    //Actualiza la lista una vez que el usuario crea un posteo
    useEffect(() => {
        if (recentPost?.user?.id == userData?.id) {
            getPosteos("DESC", "DATE");
            setSelectedOption("recientes");
            setIsSorted(true);
        }
    }, [recentPost]);

    const handleNewOpenProject = () => {
        if (isUserPremium) {
            fireAnalytics("crear_obra_privada", { obra_privada_user_id: userData.id });
            navigate("/obrasprivadas/crear");
        } else {
            fireAnalytics("usuario_sin_plan", { no_plan_user_info: userData.id });
            openSuscriptionModal();
        }
    };

    //Abre el modal de texto
    const handleOpenMainModalTxt = () => {
        openFeedTextModal({
            text: true,
        });
    };

    //Abre el modal de imagen
    const handleOpenMainModalWithImg = () => {
        openFeedTextModal({
            image: true,
        });
    }

    //Abre el modal de video
    const handleOpenMainModalWithVideo = () => {
        openFeedTextModal({
            video: true,
        });
    };

    //Menu de opciones de visualizacion
    const handleOptionClick = (option) => {
        setSelectedOption(option);

        if (option === "recientes") {
            getPosteos("DESC", "DATE");

        }
        if (option === "recomendados") {
            getPosteos("ASC", "MG");
        }
        if (option === "propios") {
            getOwnPosteos(userData.id);
            setItems(ownPosteos);
            setMyPosts(true);
        }
    };

    const handleRedirectToQuotation = () => {
        if (isUserPremium) {
            navigate("/cotizaciondemateriales/crear");
        } else {
            fireAnalytics("usuario_sin_plan", { no_plan_user_info: userData.id });
            openSuscriptionModal();
        }
    };

    const handleCreateWorkOffer = () => {
        if (isUserPremium) {
            openWorkOfferModal();
        } else {
            fireAnalytics("usuario_sin_plan", { no_plan_user_info: userData.id });
            openSuscriptionModal();
        }
    }

    return (
        <div className={styles.container}>
            <div
                className={styles.stickyContainer}
                style={{
                    height: showFeedBar ? '12rem' : '0',
                }}
            >
                <div ref={feedBarRef}
                    className={`${styles.feed} ${showFeedBar ? styles.feedVisible : ''}`}
                    style={{
                        transform: showFeedBar ? 'translateY(0)' : 'translateY(-100%)',
                        transition: 'transform 0.3s ease-out',
                        willChange: 'transform'
                    }}>
                    <div className={styles.feed_header}>
                        <div className={styles.feed_itemHeader}>
                            <div className={styles.feed_itemHeaderAvatar}>
                                {!imageError && profileImage ? (
                                    <img
                                        className={styles.feed_itemHeaderAvatar_img}
                                        src={profileImage}
                                        alt="user"
                                    />
                                ) : (
                                    <div className={styles.feed_itemHeaderAvatar_initials}>{initials}</div>
                                )}
                            </div>
                            <div className={styles.feed_input} onClick={premiumCheck(handleOpenMainModalTxt)}>
                                <input type="text"
                                    readOnly
                                    placeholder={"¿Qué querés publicar en el muro de edify?"}
                                    className={styles.feed_input_item} />

                            </div>
                            <div className={styles.feed_itemFooterContainer} >
                                <div className={styles.feed_itemFooterContainer_buttons}>
                                    <div className={styles.feed_itemFooterLeft} onClick={premiumCheck(handleOpenMainModalWithImg)}>
                                        <FontAwesomeIcon icon={faCamera} style={{ fontSize: '2.5rem' }} />
                                    </div>
                                    <div className={styles.feed_itemFooterRight} onClick={premiumCheck(handleOpenMainModalWithVideo)}>
                                        <FontAwesomeIcon icon={faVideo} style={{ fontSize: '2.5rem' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.feed_buttonsContainer} ref={buttonsContainerRef}  >
                <h2 className={styles.feed_buttonsContainer_title}>¿Qué necesitás hacer?</h2>
                <h5 className={styles.feed_buttonsContainer_subtitle}>Selecciona una opción para comenzar</h5>
                <div className={styles.feed_buttonsContainer_buttons}>
                    <div
                        id={CLICK_IDS.OBRA_PRIVADA_BUTTON_HOME}
                        className={`${styles.feed_buttonsContainer_button} 
                        ${styles.hoverObraPrivada} 
                        ${styles.feed_buttonsContainer_button_obraPrivada}`}
                        onClick={handleNewOpenProject}
                    >
                        <div className={`${styles.feed_buttonsContainer_button_icon} ${styles.feed_buttonsContainer_button_icon_obraPrivada} `}>
                            <ObraPrivada width={32} height={32} />
                        </div>
                        <div className={styles.feed_buttonsContainer_button_body}>
                            <div className={styles.feed_buttonsContainer_button_title} >
                                Nueva obra privada
                            </div>
                            <div className={styles.feed_buttonsContainer_button_text}>
                                <span>Publica tu proyecto y recibe propuestas de profesionales calificados</span>
                            </div>
                        </div>
                        <div className={styles.feed_buttonsContainer_button_arrow}>
                            <ArrowRight size={24} />
                        </div>
                    </div>
                    <div
                        id={CLICK_IDS.COTIZACION_MATERIALES_BUTTON_HOME}
                        className={`${styles.feed_buttonsContainer_button} ${styles.hoverCotizacion} ${styles.feed_buttonsContainer_button_cotizacion}`}
                        onClick={handleRedirectToQuotation}
                    >
                        <div className={`${styles.feed_buttonsContainer_button_icon} ${styles.feed_buttonsContainer_button_icon_cotizacion}`}>
                            <Cotizacion width={32} height={32} />
                        </div>
                        <div className={styles.feed_buttonsContainer_button_body}>
                            <div className={styles.feed_buttonsContainer_button_title}>
                                Cotizar materiales
                            </div>
                            <div className={styles.feed_buttonsContainer_button_text}>
                                <span>Crea una lista y recibe cotizaciones de proveedores de materiales</span>
                            </div>
                        </div>
                        <div className={styles.feed_buttonsContainer_button_arrow}>
                            <ArrowRight size={24} />
                        </div>
                    </div>
                    <div
                        id={CLICK_IDS.MURO_BUTTON_HOME}
                        className={`${styles.feed_buttonsContainer_button} ${styles.hoverMuroEdify} ${styles.feed_buttonsContainer_button_muroEdify}`}
                        onClick={premiumCheck(handleOpenMainModalTxt)}
                    >
                        <div className={`${styles.feed_buttonsContainer_button_icon} ${styles.feed_buttonsContainer_button_icon_muroEdify}`}>
                            <Proyectos width={32} height={32} />
                        </div>
                        <div className={styles.feed_buttonsContainer_button_body}>
                            <div className={styles.feed_buttonsContainer_button_title}>
                                Publicar en el muro
                            </div>
                            <div className={styles.feed_buttonsContainer_button_text}>
                                <span>Comparte novedades, fotos y actualizaciones con la comunidad</span>
                            </div>
                        </div>
                        <div className={styles.feed_buttonsContainer_button_arrow}>
                            <ArrowRight size={24} />
                        </div>
                    </div>
                    <div
                        id={CLICK_IDS.OFERTA_DE_TRABAJO_BUTTON_HOME}
                        className={`${styles.feed_buttonsContainer_button} ${styles.hoverOfertaTrabajo} ${styles.feed_buttonsContainer_button_ofertaTrabajo}`}
                        onClick={handleCreateWorkOffer}
                    >
                        <div className={`${styles.feed_buttonsContainer_button_icon} ${styles.feed_buttonsContainer_button_icon_ofertaTrabajo}`}>
                            <HardHat size={32} />
                        </div>
                        <div className={styles.feed_buttonsContainer_button_body}>
                            <div className={styles.feed_buttonsContainer_button_title}>
                                Oferta de trabajo
                            </div>
                            <div className={styles.feed_buttonsContainer_button_text}>
                                <span>Encuentra al trabajador ideal para tu equipo</span>
                            </div>
                        </div>
                        <div className={styles.feed_buttonsContainer_button_arrow}>
                            <ArrowRight size={24} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.header_titleSortContainer}>
                <div className={styles.dropdown}>
                    <span className={styles.header_line} />
                    <SlidersHorizontal size={14} style={{ color: "#000", marginLeft: "2rem", minWidth: "1.4rem" }} />
                    <select
                        className={styles.dropdown_button}
                        value={selectedOption}
                        onChange={(e) => handleOptionClick(e.target.value)}
                    >
                        <option disabled value="Ordenar por">Ordenar por</option>
                        <option value="recientes">Recientes</option>
                        <option value="propios">Propios</option>
                        <option value="recomendados">Recomendados</option>
                    </select>
                </div>
            </div>

            <div className={styles.feedItemContainer}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={myPosts ? () => { } : handleShuffle}
                    hasMore={true || false}
                    className={styles.feedItemContainer}
                >
                    {componentLoading && items?.length > 0 ? Array.from({ length: 30 }).map((_, index) => (
                        <FeedCardSkeleton key={`skeleton-${index}`} />
                    )) : (
                        items?.length > 0 ? items?.map((item, index) => (
                            <FeedCard
                                key={`${item.id}-${page}-${index}`}
                                item={item}
                                myPosts={myPosts}
                            />
                        )) :
                            <div className={styles.feedItemContainer_empty}>
                                <div className={styles.feedItemContainer_empty_icon}>
                                    <FileText size={34} />
                                </div>
                                <h2 className={styles.feedItemContainer_empty_title}>No tenés publicaciones propias</h2>
                                <p className={styles.feedItemContainer_empty_text}>Comparte novendades, fotos y actualizaciones con la comunidad</p>
                                <button className={styles.feedItemContainer_empty_button} onClick={premiumCheck(handleOpenMainModalTxt)}>Crear publicación</button>
                            </div>
                    )}
                </InfiniteScroll>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {

    return {
        userData: state.general.userData,
        posteos: state.dashboard.posteos,
        ownPosteos: state.dashboard.ownPosteos,
        recentPost: state.dashboard.recentPost,
        isUserPremium: state.general.isUserPremium,
        sideNavBarExpanded: state.general.sideNavBarExpanded,
        componentLoading: state.general.componentLoading
    };
};

export default connect(mapStateToProps, {
    openSuscriptionModal,
    openFeedTextModal,
    closeMainModal,
    getPosteos,
    getOwnPosteos,
    openWorkOfferModal,
    resetRecentPost
})(Feed);
