import React from "react";
import styles from "./ResponseComments.module.scss";

const ResponseComments = ({ commentResponses, calcularDiferenciaTiempo, isHovered }) => {

    return (
        <div className={styles.response}>
            {commentResponses.map((response, index) => {
                let responseCompleteName = response?.user?.type === "COMPANY" ? response?.user?.razon_social : response?.user?.name + " " + response?.user?.last_name;
                let responseImageProfile = response?.user?.image_profile;
                let responseContent = response?.content;
                let responseCreation = response?.createdAt;
                let responseIntials = responseCompleteName?.split(" ").map((n) => n[0]).join("");
                let responseProfileImageError = response?.user?.image_profile === "https://edify-files-dev.s3.amazonaws.com/profile-pictures/ebba9aa8-4346-4662-b3e5-8699015659ba-image-profile-default.png";
                return (
                    <div className={styles.response_item} key={index}>
                        <div className={styles.response_item_imgContainer}>
                            {!responseProfileImageError && responseImageProfile ? (
                                <img
                                    className={styles.response_item_imgContainer_img}
                                    src={responseImageProfile}
                                    alt="user"
                                />
                            ) : (
                                <div className={styles.response_item_imgContainer_initials}>
                                    {responseIntials}
                                </div>
                            )}
                        </div>
                        <div className={styles.response_item_contentContainer}>
                            <div className={`${styles.response_item_content} ${isHovered && styles.response_item_content_hovered}`}>
                                <div className={styles.response_item_content_header}>
                                    <div className={styles.response_item_content_header_name}>
                                        {responseCompleteName}
                                        <span className={styles.response_item_content_header_time}>
                                            {calcularDiferenciaTiempo(responseCreation)}
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.response_item_text}>
                                    {responseContent}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )

}

export default ResponseComments;