import axios from "../../axios";
import * as actionTypes from "../actions/types";
import { openEdifySpinner, getQuotationById, openMainModal } from "./profileActions";

export const getCategoriesWithMaterials = () => async (dispatch) => {
    let authToken = localStorage.getItem("access-Token-Edify");
    let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
    try {
        const res = await axios.get('/category/all/materials', bearerToken);
        dispatch({
            type: actionTypes.GET_CATEGORIES_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: actionTypes.GET_CATEGORIES_ERROR,
            payload: err.message,
        });
    }
};

export const getCategories = () => async (dispatch) => {
    let authToken = localStorage.getItem("access-Token-Edify");
    let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

    try {
        const res = await axios.get('/category/all', bearerToken);
        dispatch({
            type: actionTypes.GET_CATEGORY_LIST,
            payload: res.data,
        });

    } catch (err) {
        dispatch({
            type: actionTypes.GET_CATEGORY_ERROR,
            payload: err.message,
        });
    }
};


export const sendQuote = (quoteData, materials, navigate) => (dispatch) => {
    let authToken = localStorage.getItem("access-Token-Edify");
    let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

    dispatch({ type: actionTypes.SEND_QUOTE_REQUEST });
    dispatch(openEdifySpinner(true));
    var quotationId

    const queryParams = {
        active: 1,
        description: quoteData.description,
        name: quoteData.name,
        zone: quoteData.city,
        startDate: quoteData.receiveFrom,
        endDate: quoteData.receiveTo,
        type: "PRIVATE",
        jurisdiccion: quoteData.jurisdiccion,
        currency: quoteData.currency
    };

    let sendUrl = `/quotation/assign?description=${encodeURIComponent(queryParams.description)}&endDate=${queryParams.endDate}&active=${queryParams.active}&name=${encodeURIComponent(queryParams.name)}&startDate=${queryParams.startDate}&zone=${encodeURIComponent(queryParams.zone)}&currency=${encodeURIComponent(queryParams.currency)}&jurisdiccion=${encodeURIComponent(queryParams.jurisdiccion)}&type=${encodeURIComponent(queryParams.type)}`;

    axios.post(sendUrl, null, bearerToken)
        .then((quotationResponse) => {
            quotationId = quotationResponse.data.id;
            if (!Array.isArray(materials)) {
                throw new Error('materials no es un array');
            }

            const materialsAux = [];

            materials.forEach(material => {
                materialsAux.push({
                    category_id: material.categoryId,
                    material_id: material.id,
                    temporary: material.temporary,
                    amount: material.quantity,
                    name: material.name,
                    unit: material.unit
                })
            });

            return axios.post('/sub_quotation/materials/crear', {
                quotation_id: quotationId,
                materials: materialsAux
            }, bearerToken);
        })
        .then((subqui) => {
            dispatch(openEdifySpinner(false));
            dispatch({ type: actionTypes.SEND_QUOTE_SUCCESS });
            dispatch(
                openMainModal({
                  title: "¡Tu pedido de materiales se ha publicado correctamente!",
                  body: "Te notificaremos por correo electrónico y en la plataforma cuando los proveedores envíen un presupuesto para los materiales que has solicitado. ¡Gracias por confiar en nosotros!",
                  isSuccess: true,
                  confirmHandler: null,
                  iconType: "success",
                  noLabel: "Cerrar"
                })
              );
            setTimeout(() => {
                dispatch(getQuotationById(quotationId, false, null))
            }, 2000);
            setTimeout(() => {
                navigate(`/cotizaciondemateriales/${quotationId}`);
            }, 2000);

        })
        .catch((error) => {
            dispatch(openEdifySpinner(false));
            dispatch({
                type: actionTypes.SEND_QUOTE_FAIL,
                payload: error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            });
            dispatch(
                openMainModal({
                    title: "¡Ups!",
                    body: "No hemos podido crear la solicitud, por favor inténtelo nuevamente o escribanos a contacto@edify.la ¡Muchas gracias!",
                    isSuccess: true,
                    confirmHandler: null,
                })
            );
            console.error('Error en sendQuote:', error);
        });
};

export const getAllMaterialsCatalogFile = () => async (dispatch) => {
    let authToken = localStorage.getItem("access-Token-Edify");
    let bearerToken = { 
        headers: { Authorization: `Bearer ${authToken}` },
        responseType: 'blob' 
    };

    try {
        dispatch(openEdifySpinner(true));
        const res = await axios.get('/materials/catalog/export', bearerToken);

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'catalogo.pdf'); 
        document.body.appendChild(link);
        link.click();


        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        dispatch(openEdifySpinner(false));
        dispatch(
            openMainModal({
                title: "¡Descarga exitosa!",
                body: "El archivo se ha descargado correctamente",
                iconType: "success",
                isSuccess: true,
                confirmHandler: null,
            })
        );

    } catch (err) {
        dispatch(openEdifySpinner(false));
        dispatch(
            openMainModal({
                title: "¡Ups!",
                body: "No hemos podido descargar el archivo, por favor inténtelo nuevamente o escribanos a contacto@edify.la ¡Muchas gracias!",
                isSuccess: true,
                iconType: "error",
                confirmHandler: null,
            })
        );  
    }
};


export const resetSuccess = () => ({
    type: actionTypes.RESET_SUCCESS,
});

