import React from "react";
import styles from "./NavBar.module.scss";

const NavBar = ({setIsActive, isActive, myPerson, setInfoReady}) => {

    const commonSections = [
        { name: "Resumen"},
        { name: "Productos/Servicios"},
        { name: "Calificaciones"},
        { name: "Proyectos publicados"},
        { name: "Cotización de materiales"},

    ];

    const conditionalSections = [
        { name: "Accesos a obras", condition: myPerson },
        { name: "Configuración", condition: myPerson },
        
    ];

    const sections = [...commonSections, ...conditionalSections.filter((section) => section.condition)];

       
    const handleClick = (section) => {
        setInfoReady(false);
        setIsActive(section);
    };


    
    return (
        <div className={styles.navBar}>
            <div className={styles.navBar_wrapper}>
                <nav className={styles.navBar_nav}>
                    {sections.map((section, index) => (
                        <button key={index} className={`${styles.navBar_button} ${isActive === section.name && styles.navBar_button_active}`}
                        onClick={() => handleClick(section.name)}>
                            {section.name}
                        </button>
                    ))}


                </nav>

            </div>
       
        </div>
    );
    };

export default NavBar;