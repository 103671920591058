import React, { useState } from "react";
import styles from "./NuevoProductoModal.module.scss";
import { connect } from "react-redux";
import { deleteImageFromPortfolio, openMainModal, closeMainModal } from "../../../../../store/actions";
import { ReactComponent as FolderIcon } from "../../../../../assets/svg/input-img.svg";
import { Carousel } from "react-responsive-carousel";
import "./PortfolioCarousel.css";
import { ImagePlus } from "lucide-react";


const NuevoProductoModal = ({
    values,
    formik,
    fileName = [],
    setImage,
    ...props
}) => {


    let { name, description, endDate, startDate, zone, images, type, priceUnit, price, currencyType } =
        values;

    const [isStartDateInput, setIsStartDateInput] = useState(true);
    const [isEndDateInput, setIsEndDateInput] = useState(true);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [deletedImageIds, setDeletedImageIds] = useState([]);

    //Definimos la función que elimina las imágenes
    const deleteImage = (imageId) => {
        openMainModal({
            title: "¿Estás seguro que deseas eliminar esta imagen?",
            body: "Esta acción no se puede deshacer.",
            confirmHandler: () => {
                deleteImageFromPortfolio(imageId);
                closeMainModal();
            },
        });
        setDeletedImageIds((prevDeletedImageIds) => [...prevDeletedImageIds, imageId]);
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        const updatedFiles = Array.from(files);

        if (updatedFiles.length + images.length > 5) {
            openMainModal({
                title: "No se pueden agregar más de 5 imágenes",
                body: "Por favor, elimine alguna de las imágenes existentes ¡Muchas gracias!",
                isSuccess: true,
            });
            return;
        }

        // Actualiza los archivos de imagen
        formik.setFieldValue("images", [...formik.values.images, ...updatedFiles]);
        setImage((prevFiles) => {
            const newImages = [...prevFiles, ...updatedFiles];
            setCurrentImageIndex(newImages.length - 1);
            return newImages;
        });

    };

    const handleDeleteImageFromNewPortfolio = (index) => {
        const imagesCopy = [...images];
        imagesCopy.splice(index, 1);
        formik.setFieldValue("images", imagesCopy);
        setImage(imagesCopy);
        setCurrentImageIndex(0);
    };


    return (
        <div className={styles.infoGeneralContainer}>
            {images && images?.length > 0 ? (
                <Carousel
                    showThumbs={false}
                    showStatus={false}
                    onChange={(index) => setCurrentImageIndex(index)}
                    selectedItem={currentImageIndex}
                >
                    {images.map((image, index) => (
                        <div key={index} className={styles.infoGeneralContainer_customImg}>
                            <img
                                src={URL.createObjectURL(image)}
                                alt={`slide-${index}`}
                                className={styles.infoGeneralContainer_customImg_img}
                            />
                            <button onClick={() => handleDeleteImageFromNewPortfolio(index)} className={styles.deleteButton}>X</button>
                            <div className={styles.emptyContainer_withImage}>
                                <label htmlFor="images" className={`${styles.itemInfo_imageMask} ${styles.withImage}`}>
                                    <span className={`${styles.emptyContainer_text} ${styles.withImage}`}>Seleccionar archivos</span>
                                    <input
                                        type="file"
                                        name="images"
                                        id="images"
                                        onChange={handleImageChange}
                                        style={{ display: "none" }}
                                        accept="image/*"
                                        multiple
                                    />
                                </label>
                            </div>
                        </div>
                    ))}
                </Carousel>
            ) : (
                <div className={styles.emptyContainer}>
                    <ImagePlus size={48} style={{color: "rgb(156 163 175)", marginBottom: "1.6rem"}}/>
                    <span className={styles.emptyContainer_subText}>Agrega imágenes de tu producto o servicio</span>
                    <span className={styles.emptyContainer_fileType}>JPG, PNG hasta 5MB</span>
                    <label
                        htmlFor="images"
                        className={styles.itemInfo_imageMask}
                    >
                        <span className={styles.emptyContainer_text}>Seleccionar archivos</span>
                        <input
                            type="file"
                            name="images"
                            id="images"
                            onChange={handleImageChange}
                            style={{ display: "none" }}
                            accept="image/*"
                            multiple
                        />
                    </label>
                </div>
            )}
            <div className={styles.isPortfolio}>
                <div className={styles.itemInfo}>
                    <div className={styles.itemInfo_column}>
                        NOMBRE
                        <input
                            type="text"
                            id="name"
                            value={name}
                            className={styles.itemInfo_input}
                            placeholder="Por ejemplo: Instalación de A/A"
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className={styles.itemInfo_column}>
                        TIPO
                        <select
                            id="type"
                            value={type}
                            className={styles.itemInfo_input}
                            onChange={formik.handleChange}
                        >
                            <option value="" label="Producto/servicio" disabled/>
                            <option value="producto" label="Producto" />
                            <option value="servicio" label="Servicio" />
                        </select>
                    </div>
                </div>
                <div className={styles.itemInfo}>
                    <div className={styles.itemInfo_column}>
                        UBICACIÓN
                    <input
                        type="text"
                        id="zone"
                        value={zone}
                        className={styles.itemInfo_input}
                        placeholder="Pilar, Buenos Aires"
                        onChange={formik.handleChange}
                    />
                    </div>
                    <div className={styles.itemInfo_column}>
                        MONEDA
                    <select
                        id="currencyType"
                        value={currencyType}
                        className={styles.itemInfo_input}
                        onChange={formik.handleChange}
                    >
                        <option value="" label="ARS/USD/EUR" disabled />
                        <option value="ARS" label="ARS" />
                        <option value="USD" label="USD" />
                        <option value="EUR" label="EUR" />

                    </select>
                    </div>
                </div>
                <div className={styles.itemInfo}>
                <div className={styles.itemInfo_column}>
                        PRECIO
                    <select
                        id="priceUnit"
                        value={priceUnit}
                        className={styles.itemInfo_input}
                        onChange={formik.handleChange}
                    >
                        <option value="" label="Precio por" disabled/>
                        <option value="unidad" label="Unidad" />
                        <option value="m" label="Metro" />
                        <option value="m2" label="Metro cuadrado" />
                        <option value="cm3" label="Centimetro cúbico" />
                        <option value="hora" label="Hora" />
                        <option value="trabajo completo" label="Trabajo completo" />
                    </select>
                    </div>
                    <div className={styles.itemInfo_column}>
                        VALOR 
                    <input
                        type="number"
                        id="price"
                        value={price}
                        className={styles.itemInfo_input}
                        placeholder="Ej: 100"
                        onChange={formik.handleChange}
                    />
                    </div>
                </div>
                <div className={styles.itemInfo}>
                    <div className={styles.itemInfo_column}>
                        INICIO
                        <input
                            type="date"
                            name="startDate"
                            id="startDate"
                            className={`${styles.itemInfo_input}`}
                            value={startDate}
                            onChange={(e) => {
                                formik.handleChange(e);
                                setIsStartDateInput(!isStartDateInput);
                            }}
                        />
                    </div>
                    <div className={styles.itemInfo_column}>
                        FIN
                        <input
                            type="date"
                            name="endDate"
                            id="endDate"
                            className={`${styles.itemInfo_input}`}
                            value={endDate}
                            min={startDate}
                            onChange={(e) => {
                                formik.handleChange(e);
                                setIsEndDateInput(!isEndDateInput);
                            }}
                        />
                   </div>
                </div>
                <div className={styles.itemInfo}>
                    <div className={styles.itemInfo_column}>
                        DESCRIPCIÓN
                    <textarea
                        value={description}
                        id="description"
                        name="description"
                        className={`${styles.itemInfo_input} ${styles.itemInfo_area}`}
                        onChange={formik.handleChange}
                        placeholder="Ej: Instalación de 10 unidades de A/A en edificio de oficinas."
                    />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
    }

}

export default connect(mapStateToProps, {
    deleteImageFromPortfolio,
    openMainModal,
    closeMainModal
})(NuevoProductoModal);