import React from "react";
import styles from "./ErrorMenu.module.scss";
import { Info } from "lucide-react";

const ErrorMenu = ({ errors, setShowErrors, handleSectionChange }) => {
    // Mapeo de secciones
    const sectionMapping = {
        instagram: "Redes",
        linkedin: "Redes",
        url_site_web: "Redes",
        facebook: "Redes",
        name: "Datos personales",
        last_name: "Datos personales",
        razon_social: "Datos personales",
        userSkills: "Mis rubros",
    };

    // Mapeo de nombres amigables
    const friendlyNames = {
        instagram: "Instagram",
        linkedin: "LinkedIn",
        url_site_web: "Sitio web",
        facebook: "Facebook",
        name: "Nombre",
        last_name: "Apellido",
        razon_social: "Razón social",
        userSkills: "Rubros",
    };

    // Función para transformar los errores
    const transformErrors = (errors, sectionMapping, friendlyNames) => {
        return Object.keys(errors).map((key) => ({
            key: friendlyNames[key] || key, // Usa el nombre amigable o la clave original
            message: errors[key],
            sectionToChange: sectionMapping[key] || "Otros", // Asigna la sección
        }));
    };

    // Transforma los errores
    const transformedErrors = transformErrors(errors, sectionMapping, friendlyNames);

    // Agrupa los errores por sección
    const groupedErrors = transformedErrors.reduce((grouped, error) => {
        const section = error.sectionToChange;
        if (!grouped[section]) {
            grouped[section] = [];
        }
        grouped[section].push(error);
        return grouped;
    }, {});


    const handleClick = (section) => {
        handleSectionChange(section);
        setShowErrors(false);
    };



    return (
        <div className={styles.errorMenu}>
            <div className={styles.errorMenuHeader}>
                <Info size={16} />
                <h3 className={styles.errorMenuTitle}>Errores por sección</h3>
            </div>
            <div className={styles.errorSection}>
                {Object.keys(groupedErrors).map((section, index) => (
                    <div key={index} className={styles.errorSectionMenu} onClick={() => handleClick(section)}>
                        <h4 className={styles.errorSectionTitle}>{section}</h4>
                        <ul className={styles.errorSectionContent}>
                            {groupedErrors[section].map((error, errorIndex) => (
                                <li key={errorIndex} className={styles.errorItem}>
                                        <span className={styles.errorItemBorder}></span>
                                        <p className={styles.errorItemMessage}>
                                            {error.key}: {error.message}
                                        </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ErrorMenu;
