import React from "react";
import styles from "./PrivateQuotationItem.module.scss";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Cuboid } from "lucide-react";

const PrivateQuotationItem = ({ quotation }) => {

    const navigate = useNavigate();

    const handleRedirectToProject = () => {
        navigate(`/cotizaciondemateriales/${quotation.id}`);
    };

    // Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const options = { day: 'numeric', month: 'short' };
        return utcDate.toLocaleDateString('es-ES', options);
    };

    const zoneToShow = quotation?.zone ? quotation?.zone : quotation?.jurisdiccion?.name;

    const extractMaterialsCategories = () => {
        const categories = new Set();

        quotation?.subquotation[0]?.subQuotation_materials?.forEach(item => {
            const categoryName = item?.material?.category?.name;
            if (categoryName) {
                categories.add(categoryName);
            }
        });
        return Array.from(categories);
    };

    return (
        <div className={styles.container}>
            <div className={styles.container_header}>
                <div className={styles.container_header_leftSide}>
                    <div className={styles.container_header_leftSide_titleContainer}>
                        <h2 className={styles.container_header_title}>{quotation?.name}</h2>
                        <span className={`${styles.container_header_activeStatus} ${quotation?.active && styles.container_header_activeStatus_active}`}>
                            {quotation.active ? "En proceso" : "Vencida"}
                        </span>
                    </div>
                    {quotation?.description && <p className={styles.container_header_description}>{quotation?.description}</p>}
                </div>
                <div className={styles.container_header_rightSide}>
                    <div className={styles.container_header_rightSide_currency}>
                        <span className={styles.container_header_rightSide_currency_info}>Moneda:</span>
                        <span className={styles.container_header_rightSide_currency_value}>{quotation?.currency || "ARS"}</span>
                    </div>
                    <div className={styles.container_header_rightSide_jurisdiction}>
                        <span className={styles.container_header_rightSide_jurisdiction_value}>{zoneToShow}</span>
                    </div>
                </div>
            </div>
            <div className={styles.container_body}>
                <div className={styles.container_body_box}>
                    <div className={styles.container_body_box_title}>Categoría</div>
                    <div className={styles.container_body_box_categoryContainer}>
                        {extractMaterialsCategories().map((category, index) => (
                            <span key={index} className={styles.container_body_box_category}>
                                <Cuboid size={16} />
                                {category}</span>
                        ))}
                    </div>
                </div>
                <div className={styles.container_body_box}>
                    <div className={styles.container_body_box_title}>Cantidad</div>
                    <div className={styles.container_body_box_value}>{quotation?.subquotation[0]?.subQuotation_materials?.length || 0} Materiales</div>
                </div>
                <div className={styles.container_body_box}>
                    <div className={styles.container_body_box_title}>Fecha de inicio</div>
                    <div className={styles.container_body_box_value}>{formatDate(quotation?.startDate)}</div>
                </div>
                <div className={styles.container_body_box}>
                    <div className={styles.container_body_box_title}>Fecha de fin</div>
                    <div className={styles.container_body_box_value}>{formatDate(quotation?.endDate)}</div>
                </div>
            </div>
            <button className={styles.container_button} onClick={handleRedirectToProject}>
                <p>Ver detalles</p>
            </button>
        </div>
    );
};


export default connect(null)(PrivateQuotationItem);
