import React, { useEffect } from "react";
import styles from "./NotFound.module.scss";
import { ReactComponent as NotFoundIcon } from "../../assets/svg/error.svg";
import InitialWrapper from "../../components/Layout/InitialWrapper/InitialWrapper";
import Button from "../../components/UI/Forms/Button/Button";
import { Link } from "react-router-dom";


const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (

    <InitialWrapper >
      <div className={styles.container}>
        <h1 className={styles.title}>
          ¡Ups! <br />
        </h1>
        <span className={styles.subtitle}>
          Ocurrió un error.
        </span>
        <div className={styles.NotFound}>
          <NotFoundIcon />
          <h3 className={styles.NotFound_h3}>Lo sentimos, no encontramos lo que buscas</h3>
        </div>
        <Link to="/dashboard">

          <Button
            to="/dashboard"
            colored="white"
            typebtn="link"
            style={{ border: "none", padding: "20px 0 0 0" }}
            className={styles.hover}
          >
            <span>Volver a la página principal </span>
          </Button>
        </Link>
      </div>
    </InitialWrapper>
  );
}

export default (NotFound);
