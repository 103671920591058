import React, { useState, useEffect } from "react";
import styles from "./UserInfo.module.scss";
import { connect } from "react-redux";
import { setFile, openMainModal, closeMainModal, sendContactNotification, openCalificationModal, getVisits } from "../../../../store/actions";
import { openSendContactNotificationModal } from "../../../../store/actions";
import { setPreviousLocation } from "../../../../store/actions";
import { ReactComponent as WebIcon } from "../../../../assets/svg/webIcon.svg";
import { ReactComponent as StarFull } from "../../../../assets/svg/starFullSvg.svg";
import { ReactComponent as HalfStar } from "../../../../assets/svg/halfStarSvg.svg";
import { ReactComponent as StarEmpty } from "../../../../assets/svg/emptyStarSvg.svg";
import Tooltip from "../../../UI/ToolTip/ToolTip";
import usePremiumCheck from "../../../../hooks/usePremiumCheck";
import useUserImage from "../../../../hooks/useUserImage";
import { fireAnalytics } from "../../../../utils/firebase";
import { MessageCircle, Star, Mail, UserRoundPen, Eye } from "lucide-react";

const UserInfo = ({
  userData,
  profileVisualizerData,
  myPerson,
  setIsEditing,
  openSendContactNotificationModal,
  openCalificationModal,
  getVisits,
  profileVisits,
}) => {

  const data = myPerson ? userData : profileVisualizerData;

  const {
    username,
    type,
    razon_social,
    name,
    last_name,
    url_site_web,
    stars,
  } = data



  const [loading, setLoading] = useState(true);
  const [showToolTip, setShowToolTip] = useState({
    email: { show: false, text: "Enviar correo" },
    web: { show: false, text: "Visitar sitio web" },
  });

  const { imageError, initials, profileImage } = useUserImage(data);

  useEffect(() => {
    getVisits();
  }, []);

  const premiumCheck = usePremiumCheck();

  const handleTooltip = (tooltip) => {
    setShowToolTip((prevState) => ({
      ...prevState,
      [tooltip]: { ...prevState[tooltip], show: !prevState[tooltip].show },
    }));
  };

  let userNameInfo;
  if (type === "COMPANY") {
    userNameInfo = razon_social;
  } else if (name && last_name) {
    userNameInfo = `${name} ${last_name}`;
  }

  const formattedUrl = url_site_web ? (url_site_web.startsWith('http') ? url_site_web : `https://${url_site_web}`) : '#';

  useEffect(() => {
    setLoading(false);
  }, [userNameInfo]);

  const userStars = stars?.toFixed(1 - (stars % 1 === 0));

  let starsToShow = []
  const starsCount = userStars !== null ? Math.floor(userStars) : 0;

  for (let i = 0; i < 5; i++) {
    if (i < starsCount) {
      starsToShow.push(<StarFull key={i} width={15} height={15} style={{color: "rgb(250 204 21 )"}} />);
    } else if (i === starsCount && userStars % 1 !== 0) {
      starsToShow.push(<HalfStar key={i} width={15} height={15} />);
    } else {
      starsToShow.push(<StarEmpty key={i} width={15} height={15} />);
    }
  }

  const handleMail = (email) => {
    const isGmail = email?.includes("@gmail.com");
    const mailtoLink = isGmail ? `https://mail.google.com/mail/?view=cm&fs=1&to=${email}` : `mailto:${email}`;
    window.open(mailtoLink, "_blank");
  }

  const handleSendMesssage = () => {
    fireAnalytics("send_message_from_profile",
      { action: "open_send_message_modal", send_message_from_profile_user_receiver: data.id, send_message_from_profile_user_sender: userData.id });
    openSendContactNotificationModal(data);
  }


  return (
    <div className={styles.userInfoContainer}>
      <div className={styles.userInfoContainer_imageAndName}>
        <div className={styles.userInfoContainer_imageAndName_image}>
          {!imageError && profileImage ? (
            <img
              className={styles.userInfoContainer_imageAndName_image_img}
              src={profileImage}
              alt="user"
            />
          ) : (
            <div className={styles.initialsContainer}>
              {initials || 'N/A'}
            </div>
          )}
        </div>
        <div className={styles.userInfoContainer_data}>
          {userNameInfo &&
            <h2 className={styles.userInfoContainer_imageAndName_name}>{userNameInfo}</h2>
          }
          <div className={styles.userInfoContainer_stars}>
            {starsToShow}
          </div>
          <div className={styles.userInfoContainer_contact}>
          <div className={styles.userInfoContainer_buttonsContainer_webSite}
            onClick={() => handleMail(username)}
            onMouseEnter={() => handleTooltip("email")}
            onMouseLeave={() => handleTooltip("email")}  >
            <Mail size={16} />
            <span>Enviar email</span>
            {showToolTip.email.show && (
              <Tooltip text={showToolTip.email.text} fromHeaderNav />
            )}
          </div>
          {url_site_web && (
          <div className={styles.userInfoContainer_buttonsContainer_webSite}
            onMouseEnter={() => handleTooltip("web")}
            onMouseLeave={() => handleTooltip("web")}>
            <a href={formattedUrl} target="_blank" rel="noreferrer" className={styles.userInfoContainer_buttonsContainer_webSiteButton}>
              <WebIcon width={16} height={16} />
              <span>Sitio web</span>
            </a>
            {showToolTip.web.show && (
              <Tooltip text={showToolTip.web.text} fromHeaderNav />
            )}
          </div>
          )}
          </div>
        </div>
      </div>
      <div className={styles.userInfoContainer_buttonsContainer}>
        {myPerson && (
          <div className={styles.userInfoContainer_buttonsContainer_myProfile}>
            <div className={styles.userInfoContainer_buttonsContainer_visits}>
              <Eye size={20} />
              <span>{profileVisits.viewsCount} visitas</span>
            </div>
          <div className={styles.userInfoContainer_buttonsContainer_editProfile} onClick={() => setIsEditing(true)}>
            <p>Editar perfil</p>
            <UserRoundPen size={16} />
          </div>
          </div>
        )}
        {!myPerson && (
          <>
            <button className={styles.userInfoContainer_buttonsContainer_contact} onClick={premiumCheck(handleSendMesssage)}>
              <MessageCircle size={16} />
              <p>Enviar mensaje</p>
            </button>
            <button className={styles.userInfoContainer_buttonsContainer_rate} onClick={() => openCalificationModal(data)}>
              <Star size={16} />
              <p>Calificar</p>
            </button>
          </>
        )}
      </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    profileVisits: state.profile.profileVisits,
  };
};

export default connect(mapStateToProps, { 
    setFile, 
    openMainModal, 
    closeMainModal, 
    setPreviousLocation, 
    sendContactNotification, 
    openCalificationModal,
    getVisits,
    openSendContactNotificationModal })(UserInfo);
